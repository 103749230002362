// import { DecimalPipe } from '@angular/common';
import { ChartData, ChartOptions } from 'chart.js';
import * as pattern from 'patternomaly';

/**
 * @description Utils for chart (only frontend)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class OmedomChart
 */
export class OmedomChart {
    /**
     * @description Background color for chart
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly backgroundColor = '#eeeeee';

    /**
     * @description White light color for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly whiteLight = '#ffffff';

    /**
     * @description Red color for chart
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly red = '#FF5641';

    /**
     * @description Green color for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly green = '#00C29A';

    /**
     * @description Gold color for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly gold = '#FFBE00';

    /**
     * @description Blue color for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly blue = '#0076C8';

    /**
     * @description Grey color for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly grey = '#A8ADAF';

    /**
     * @description Colors for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly colors = [
        '#3491D2',
        '#2DC2A4',
        '#FF8273',
        '#FFD456',
        '#122F3A',
        OmedomChart.red,
        OmedomChart.gold,
        '#04151C',
        OmedomChart.blue,
        OmedomChart.green,
        '#0061A4',
        '#01856E',
        '#CD493A',
        '#DCA400',
        '#000000',
    ];

    /**
     * @description Desaturated colors for chart (for example for legend background color)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly desaturatedColors = [
        'hsl(205, 2%, 51%)',
        'hsl(168, 2%, 47%)',
        'hsl(6, 3%, 73%)',
        'hsl(45, 3%, 67%)',
        'hsl(197, 2%, 15%)',
        'hsl(7, 3%, 63%)',
        'hsl(45, 3%, 50%)',
        'hsl(198, 2%, 6%)',
        'hsl(205, 3%, 39%)',
        'hsl(168, 3%, 38%)',
        'hsl(205, 3%, 32%)',
        'hsl(170, 3%, 26%)',
        'hsl(6, 2%, 52%)',
        'hsl(45, 3%, 43%)',
        'hsl(0, 0%, 21%)',
    ];

    /**
     * @description Colors for chart by treasury type
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof OmedomChart
     */
    public static readonly colorByTreasury = {
        // Charges
        // TODO colorByTreasury must implement color of AllchargeCategories

        water: '#FFCC9D',
        electricity: '#FFD559',
        heater: '#F40027',
        insurance: '#FF5641',
        condominium: '#FFAA5B',
        taxes: '#BE0220',
        managementFees: '#FF536F',
        banking: '#FF8C21',
        workAndMaintains: '#D96800',
        furniture: '#77251B',
        internet: '#FFA499',
        variousCharge: '#DBA300',
        loanInsurance: '#FF5641',
        landlordInsurance: '#FF5641',
        houseInsurance: '#FF5641',
        rentGuaranteeInsurance: '#FF5641',
        legalInsurance: '#FF5641',
        common: '#DBA300',
        // Incomes
        aid: '#0076C8',
        rent: '#00C29A',
        regularization: '#95BF42',
        variousIncome: '#A95AFF',
        incomeSociety: '#A95AFF',
        // Default
        various: '#eff199',
    };

    /**
     * @description Colors for chart by treasury type with desaturated colors for legend background color
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @param {string} hexaColor
     * @param {'legend'} [usage]
     * @returns {*}  {(CanvasPattern | string)}
     * @memberof OmedomChart
     */
    public static hatchColor(hexaColor: string, usage?: 'legend'): CanvasPattern | string {
        // Set transparency
        const transparency = 20;

        // Create hexa opacity
        const hexaOpacity = Math.round(255 - transparency * 2.55).toString(16);

        // Create color
        const color = hexaColor + hexaOpacity;

        if (usage === 'legend') {
            // Create diagonal pattern with gradient
            const gradient = `linear-gradient(135deg, ${color} 0% 30%, ${
                hexaColor + '20'
            } 30% 40%, ${color} 40% 75%, ${hexaColor + '20'} 75% 85%, ${color} 85% 100%)`;
            return gradient;
        }
        return pattern.draw('diagonal-right-left', color);
    }
}

/**
 * @description Utils for dougnut chart
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class OmedomDoughnutChart
 */
export class OmedomDoughnutChart {
    /**
     * @description Doughnut chart data for chart.js (labels, datasets, backgroundColor, etc...)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @type {ChartData<'doughnut'>}
     * @memberof OmedomDoughnutChart
     */
    public static readonly doughnutChartData: ChartData<'doughnut'> = {
        labels: [],
        datasets: [{ data: [], backgroundColor: [] }],
    };

    /**
     * @description Doughnut chart options for chart.js (spacing, cutout, borderColor, etc...)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @type {ChartOptions<'doughnut'>}
     * @memberof OmedomDoughnutChart
     */
    public static readonly doughnutChartOptions: ChartOptions<'doughnut'> = {
        spacing: 10,
        cutout: '70%',
        borderColor: 'red',
        animation: {
            animateScale: false,
            delay: 400,
        },
        layout: {
            autoPadding: true,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'white',
                cornerRadius: 10,
                caretSize: 0,
                bodyColor: '#04151c',
                bodyAlign: 'center',
                bodyFont: {
                    family: 'Asap',
                    size: 16,
                    lineHeight: 1.1,
                    weight: '700',
                },
                borderColor: '#04151c',
                borderWidth: 1.5,
                displayColors: false,
                usePointStyle: true,
                padding: 10,
                callbacks: {
                    label: (context) => {
                        const labels: string[] = [];
                        const percent = context.parsed;
                        labels.push(context.label);
                        labels.push(`${percent}%`);
                        return labels;
                    },
                },
            },
        },
        elements: {
            arc: {
                borderWidth: 3,
                borderRadius: 20,
                hoverBorderWidth: 3,
                borderColor: OmedomChart.whiteLight,
            },
        },
    };
}

export class OmedomStackedBarChart {
    public static readonly stackedBarChartData: ChartData<'bar'> = {
        labels: [],
        datasets: [],
    };

    public static readonly stackedBarChartOptions: ChartOptions<'bar'> = {
        animation: {
            delay: 400,
        },

        layout: {
            autoPadding: true,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                titleColor: '#04151c',
                backgroundColor: 'white',
                cornerRadius: 10,
                caretSize: 0,
                bodyColor: '#04151c',
                bodyAlign: 'center',
                bodyFont: {
                    family: 'Asap',
                    size: 16,
                    lineHeight: 1.1,
                    weight: '700',
                },
                borderColor: '#04151c',
                borderWidth: 1.5,
                displayColors: false,
                usePointStyle: true,
                padding: 10,
                callbacks: {
                    label: (context) => {
                        const labels: string[] = [];
                        // const numberPipe = new DecimalPipe('fr-FR');
                        labels.push(context.dataset.label ?? '');
                        // const number = numberPipe.transform(context.parsed.y);
                        let total = 0;
                        if (context.parsed._stacks) {
                            const yAxis = context.parsed._stacks['y'];
                            const keysOfYAxis = Object.keys(yAxis)
                                .map((key) => {
                                    // verify if key is a number
                                    if (isNaN(parseInt(key))) {
                                        return null;
                                    }
                                    return parseInt(key);
                                })
                                .filter((key) => key !== null) as number[];
                            keysOfYAxis.forEach((key) => {
                                total += yAxis[key];
                            });
                        }
                        const percent = (context.parsed.y / total) * 100;

                        labels.push(`${context.parsed.y} € (${percent} %)`);
                        return labels;
                    },
                },
            },
        },
    };
}
