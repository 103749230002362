import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    BankAccountEntity,
    BankAccountType,
    BankEntity,
    BankItemEntity,
    BankTransactionEntity,
} from '@omedom/data';
import {
    BankAccountService,
    BankItemService,
    BankService,
    BankTransactionService,
    UserService,
} from '@omedom/services';
import { BankTransactionDissociationConfirmContainer } from '@omedom/ui';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { HeaderPreviousType } from '../../../component/header';
import { OmedomTab, OmedomTabType } from '../../../component/tab';

@Component({
    templateUrl: './bank-info.page.html',
    styleUrls: ['./bank-info.page.scss'],
    animations: [
        trigger('element', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(15px)' }),
                animate('0.25s', style({ opacity: 1, transform: 'translateY(0px)' })),
            ]),
        ]),
    ],
})
export class BankInfoPage implements OnDestroy {
    /**
     * @description Button to go back to the previous page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @memberof BankInfoPage
     */
    public previousType = HeaderPreviousType.previous;

    /**
     * @description Bank item to display in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @type {Observable<BankItemEntity>}
     * @memberof BankInfoPage
     */
    public item$: Observable<BankItemEntity>;

    /**
     * @description Bank entity to display in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @type {Observable<BankEntity>}
     * @memberof BankInfoPage
     */
    public bank$: Observable<BankEntity>;

    /**
     * @description Bank accounts to display in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @type {Observable<BankAccountEntity[]>}
     * @memberof BankInfoPage
     */
    public accounts$: Observable<BankAccountEntity[]>;

    /**
     * @description Selected bank account to display in the page by default when the page is loaded
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {Observable<BankAccountEntity>}
     * @memberof BankInfoPage
     */
    public selectedAccount$ = new BehaviorSubject<BankAccountEntity | undefined>(undefined);

    /**
     * @description Bank transactions to display in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @type {Observable<BankTransactionEntity[]>}
     * @memberof BankInfoPage
     */
    public transactions$: Observable<BankTransactionEntity[]> = this.selectedAccount$.pipe(
        switchMap((account) => {
            // Check if account is not undefined
            if (!account) {
                return of([]);
            }

            return this.getTransactions(account);
        })
    );

    /**
     * @description Title of the page to display in the header component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @memberof BankInfoPage
     */
    public title$ = new BehaviorSubject<string>('Chargement...');

    /**
     * @description Logo of the bank to display in the header component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @memberof BankInfoPage
     */
    public logo$ = new BehaviorSubject<string>('');

    /**
     * @description Tabs to display in the page to navigate between the different sections of the bank info page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {OmedomTab[]}
     * @memberof BankInfoPage
     */
    public tabs: OmedomTab[] = [
        {
            label: 'Historique',
            icon: 'uil-history-alt',
            id: 'history',
            type: OmedomTabType.white,
        },
        {
            label: 'Mouvements',
            icon: 'uil-layer-group',
            id: 'transactions',
            type: OmedomTabType.white,
        },
        {
            label: 'Masqués',
            icon: 'uil-eye-slash',
            id: 'hidden',
            type: OmedomTabType.white,
        },
    ];

    /**
     * @description Selected tab to display in the page by default when the page is loaded
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @type {OmedomTab}
     * @memberof BankInfoPage
     */
    public selectedTab: OmedomTab = this.tabs[1];

    /**
     * @description Subscriptions to unsubscribe when the component is destroyed to avoid memory leaks
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankInfoPage
     */
    private subscriptions: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private bankItemService: BankItemService,
        private bankAccountService: BankAccountService,
        private bankService: BankService,
        private bankTransactionService: BankTransactionService,
        private router: Router,
        private userService: UserService,
        private modalController: ModalController
    ) {
        const params$ = this.activatedRoute.params
            .pipe(
                map((params) => params.uid),
                switchMap((uid: string | undefined) => {
                    // Check if uid is not undefined
                    if (!uid) {
                        return;
                    }

                    return this.getItem(uid);
                }),
                switchMap((item: BankItemEntity | undefined) => {
                    // Check if item is not undefined
                    if (!item) {
                        return;
                    }

                    return combineLatest([this.getAccounts(item), this.getBank(item)]);
                }),
                tap(([accounts, bank]: [BankAccountEntity[], BankEntity]) => {
                    // Check if accounts and bank are not undefined
                    if (!accounts || !bank) {
                        return;
                    }

                    this.setTitle(bank);
                    this.setLogo(bank);
                })
            )
            .subscribe();

        const queryParams$ = this.activatedRoute.queryParams
            .pipe(
                switchMap((params) => {
                    // Check if tab query param is not undefined
                    if (params.tab) {
                        this.selectedTab =
                            this.tabs.find((tab) => tab.id === params.tab) || this.selectedTab;
                    }

                    // Check if account query param is not undefined
                    if (!params.account) {
                        return of(undefined);
                    }

                    return this.bankAccountService._get(params.account);
                }),
                tap((account) => {
                    // Check if account is not undefined
                    if (!account) {
                        return;
                    }

                    this.selectedAccount$.next(account);
                })
            )
            .subscribe();

        this.subscriptions.push(params$, queryParams$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * @description Get a bank item from its uid
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @private
     * @param {string} uid
     * @returns {Observable<BankItemEntity>}
     * @memberof BankInfoPage
     */
    private getItem(uid: string): Observable<BankItemEntity> {
        this.item$ = this.bankItemService._get(uid);
        //this.item$ = of();

        return this.item$;
    }

    /**
     * @description Get bank accounts from a bank item entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @private
     * @param {BankItemEntity} item
     * @returns {Observable<BankAccountEntity[]>}
     * @memberof BankInfoPage
     */
    private getAccounts(item: BankItemEntity): Observable<BankAccountEntity[]> {
        this.accounts$ = this.userService.user$.pipe(
            switchMap((user) => {
                // Check if user is not undefined
                if (!user) {
                    return of([]);
                }

                return this.bankAccountService._getBankAccountsFromItem(item, user.uid);
            }),
            map((accounts: BankAccountEntity[]) => accounts
                // Filter accounts to display only card and checking accounts
                .filter(account => account.type === BankAccountType.Card || account.type === BankAccountType.Checking)
                // Sort accounts by name
                // Checking first, then card
                .sort((a, b) => {
                    // Sort by type
                    // First Checking, then Card, then Savings,
                    // Then sort by name
                    const typeOrder = [
                        BankAccountType.Checking,
                        BankAccountType.Card,
                        BankAccountType.Savings,
                        BankAccountType.LifeInsurance,
                        BankAccountType.SharedSavingPlan,
                        BankAccountType.Brokerage,
                        BankAccountType.Loan,
                    ];

                    if (a.type === b.type) {
                        return a.name.localeCompare(b.name);
                    } else {
                        return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
                    }
                })
            )
        );

        return this.accounts$;
    }

    /**
     * @description Get bank entity from a bank item entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @private
     * @param {BankItemEntity} item
     * @returns {Observable<BankEntity>}
     * @memberof BankInfoPage
     */
    private getBank(item: BankItemEntity): Observable<BankEntity> {
        this.bank$ = this.bankService._getBankFromID(item.bankID);

        return this.bank$;
    }

    /**
     * @description Get bank transactions from a bank account entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @private
     * @param {BankAccountEntity} account
     * @returns {Observable<BankTransactionEntity[]>}
     * @memberof BankInfoPage
     */
    private getTransactions(account: BankAccountEntity): Observable<BankTransactionEntity[]> {
        this.transactions$ = this.userService.user$.pipe(
            switchMap((user) => {
                // Check if user is not undefined
                if (!user) {
                    return of([]);
                }

                return this.bankTransactionService._getBankTransactionsFromAccount(
                    account,
                    user.uid
                );
            })
        );

        return this.transactions$;
    }

    /**
     * @description Set the title of the page with the bank name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @private
     * @param {BankEntity} bank
     * @param {BankAccountEntity[]} accounts
     * @memberof BankInfoPage
     */
    private setTitle(bank: BankEntity): void {
        this.title$.next(bank.name);
    }

    /**
     * @description Set the logo of the page with the bank logo URL
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 25/04/2024
     * @private
     * @param {BankEntity} bank
     * @memberof BankInfoPage
     */
    private setLogo(bank: BankEntity): void {
        this.logo$.next(bank.logoUrl);
    }

    /**
     * @description Set the selected bank account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/04/2024
     * @param {BankAccountEntity} [account]
     * @memberof BankInfoPage
     */
    public setSelectedAccount(account?: BankAccountEntity): void {
        this.selectedAccount$.next(account);
        this.transactions$ = this.getTransactions(account);

        // Add the selected account in query params without removing the other query params
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                tab: this.selectedTab.id,
                account: account?.uid,
            },
        });
    }

    /**
     * @description Redirect to the association form of a transaction when the user wants to associate it with a treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/05/2024
     * @param {BankTransactionEntity} transaction
     * @returns {Promise<void>}
     * @memberof BankInfoPage
     */
    public async redirectToTransactionAssociationForm(
        transaction: BankTransactionEntity
    ): Promise<void> {
        await this.router.navigate(['tabs', 'bank', 'transaction', transaction.uid, 'association']);
    }

    /**
     * @description Dissociate a transaction from a treasury when the user wants to dissociate it from a treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 24/05/2024
     * @param {BankTransactionEntity} transaction
     * @memberof BankInfoPage
     */
    public async dissociateTransaction(transaction: BankTransactionEntity): Promise<void> {
        // Create a modal to confirm the dissociation of the transaction
        const modal = await this.modalController.create({
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            component: BankTransactionDissociationConfirmContainer,
            componentProps: {
                transaction,
            },
        });

        await modal.present();
    }
}
