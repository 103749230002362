import { Component, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import { AssetTypes, SelectOption } from '@omedom/data';

@Component({
    selector: 'app-property-filter-item',
    templateUrl: './property-filter-item.component.html',
    styleUrls: ['./property-filter-item.component.scss'],
})
export class PropertyFilterItemComponent implements OnDestroy {
    @Input() asset: SelectOption;

    @HostBinding('class.is-selected')
    get isSelected() {
        return this.asset.isSelected;
    }

    @ViewChild('popover')
    public popover: IonPopover;

    constructor() {}

    public ngOnDestroy(): void {
        this.popover.dismiss();
    }

    public getDefaultIcon() {
        switch (this.asset.assetType) {
            case AssetTypes.property: {
                return 'uil-home';
            }
            case AssetTypes.society: {
                return 'uil-suitcase';
            }
            case AssetTypes.building: {
                return 'uil-building';
            }
            default: {
                return 'uil-home';
            }
        }
    }
}
