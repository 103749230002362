import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'omedom-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopoverComponent implements OnInit, OnChanges {
    @Input() content: string | string[] | Iterable<string> = '';
    @Input() withMargin = true;
    @Input() iconTop = false;
    @Input() withIcon = true;
    @Input() icon = 'uil uil-info-circle';
    @Input() iconColor = '';

    popoverId!: string;
    contentType!: string;

    constructor(protected popoverController: PopoverController) {}

    async ngOnInit() {
        // close all other popover
        if (await this.popoverController.getTop()) {
            this.popoverController.dismiss();
        }

        // Id générée en mode random pour les cas où on a plusieurs popover sur la même page
        this.popoverId = Math.random().toString(36).substring(2);
        if (Array.isArray(this.content) && this.content.length === 1) {
            this.content = this.content[0] as string;
        }
        this.contentType = typeof this.content;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.contentType = typeof changes['content'].currentValue;
    }
}
