// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `omedom-select.ng-dirty.ng-invalid .content-container {
  color: #ff5641;
  border: 1.5px solid #ff5641;
  border-radius: 10px;
}
omedom-select.ng-dirty.ng-invalid .omedom-select-option-label {
  color: #ff5641;
}
omedom-select.ng-dirty.ng-invalid i {
  color: #ff5641;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLGNBQUE7RUFDQSwyQkFBQTtFQUNBLG1CQUFBO0FBQVI7QUFFSTtFQUNJLGNBQUE7QUFBUjtBQUVJO0VBQ0ksY0FBQTtBQUFSIiwiZmlsZSI6InNlbGVjdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIm9tZWRvbS1zZWxlY3QubmctZGlydHkubmctaW52YWxpZCB7XG4gICAgLmNvbnRlbnQtY29udGFpbmVyIHtcbiAgICAgICAgY29sb3I6ICNmZjU2NDE7XG4gICAgICAgIGJvcmRlcjogMS41cHggc29saWQgI2ZmNTY0MTtcbiAgICAgICAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgICB9XG4gICAgLm9tZWRvbS1zZWxlY3Qtb3B0aW9uLWxhYmVsIHtcbiAgICAgICAgY29sb3I6ICNmZjU2NDE7XG4gICAgfVxuICAgIGkge1xuICAgICAgICBjb2xvcjogI2ZmNTY0MTtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/select/select.component.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,2BAAA;EACA,mBAAA;AAAR;AAEI;EACI,cAAA;AAAR;AAEI;EACI,cAAA;AAAR;AACA,wrBAAwrB","sourcesContent":["omedom-select.ng-dirty.ng-invalid {\n    .content-container {\n        color: #ff5641;\n        border: 1.5px solid #ff5641;\n        border-radius: 10px;\n    }\n    .omedom-select-option-label {\n        color: #ff5641;\n    }\n    i {\n        color: #ff5641;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
