import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LicenceEntity, UserEntity } from '@omedom/data';
import { LicenceService, UserService } from '@omedom/services';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LicenceGuard {
    constructor(
        private userService: UserService,
        private router: Router,
        private licenceService: LicenceService
    ) {}

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            switchMap((user: UserEntity) => {
                if (user) {
                    return this.licenceService._getLicencesByUserEmail(user.email);
                } else {
                    of(undefined);
                }
            }),
            switchMap((licences: LicenceEntity[]) => {
                // For the moment, we manage use case where there is one licence
                if (this.needValidation(licences)) {
                    this.router.navigate(['/tunnel/licence-gift']);
                    return of(false);
                } else {
                    return of(true);
                }
            })
        );
    }

    private needValidation(licences: LicenceEntity[] | undefined) {
        if (licences === undefined || licences.length === 0) {
            return false;
        }
        const licence = licences[0];

        const needValidation = licence.validationPending && !licence.validatedByClient;
        return needValidation;
    }
}
