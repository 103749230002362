import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SavingEntity } from '@omedom/data';
import { OmedomChart } from '@omedom/utils';

/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable no-unused-vars */
/**
 * @description Layout of the saving synthesis container (default, mobile)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 01/07/2024
 * @export
 * @enum {number}
 */
export enum SavingSynthesisLayout {
    DEFAULT = 'default',
    MOBILE = 'mobile',
}

@Component({
    selector: 'omedom-saving-synthesis',
    templateUrl: './saving-synthesis.container.html',
    styleUrls: ['./saving-synthesis.container.scss'],
})
export class SavingSynthesisContainer implements OnInit {
    /**
     * @description Savings of the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @type {SavingEntity[]}
     * @memberof SavingSynthesisContainer
     */
    @Input({ required: true })
    public savings: SavingEntity[] = [];

    /**
     * @description Layout of the saving synthesis container
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @type {SavingSynthesisLayout}
     * @memberof SavingSynthesisContainer
     */
    @Input()
    public layout: SavingSynthesisLayout = SavingSynthesisLayout.DEFAULT;

    constructor(
        private readonly ref: ElementRef
    ) { }

    /**
     * @description Total withdraw amount of the user savings
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @returns {number}
     * @memberof SavingSynthesisContainer
     */
    public get totalWithdraw(): number {
        return this.savings.reduce((acc, saving) => acc + (saving.withdrawAmount || 0), 0);
    }

    /**
     * @description Total deposit amount of the user savings
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingSynthesisContainer
     */
    public get totalDeposit(): number {
        return this.savings.reduce((acc, saving) => acc + (saving.depositAmount || 0), 0);
    }

    /**
     * @description Average interest rate of the user savings (average of all interest rates of the savings accounts)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingSynthesisContainer
     */
    public get averageInterest(): number {
        // If there is no saving, we return 0
        if (this.savings.length === 0) {
            return 0;
        }

        // Check if there is a balance of 0
        if (this.totalBalance === 0) {
            return 0;
        }

        // Return the average interest rate
        // With a precision of 2
        // And apply a coefficient with balance of each saving
        return (
            this.savings.reduce((acc, saving) => acc + (saving.interestRate || 0) * (saving.balance || 0), 0) /
            this.totalBalance
        );
    }

    /**
     * @description Total balance of the user savings (sum of all balances of the savings accounts)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingSynthesisContainer
     */
    public get totalBalance(): number {
        return this.savings.reduce((acc, saving) => acc + (saving.balance || 0), 0);
    }

    ngOnInit(): void {
        // Add the layout class to ref
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);
    }

    /**
     * @description Percentage of balance of a saving
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @param {SavingEntity} saving
     * @returns {number}
     * @memberof SavingSynthesisContainer
     */
    public getPercentage(saving: SavingEntity): number {
        // If the saving is not defined, we return 0
        if (!saving) {
            return 0;
        }

        // If the balance is 0, we return 0
        if (saving.balance === 0) {
            return 0;
        }

        // Return the percentage of the balance
        return (saving.balance / this.totalBalance) * 100;
    }

    /**
     * @description Get a color for a saving based on his name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/07/2024
     * @param {SavingEntity} saving
     * @returns {string}
     * @memberof SavingSynthesisContainer
     */
    public getColor(saving: SavingEntity): string {
        // Get colors from OmedomChart
        const colors = OmedomChart.colors;

        // Get order in the savings list
        const order = this.savings.indexOf(saving);

        // Return the color based on the order
        return colors[order];
    }
}
