// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smart-option-container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.1);
  gap: 20px;
}

button {
  max-width: 800px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN1Z2dlc3Qtc21hcnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxXQUFBO0VBQ0EsZ0JBQUE7RUFDQSxZQUFBO0VBQ0EsbUJBQUE7RUFDQSxnQ0FBQTtFQUNBLDBCQUFBO0VBQ0EsU0FBQTtFQUNBLE9BQUE7RUFDQSxRQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsbUJBQUE7RUFDQSxhQUFBO0VBQ0EsNkNBQUE7RUFDQSxTQUFBO0FBQ0o7O0FBRUE7RUFDSSxnQkFBQTtBQUNKIiwiZmlsZSI6InN1Z2dlc3Qtc21hcnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc21hcnQtb3B0aW9uLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgbWF4LXdpZHRoOiA4MDBweDtcbiAgICBtYXJnaW46IGF1dG87XG4gICAgYmFja2dyb3VuZDogI2ZmZmZmZjtcbiAgICBib3JkZXItcmFkaXVzOiAxMHB4IDEwcHggMHB4IDBweDtcbiAgICBwb3NpdGlvbjogZml4ZWQgIWltcG9ydGFudDtcbiAgICBib3R0b206IDA7XG4gICAgbGVmdDogMDtcbiAgICByaWdodDogMDtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBwYWRkaW5nOiAyMHB4O1xuICAgIGJveC1zaGFkb3c6IDBweCAtMTBweCAxNXB4IHJnYmEoMCwgMCwgMCwgMC4xKTtcbiAgICBnYXA6IDIwcHg7XG59XG5cbmJ1dHRvbiB7XG4gICAgbWF4LXdpZHRoOiA4MDBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/component/suggest-smart/suggest-smart.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,YAAA;EACA,mBAAA;EACA,gCAAA;EACA,0BAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,6CAAA;EACA,SAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AACA,gjCAAgjC","sourcesContent":[".smart-option-container {\n    width: 100%;\n    max-width: 800px;\n    margin: auto;\n    background: #ffffff;\n    border-radius: 10px 10px 0px 0px;\n    position: fixed !important;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.1);\n    gap: 20px;\n}\n\nbutton {\n    max-width: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
