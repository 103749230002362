import { OmedomTabType } from './omedom-tab-type';

export class OmedomTab {
    id: any;
    label: string;
    icon: string;
    type: OmedomTabType;
    disabled?: boolean;

    constructor(init?: Partial<OmedomTab>) {
        Object.assign(this, init);
    }
}
