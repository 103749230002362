import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    @Input() avatar: string;

    @Input() title: string;

    @Input() image: string;

    constructor(
        private modalController: ModalController,
        private navController: NavController
    ) {}

    ngOnInit() {}

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    back() {
        this.dismiss();
        this.navController.back();
    }
}
