import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { SubscriptionEntity } from '@omedom/data';
import { SmartService, SubscriptionService } from '@omedom/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-subscription-slide-premium',
    templateUrl: './subscription-slide-premium.component.html',
    styleUrls: ['./subscription-slide-premium.component.scss'],
})
export class SubscriptionSlidePremiumComponent implements OnInit, OnDestroy {
    @Output() choose = new EventEmitter();

    @Input()
    public displayButton: boolean;

    @Input()
    public displayPrice: boolean;

    @Input()
    public hideHeader: boolean;

    public hasSmart: boolean;

    /**
     * @description Subscription of the user
     * @author Jérémie Lopez
     * @type {(SubscriptionEntity | undefined)}
     * @memberof SubscriptionSlidePremiumComponent
     */
    public subscription: SubscriptionEntity | undefined;

    private subscriptions: Subscription[] = [];

    /**
  * @description: Check if user buy a new subscription or only update his billing informations
  * @author Didier Pascarel
  * @memberof SubscriptionPage
  * @type {boolean}
  */
    public maySubscribeSmart: boolean;

    constructor(
        private smartService: SmartService,
    ) { }

    ngOnInit(): void {
        const smart$ = this.smartService.hasSmart$.subscribe(async (hasSmart: boolean) => {
            this.hasSmart = hasSmart;

            if (hasSmart) {
                this.subscription = this.smartService.subscription$.value;
                this.maySubscribeSmart = !this.subscription || !!this.subscription.trial;
            } else {
                this.maySubscribeSmart = true
            }

        });;

        this.subscriptions.push(smart$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }
}
