import {
    DateAndLocation,
    DateInterval,
    IncomeEntity,
    IncomePeriodicity,
    LeaseEntity,
    LessorInformations,
    PaiementDateAndReccurency,
    PostalAddress,
    PropertyEntity,
    Reccurency,
    RentAmounts,
    Story,
    TenantEntity,
    TenantInformations,
    UserEntity,
    RentReceiptAndRelaunchData,
    SocietyEntity,
    SocietyInformations,
    SocietyStatusMember,
    SocietyManagerInformations,
    IncomeHistory,
    ReceiptAndRelaunchPDFMissingData,
    RentReceiptAndRelaunchDataToVerify,
} from '@omedom/data';
import { OmedomPeriodicity } from './periodicity';
import { OmedomDate } from './date';

export class ReceiptAndRelaunchData {
    public static constructData(
        user: UserEntity,
        income: IncomeEntity,
        story: Story,
        lease: LeaseEntity,
        property: PropertyEntity,
        tenants: TenantEntity[],
        society?: SocietyEntity
    ): RentReceiptAndRelaunchData {
        const documentPeriod: DateInterval = this.getDocumentPeriod(income, story, lease);
        const documentDate: string = this.getDocumentDate(documentPeriod, income.periodicity);
        const societyInformations: SocietyInformations | undefined = society
            ? this.getSocietyInformations(society)
            : undefined;
        const lessorInformations: LessorInformations = this.getLessorInformations(
            user,
            societyInformations
        );
        const propertyAddress: PostalAddress = this.getPropertyAddress(property);
        const reccurency: Reccurency = Reccurency[income.periodicity];
        const rentAmounts: RentAmounts = this.getRentAmounts(income, story, documentPeriod);
        const tenantsInformations: TenantInformations[] = this.getTenantsInformations(tenants);
        const dateAndLocation: DateAndLocation = {
            date: new Date().toUTC(),
            location: lessorInformations.postalAddress.city ?? '',
        };
        const paiementDateAndReccurency: PaiementDateAndReccurency = {
            paiementDate: new Date(OmedomDate.timestampToMilliseconds(income.debitDate!)),
            reccurency,
        };
        const rentReceiptAndRelaunchData: RentReceiptAndRelaunchData = {
            documentDate,
            lessorInformations,
            tenantsInformations,
            propertyAddress,
            dateAndLocation,
            paiementDateAndReccurency,
            rentAmounts,
            documentPeriod,
        };

        if (societyInformations) {
            rentReceiptAndRelaunchData.societyInformations = societyInformations;
        }
        return rentReceiptAndRelaunchData;
    }

    public static checkMissingData(
        data: RentReceiptAndRelaunchDataToVerify
    ): ReceiptAndRelaunchPDFMissingData {
        const { user, property, tenants, society } = data;
        const missingData: ReceiptAndRelaunchPDFMissingData = {
            lessor: false,
            property: false,
            societyManager: false,
        };

        // Tenants
        missingData.tenant = !tenants || tenants.length < 1;

        // Property
        missingData.property =
            !property.address?.streetNumber ||
            !property.address?.street ||
            !property.address?.city ||
            !property.address?.postalCode;

        // Lessor may be a society or a user
        // // case 1 -  Lessor is a society
        if (society) {
            // check society  address
            const hasLessorStreetNumber = !!society.address.streetNumber;
            const hasLessorStreet = !!society.address.street;
            const hasLessorCity = !!society.address.city;
            const hasLessorPostalCode = !!society.address.postalCode;
            missingData.lessor =
                !hasLessorStreetNumber ||
                !hasLessorStreet ||
                !hasLessorCity ||
                !hasLessorPostalCode;
            // check society manager
            const hasSocietyManager = society.members.some(
                (member) => member.status === SocietyStatusMember.manager
            );
            missingData.societyManager = !hasSocietyManager;
        }

        // // case 2 -  Lessor is the user
        else {
            const hasLessorStreetNumber = !!user.address?.streetNumber;
            const hasLessorStreet = !!user.address?.street;
            const hasLessorCity = !!user.address?.city;
            missingData.lessor = !hasLessorStreetNumber || !hasLessorStreet || !hasLessorCity;
        }
        return missingData;
    }

    /**
     * @description Get the document date according to the periodicity of the income
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @param {DateInterval} dateInterval
     * @param {IncomePeriodicity} periodicity
     * @return {*}  {string}
     * @memberof ReceiptAndRelaunchData
     */
    private static getDocumentDate(
        dateInterval: DateInterval,
        periodicity: IncomePeriodicity
    ): string {
        const { startDate, endDate } = dateInterval;
        // Punctual returns the day of the debit date
        if (periodicity === IncomePeriodicity.punctual) {
            return (
                startDate.getUTCDate() +
                '  ' +
                startDate.getFrenchMonth() +
                '\n' +
                startDate.getFullYear()
            );
        }

        // Yearly returns the year period, if the start and end date are the same, only the year is returned otherwise the start and end year are returned
        if (periodicity === IncomePeriodicity.yearly) {
            if (startDate.getFullYear() === endDate.getFullYear()) {
                return startDate.getFullYear().toString();
            }
            return 'Année ' + startDate.getFullYear();
        }

        // Other periodicity returns the month and year of the start date
        return startDate.getFrenchMonth() + ' ' + startDate.getFullYear();
    }

    /**
     * @description Get the document period according to the periodicity of the income and the lease period
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @private static
     * @return {*}  {DateInterval}
     * @memberof ReceiptAndRelaunchData
     */
    private static getDocumentPeriod(
        income: IncomeEntity,
        story: Story,
        lease: LeaseEntity
    ): DateInterval {
        // in case of punctual periodicity, the period is the debit date
        if (income.periodicity === IncomePeriodicity.punctual) {
            return {
                startDate: new Date(OmedomDate.timestampToMilliseconds(income.debitDate!)),
                endDate: new Date(OmedomDate.timestampToMilliseconds(income.debitDate!)),
            };
        }
        const numberOfMonth = OmedomPeriodicity.getNumberOfMonthStep(income.periodicity);

        // get the start and end date of the full period of the story
        const firstDayOfTheFullPeriodStory = new Date(story.date).getFirstDayOfMonth();
        const lastDayOfTheFullPeriodStory = new Date(story.date)
            .addMonths(numberOfMonth - 1)
            .getLastDayOfMonth();
        // get the start and end date of the income

        const firstDayOfTheIncome = new Date(OmedomDate.timestampToMilliseconds(income.startDate));
        const lastDayOfTheIncome = income.endDate
            ? new Date(
                  new Date(OmedomDate.timestampToMilliseconds(income.endDate)).getFullYear(),
                  new Date(OmedomDate.timestampToMilliseconds(income.endDate)).getMonth(),
                  new Date(OmedomDate.timestampToMilliseconds(income.endDate)).getDate(),
                  23,
                  59,
                  59,
                  999
              )
            : new Date(lastDayOfTheFullPeriodStory);
        // get the start and end date of the lease
        const firstDayOfTheLease = new Date(lease.leaseStart);
        const lastDayOfTheLease = lease.leaseEnd
            ? new Date(
                  new Date(lease.leaseEnd).getFullYear(),
                  new Date(lease.leaseEnd).getMonth(),
                  new Date(lease.leaseEnd).getDate(),
                  23,
                  59,
                  59,
                  999
              )
            : new Date(lastDayOfTheFullPeriodStory);

        // check if the dates of income and lease are inside the full period range
        if (firstDayOfTheIncome.getTime() > lastDayOfTheFullPeriodStory.getTime()) {
            throw new Error(
                'income start greater than end: ' +
                    firstDayOfTheIncome +
                    ' > ' +
                    lastDayOfTheFullPeriodStory
            );
        }
        if (firstDayOfTheLease.getTime() > lastDayOfTheFullPeriodStory.getTime()) {
            throw new Error(
                'lease start greater than end: ' +
                    firstDayOfTheLease +
                    ' > ' +
                    lastDayOfTheFullPeriodStory
            );
        }
        if (lastDayOfTheIncome.getTime() < firstDayOfTheFullPeriodStory.getTime()) {
            throw new Error(
                'income last day is smaller than start: ' +
                    lastDayOfTheIncome +
                    ' < ' +
                    firstDayOfTheFullPeriodStory
            );
        }
        if (lastDayOfTheLease.getTime() < firstDayOfTheFullPeriodStory.getTime()) {
            throw new Error(
                'lease last day is smaller than start: ' +
                    lastDayOfTheLease +
                    ' < ' +
                    firstDayOfTheFullPeriodStory
            );
        }

        // get the max start date // TODO: explain why
        const startDates = [firstDayOfTheFullPeriodStory, firstDayOfTheIncome, firstDayOfTheLease];
        let startDate = new Date(
            Math.max.apply(
                null,
                startDates.map((date) => date.getTime())
            )
        );

        // get the min end date // TODO: explain why
        const endDates = [lastDayOfTheFullPeriodStory, lastDayOfTheIncome, lastDayOfTheLease];
        let endDate = new Date(
            Math.min.apply(
                null,
                endDates.map((date) => date.getTime())
            )
        );

        startDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            0,
            0,
            0,
            0
        );
        endDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate(),
            23,
            59,
            59,
            999
        );

        return { startDate, endDate };
    }

    /**
     * @description Get the tenants informations for the document
     * @param {TenantEntity[]} tenants
     * @return {*}  {TenantInformations[]}
     * @memberof ReceiptAndRelaunchData
     */
    private static getTenantsInformations(tenants: TenantEntity[]): TenantInformations[] {
        const tenantsInformations: TenantInformations[] = tenants.map((tenant) => {
            const { uid, email, firstname, lastname } = tenant;
            const tenantInformation: TenantInformations = {
                uid,
                lastname,
                firstname,
            };
            if (email) {
                tenantInformation.email = email;
            }
            return tenantInformation;
        });
        return tenantsInformations;
    }

    /**
     * @description Get the society informations for the document
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @private static
     * @return {*}  {SocietyInformations}
     * @memberof ReceiptAndRelaunchData
     */
    private static getSocietyInformations(society: SocietyEntity): SocietyInformations {
        const { name, address } = society;
        const societyManagerInformations = this.getSocietyManagerInformations(society);
        const societyInformations: SocietyInformations = {
            name,
            postalAddress: {
                street: address?.street ?? '',
                streetNumber: address?.streetNumber ?? 0,
                postalCode: address?.postalCode ?? '',
                city: address?.city ?? '',
            },
        };

        if (!!societyManagerInformations) {
            societyInformations.societyManagerInformations = societyManagerInformations;
        }

        return societyInformations;
    }

    /**
     * @description Get the society manager informations for the document
     * @param {SocietyEntity} society
     * @return {*}  {SocietyManagerInformations | undefined}
     * @memberof ReceiptAndRelaunchData
     */
    private static getSocietyManagerInformations(
        society: SocietyEntity
    ): SocietyManagerInformations | undefined {
        const societyManager = society.members.find(
            (member) => member.status === SocietyStatusMember.manager
        );
        if (!societyManager) {
            return undefined;
        }
        const societyManagerInformations = {
            email: societyManager.email,
            firstname: societyManager.firstname,
            lastname: societyManager.name,
        };

        return societyManagerInformations;
    }

    /**
     * @description Get the lessor informations for the document
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @private static
     * @return {*}  {LessorInformations}
     * @memberof ReceiptAndRelaunchData
     */
    private static getLessorInformations(
        user: UserEntity,
        societyInformations: SocietyInformations | undefined
    ): LessorInformations {
        let lessorInformations: LessorInformations;

        if (societyInformations) {
            const societyManagerEmail = societyInformations.societyManagerInformations?.email;

            lessorInformations = {
                name: societyInformations.name,
                postalAddress: societyInformations.postalAddress,
                email: societyManagerEmail ?? user.email!,
            };
        } else {
            lessorInformations = {
                name: user.firstname + ' ' + user.name,
                postalAddress: user.address!,
                email: user.email,
            };
        }

        return lessorInformations;
    }
    /**
     * @description Get the property informations for the document
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @private static
     * @return {*}  {PostalAddress}
     * @memberof ReceiptAndRelaunchData
     */
    private static getPropertyAddress(property: PropertyEntity): PostalAddress {
        const { address } = property;
        const propertyAddress = {
            street: address?.street ?? '',
            streetNumber: address?.streetNumber ?? 0,
            postalCode: address?.postalCode ?? '',
            city: address?.city ?? '',
        };
        return propertyAddress;
    }

    /**
     * @description Get the rent amounts for the document according to the document period and the periodicity of the income and the lease period
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @private static
     * @return {*}  {RentAmounts}
     * @memberof ReceiptAndRelaunchData
     */
    private static getRentAmounts(
        income: IncomeEntity,
        story: Story,
        documentPeriod: DateInterval
    ): RentAmounts {
        if (income.periodicity === IncomePeriodicity.punctual) {
            const rentAmount = income.rentWithCharges || income.amount;
            const totalAmount = income.amount;
            const chargesAmount = totalAmount - rentAmount;
            const rentAmounts = {
                rentAmount,
                chargesAmount,
                totalAmount: story.amount,
            };
            return rentAmounts;
        }
        // retreive fiscal period
        const documentFiscalPeriod = this.getDocumentFiscalPeriod(income, story);

        // Compare fiscal period with document period in days
        // - In case of difference between fiscal period and document period durations, calculate the amounts.
        // calcul:
        // amount / 30 * ( fiscal period duration - document period duration ) for monthly periodicity
        const documentPeriodNumbreOfDays = OmedomDate.numberOfDaysInAPeriod(documentPeriod);
        const fiscalPeriodNumbreOfDays = OmedomDate.numberOfDaysInAPeriod(documentFiscalPeriod);
        const fiscalNumberOfDays = OmedomPeriodicity.fiscaleNumberOfDays(income.periodicity);

        if (documentPeriodNumbreOfDays < fiscalPeriodNumbreOfDays) {
            const totalAmount = (income.amount / fiscalNumberOfDays) * documentPeriodNumbreOfDays;
            const rentAmount = income.rentWithCharges
                ? (income.rentWithCharges / fiscalNumberOfDays) * documentPeriodNumbreOfDays
                : totalAmount;
            const chargesAmount = totalAmount - rentAmount;
            const rentAmounts = {
                rentAmount,
                chargesAmount,
                totalAmount,
            };
            return rentAmounts;
        } else {
            // in case of equality between fiscal period and document period durations, return the amounts.
            const totalAmount = story.amount;
            const rentAmount = story.rentWithCharges || totalAmount;
            const chargesAmount = totalAmount - rentAmount;
            const rentAmounts = {
                rentAmount,
                chargesAmount,
                totalAmount,
            };

            return rentAmounts;
        }
    }

    /**
     * @description Get the actual history of the income for the date of the story
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 05/03/2024
     * @private
     * @static
     * @param {IncomeEntity} income
     * @param {Date} date
     * @returns {*}  {IncomeHistory}
     * @memberof ReceiptAndRelaunchData
     */
    private static getActualHistory(income: IncomeEntity, date: Date): IncomeHistory {
        const histories = income.history;
        const actualHistory = histories!.find((history) => {
            const historyDate = new Date(
                OmedomDate.timestampToMilliseconds(history.date)
            ).getTime();
            const storyDate = new Date(date).getTime();

            return historyDate === storyDate;
        });
        if (!actualHistory) {
            throw new Error('No history found for the date');
        }
        return actualHistory;
    }

    /**
     * @description Get the date periode of the rent paiement according to the periodicity of the income
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 07/02/2024
     * @private static
     * @return {*}  {DateInterval}
     * @memberof ReceiptAndRelaunchData
     */
    private static getDocumentFiscalPeriod(income: IncomeEntity, story: Story): DateInterval {
        if (income.periodicity === IncomePeriodicity.punctual) {
            return {
                startDate: new Date(OmedomDate.timestampToMilliseconds(income.debitDate!)),
                endDate: new Date(OmedomDate.timestampToMilliseconds(income.debitDate!)),
            };
        }
        const startDate = new Date(story.date).getFirstDayOfMonth();

        const numberOfMonth = OmedomPeriodicity.getNumberOfMonthStep(income.periodicity);
        const endDate = new Date(startDate.addMonths(numberOfMonth).subDays(1));
        const fiscalPeriod = {
            startDate,
            endDate,
        };

        return fiscalPeriod;
    }
}
