import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { SelectOption } from '@omedom/data';

import { OmedomRadioOption } from './omedom-radio-option';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
    ],
})
export class RadioComponent implements ControlValueAccessor, Validator {
    @Input() label: string;

    @Input() name: string;

    @Input() options: OmedomRadioOption[];

    @Input() required: boolean;

    @Input() popover: string;

    @Input() type: 'column' | 'row' = 'row';

    value: any;
    isDisabled: boolean;

    private onChangeCallback: any;

    validate(): null {
        return undefined;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void { }

    writeValue(newValue: SelectOption | SelectOption[]): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    valueChange(option: OmedomRadioOption) {
        this.writeValue(option.id);
        this.onChangeCallback(option.id);
    }
}
