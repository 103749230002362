import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-widget-rentability',
    templateUrl: './widget-rentability.component.html',
    styleUrls: ['./widget-rentability.component.scss'],
})
export class WidgetRentabilityComponent {
    @Input() value: number;

    @Input() type: 'rentability' | 'liquidity' | 'rental';

    @Input() isProperty: boolean;
}
