import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController, ToastOptions } from '@ionic/angular';
import {
    Story,
    UserEntity,
    PropertyEntity,
    SocietyEntity,
    TenantEntity,
    LessorInformations,
    LeaseEntity,
    ReceiptAndRelaunchPDFMissingData,
    SocietyMember,
    SocietyStatusMember,
} from '@omedom/data';
import {
    PropertyService,
    ShareService,
    SocietyService,
    TenantService,
    UserService,
} from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';

@Component({
    selector: 'app-receipt-relaunch-missing-fields',
    templateUrl: './receipt-relaunch-missing-fields.component.html',
    styleUrls: ['./receipt-relaunch-missing-fields.component.scss'],
})
export class ReceiptRelaunchMissingFieldsComponent implements OnInit {
    /**
     * @description context of usage
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {string}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    public context: string;

    /**
     * @description Partial datas for document
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    public missingData: ReceiptAndRelaunchPDFMissingData;

    /**
     * @description Source story
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Story}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    public story: Story;

    /**
     * @description Source lease for the story
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Lease}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    public lease: LeaseEntity;

    /**
     * @description lessor
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {UserEntity}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    private user: UserEntity;

    /**
     * @description Property
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {PropertyEntity}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    public property: PropertyEntity;

    /**
     * @description Society
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Partial<SocietyEntity>}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    public society: SocietyEntity;

    /**
     * @description  Modal controller
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {ModalController}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    @Input()
    private modalController: ModalController;

    /**
     * @description Tenants informations
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 09/04/2024
     * @type {TenantEntity[]}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    public tenants: TenantEntity[];

    /**
     * @description New tenant informations to be created if missingData.tenant is true
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 16/04/2024
     * @type {any}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    public currentTenant: any;

    /**
     * @description Lessor informations
     * @type {LessorInformations}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    public lessor: LessorInformations;

    public societyManager: SocietyMember;

    public postalCodeRegex: RegExp = OmedomRegex.postalCodeRegex;

    public emailRegex: string = OmedomRegex.emailRegex;
    public phoneRegex = OmedomRegex.phoneRegex;

    private toast: HTMLIonToastElement;

    /**
     * @description Data send back to the parent component when the modal is closed
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 16/04/2024
     * @private
     * @type {*}
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    private data: any;

    constructor(
        private societyService: SocietyService,
        private userService: UserService,
        private tenantService: TenantService,
        private propertyService: PropertyService,
        private toastController: ToastController,
        private shareService: ShareService
    ) {}

    ngOnInit(): void {
        this.getExistingdata();
    }

    /**
     * @description Get missing data expected for document edition
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 05/04/2024
     * @private
     * @memberof ReceiptRelaunchMissingFieldsComponent
     */
    private getExistingdata() {
        // Lessor informations
        if (this.society) {
            this.lessor = {
                name: this.society.name,
                postalAddress: {
                    street: this.society.address.street,
                    streetNumber: this.society.address.streetNumber,
                    city: this.society.address.city,
                    postalCode: this.society.address.postalCode,
                },
                email: this.user.email,
            };
        } else {
            this.lessor = {
                name: this.user.firstname + ' ' + this.user.name,
                postalAddress: {
                    street: this.user.address.street,
                    streetNumber: this.user.address.streetNumber,
                    city: this.user.address.city,
                    postalCode: this.user.address.postalCode,
                },
                email: this.user.email,
            };
        }

        // Tenant informations
        if (this.missingData.tenant) {
            this.currentTenant = {
                firstname: null,
                lastname: null,
                email: null,
            };
        }

        // Society manager informations
        if (this.missingData.societyManager) {
            this.societyManager = {
                firstname: null,
                name: null,
                email: null,
                tel: null,
                status: SocietyStatusMember.manager,
                isCreator: false,
            };
        }
    }

    public async continue() {
        // Lessor informations
        // and if the property is in a Society manager informations
        if (this.missingData.lessor) {
            // if lessor is a society
            if (this.society) {
                if (this.missingData.lessor) {
                    this.society.address = {
                        street: this.lessor.postalAddress.street,
                        streetNumber: this.lessor.postalAddress.streetNumber,
                        city: this.lessor.postalAddress.city,
                        postalCode: this.lessor.postalAddress.postalCode,
                    };
                }
                try {
                    await this.societyService.update(this.society);
                    this.data = { ...this.data, society: this.society };
                } catch (error) {
                    console.error(error);
                    const toastOptions: ToastOptions = {
                        message: 'Une erreur est survenue lors de la mise à jour de la société',
                        color: 'danger',
                    };
                    this.displayToast(toastOptions);
                }
            }
            // if lessor is a person
            else {
                this.user.address = {
                    ...this.user.address,
                    street: this.lessor.postalAddress.street,
                    streetNumber: this.lessor.postalAddress.streetNumber,
                    city: this.lessor.postalAddress.city,
                    postalCode: this.lessor.postalAddress.postalCode,
                };
                try {
                    await this.userService.update(this.user);
                    this.data = { ...this.data, user: this.user };
                } catch (error) {
                    const toastOptions: ToastOptions = {
                        message: 'Une erreur est survenue lors de la mise à jour de votre compte',
                        color: 'danger',
                    };
                    this.displayToast(toastOptions);
                }
            }
        }
        if (this.missingData.societyManager) {
            try {
                const sharingIndex = undefined; // sharingIndex is undefined to push the manager in the society
                await this.shareService.addSocietyMember(
                    this.society,
                    this.societyManager,
                    sharingIndex
                );
            } catch (error) {
                const toastOptions: ToastOptions = {
                    message: 'Une erreur est survenue lors de la mise à jour de votre compte',
                    color: 'danger',
                };
                this.displayToast(toastOptions);
            }
        }

        // Tenant informations
        if (this.missingData.tenant) {
            const tenant = {
                firstname: this.currentTenant.firstname,
                lastname: this.currentTenant.lastname,
                email: this.currentTenant.email,
                leaseUID: this.lease.uid,
                warrantor: {
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                },
            };

            try {
                const tenantDocument = await this.tenantService.create(tenant);
                const tenants = [(await tenantDocument.get()).data()];
                this.data = { ...this.data, tenants };
            } catch (error) {
                const toastOptions: ToastOptions = {
                    message: 'Une erreur est survenue lors de la création du locataire',
                    color: 'danger',
                };
                this.displayToast(toastOptions);
            }
        }

        // Property informations
        if (this.missingData.property) {
            this.property.address = {
                street: this.property.address.street,
                streetNumber: this.property.address?.streetNumber,
                city: this.property.address.city,
                postalCode: this.property.address.postalCode,
            };
            try {
                await this.propertyService.update(this.property);
                this.data = { ...this.data, property: this.property };
            } catch (error) {
                const toastOptions: ToastOptions = {
                    message: 'Une erreur est survenue lors de la mise à jour du bien',
                    color: 'danger',
                };
                this.displayToast(toastOptions);
            }
        }

        this.close();
    }

    private async displayToast(toastOptions: ToastOptions): Promise<void> {
        this.toast = await this.toastController.create({
            ...toastOptions,
            position: 'top',
            duration: 4000,
        });
        this.toast.present();
    }

    public async close(): Promise<void> {
        await this.modalController.dismiss(this.data);
    }
}
