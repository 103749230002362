import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-treasury-card',
    templateUrl: './treasury-card.component.html',
    styleUrls: ['./treasury-card.component.scss'],
})
export class TreasuryCardComponent implements OnInit {
    @Input() treasury: any;

    @Output() treasuryDeleted = new EventEmitter();

    @Input() showProperty: boolean = false;

    @Input() canDelete: boolean = false;

    @Input() backgroundColor: string;
    constructor() {}

    ngOnInit() {}
    removeTreasury() {
        this.treasuryDeleted.emit();
    }
}
