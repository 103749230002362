import { Component, Input } from '@angular/core';
import { SavingEntity } from '@omedom/data';

import { SavingCardLayout } from '../..';
import { elementAnimation } from '../../../../animations';

/* eslint-disable @angular-eslint/component-class-suffix */
@Component({
    selector: 'omedom-saving-confirmation-step',
    templateUrl: './saving-confirmation-step.container.html',
    styleUrls: ['./saving-confirmation-step.container.scss'],
    animations: [
        elementAnimation
    ],
})
export class SavingConfirmationStepContainer {
    /**
     * @description Saving entity input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {SavingEntity}
     * @memberof SavingConfirmationStepContainer
     */
    @Input({ required: true })
    public saving!: SavingEntity;

    /**
     * @description Layout of the saving card component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @memberof SavingConfirmationStepContainer
     */
    @Input()
    public layout = SavingCardLayout.MOBILE;
}
