import { Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-widget-rentability',
    templateUrl: './widget-rentability.component.html',
    styleUrls: ['./widget-rentability.component.scss'],
})
export class WidgetRentabilityComponent {
    @Input({ required: true }) value!: number;

    @Input({ required: true }) type!: 'rentability' | 'liquidity' | 'rental';

    @Input({ required: true }) isProperty!: boolean;
}
