import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { startDateTarification, SubscriptionEntity } from '@omedom/data';
import { SmartService } from '@omedom/services';
import { SubscriptionConfirmCancellationComponent } from '@omedom/ui';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-subscription-slide-essential',
    templateUrl: './subscription-slide-essential.component.html',
    styleUrls: ['./subscription-slide-essential.component.scss'],
})
export class SubscriptionSlideEssentialComponent implements OnInit, OnDestroy {
    @Input()
    public displayButton: boolean;

    @Input()
    public displayPrice: boolean;

    @Input()
    public hideHeader: boolean;

    public hasSmart: boolean;

    public subscription: SubscriptionEntity;
    private subscriptions: Subscription[] = [];
    protected isBeforeTransitionDate = false;

    constructor(
        private smartService: SmartService,
        private navController: NavController,
        private modalContoller: ModalController
    ) {}

    ngOnInit(): void {
        const today = new Date();
        const transitionDate = startDateTarification;
        this.isBeforeTransitionDate = today < transitionDate;
        const smart$ = this.smartService.hasSmart$.subscribe(async (hasSmart: boolean) => {
            this.hasSmart = hasSmart;
        });

        const subscription$ = this.smartService.subscription$.subscribe(
            (subscription: SubscriptionEntity) => {
                this.subscription = subscription;
            }
        );

        this.subscriptions.push(smart$, subscription$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public async chooseSubscription(): Promise<void> {
        if (this.hasSmart && !this.subscription?.cancelSubscription) {
            const modal = await this.modalContoller.create({
                component: SubscriptionConfirmCancellationComponent,
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                componentProps: {
                    subscription: this.subscription,
                },
            });

            await modal.present();
        } else {
            this.navController.pop();
        }
    }
}
