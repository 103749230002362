import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IncomeCategoryProperty, LeaseEntity, SelectOption, TenantEntity } from '@omedom/data';
import { IncomeService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject, of } from 'rxjs';

@Component({
    selector: 'app-tenant-form',
    templateUrl: './tenant-form-modal.component.html',
    styleUrls: ['./tenant-form-modal.component.scss'],
})
export class TenantFormModalComponent implements OnInit {
    @Input() lease: Partial<LeaseEntity>;
    @Input() tenant: Partial<TenantEntity>;

    public emailRegex: string = OmedomRegex.emailRegex;
    public phoneRegex = OmedomRegex.phoneRegex;
    public aid = [];

    /**
     * @description Display warrantor form after collapse
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public displayGarant = new BehaviorSubject<boolean>(false);
    /**
     * @description Display aid info after collapse
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public displayaid = new BehaviorSubject<boolean>(false);

    /**
     * @description Placeholder of select component
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public aidPlaceholder = {
        id: null,
        label: 'Choisir dans ma liste des aides',
    } as SelectOption;

    public selectedAid: SelectOption;
    /**
     * @description List type of aid incomes in the select component
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public aidOptions$ = of([]);
    edit: boolean;
    afterAddAid: boolean;

    constructor(
        private incomeService: IncomeService,
        private modalController: ModalController,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        this.aid = await this.incomeService.getIncomesByCategoryByPropertyIds(
            IncomeCategoryProperty.aid,
            [this.lease.propertyUID]
        );

        this.aidOptions$ = of(
            this.aid.map(
                (income) =>
                    ({
                        id: income.uid,
                        label: `Aide ${income.designation ? '(' + income.designation + ')' : ''} ${
                            income.amount
                        }€`,
                        icon: 'uil uil-euro-circle',
                    } as SelectOption)
            )
        );
        if (!this.tenant.uid) {
            if (this.lease.uid) {
                this.tenant.leaseUID = this.lease.uid;
            }
        } else {
            this.aidOptions$.subscribe(
                (aid) => (this.selectedAid = aid.find((x) => x.id === this.tenant.helpUID))
            );
        }
    }

    public async saveTenant(): Promise<void> {
        localStorage.clear();
        if (this.tenant.helpUID || this.selectedAid) {
            this.tenant.helpUID = this.selectedAid ? this.selectedAid.id : null;
        }
        await this.modalController.dismiss('saved');
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss('cancelled');
    }

    public async addAid(): Promise<void> {
        await this.modalController.dismiss('aid');
        const navigationExtras: NavigationExtras = {
            queryParams: {
                income: IncomeCategoryProperty.aid,
            },
            state: { back: true },
        };

        await this.router.navigate(
            [`/tabs/property/info/${this.lease.propertyUID}/income/form`],
            navigationExtras
        );
    }
}
