import { AssetTypes } from '../enums';
import { PostalAddress } from '../interfaces';
import { SelectOption } from '../models';
import { Sharing } from './property.entity';
import { RestEntity } from './rest.entity';

export interface SocietyMember {
    name: string;
    firstname: string;
    email: string;
    tel: string;
    status: SocietyStatusMember;
    isCreator: boolean;
    role?: SocietyRoleMember;
}

export enum SocietyStatusMember {
    associated = 'Associé.e',
    manager = 'Gérant.e',
}

export enum SocietyRoleMember {
    admin = 'Administrateur',
    editor = 'Editeur',
    reader = 'Lecture seule',
}

export enum SocietyType {
    sci = 'SCI',
    sas = 'SAS',
    sarl = 'SARL',
    familySarl = 'SARL de famille',
}

export class SocietyEntity extends RestEntity {
    /**
     * @description Name of the society
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SocietyEntity
     */
    public name!: string;

    /**
     * @description Type of the society
     * @author Jérémie Lopez
     * @type {SocietyType}
     * @memberof SocietyEntity
     */
    public type!: SocietyType;

    /**
     * @description Avatar URL of the society
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SocietyEntity
     */
    public photo?: string;

    /**
     * @description Address of the society
     * @author Jérémie Lopez
     * @type {PostalAddress}
     * @memberof SocietyEntity
     */
    public address!: PostalAddress;

    /**
     * @description Owner's User UID of the society
     * @author Jérémie Lopez
     * @type {string}
     * @memberof SocietyEntity
     */
    public userUID!: string;

    /**
     * @description If true, the user owner is the manager of the society
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof SocietyEntity
     */
    // public isUserManager?: boolean;

    /**
     * @description Members of the society
     * @author Jérémie Lopez
     * @type {SocietyMember[]}
     * @memberof SocietyEntity
     */
    public members!: SocietyMember[];

    /**
     * @description List of user that the society is sharing
     * @author Jérémie Lopez
     * @type {string[]}
     * @memberof SocietyEntity
     */
    public sharing!: Sharing[];

    /**
     * @description Income of the manager
     * @author Jérémie Lopez
     * @type {number}
     * @memberof SocietyEntity
     */
    public managerIncome!: number;

    /**
     * @description Year of society creation
     * @author ANDRE Felix
     * @type {number}
     * @memberof SocietyEntity
     */
    public creationYear?: number;

    /**
     * @description If true, the society is shared to the pro of the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @type {boolean}
     * @memberof SocietyEntity
     */
    public sharedToPro?: boolean;

    /**
     * @description Transformed the entity to a select option for the UI
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @returns {SelectOption}
     * @memberof SocietyEntity
     */
    public toSelectOption?(): SelectOption {
        return {
            id: this.uid,
            label: this.name,
            image: this.photo,
            icon: this.photo ? undefined : 'uil uil-suitcase',
            assetType: AssetTypes.society,
        };
    }
}
