import {
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PopoverComponent implements OnInit, OnChanges {
    @Input() content: string | string[];
    @Input() withMargin = true;
    @Input() iconTop = false;
    @Input() withIcon = true;
    @Input() icon = 'uil uil-info-circle';
    @Input() iconColor = '';

    popoverId: string;
    contentType: string;

    ngOnInit() {
        // Id générée en mode random pour les cas où on a plusieurs popover sur la même page
        this.popoverId = Math.random().toString(36).substring(2);
        if (typeof this.contentType != 'string' && this.content.length === 1) {
            this.content = this.content[0];
        }
        this.contentType = typeof this.content;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.contentType = typeof changes.content.currentValue;
    }
}
