import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { OmedomChartLegend } from './omedom-chart-legend';

@Component({
    selector: 'app-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChartLegendComponent implements OnInit {
    @Output() legendClicked = new EventEmitter<OmedomChartLegend>();

    @Input() legends: OmedomChartLegend[] = [];

    @Input() disablePercentage: boolean;

    @Input() forecastAuthorised: false;

    @Input() isLegendClickable?: boolean;

    ngOnInit() {
        this.isLegendClickable = this.isLegendClickable || false;
    }
}
