import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiModule } from '@omedom/ui';

import { OmedomCommonModule } from '../common';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { BankRoutingModule } from './bank-routing.module';
import {
    BankFormPage,
    BankHomePage,
    BankInfoPage,
    BankTransactionAssociationFormPage,
} from './pages';

@NgModule({
    declarations: [BankHomePage, BankFormPage, BankInfoPage, BankTransactionAssociationFormPage],
    imports: [
        CommonModule,
        IonicModule,
        UiModule,
        OmedomComponentModule,
        OmedomCommonModule,
        BankRoutingModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class BankModule {}
