// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charges-container {
  border-left: 2px solid #eeeeee;
}

.omedom-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: auto;
  width: 100%;
}
.omedom-page-container .omedom-page-container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXJnZS1saXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksOEJBQUE7QUFDSjs7QUFDQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLGdCQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7QUFFSjtBQUFJO0VBQ0ksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsbUJBQUE7QUFFUiIsImZpbGUiOiJjaGFyZ2UtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jaGFyZ2VzLWNvbnRhaW5lciB7XG4gICAgYm9yZGVyLWxlZnQ6IDJweCBzb2xpZCAjZWVlZWVlO1xufVxuLm9tZWRvbS1wYWdlLWNvbnRhaW5lciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIG1pbi1oZWlnaHQ6IDEwMCU7XG4gICAgbWFyZ2luOiBhdXRvO1xuICAgIHdpZHRoOiAxMDAlO1xuXG4gICAgLm9tZWRvbS1wYWdlLWNvbnRhaW5lci1jb250ZW50IHtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/charge-list/charge-list.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;;AACA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AAEJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AAER;AACA,g1BAAg1B","sourcesContent":[".charges-container {\n    border-left: 2px solid #eeeeee;\n}\n.omedom-page-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100%;\n    margin: auto;\n    width: 100%;\n\n    .omedom-page-container-content {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
