import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'omedom-directory-trusted-pro-list-component',
    templateUrl: './directory-trusted-pro-list.component.html',
    styleUrls: ['./directory-trusted-pro-list.component.scss'],
})
export class DirectoryTrustedProListComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
