import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatEntity, UserEntity } from '@omedom/data';
import { ChatMessage } from '../chat-message-card/chat-message-card.component';

export interface Chat {
    chatEntity: ChatEntity;
    lastMessage?: ChatMessage;
    users: (UserEntity | Partial<UserEntity>)[];
}

@Component({
    selector: 'omedom-chat-card',
    templateUrl: './chat-card.component.html',
    styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent {
    @Input({ required: true }) chat!: Chat;

    @Input() user?: UserEntity;

    @Input() isSelected: boolean = false;

    @Output() chatSelected = new EventEmitter<Chat>();

    get users(): string {
        return (
            this.chat.users
                .filter((user) => user.uid !== this.user?.uid)
                .map((user) => user.firstname + ' ' + user.name)
                .concat('Vous')
                .join(', ') || ''
        );
    }

    constructor() {}
}
