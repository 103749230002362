import * as dateFns from 'date-fns';
declare global {
    interface Date {
        getNumberOfDaysInMonth(): number;

        getFirstDayOfMonth(): Date;

        getLastDayOfMonth(): Date;

        between(startDate: Date, endDate: Date): boolean;

        getDateWithoutTime(): Date;

        toUTC(): Date;

        addDays(nbDays: number): Date;

        subDays(nbDays: number): Date;

        addMonths(nbMonths: number): Date;

        subMonths(nbMonths: number): Date;

        addUTCMonths(nbMonths: number): Date;

        subUTCMonths(nbMonths: number): Date;

        addDays(nbDays: number): Date;

        subDays(nbDays: number): Date;

        addUTCDays(nbDays: number): Date;

        subUTCDays(nbDays: number): Date;

        getUTCDateWithoutTime(): Date;

        getUTCFirstDayOfMonth(): Date;

        getUTCLastDayOfMonth(): Date;

        getUTCDiffMonth(dateTo: Date): number;

        toInputString(): string;

        getFrenchFormatLong(): string;

        getFrenchMonth(): string;

        isSameDay(date: Date): boolean;
    }
}

Date.prototype.getNumberOfDaysInMonth = function (): number {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
};

Date.prototype.getFirstDayOfMonth = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), 1);
};

Date.prototype.getLastDayOfMonth = function (): Date {
    return new Date(this.getFullYear(), this.getMonth() + 1, 0, 23, 59, 59);
};

Date.prototype.between = function (startDate: Date, endDate: Date): boolean {
    return this.getTime() >= startDate.getTime() && this.getTime() <= endDate.getTime();
};

Date.prototype.addDays = function (nbDays: number): Date {
    // For nbDays > 0 we add the number of days
    // For nbDays < 0 we substract the number of days
    switch (true) {
        case nbDays > 0:
            return dateFns.addDays(this, nbDays);
        case nbDays < 0:
            return dateFns.subDays(this, Math.abs(nbDays));
        case nbDays === 0:
        default:
            return this;
    }
}

Date.prototype.subDays = function (nbDays: number): Date {
    // For nbDays > 0 we substract the number of days
    // For nbDays < 0 we add the number of days
    switch (true) {
        case nbDays > 0:
            return dateFns.subDays(this, nbDays);
        case nbDays < 0:
            return dateFns.addDays(this, Math.abs(nbDays));
        case nbDays === 0:
        default:
            return this;
    }
}
    

Date.prototype.addMonths = function (nbMonths: number): Date {
    // For nbMonth < 0 we substract the number of month
    // For nbMonth >  0 we add the number of month
    // For nbMonth = 0 we return the same date
    switch (true) {
        case nbMonths > 0:
            return dateFns.addMonths(this, nbMonths);
        case nbMonths < 0:
            return dateFns.subMonths(this, Math.abs(nbMonths));
        case nbMonths === 0:
        default:
            return this;
    }
};

Date.prototype.subMonths = function (nbMonths: number): Date {
    // For nbMonth > 0 we substract the number of month
    // For nbMonth < 0 we add the number of month
    // For nbMonth = 0 we return the same date
    switch (true) {
        case nbMonths > 0:
            return dateFns.subMonths(this, nbMonths);
        case nbMonths < 0:
            return dateFns.addMonths(this, Math.abs(nbMonths));
        case nbMonths === 0:
        default:
            return this;
    }
};
Date.prototype.addDays = function (nbDays: number): Date {
    // For nbDay < 0 we substract the number of day
    // For nbDay >  0 we add the number of day
    // For nbDay = 0 we return the same date
    switch (true) {
        case nbDays > 0:
            return dateFns.addDays(this, nbDays);
        case nbDays < 0:
            return dateFns.subDays(this, Math.abs(nbDays));
        case nbDays === 0:
        default:
            return this;
    }
};

Date.prototype.subDays = function (nbDays: number): Date {
    // For nbDay > 0 we substract the number of day
    // For nbDay < 0 we add the number of day
    // For nbDay = 0 we return the same date
    switch (true) {
        case nbDays > 0:
            return dateFns.subDays(this, nbDays);
        case nbDays < 0:
            return dateFns.addDays(this, Math.abs(nbDays));
        case nbDays === 0:
        default:
            return this;
    }
};

Date.prototype.addUTCMonths = function (nbMonths: number): Date {
    // hours difference seasonal time change: fix for the bug of the date-fns library
    const beforeTimezoneOffset = this.getTimezoneOffset();
    const afterTimezoneOffset = dateFns.addMonths(this.toUTC(), nbMonths).getTimezoneOffset();

    // - calculate the difference between the two time zones,
    const differenceTimezoneMinutes = beforeTimezoneOffset - afterTimezoneOffset;

    if (nbMonths > 0) {
        const utc = Date.UTC(
            dateFns.addMonths(this, nbMonths).getUTCFullYear(),
            dateFns.addMonths(this, nbMonths).getUTCMonth(),
            dateFns.addMonths(this, nbMonths).getUTCDate(),
            dateFns.addMonths(this, nbMonths).getUTCHours(),
            dateFns.addMonths(this, nbMonths).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.addMonths(this, nbMonths).getUTCSeconds(),
            dateFns.addMonths(this, nbMonths).getUTCMilliseconds()
        );
        return new Date(utc);
    } else if (nbMonths < 0) {
        const utc = Date.UTC(
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCFullYear(),
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCMonth(),
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCDate(),
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCHours(),
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCSeconds(),
            dateFns.subMonths(this, Math.abs(nbMonths)).getUTCMilliseconds()
        );
        return new Date(utc);
    } else {
        return this.toUTC();
    }
};

Date.prototype.subUTCMonths = function (nbMonths: number): Date {
    // hours difference seasonal time change: fix for the bug of the date-fns library
    const beforeTimezoneOffset = this.getTimezoneOffset();
    const afterTimezoneOffset = dateFns.subMonths(this.toUTC(), nbMonths).getTimezoneOffset();

    // - calculate the difference between the two time zones, the number of minutes if the number of hours is not an integer
    const differenceTimezoneMinutes = beforeTimezoneOffset - afterTimezoneOffset;

    if (nbMonths > 0) {
        const utc = Date.UTC(
            dateFns.subMonths(this, nbMonths).getUTCFullYear(),
            dateFns.subMonths(this, nbMonths).getUTCMonth(),
            dateFns.subMonths(this, nbMonths).getUTCDate(),
            dateFns.subMonths(this, nbMonths).getUTCHours(),
            dateFns.subMonths(this, nbMonths).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.subMonths(this, nbMonths).getUTCSeconds(),
            dateFns.subMonths(this, nbMonths).getUTCMilliseconds()
        );
        return new Date(utc);
    } else if (nbMonths < 0) {
        const utc = Date.UTC(
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCFullYear(),
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCMonth(),
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCDate(),
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCHours(),
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCSeconds(),
            dateFns.addMonths(this, Math.abs(nbMonths)).getUTCMilliseconds()
        );
        return new Date(utc);
    } else {
        return this.toUTC();
    }
};

Date.prototype.addUTCDays = function (nbDays: number): Date {
    // hours difference seasonal time change: fix for the bug of the date-fns library
    const beforeTimezoneOffset = this.getTimezoneOffset();
    const afterTimezoneOffset = dateFns.addDays(this.toUTC(), nbDays).getTimezoneOffset();

    // - calculate the difference between the two time zones,
    const differenceTimezoneMinutes = beforeTimezoneOffset - afterTimezoneOffset;

    if (nbDays > 0) {
        const utc = Date.UTC(
            dateFns.addDays(this, nbDays).getUTCFullYear(),
            dateFns.addDays(this, nbDays).getUTCMonth(),
            dateFns.addDays(this, nbDays).getUTCDate(),
            dateFns.addDays(this, nbDays).getUTCHours(),
            dateFns.addDays(this, nbDays).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.addDays(this, nbDays).getUTCSeconds(),
            dateFns.addDays(this, nbDays).getUTCMilliseconds()
        );
        return new Date(utc);
    } else if (nbDays < 0) {
        const utc = Date.UTC(
            dateFns.subDays(this, Math.abs(nbDays)).getUTCFullYear(),
            dateFns.subDays(this, Math.abs(nbDays)).getUTCMonth(),
            dateFns.subDays(this, Math.abs(nbDays)).getUTCDate(),
            dateFns.subDays(this, Math.abs(nbDays)).getUTCHours(),
            dateFns.subDays(this, Math.abs(nbDays)).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.subDays(this, Math.abs(nbDays)).getUTCSeconds(),
            dateFns.subDays(this, Math.abs(nbDays)).getUTCMilliseconds()
        );
        return new Date(utc);
    } else {
        return this.toUTC();
    }
};

Date.prototype.subUTCDays = function (nbDays: number): Date {
    // hours difference seasonal time change: fix for the bug of the date-fns library
    const beforeTimezoneOffset = this.getTimezoneOffset();
    const afterTimezoneOffset = dateFns.subDays(this.toUTC(), nbDays).getTimezoneOffset();

    // - calculate the difference between the two time zones, the number of minutes if the number of hours is not an integer
    const differenceTimezoneMinutes = beforeTimezoneOffset - afterTimezoneOffset;

    if (nbDays > 0) {
        const utc = Date.UTC(
            dateFns.subDays(this, nbDays).getUTCFullYear(),
            dateFns.subDays(this, nbDays).getUTCMonth(),
            dateFns.subDays(this, nbDays).getUTCDate(),
            dateFns.subDays(this, nbDays).getUTCHours(),
            dateFns.subDays(this, nbDays).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.subDays(this, nbDays).getUTCSeconds(),
            dateFns.subDays(this, nbDays).getUTCMilliseconds()
        );
        return new Date(utc);
    } else if (nbDays < 0) {
        const utc = Date.UTC(
            dateFns.addDays(this, Math.abs(nbDays)).getUTCFullYear(),
            dateFns.addDays(this, Math.abs(nbDays)).getUTCMonth(),
            dateFns.addDays(this, Math.abs(nbDays)).getUTCDate(),
            dateFns.addDays(this, Math.abs(nbDays)).getUTCHours(),
            dateFns.addDays(this, Math.abs(nbDays)).getUTCMinutes() + differenceTimezoneMinutes,
            dateFns.addDays(this, Math.abs(nbDays)).getUTCSeconds(),
            dateFns.addDays(this, Math.abs(nbDays)).getUTCMilliseconds()
        );
        return new Date(utc);
    } else {
        return this.toUTC();
    }
};

Date.prototype.getDateWithoutTime = function (): Date {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate());
};

Date.prototype.toUTC = function (): Date {
    const utc = Date.UTC(
        this.getUTCFullYear(),
        this.getUTCMonth(),
        this.getUTCDate(),
        this.getUTCHours(),
        this.getUTCMinutes(),
        this.getUTCSeconds(),
        this.getUTCMilliseconds()
    );
    return new Date(utc);
};

Date.prototype.getUTCDateWithoutTime = function (): Date {
    const utc = Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate());
    return new Date(utc);
};

Date.prototype.getUTCFirstDayOfMonth = function (): Date {
    const utc = Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), 1);
    return new Date(utc);
};

Date.prototype.getUTCLastDayOfMonth = function (): Date {
    const utc = Date.UTC(this.getUTCFullYear(), this.getUTCMonth() + 1, 0, 23, 59, 59);
    return new Date(utc);
};

Date.prototype.getUTCDiffMonth = function (dateTo: Date): number {
    return (
        dateTo.getUTCMonth() -
        this.getUTCMonth() +
        12 * (dateTo.getUTCFullYear() - this.getUTCFullYear())
    );
};

Date.prototype.toInputString = function (): string {
    const month = this.getMonth() + 1 < 10 ? `0${this.getMonth() + 1}` : `${this.getMonth() + 1}`;
    const day = this.getDate() < 10 ? `0${this.getDate()}` : `${this.getDate()}`;
    return `${this.getFullYear()}-${month}-${day}`;
};

Date.prototype.getFrenchFormatLong = function () {
    return `${this.getDate() < 10 ? '0' : ''}${this.getDate()}/${this.getMonth() < 9 ? '0' : ''}${this.getMonth() + 1
        }/${this.getFullYear()}`;
};

Date.prototype.getFrenchMonth = function (): string {
    const frenchMonths = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];
    return frenchMonths[this.getMonth()];
};

Date.prototype.isSameDay = function (date: Date): boolean {
    return (
        this.getDate() === date.getDate() &&
        this.getMonth() === date.getMonth() &&
        this.getFullYear() === date.getFullYear()
    );
};

export { };
