// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.container {
  padding: 40px;
}

.licence-display {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licence-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.licence-form,
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button {
  width: -moz-fit-content;
  width: fit-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxpY2VuY2UtZXhjaGFuZ2UuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxTQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxRQUFBO0FBQ0o7O0FBRUE7O0VBRUksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsU0FBQTtBQUNKOztBQUVBO0VBQ0ksdUJBQUE7RUFBQSxrQkFBQTtBQUNKIiwiZmlsZSI6ImxpY2VuY2UtZXhjaGFuZ2UuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG59XG5cbi5jb250YWluZXIge1xuICAgIHBhZGRpbmc6IDQwcHg7XG59XG5cbi5saWNlbmNlLWRpc3BsYXkge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDIwcHg7XG59XG5cbi5saWNlbmNlLWluZm8ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDRweDtcbn1cblxuLmxpY2VuY2UtZm9ybSxcbmZvcm0ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDIwcHg7XG59XG5cbmJ1dHRvbiB7XG4gICAgd2lkdGg6IGZpdC1jb250ZW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/licence/licence-exchange/licence-exchange.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;;AAEA;;EAEI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,uBAAA;EAAA,kBAAA;AACJ;AAEA,ggCAAggC","sourcesContent":[":host {\n    display: block;\n}\n\n.container {\n    padding: 40px;\n}\n\n.licence-display {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.licence-info {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.licence-form,\nform {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\nbutton {\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
