import { formatDate } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import {
    AllChargeCategories,
    AllIncomeCategories,
    AssetTypes,
    ChargeAssociateTo,
    ChargeCategoryBuilding,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeCategorySociety,
    ChargeEntity,
    ChargePeriodicity,
    IncomeCategoryBuilding,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeCategorySociety,
    IncomeEntity,
    PopupTypes,
    PropertyEntity,
    PropertyType,
    SelectOption,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    IncomeService,
    PropertyService,
    PropertyTutorialService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomTreasury } from '@omedom/utils';
import { ShepherdService } from 'angular-shepherd';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import Step from 'shepherd.js/src/types/step';

import { FormAssociateToComponent } from '../../components/form-associate-to/form-associate-to.component';
import { OmedomRadioOption } from '../../components/radio';
import { OmedomFormatNumber } from '../../functions/format-number';
import { OmedomIsAuthorisedPipe } from '../../pipes';
import { OmedomNumberPipe } from '../../pipes/number.pipe';

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
@Component({
    selector: 'omedom-treasury-form',
    templateUrl: './treasury-form.component.html',
    styleUrls: ['./treasury-form.component.scss'],
})
export class TreasuryFormComponent implements OnInit, OnDestroy {
    /**
     * @description use to get the data from assotiation input
     * @author ANDRE Felix
     * @type {QueryList<FormAssociateToComponent>}
     * @memberof TreasuryFormComponent
     */
    @ViewChildren(FormAssociateToComponent) formAssociateTos?: QueryList<FormAssociateToComponent>;
    /**
     * @description Active step form state
     * @author Jérémie Lopez
     * @memberof TreasuryFormComponent
     */
    activeStepForm = 0;

    /**
     * @description User Data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof TreasuryFormComponent
     */
    user?: UserEntity;

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof TreasuryFormComponent
     */
    pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Treasury of form
     * @author Brisset Killian
     * @date 14/06/2024
     * @type {(Partial<ChargeEntity> | ChargeEntity)}
     * @memberof TreasuryFormComponent
     */
    treasury: Partial<ChargeEntity> | ChargeEntity | Partial<IncomeEntity> | IncomeEntity = {};

    /**
     * @description Schedule of the income
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeFormPage
     */
    selectedPeriodicity: string | null = null;

    /**
     * @description Property UID to redirect after treasury created
     * @author ANDRE Felix
     * @type {string}
     * @memberof PropertyTutorialService
     */
    public propertyUID?: string;

    periodicityPlaceholder = { label: 'Périodicité' } as SelectOption;

    /**
     * @description used to display/retrieve the debit date in the form as day only
     * @author Hanane Djeddal
     * @type {number}
     * @memberof TreasuryFormComponent
     */
    private _displayDebitDate: string | null = null;
    get displayDebitDate() {
        if (this._displayDebitDate === null && this.startDate) {
            const startdate_toDate = new Date(this.startDate);
            return startdate_toDate.getDate().toString();
        } else {
            return this._displayDebitDate || '';
        }
    }

    set displayDebitDate(date: string) {
        this._displayDebitDate = date;
    }

    public treasuryForm?: UntypedFormGroup;

    periodicityOptions$ = of([
        { id: ChargePeriodicity.punctual, label: 'Ponctuelle' } as SelectOption,
        { id: ChargePeriodicity.monthly, label: 'Mensuelle' } as SelectOption,
        {
            id: ChargePeriodicity.bimonthly,
            label: 'Bimestrielle',
        } as SelectOption,
        {
            id: ChargePeriodicity.quarterly,
            label: 'Trimestrielle',
        } as SelectOption,
        {
            id: ChargePeriodicity.halfYearly,
            label: 'Semestrielle',
        } as SelectOption,
        { id: ChargePeriodicity.yearly, label: 'Annuelle' } as SelectOption,
    ]);

    chargePeriodicity = ChargePeriodicity;

    notificationRadioOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    selectedProperty: string | null = null;

    propertyPlaceholder = {
        label: 'Sélectionner',
        icon: 'uil uil-home',
    } as SelectOption;

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    @Input()
    selectedChargeCategory: ChargeCategoryInfo | null = null;

    @Input()
    selectedIncomeCategory: IncomeCategoryInfo | null = null;

    @Input()
    isCharge: boolean = true;

    chargeCategories: ChargeCategoryInfo[] = [];

    incomeCategories: IncomeCategoryInfo[] = [];

    debitDate: string | null = null;

    startDate: string | null = null;

    endDate: string | null = null;

    isCategorySelectedByRoute = false;

    @Input()
    propertyUid?: string;

    @Input()
    societyUid?: string;

    @Input()
    property?: PropertyEntity;

    private subscription?: Subscription;

    /**
     * @description used to position tutorial popups
     * @author Hanane Djeddal
     * @type {number}
     * @memberof TreasuryFormComponent
     */
    offset?: number;

    /**
     * @description used to hidethe tabs bar of the app
     * @author Hanane Djeddal
     * @memberof TreasuryFormComponent
     */
    @ViewChild('tabsBar') tabsBar: any;

    /**
     * @description if true the previous componenet was treasury list : used to go back to the proper view with date
     * @author Hanane Djeddal
     * @type {string}
     * @memberof TreasuryFormComponent
     */
    previousComponent?: string;

    /**
     * @description If true use nav.back() after submit (no specific url is given), else go back via a URL
     * @author Hanane Djeddal
     * @type {boolean}
     * @memberof TreasuryFormComponent
     */
    routeBack?: boolean;

    /**
     * @description contains URL of the previous view, retrieved via router link
     * @author Hanane Djeddal
     * @type {string}
     * @memberof TreasuryFormComponent
     */
    previousUrl?: string;

    isOptionPropertyReady = false;

    @Input()
    assetType?: AssetTypes;

    /**
     * @description treasury associate to other properties (ex: lot in building)
     * @author ANDRE Felix
     * @type {ChargeAssociateTo[]}
     * @memberof TreasuryFormComponent
     */
    associatedTo: ChargeAssociateTo[] = [];

    /**
     * @description Properties where we can assotiate the treasury (ex: lot in building)
     * @author ANDRE Felix
     * @type {SelectOption[]}
     * @memberof TreasuryFormComponent
     */
    propertiesToAssociate: SelectOption[] = [];

    lotToAssociate: ChargeAssociateTo[] = [];

    isSelectedPropertyBuilding = false;

    isSelectedPropertySociety = false;

    /**
     * @description Use to check the total percentage,
     * @author ANDRE Felix
     * @memberof TreasuryFormComponent
     */
    isTotalPercentageInRange = true;

    private canManageSociety = false;

    @Output()
    public backEvent = new EventEmitter<void>();

    constructor(
        private navController: NavController,
        private userService: UserService,
        private propertyService: PropertyService,
        private activatedRoute: ActivatedRoute,
        private chargeService: ChargeService,
        private toast: ToastController,
        private numberPipe: OmedomNumberPipe,
        private readonly shepherdService: ShepherdService,
        private router: Router,
        private platform: Platform,
        protected propertyTutorialService: PropertyTutorialService,
        private modalController: ModalController,
        private analyticsService: AnalyticsService,
        private societyService: SocietyService,
        private omedomIsAuthorisedPipe: OmedomIsAuthorisedPipe,
        private incomeService: IncomeService
    ) {}

    async ngOnInit(): Promise<void> {
        this.analyticsService.logEvent('treasury form opened');

        const canManageSociety$ = this.omedomIsAuthorisedPipe.transform('accessManageSociety');

        const category$ = this.activatedRoute.queryParams.pipe(
            map((params) => params['charge'] ?? params['income'])
        );
        const assetType$ = this.activatedRoute.queryParams.pipe(
            map((params) => params['assetType'])
        );

        const previousComponent$ = this.activatedRoute.queryParams.pipe(
            map((params) => params['list'])
        );
        const property$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get('propertyUid'))
        );
        const society$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get('societyUid'))
        );
        const user$ = this.userService.user$.pipe(take(1));
        this.previousUrl = this.router.url
            .replace(this.isCharge ? 'charge/' : 'income/', '')
            .replace('form', '');

        this.subscription = combineLatest([
            category$,
            property$,
            user$,
            society$,
            previousComponent$,
            assetType$,
            canManageSociety$,
        ]).subscribe(
            async ([
                category,
                propertyUid,
                user,
                societyUid,
                previousComponent,
                assetType,
                canManageSociety,
            ]) => {
                //for routing back from form to proper date:
                if (previousComponent) {
                    this.previousUrl = this.previousUrl?.replace('?list=true', '');
                }

                this.canManageSociety = canManageSociety;

                const chargeCategoryInfo =
                    this.selectedChargeCategory || category
                        ? new ChargeCategoryInfo(
                              (this.selectedChargeCategory ?? category) as AllChargeCategories
                          )
                        : null;

                const incomeCategoryInfo =
                    this.selectedIncomeCategory || category
                        ? new IncomeCategoryInfo(
                              (this.selectedIncomeCategory ?? category) as AllIncomeCategories
                          )
                        : null;

                if (category) {
                    this.routeBack = true;
                }

                this.propertyUid = this.propertyUid ?? propertyUid ?? undefined;
                this.societyUid = this.societyUid ?? societyUid ?? undefined;
                this.activeStepForm = chargeCategoryInfo || incomeCategoryInfo ? 1 : 0;
                this.isCategorySelectedByRoute = !!chargeCategoryInfo || !!incomeCategoryInfo;
                this.previousComponent = previousComponent;
                this.assetType = this.assetType ?? (assetType as AssetTypes);
                this.user = user;
                if (propertyUid) {
                    this.property = await this.propertyService.get(propertyUid);
                    if (this.property?.type === PropertyType.immeuble) {
                        this.assetType = AssetTypes.building;
                    }
                }
                this.setCategories(this.assetType);

                await this.setPropertyOptions(user);

                if (!!this.propertyUid) {
                    this.selectedProperty =
                        this.propertyOptions$.value.find((x) => x.id === this.propertyUid)?.id ??
                        null;
                }
                this.updateIsSelectedAsset();

                this.isOptionPropertyReady = true;
                this.updatePropertiesToAssociate();
                if (chargeCategoryInfo) {
                    this.onCategorySelected(chargeCategoryInfo);
                } else if (incomeCategoryInfo) {
                    this.onCategorySelected(incomeCategoryInfo);
                }

                let tutorialFirstStep;
                this.propertyTutorialService.tutorialFirstStep$.subscribe(
                    (started) => (tutorialFirstStep = started)
                );
                // if (
                //     this.propertyUid &&
                //     !this.user?.finishedTutorials?.property &&
                //     !tutorialFirstStep
                // ) {
                //     if (!this.shepherdService.isActive) {
                //         this.launchTutorial();
                //     } else {
                //         const tempChargeCategoryInfo = new ChargeCategoryInfo(
                //             ChargeCategoryProperty[ChargeCategoryProperty.insurance]
                //         );
                //         this.onCategorySelected(tempChargeCategoryInfo);
                //         this.selectedPeriodicity = ChargePeriodicity.monthly;
                //         this.activeStepForm = 1;
                //     }
                // }
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private setCategories(assetType: AssetTypes) {
        let chargeToCategorize: AllChargeCategories[] = Object.values(ChargeCategoryProperty);
        let incomeToCategorize: AllIncomeCategories[] = Object.values(IncomeCategoryProperty);
        switch (assetType) {
            case AssetTypes.building:
                chargeToCategorize = [
                    ...chargeToCategorize,
                    ...Object.values(ChargeCategoryBuilding),
                ];
                incomeToCategorize = [
                    ...incomeToCategorize,
                    ...Object.values(IncomeCategoryBuilding),
                ];
                break;

            case AssetTypes.society:
                chargeToCategorize = [
                    ...chargeToCategorize,
                    ...Object.values(ChargeCategorySociety),
                ];
                incomeToCategorize = [
                    ...incomeToCategorize,
                    ...Object.values(IncomeCategorySociety),
                ];
                break;
        }

        this.chargeCategories = chargeToCategorize.map((x) => new ChargeCategoryInfo(x));
        this.incomeCategories = incomeToCategorize.map((x) => new IncomeCategoryInfo(x));
    }

    private async setPropertyOptions(user: UserEntity) {
        if (!!this.societyUid) {
            const currentSociety = await this.societyService.get(this.societyUid);
            const currentSocietyOption = [];
            if (currentSociety?.toSelectOption) {
                currentSocietyOption.push(currentSociety.toSelectOption());
            }
            let propertyOfSocietyOptions: SelectOption[] = [];

            if (!this.isAssetOptionsForSociety()) {
                propertyOfSocietyOptions = (
                    await this.propertyService.getSocietyPropertiesOptions(this.societyUid)
                ).filter((x) => x.isAccesible);
            }
            if (propertyOfSocietyOptions.length === 0) {
                this.selectedProperty = currentSocietyOption[0].id;
            }

            this.propertyOptions$.next([...currentSocietyOption, ...propertyOfSocietyOptions]);
        } else if (
            this.property?.lotsUID &&
            this.property?.lotsUID.length >= 0 &&
            this.propertyUid
        ) {
            const buildingOption = await this.propertyService.getBuildingPropertiesOptions(
                this.propertyUid
            );
            this.propertyOptions$.next(buildingOption);
            if (buildingOption.length === 1) {
                // if there is only a building as selected option, we put it as selected
                this.selectedProperty = buildingOption[0].id;
            }
        } else {
            const propertyOptions = (
                await this.propertyService.getUserPropertiesAndSharedAsAdminOptions(user.uid)
            ).filter((x) => x.isAccesible);

            if (!this.canManageSociety) {
                this.propertyOptions$.next(propertyOptions);
                this.selectedProperty = propertyOptions[0].id;
                return;
            }

            const societyOptions = await this.societyService.getUserSocietiesOptions(user.uid);
            const allOptions = [...societyOptions, ...propertyOptions];
            this.propertyOptions$.next(allOptions);

            if (allOptions.length === 1) {
                this.selectedProperty = allOptions[0].id;
            }
        }
    }

    private isAssetOptionsForSociety() {
        if (!this.selectedChargeCategory || !this.selectedIncomeCategory) {
            return false;
        }
        const societyCategory = [
            ...Object.values(ChargeCategorySociety),
            ...Object.values(IncomeCategorySociety),
        ];

        for (const category of societyCategory) {
            if (
                category === this.selectedChargeCategory.category ||
                category === this.selectedIncomeCategory.category
            ) {
                return true;
            }
        }
        return false;
    }

    /**
     * @description Reset the form
     * @author Jérémie Lopez
     * @private
     * @memberof TreasuryFormComponent
     */
    private reset(): void {
        this.activeStepForm = 0;
        this.isCategorySelectedByRoute = false;

        this.treasury = {};

        this.startDate = null;
        this.endDate = null;

        this.selectedProperty = null;
        this.selectedPeriodicity = null;
        this.selectedChargeCategory = null;
        this.selectedIncomeCategory = null;
    }

    onCategorySelected(category: ChargeCategoryInfo | IncomeCategoryInfo): void {
        if (this.isCharge) {
            this.selectedChargeCategory = category as ChargeCategoryInfo;
        } else {
            this.selectedIncomeCategory = category as IncomeCategoryInfo;
        }
        this.treasury.category = category.category;
        if (this.user) {
            this.setPropertyOptions(this.user);
        }
        this.activeStepForm = 1;
    }

    /**
     * @description Navigate back
     * @author Jérémie Lopez Felix Andre (refacto)
     * @memberof PropertyDataFormPage
     */
    async back(date?: Date): Promise<void> {
        if (await this.modalController.getTop()) {
            this.modalController.dismiss();
            return;
        }

        this.backEvent.emit();

        if (this.routeBack || !date || !this.previousUrl) {
            this.navController.pop();
            return;
        }

        // if (this.previousComponent) {
        //     // form was called from list of incomes
        //     this.router.navigate([
        //         this.previousUrl + (this.isCharge ? 'charge' : 'income') + '/list/' + date,
        //     ]);
        //     return;
        // }

        // if (this.propertyUid) {
        //     // form was called from a property view
        //     this.router.navigate([this.previousUrl], {
        //         queryParams: {
        //             tab: this.isCharge ? 'charge' : 'income',
        //             date,
        //         },
        //     });
        // } else if (this.societyUid) {
        //     this.previousUrl = this.previousUrl.replace('/?assetType=society', '');
        //     // form was called from a society view
        //     this.router.navigate([this.previousUrl], {
        //         queryParams: {
        //             tab: this.isCharge ? 'charge' : 'income',
        //             date,
        //         },
        //     });
        // } else {
        //     this.router.navigate([this.previousUrl + (this.isCharge ? '/charge/' : '/income/')], {
        //         queryParams: {
        //             date,
        //         },
        //     });
        // }
    }

    formatAmountNumber(newValue: string) {
        OmedomFormatNumber.formatOmedomInput(newValue, this.numberPipe, 'amount');
    }

    /**
     * @description Create or edit an treasury
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof TreasuryFormComponent
     */
    async submit(): Promise<void> {
        this.analyticsService.logEvent('treasury form submitted');
        this.pending$.next(true);
        const startdate_toDate = this.startDate ? new Date(this.startDate) : new Date();
        const endDate_toDate = this.endDate ? new Date(this.endDate) : null;
        let dateToNavigateAfterSubmit = new Date();
        this.setChargeWithInputValue();
        this.checkTotalPercentage();

        //used to redirect treasury list page to the proper date when periodicity: punctual, annual, quarterly

        if (this.treasury.periodicity === ChargePeriodicity.punctual) {
            dateToNavigateAfterSubmit = this.debitDate ? new Date(this.debitDate) : new Date();

            this.setChargeWhenPunctual(dateToNavigateAfterSubmit);
        } else {
            this.correctEndOfMonthDay(startdate_toDate);
        }

        const isPeriodValid = await this.checkIfValidTimeInterval(startdate_toDate, endDate_toDate);
        if (!isPeriodValid) {
            return;
        }
        if (
            this.debitDate &&
            (this.startDate || this.treasury.periodicity === ChargePeriodicity.punctual)
        ) {
            OmedomTreasury.calculateDateAndCalculateHistory(
                this.treasury as ChargeEntity | IncomeEntity,
                this.debitDate,
                this.startDate ?? undefined
            );
        } else {
            this.displayErrorToast('');
            return;
        }
        if (this.isPeriodicityLonguerThanMonthly()) {
            dateToNavigateAfterSubmit = this.setNavigationDateForLongPeriodicity();
        }
        this.saveChargeCreated(dateToNavigateAfterSubmit);
    }

    private setChargeWithInputValue() {
        if (!this.selectedPeriodicity || !this.selectedProperty) {
            return;
        }

        this.treasury.amount = +this.numberPipe.parse(this.treasury.amount?.toString() ?? '');
        this.treasury.userUID = this.user?.uid;
        this.treasury.periodicity = this.selectedPeriodicity as ChargePeriodicity;
        const currentAsset = this.propertyOptions$.value.find(
            (value) => this.selectedProperty === value.id
        );
        if (
            this.selectedProperty === this.societyUid ||
            currentAsset?.assetType === AssetTypes.society
        ) {
            // if the treasury is for a society
            this.treasury.societyUID = this.selectedProperty ?? this.societyUid;
        } else {
            this.treasury.propertyUID = this.selectedProperty ?? this.propertyUid;
        }
        this.getAssociatedData();
    }

    getAssociatedData() {
        this.treasury.associatedTo = [];
        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                if (this.treasury.associatedTo) {
                    this.treasury.associatedTo.push(formAssociateTo.getInputData());
                }
            });
        }
    }

    private setChargeWhenPunctual(dateToNavigateAfterSubmit: Date) {
        dateToNavigateAfterSubmit = this.debitDate ? new Date(this.debitDate) : new Date();

        delete this.treasury.endDate;
        this.treasury.isReaded = false;
        this.treasury.isPayed = dateToNavigateAfterSubmit.getTime() < new Date().getTime();
    }

    private correctEndOfMonthDay(startDate: Date) {
        this.debitDate = this.displayDebitDate === '31' ? '30' : this.displayDebitDate;
        const datefromstart = this.startDate ? new Date(this.startDate) : new Date();
        datefromstart.setDate(+this.debitDate);

        // if Month day invalid (31 for a month with only 30days, it passes to the first day of the next month)
        if (startDate.getMonth() !== datefromstart.getMonth()) {
            datefromstart.setDate(+this.debitDate); //correct the day
        }
        this.debitDate = formatDate(datefromstart, 'YYYY-MM-dd', 'fr');
    }

    private async checkIfValidTimeInterval(startDate: Date, endDate: Date | null) {
        if (this.endDate && endDate && startDate > endDate) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    'Veuillez renseigner une date de début de contrat antérieure à la date de fin',
            });

            await toast.present();

            this.pending$.next(false);
            return false;
        }
        const currentProperty = this.propertyOptions$.value.find(
            (value) => this.selectedProperty === value.id
        );
        if (
            currentProperty?.purchaseYear &&
            startDate.getFullYear() < currentProperty.purchaseYear
        ) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    "Veuillez renseigner une date de début de contrat postérieure à la date d'acquisition du bien",
            });

            await toast.present();
            this.pending$.next(false);
            return false;
        }
        return true;
    }

    private isPeriodicityLonguerThanMonthly() {
        return (
            this.treasury.periodicity === ChargePeriodicity.yearly ||
            this.treasury.periodicity === ChargePeriodicity.halfYearly ||
            this.treasury.periodicity === ChargePeriodicity.bimonthly ||
            this.treasury.periodicity === ChargePeriodicity.quarterly
        );
    }

    private setNavigationDateForLongPeriodicity() {
        if (this.treasury.history && this.treasury.history?.length > 1) {
            return (
                this.treasury.history
                    ?.sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime())[0]
                    .date.toDate() ?? new Date()
            );
        } else if (this.treasury.startDate) {
            return new Date(this.treasury.startDate.toDate());
        } else {
            return new Date();
        }
    }

    private async saveChargeCreated(dateToNavigateAfterSubmit: Date) {
        try {
            if (this.isCharge) {
                this.chargeService.create(this.treasury as Partial<ChargeEntity>);
            } else {
                this.incomeService.create(this.treasury as Partial<IncomeEntity>);
            }

            this.reset();

            const popupValidated = this.isPopupValidated();

            if (!!this.user?.bridgeUUID || popupValidated) {
                this.displaySuccessToast(dateToNavigateAfterSubmit);
            }
        } catch (error) {
            this.displayErrorToast(error);
        }
    }

    private isPopupValidated() {
        return (
            (this.user?.popups?.filter((popup) => {
                if (
                    popup.name === 'To banking connexion' &&
                    popup.version === 1 &&
                    popup.type === PopupTypes.redirection &&
                    popup.isValidated === true
                ) {
                    return true;
                } else {
                    return false;
                }
            }).length ?? 0) > 0 || false
        );
    }

    private async displaySuccessToast(dateToNavigate: Date) {
        const toast = await this.toast.create({
            position: 'top',
            color: 'primary',
            duration: 4000,
            message: 'Vous avez enregistré ' + (this.isCharge ? 'une charge' : 'un revenue'),
        });
        await toast.present();
        this.pending$.next(false);
        this.back(dateToNavigate);
    }

    async handleAssociateChargeTo() {
        // TODO actually, when we create a treasury globally, it's complicated to had the
        // functionnality to associate treasury (in building and lot) with actual code, and
        // keep something readable and understandable
        if (!(this.property || this.societyUid)) {
            return;
        }

        this.updateIsSelectedAsset();
        if (!this.property && this.selectedProperty) {
            this.property = await this.propertyService.get(this.selectedProperty);
        }
        if (this.isSelectedPropertyBuilding || this.isSelectedPropertySociety) {
            this.updatePropertiesToAssociate();
        }
    }

    updatePropertiesToAssociate() {
        if (!this.selectedProperty || !this.property) {
            return;
        }
        this.propertiesToAssociate = this.propertyOptions$.value.filter((property) => {
            if (property.id === this.societyUid || property.id === this.property?.uid) {
                return false;
            }
            return true;
        });
        this.lotToAssociate = this.propertiesToAssociate.map((propertyOption) => {
            return {
                propertyUid: propertyOption.id,
                propertyLabel: propertyOption.label,
                percentage: 0,
                amount: 0,
            };
        });
    }

    updateIsSelectedAsset() {
        const currentProperty = this.propertyOptions$.value.find(
            (value) => this.selectedProperty === value.id
        );
        this.isSelectedPropertyBuilding = currentProperty?.assetType === AssetTypes.building;
        this.isSelectedPropertySociety = currentProperty?.assetType === AssetTypes.society;
    }

    onPercentageChange(val: any) {
        let totalPourcentage = 0;
        let currentModificatedForm: FormAssociateToComponent | undefined;
        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                totalPourcentage += formAssociateTo.percentage;
                if (formAssociateTo.propertyInfo?.propertyUid === val.propertyUid) {
                    currentModificatedForm = formAssociateTo;
                }
            });
        }
        this.checkValueIsInPercentageRange(totalPourcentage);

        if (totalPourcentage > 100) {
            const maxPossibleValue = 100 - (totalPourcentage - val.percentage);
            currentModificatedForm?.percentageChange(maxPossibleValue);
            this.checkValueIsInPercentageRange(maxPossibleValue);
        }
    }

    checkTotalPercentage() {
        let totalPourcentage = 0;

        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                totalPourcentage += formAssociateTo.percentage;
            });
        }
        this.checkValueIsInPercentageRange(totalPourcentage);
    }

    checkValueIsInPercentageRange(value: number) {
        if (value > 100 || value < 0) {
            this.isTotalPercentageInRange = false;
        } else {
            this.isTotalPercentageInRange = true;
        }
    }
    private async displayErrorToast(error: any) {
        const toast = await this.toast.create({
            position: 'top',
            color: 'danger',
            duration: 4000,
            message: "Une erreur s'est produite, veuillez réessayer plus tard.",
        });
        await toast.present();
        this.pending$.next(false);
    }

    /**
     * @description Tutotial functons
     * @author Hanane Djeddal
     * @function getSelectTreasuryTypeStep
     * @function getAmountFieldStep
     * @function getPeriodicityFieldStep
     * @function getScheduleAlertStep
     * @function getShowChargeStep
     * @function getShowIncomeStep
     * @function getShowLiquidityStep
     * @memberof TreasuryFormComponent
     */

    launchTutorial(): void {
        this.offset = this.platform.height() - 20;
        this.shepherdService.modal = true;
        this.shepherdService.defaultStepOptions = {
            canClickTarget: false,
            arrow: false,
        };
        this.shepherdService.addSteps([
            this.getSelectTreasuryTypeStep(),
            this.getAmountFieldStep(),
            this.getPeriodicityFieldStep(),
            // this.getScheduleAlertStep(),
            this.getShowChargeStep(),
            this.getShowIncomeStep(),
            this.getShowLiquidityStep(),
        ]);

        // On utilise un settimeout, sinon les boutons et le message ne s'affichent pas
        setTimeout(() => this.shepherdService.start());
    }

    private getSelectTreasuryTypeStep(): Step.StepOptions {
        return {
            id: 'treasuryTypeTuto',
            attachTo: {
                element: '#treasuryTypeTuto',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -400] } }],
            // },
            when: {
                show() {
                    document.getElementById('tabsBar')?.setAttribute('hidden', 'true');
                },
            },
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Choisissez le type de charge que vous souhaitez enregistrer. </span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                // Not working
                // {
                //     text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                //     action: () => {
                //         this.router
                //             .navigate(['/tabs/property/info/' + this.selectedProperty.id], {
                //                 queryParams: {
                //                     tab: 'charge',
                //                 },
                //             })
                //             .then(() => {
                //                 // this.activeStepForm = 0;
                //                 setTimeout(() => this.shepherdService.back());
                //             });
                //     },
                // },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        const chargeCategoryInfo = new ChargeCategoryInfo(
                            ChargeCategoryProperty[ChargeCategoryProperty.insurance]
                        );
                        this.onCategorySelected(chargeCategoryInfo);

                        setTimeout(() => this.shepherdService.next());
                    },
                },
            ],
        };
    }

    // Tuto step: amount field
    private getAmountFieldStep(): Step.StepOptions {
        return {
            id: 'fieldsTuto',
            attachTo: {
                element: '#fieldsTuto',
                on: 'top',
            },
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Assurez-vous que votre charge ou revenu est associé(e) au bon bien. Indiquez un montant (obligatoire) à votre charge ou revenu.</span>',
            // '<span class="tutorial-text">Indiquez un montant (obligatoire) et une désignation (facultatif) pour ajouter plus de détails à votre charge ou revenu.</span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: () => {
                        this.router
                            .navigate([
                                '/tabs/property/info/' + this.selectedProperty + '/charge/form',
                            ])
                            .then(() => {
                                this.activeStepForm = 0;
                                setTimeout(() => this.shepherdService.back());
                            });
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: () => this.shepherdService.next(),
                },
            ],
        };
    }

    // Tuto step: periodicity
    // Vous disposez de plusieurs type de périodicités (ponctuelle, mensuelle, bimestrielle, trimestrielle et annuelle), la périodicité ponctuelle ne sera prise en compte qu'une seule fois.
    private getPeriodicityFieldStep(): Step.StepOptions {
        return {
            id: 'periodicityStepTuto',
            attachTo: {
                element: '#periodicityStepTuto',
                on: 'bottom-start',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Vous pouvez sélectionner une périodicité récurrente ou ponctuelle pour des charges ou revenus n’arrivant qu’une seule fois.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: async () => {
                        const chargeCategoryInfo = new ChargeCategoryInfo(
                            ChargeCategoryProperty.insurance
                        );
                        this.onCategorySelected(chargeCategoryInfo);

                        setTimeout(() => this.shepherdService.back());
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        this.selectedPeriodicity = ChargePeriodicity.monthly;
                        setTimeout(() => this.shepherdService.next());
                    },
                },
            ],
        };
    }

    // Tuto step: schedule alert
    private getScheduleAlertStep(): Step.StepOptions {
        return {
            id: 'scheduleAlertStepTuto',
            attachTo: {
                element: '#scheduleAlertStepTuto',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
            // },
            when: {
                show() {
                    document.getElementById('scheduleAlertStepTuto')?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                },
            },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Programmez une alerte sur des mouvements récurrents. Retrouvez les sur votre tableau de bord.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: () => {
                        this.shepherdService.back();
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'charge',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next(), 200));
                    },
                },
            ],
        };
    }

    // Tuto step: show charge
    private getShowChargeStep(): Step.StepOptions {
        return {
            id: 'showChargeStep',
            attachTo: {
                element: '#showChargeStep',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -this.offset] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Ici, la magie opère ! Tous les jours, le diagramme se met à jour, selon les mouvements passés.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: async () => {
                        this.router
                            .navigate([
                                '/tabs/property/info/' + this.selectedProperty + '/charge/form',
                            ])
                            .then(() => {
                                setTimeout(() => this.shepherdService.back(), 400);
                            });
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'income',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next()));
                    },
                },
            ],
        };
    }
    // Tuto step: show income
    private getShowIncomeStep(): Step.StepOptions {
        return {
            id: 'showIncomeStep',
            attachTo: {
                element: '#showIncomeStep',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -this.offset] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Il en est de même pour l’onglet Revenus.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.selectedProperty], {
                                queryParams: {
                                    tab: 'charge',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.back()));
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'liquidity',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next()));
                    },
                },
            ],
        };
    }

    // Tuto step: show liquidity
    private getShowLiquidityStep(): Step.StepOptions {
        return {
            id: 'showLiquidityStep',
            attachTo: {
                element: '#showLiquidityStep',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -this.offset] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Visualisez les montants de vos mouvements et vos liquidités par mois et par bien. <br> Vérifiez la courbe de votre trésorerie cumulée pour anticiper des débits futurs (courbe bleue). </span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                // {
                //     text: '<i class="uil uil-angle-right"></i><span>Précedent</span>',
                //     action: async () => {
                //         await this.router
                //             .navigate(['/tabs/property/info/' + this.propertyUid], {
                //                 queryParams: {
                //                     tab: 'income',
                //                 },
                //             })
                //             .then(() => setTimeout(() => this.shepherdService.back()));
                //     },
                // },
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Débuter maintenant l’aventure</span>',
                    action: () => {
                        this.cancelTutorial();
                    },
                },
            ],
        };
    }
    private async cancelTutorial(): Promise<void> {
        document.getElementById('tabsBar')?.removeAttribute('hidden');
        this.shepherdService.cancel();
        await this.userService.update({
            finishedTutorials: {
                ...this.user?.finishedTutorials,
                property: true,
            },
            uid: this.user?.uid,
        });

        await this.router.navigate(['/tabs/property']);
        // this.modalController.dismiss();
        // await this.router.navigate(['/tabs/property/info/' + this.propertyUid], {
        //     queryParams: {
        //         tab: 'charge',
        //     },
        // });
    }
}
