/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, ResolveStart, Router } from '@angular/router';
import { CategoryInfo, ChargeEntity, IncomeEntity, PropertyEntity, UserEntity } from '@omedom/data';
import { PropertyTutorialService, RefreshService, UserService } from '@omedom/services';
import { OmedomChart, OmedomDate, OmedomDoughnutChart, OmedomTreasury } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';

import { OmedomChartLegend } from '../../component/chart-legend';
import { OmedomDateType } from '../../component/date';
import { OmedomSubTab } from '../../component/sub-tab';
import { map, filter } from 'rxjs/operators';

@Injectable()
export abstract class TreasuryProperty<TEntity extends ChargeEntity | IncomeEntity, TCategory>
    implements OnInit, OnDestroy {
    @Input() public property: PropertyEntity;

    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({
            id: 1,
            label: 'Comptabilité automatique',
            popover: 'Fonctionnalité bientôt disponible dans votre abonnement Smart.',
            disabled: true,
        }), //icon: 'uil uil-star'
        new OmedomSubTab({ id: 2, label: 'Comptabilité manuelle' }),
    ];

    selectedSubTab: OmedomSubTab;

    omedomDateType = OmedomDateType;

    total = 0;

    doughnutChartData = OmedomDoughnutChart.doughnutChartData;

    doughnutChartOptions = OmedomDoughnutChart.doughnutChartOptions;

    chartLegends: OmedomChartLegend[] = [];

    treasuryList: {
        categoryInfo: CategoryInfo<any>;
        amount: number;
        percent: number;
        isPayed: boolean;
    }[] = [];

    currentDate = new Date();

    totalAmount = 0;

    totalAmountPayed = 0;

    totalAmountNotPayed = 0;

    private subscriptions: Subscription[] = [];

    protected currentUser: UserEntity;

    private entities: TEntity[];

    abstract get entityType(): string;

    /**
     * @description the date of a newly created treasury: used to redirect to the right month
     * @author Hanane Djeddal
     * @abstract
     * @type {Date}
     * @memberof TreasuryProperty
     */
    abstract get redirectedDate(): Date;

    abstract set redirectedDate(d: Date);

    protected tutorialFirstStep = false;

    isTreasuryLoaded = false;

    /**
     * @description If there is at least one charge or income
     * @author ANDRE Felix
     * @memberof TreasuryProperty
     */
    hasAtLeastOneEntity = true;

    protected constructor(
        private userService: UserService,
        private refreshService: RefreshService,
        protected propertyTutorialService: PropertyTutorialService,
        protected router: Router,
        private route: ActivatedRoute
    ) { }

    async ngOnInit(): Promise<void> {
        this.selectedSubTab = this.subTabs[1];

        const didEnter$ = this.refreshService.viewDidEnter$.subscribe(() => {
            const user$ = this.userService.user$.subscribe(async (user) => {
                this.currentUser = user;
                // if the property tutorial hasn't been finished && this is the first pass by treasury-property (tuto hasn't yet started)
                if (this.property && !this.currentUser?.finishedTutorials?.property) {
                    this.propertyTutorialService.tutorialFirstStep$.subscribe(
                        (started) => (this.tutorialFirstStep = started)
                    );
                }

                this.loadTreasuryByProperty()
                    .then((x) => {
                        this.entities = x;
                        // get proper date to show treasury
                        if (this.redirectedDate) {
                            this.currentDate = this.redirectedDate.toUTC().getUTCDateWithoutTime();
                        }
                        this.updateGraphData();
                    })
                    .catch((err) => {
                        // Otherwise, if firebase fire an error, isTreasuryLoaded will not be
                        this.isTreasuryLoaded = true;
                    });
            });

            this.subscriptions.push(user$);
        });

        const routerSubscription$ = this.route.queryParams
            .pipe(map((params) => params.date))
            .subscribe((date) => {
                if (date) {
                    this.currentDate = new Date(date).toUTC().getUTCDateWithoutTime();
                }
            });

        this.subscriptions.push(didEnter$, routerSubscription$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    dateChange(date: Date) {
        this.currentDate = date;
        this.redirectedDate = null;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                date: OmedomDate.formatDateToISO(this.currentDate),
            },
            queryParamsHandling: 'merge',
        });
        this.updateGraphData();
    }

    async updateGraphData(loadData = false): Promise<void> {
        if (!this.currentUser) {
            return;
        }

        if (this.entities.length === 0 || loadData) {
            this.entities = await this.loadTreasuryByProperty();
            // this.entities = await this.loadTreasury(this.currentUser.uid);
        }
        // after charge (income)/ form onInit is not executed so update date here
        if (this.redirectedDate) {
            this.currentDate = this.redirectedDate.toUTC().getUTCDateWithoutTime();
            this.redirectedDate = null;
        }

        // We check if a charge or income exist, whatever the month picked up
        this.hasAtLeastOneEntity = this.entities.length > 0;

        const startDate = this.currentDate.getUTCFirstDayOfMonth();
        const endDate = this.currentDate.getUTCLastDayOfMonth();

        const filteredData = OmedomTreasury.filterTreasury<TEntity>(
            this.entities,
            startDate.getUTCDateWithoutTime(),
            endDate.getUTCDateWithoutTime()
        );
        const amount = OmedomTreasury.getByType<TEntity>(filteredData, startDate, endDate);
        this.isTreasuryLoaded = true;

        this.total = amount.sumBy((x) => x.amount);
        this.totalAmount = this.total;
        this.totalAmountPayed = amount.filter((x) => !!x.isPayed).sumBy((x) => x.amount);
        this.totalAmountNotPayed = amount.filter((x) => !x.isPayed).sumBy((x) => x.amount);
        amount.sort((a, b) => {
            if (!!a.isPayed && !b.isPayed) {
                return -1;
            } else if (!a.isPayed && !!b.isPayed) {
                return 1;
            }
            return a.category.localeCompare(b.category);
        });
        const treasuriesGrouped = amount.reduce((tbt, treasury) => {
            const key = treasury.category + !!treasury.isPayed;
            if (!tbt[key]) {
                tbt[key] = {
                    amount: 0,
                    percent: 0,
                    isPayed: !!treasury.isPayed,
                    categoryInfo: this.transformCategoryToLabel(treasury.category),
                };
            }
            tbt[key].amount += treasury.amount;
            tbt[key].percent = Math.round((tbt[key].amount * 100) / this.total);
            return tbt;
        }, {});

        this.treasuryList = Object.values(treasuriesGrouped);

        this.chartLegends = this.treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.categoryInfo.category];

            return new OmedomChartLegend({
                label: x.categoryInfo.category === 'rent' ? 'Loyer perçu' : x.categoryInfo.label,
                //percent: x.percent,
                amount: x.amount,
                color: !!x.isPayed ? color : OmedomChart.hatchColor(color, 'legend'),
            });
        });

        const colorsWithSaturation = this.treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.categoryInfo.category];
            return !!x.isPayed ? color : OmedomChart.hatchColor(color);
        });

        this.doughnutChartData.labels = this.treasuryList.map((x) => x.categoryInfo.label);
        this.doughnutChartData.datasets[0].data = this.treasuryList.map((x) => x.percent);
        this.doughnutChartData.datasets[0].backgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBackgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBorderColor = colorsWithSaturation;

        if (this.treasuryList.length === 1) {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: OmedomDoughnutChart.doughnutChartOptions.spacing,
                elements: OmedomDoughnutChart.doughnutChartOptions.elements,
            };
        }
        this.treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.categoryInfo.category];
            return x.isPayed ? color : OmedomChart.hatchColor(color);
        });

        if (this.baseChart) {
            this.baseChart.update();
        }
    }

    abstract loadTreasury(userUid: string): Promise<TEntity[]>;

    abstract loadTreasuryByProperty(): Promise<TEntity[]>;

    abstract transformCategoryToLabel(category: string): CategoryInfo<TCategory>;
}
