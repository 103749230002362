// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.logo-container img {
  height: 42px;
  width: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJhbmstZW1wdHkuY29udGFpbmVyLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxTQUFBO0VBQ0EsaUJBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSx1QkFBQTtFQUNBLG1CQUFBO0VBQ0EsU0FBQTtBQUNKO0FBQ0k7RUFDSSxZQUFBO0VBQ0EsV0FBQTtBQUNSIiwiZmlsZSI6ImJhbmstZW1wdHkuY29udGFpbmVyLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogMzBweDtcbiAgICBwYWRkaW5nLXRvcDogMjBweDtcbn1cblxuLmxvZ28tY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ2FwOiA0MHB4O1xuXG4gICAgaW1nIHtcbiAgICAgICAgaGVpZ2h0OiA0MnB4O1xuICAgICAgICB3aWR0aDogYXV0bztcbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/bank/bank-empty/bank-empty.container.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACI,YAAA;EACA,WAAA;AACR;AACA,oxBAAoxB","sourcesContent":[":host {\n    display: flex;\n    flex-direction: column;\n    gap: 30px;\n    padding-top: 20px;\n}\n\n.logo-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 40px;\n\n    img {\n        height: 42px;\n        width: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
