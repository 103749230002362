import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ProEntity, ReviewEntity } from '@omedom/data';
import { ReviewService } from '@omedom/services';
import { Subscription } from 'rxjs';
import { AddReviewComponent } from '../../components';

@Component({
    selector: 'omedom-review-list',
    templateUrl: './review-list.component.html',
    styleUrls: ['./review-list.component.scss'],
})
export class ReviewListComponent implements OnInit {
    @Input({ required: true }) pro!: ProEntity;

    reviews: ReviewEntity[] = [];

    subscriptions: Subscription[] = [];

    constructor(private reviewService: ReviewService, private modalController: ModalController) {}

    ngOnInit() {
        const reviewsSub = this.reviewService._getProReviews(this.pro.uid).subscribe((reviews) => {
            this.reviews = reviews;
        });
    }

    async addReview() {
        const modal = await this.modalController.create({
            component: AddReviewComponent,
            componentProps: {
                proUID: this.pro.uid,
            },
        });
        await modal.present();
    }
}
