// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.titles {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #01856e;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1lbnRpb25zLWxlZ2FsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksV0FBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsYUFBQTtFQUNBLFNBQUE7QUFDSjs7QUFFQTtFQUNJLGdCQUFBO0VBQ0EsZUFBQTtFQUNBLGlCQUFBO0FBQ0o7O0FBRUE7RUFDSSxnQkFBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtFQUVBLGNBQUE7QUFBSiIsImZpbGUiOiJtZW50aW9ucy1sZWdhbC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICB3aWR0aDogMTAwJTtcbn1cblxuLmNvbnRlbnQge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBwYWRkaW5nOiAxMHB4O1xuICAgIGdhcDogMjBweDtcbn1cblxuLnRleHQge1xuICAgIGZvbnQtd2VpZ2h0OiA0MDA7XG4gICAgZm9udC1zaXplOiAxNnB4O1xuICAgIGxpbmUtaGVpZ2h0OiAxOHB4O1xufVxuXG4udGl0bGVzIHtcbiAgICBmb250LXdlaWdodDogNjAwO1xuICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgICBsaW5lLWhlaWdodDogMjNweDtcblxuICAgIGNvbG9yOiAjMDE4NTZlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/mentions-legal/mentions-legal.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAAJ;AACA,g4BAAg4B","sourcesContent":[":host {\n    width: 100%;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    padding: 10px;\n    gap: 20px;\n}\n\n.text {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 18px;\n}\n\n.titles {\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 23px;\n\n    color: #01856e;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
