// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
  align-items: center;
}
:host .type-toggle {
  padding-inline-start: 0.5rem;
}
:host .type-toggle .show-option,
:host .type-toggle .hide-option {
  font-size: 1.2rem;
  display: block;
}
:host .type-toggle .show-option:not(ion-icon),
:host .type-toggle .hide-option:not(ion-icon) {
  text-transform: uppercase;
  font-size: 1rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNob3ctaGlkZS1wYXNzd29yZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxXQUFBO0VBQ0EsbUJBQUE7QUFDSjtBQUFJO0VBQ0ksNEJBQUE7QUFFUjtBQURROztFQUVJLGlCQUFBO0VBQ0EsY0FBQTtBQUdaO0FBRFk7O0VBQ0kseUJBQUE7RUFDQSxlQUFBO0FBSWhCIiwiZmlsZSI6InNob3ctaGlkZS1wYXNzd29yZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgLnR5cGUtdG9nZ2xlIHtcbiAgICAgICAgcGFkZGluZy1pbmxpbmUtc3RhcnQ6IDAuNXJlbTtcbiAgICAgICAgLnNob3ctb3B0aW9uLFxuICAgICAgICAuaGlkZS1vcHRpb24ge1xuICAgICAgICAgICAgZm9udC1zaXplOiAxLjJyZW07XG4gICAgICAgICAgICBkaXNwbGF5OiBibG9jaztcbiAgICAgICAgICAgIC8vIEluIGNhc2UgeW91IHdhbnQgdG8gdXNlIHRleHQgaW5zdGVhZCBvZiBpY29uc1xuICAgICAgICAgICAgJjpub3QoaW9uLWljb24pIHtcbiAgICAgICAgICAgICAgICB0ZXh0LXRyYW5zZm9ybTogdXBwZXJjYXNlO1xuICAgICAgICAgICAgICAgIGZvbnQtc2l6ZTogMXJlbTtcbiAgICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/component/show-hide-password/show-hide-password.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,mBAAA;AACJ;AAAI;EACI,4BAAA;AAER;AADQ;;EAEI,iBAAA;EACA,cAAA;AAGZ;AADY;;EACI,yBAAA;EACA,eAAA;AAIhB;AACA,4/BAA4/B","sourcesContent":[":host {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    .type-toggle {\n        padding-inline-start: 0.5rem;\n        .show-option,\n        .hide-option {\n            font-size: 1.2rem;\n            display: block;\n            // In case you want to use text instead of icons\n            &:not(ion-icon) {\n                text-transform: uppercase;\n                font-size: 1rem;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
