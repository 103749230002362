// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.actions-container {
  display: flex;
  gap: 20px;
}

button {
  width: -moz-fit-content;
  width: fit-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxpY2VuY2UtY2FuY2VsLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksY0FBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFNBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxTQUFBO0FBQ0o7O0FBRUE7RUFDSSx1QkFBQTtFQUFBLGtCQUFBO0FBQ0oiLCJmaWxlIjoibGljZW5jZS1jYW5jZWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgZGlzcGxheTogYmxvY2s7XG59XG5cbi5jb250YWluZXIge1xuICAgIHBhZGRpbmc6IDQwcHg7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGdhcDogNDBweDtcbn1cblxuLmFjdGlvbnMtY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGdhcDogMjBweDtcbn1cblxuYnV0dG9uIHtcbiAgICB3aWR0aDogZml0LWNvbnRlbnQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/licence/licence-cancel/licence-cancel.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,uBAAA;EAAA,kBAAA;AACJ;AAEA,wuBAAwuB","sourcesContent":[":host {\n    display: block;\n}\n\n.container {\n    padding: 40px;\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.actions-container {\n    display: flex;\n    gap: 20px;\n}\n\nbutton {\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
