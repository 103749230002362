// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host,
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNhdmluZy1leGl0LWNvbnN0cmFpbnRzLXN0ZXAuY29udGFpbmVyLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7O0VBRUksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsU0FBQTtBQUNKIiwiZmlsZSI6InNhdmluZy1leGl0LWNvbnN0cmFpbnRzLXN0ZXAuY29udGFpbmVyLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCxcbmZvcm0ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDIwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/saving/saving-form-steps/saving-exit-constraints-step/saving-exit-constraints-step.container.scss"],"names":[],"mappings":"AAAA;;EAEI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA,gcAAgc","sourcesContent":[":host,\nform {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
