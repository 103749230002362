import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DataTableAction, DataTableColumn, Primitive } from '@omedom/data';
import { Timestamp } from 'firebase/firestore';

@Component({
    selector: 'omedom-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent<T extends {}, S extends {}> implements OnChanges {
    /**
     * @description Data to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {T[]}
     * @memberof DataTableComponent
     */
    @Input()
    public data?: T[];

    /**
     * @description Sticky data to display in the table before the data to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/02/2024
     * @type {S[]}
     * @memberof DataTableComponent
     */
    @Input()
    public stickyData?: S[];

    /**
     * @description Columns to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {DataTableColumn<T>[]}
     * @memberof DataTableComponent
     */
    @Input()
    public columns?: DataTableColumn<T>[];

    /**
     * @description Sticky columns to display in the table before the columns to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/02/2024
     * @type {DataTableColumn<S>[]}
     * @memberof DataTableComponent
     */
    public stickyColumns?: DataTableColumn<S>[];

    /**
     * @description Action on the data by row
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {DataTableAction<T>[]}
     * @memberof DataTableComponent
     */
    @Input()
    public actions?: DataTableAction<T>[];

    /**
     * @description Sticky actions on the data by row before the actions on the data by row to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/02/2024
     * @type {DataTableAction<S>[]}
     * @memberof DataTableComponent
     */
    public stickyActions?: DataTableAction<S>[];

    /**
     * @description Disable condition for data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @memberof DataTableComponent
     */
    @Input()
    public disableCondition: (data: T) => boolean = () => false;

    /**
     * @description Empty message to display when there is no data to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {string}
     * @memberof DataTableComponent
     */
    @Input()
    public emptyMessage?: string;

    /**
     * @description Enable collapasable table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/02/2024
     * @type {boolean}
     * @memberof DataTableComponent
     */
    @Input()
    public collapsable?: boolean;

    /**
     * @description Enable expandable table by default
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/02/2024
     * @type {boolean}
     * @memberof DataTableComponent
     */
    @Input() public expanded: boolean = false;

    /**
     * @description Sort object to sort the data by a column name and a direction (asc or desc) and update the data array with the sorted data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {({
     *         key: keyof T;
     *         direction: 'asc' | 'desc';
     *     })}
     * @memberof DataTableComponent
     */
    public sort?: {
        key: keyof T;
        direction: 'asc' | 'desc';
    } = {
        key: 'created' as keyof T,
        direction: 'desc',
    };

    /**
     * @description Pagination object to paginate the data and update the data array with the paginated data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/09/2023
     * @memberof DataTableComponent
     */
    public pagination = {
        page: 1,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100],
        numberOfPages: 1,
        total: 0,
    };

    /**
     * @description Sorted data to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {T[]}
     * @memberof DataTableComponent
     */
    public sortedData?: T[] = [];

    /**
     * @description Paginated data to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/09/2023
     * @type {T[]}
     * @memberof DataTableComponent
     */
    public paginatedData?: T[] = [];

    /**
     * @description Primitive type of a data (string, number, boolean, date, array, object) to display in the table or in a form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @memberof DataTableComponent
     */
    public readonly primitive = Primitive;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        // If the data changes, sort the data
        if (changes['data']) {
            return this.sortData(this.sort?.key as keyof T);
        }
    }

    /**
     * @description Track items for ngFor to improve performance by avoiding to re-render the whole list when an item is added or removed from the list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @param {number} index
     * @param {any} item
     * @returns {*}  {*}
     * @memberof DataTableComponent
     */
    public trackByItems(index: number, item: any): any {
        return item.uid;
    }

    /**
     * @description Sort the data by a column name and a direction (asc or desc) and update the data array with the sorted data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @param {keyof T} column
     * @memberof DataTableComponent
     */
    public sortData(key: keyof T, change?: boolean): void {
        // If the column is already sorted, reverse the direction
        if (this.sort?.key === key) {
            // If the sort is not changed, return
            if (change) {
                this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
            }
        } else {
            this.sort = {
                key,
                direction: 'asc',
            };
        }

        // Get column type
        const type: Primitive = this.columns?.find(
            (column: DataTableColumn<T>) => column.key === key
        )?.type as Primitive;

        // Apply sort
        this.sortedData = this.data?.sort((a: T, b: T) => {
            switch (type) {
                case Primitive.string:
                    return this.sortString(a, b, key);
                case Primitive.number:
                    return this.sortNumber(a, b, key);
                case Primitive.date:
                    return this.sortDate(a, b, key);
                default:
                    return 0;
            }
        });

        // Create pagination
        if (this.sortedData) {
            this.createPagination(this.sortedData);
        }
    }

    /**
     * @description Sort Date object by a column name and a direction (asc or desc)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @private
     * @template T
     * @param {T} a
     * @param {T} b
     * @param {keyof T} key
     * @returns {number}
     * @memberof DataTableComponent
     */
    private sortDate<T extends {}>(a: T, b: T, key: keyof T): number {
        // Check if the column has a transformer
        const transformer = this.columns?.find((column: any) => column.key === key)
            ?.transformer as unknown as (data: T) => string;

        // Get the transformer value
        let keyValueA = transformer ? transformer(a) : a[key];
        let keyValueB = transformer ? transformer(b) : b[key];

        // Check if the column has a sorter
        const sorter = this.columns?.find((column: any) => column.key === key)
            ?.sorter as unknown as (data: T) => string;

        // If the column has a sorter, get the transformer value
        if (sorter) {
            keyValueA = sorter(a);
            keyValueB = sorter(b);
        }

        const transformerToDate = (
            data: string | Date | Timestamp | undefined
        ): Date | undefined => {
            // If the data is undefined, return undefined
            if (!data) {
                return undefined;
            }

            // If the data is a date, return the date
            if (data instanceof Date) {
                return data;
            }

            // If the data is a timestamp, return the date
            if (data instanceof Timestamp) {
                return data.toDate();
            }

            // If the data is a string, convert the string to a date
            if (typeof data === 'string') {
                // Split the string
                const split = data.split('/');

                // If the string is not a date, return undefined
                if (split.length !== 3) {
                    return undefined;
                }

                // Get the date
                data = `${split[1]}/${split[0]}/${split[2]}`;

                return new Date(data);
            }

            return undefined;
        };

        // Get the date value of the object
        const dateA = transformerToDate(keyValueA as string | Date | Timestamp | undefined);
        const dateB = transformerToDate(keyValueB as string | Date | Timestamp | undefined);

        // If the date is null, return 0
        if (!dateA) {
            return this.sort?.direction === 'asc' ? -1 : 1;
        }

        if (!dateB) {
            return this.sort?.direction === 'asc' ? 1 : -1;
        }

        // If the date is invalid, return 0
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            return 0;
        }

        // Compare the dates
        if (dateA < dateB) {
            return this.sort?.direction === 'asc' ? -1 : 1;
        } else if (dateA > dateB) {
            return this.sort?.direction === 'asc' ? 1 : -1;
        }

        return 0;
    }

    /**
     * @description Sort number object by a column name and a direction (asc or desc)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @private
     * @template T
     * @param {T} a
     * @param {T} b
     * @param {keyof T} key
     * @returns {*}  {number}
     * @memberof DataTableComponent
     */
    private sortNumber<T extends {}>(a: T, b: T, key: keyof T): number {
        // Check if the column has a transformer
        const transformer = this.columns?.find((column: any) => column.key === key)
            ?.transformer as unknown as (data: T) => number;

        // Get the number value of the object
        let numberA = Number(transformer ? transformer(a) : a[key]);
        let numberB = Number(transformer ? transformer(b) : b[key]);

        // Check if the column has a sorter
        const sorter = this.columns?.find((column: any) => column.key === key)
            ?.sorter as unknown as (data: T) => number;

        // If the column has a sorter, get the number value of the object
        if (sorter) {
            numberA = Number(sorter(a));
            numberB = Number(sorter(b));
        }

        // If the number is invalid, return 0
        if (isNaN(numberA) || isNaN(numberB)) {
            return 0;
        }

        // Compare the numbers
        if (numberA < numberB) {
            return this.sort?.direction === 'asc' ? -1 : 1;
        } else if (numberA > numberB) {
            return this.sort?.direction === 'asc' ? 1 : -1;
        }

        return 0;
    }

    /**
     * @description Sort string object by a column name and a direction (asc or desc)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @private
     * @template T
     * @param {T} a
     * @param {T} b
     * @param {keyof T} key
     * @returns {*}  {number}
     * @memberof DataTableComponent
     */
    private sortString<T extends {}>(a: T, b: T, key: keyof T): number {
        // Get the string value of the object
        let stringA = String(a[key]);
        let stringB = String(b[key]);

        // Check if the column has a transformer
        const transformer = this.columns?.find((column: any) => column.key === key)
            ?.transformer as unknown as (data: T) => string;

        // If the column has a transformer, get the string value of the object
        if (transformer) {
            stringA = transformer(a);
            stringB = transformer(b);
        }

        // Check if the column has a sorter
        const sorter = this.columns?.find((column: any) => column.key === key)
            ?.sorter as unknown as (data: T) => string;

        // If the column has a sorter, get the string value of the object
        if (sorter) {
            stringA = sorter(a);
            stringB = sorter(b);
        }

        // Compare the strings
        return this.sort?.direction === 'asc'
            ? stringA.localeCompare(stringB)
            : stringB.localeCompare(stringA);
    }

    /**
     * @description Create pagination with the data and update the data array with the paginated data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @private
     * @param {T[]} data
     * @memberof DataTableComponent
     */
    private createPagination(data: T[]): void {
        // If the page size is not in the page size options, set the page size to the first page size option
        if (!this.pagination.pageSizeOptions.includes(this.pagination.pageSize)) {
            this.pagination.pageSize = this.pagination.pageSizeOptions[0];
        }

        // Get the number of pages
        this.pagination.numberOfPages = Math.ceil(data.length / this.pagination.pageSize);

        // Get the total number of items
        this.pagination.total = data.length;

        // Get the paginated data
        this.paginatedData = data.slice(
            (this.pagination.page - 1) * this.pagination.pageSize,
            this.pagination.page * this.pagination.pageSize
        );

        // If the page is greater than the number of pages, set the page to the last page
        if (this.pagination.page > this.pagination.numberOfPages) {
            this.pagination.page = this.pagination.numberOfPages;
        }

        // If the page is less than 1, set the page to 1
        if (this.pagination.page < 1) {
            this.pagination.page = 1;
        }

        // If the page size is greater than the total number of items, set the page size to the total number of items
        if (this.pagination.pageSize > this.pagination.total) {
            this.pagination.pageSize = this.pagination.total;
        }
    }

    /**
     * @description Change the page of the pagination and update the data array with the paginated data of the new page number
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @param {number} page
     * @memberof DataTableComponent
     */
    public changePage(page: number): void {
        this.pagination.page = page;
        this.createPagination(this.sortedData as T[]);
    }

    /**
     * @description Change the page size of the pagination and update the data array with the paginated data of the new page size number
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @param {number} pageSize
     * @memberof DataTableComponent
     */
    public changePageSize(pageSize: number): void {
        this.pagination.pageSize = pageSize;
        this.createPagination(this.sortedData as T[]);
    }

    /**
     * @description Get the number of pages of the pagination to display in the table pagination component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @returns {*}  {number[]}
     * @memberof DataTableComponent
     */
    public getNumberOfPages(): number[] {
        return Array.from(Array(this.pagination.numberOfPages).keys()).map(
            (page: number) => page + 1
        );
    }

    /**
     * @description True if the data string is here to display as an icon in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @param {(T[keyof T] | string)} data
     * @returns {*}  {boolean}
     * @memberof DataTableComponent
     */
    public isIcon(data: T[keyof T] | string): boolean {
        // If the data is not a string, return false
        if (typeof data !== 'string') {
            return true;
        }

        // If the data is a link to an image, return false
        // TODO Remove ts-ignore
        // @ts-ignore
        return !data.startsWith('https');
    }

    /**
     * @description True if there is only one action
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/09/2023
     * @readonly
     * @type {boolean}
     * @memberof DataTableComponent
     */
    public get rowHoverable(): boolean {
        return this.actions?.length === 1;
    }

    /**
     * @description Execute the action on the data if there is only one action and the action is enable for the data, otherwise do nothing
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/09/2023
     * @param {T} data
     * @returns {*}  {void}
     * @memberof DataTableComponent
     */
    public onAction(data: T): void {
        // If there is only one action, execute the action
        if (this.actions?.length !== 1) {
            return;
        }

        // Check if the action is enable for the data
        if (this.actions[0].validator && this.actions[0].validator(data) !== true) {
            return;
        }

        // Execute the action
        this.actions[0].callback(data);
    }
}
