import { DateInterval } from '@omedom/data';
import { formatISO } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

export class OmedomDate {
    public static formatDateToISO(dateToFormat: Date) {
        return formatISO(dateToFormat);
    }

    public static timestampToMilliseconds = (timestamp: Timestamp): number => {
        return timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    };

    public static INVALIDE_DATE = 'Invalid Date';

    /**
     * @description Get the number of days in a period of time (date interval) in days. The number of days is calculated by the difference between the end date and the start date in milliseconds divided by the number of milliseconds in a day (1000 * 60 * 60 * 24). The result is rounded to the nearest whole number using the Math.round() method. The result is returned as a number of days. This method is used to calculate the number of days in a period of time (date interval) in days. It is used to calculate the number of days in the document period and the fiscal period. It is used to calculate the amounts of the rent and charges for the document
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 29/02/2024
     * @private
     * @static
     * @param {DateInterval} dateInterval
     * @returns {*}  {number}
     * @memberof ReceiptAndRelaunchData
     */
    public static numberOfDaysInAPeriod(dateInterval: DateInterval): number {
        return Math.round(
            (dateInterval.endDate.getTime() - dateInterval.startDate.getTime()) /
                (1000 * 60 * 60 * 24)
        );
    }

    // Cannot test it correctly, I don't receive good hours and month, UTC pb ?
    // public static getLastDayOfMonthAtLastMoment(date: Date) {
    //     const year = date.getFullYear();
    //     const month = date.getMonth();

    //     const formatedDate = new Date(year, month + 1, 0, 23, 59, 59);
    //     // console.log();
    //     console.log('month', month);
    //     console.log('date', formatedDate);
    //     return formatedDate;
    // }
}
