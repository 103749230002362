import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import {
    AllChargeCategories,
    AllIncomeCategories,
    AssetTypes,
    BankTransactionEntity,
    ChargePeriodicity,
    IncomePeriodicity,
    OmedomStep,
    UserEntity,
} from '@omedom/data';
import { BankTransactionService, UserService } from '@omedom/services';
import { elementAnimation, listAnimation } from '@omedom/ui';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HeaderPreviousType } from '../../../component/header';

@Component({
    templateUrl: './bank-transaction-association-form.page.html',
    styleUrls: ['./bank-transaction-association-form.page.scss'],
    animations: [
        elementAnimation,
        listAnimation
    ]
})
export class BankTransactionAssociationFormPage implements OnInit, OnDestroy {
    /**
     * @description Header previous type
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {HeaderPreviousType}
     * @memberof BankTransactionAssociationFormPage
     */
    public previousType = HeaderPreviousType;

    /**
     * @description User data observable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {Observable<UserEntity>}
     * @memberof BankTransactionAssociationFormPage
     */
    public user$: Observable<UserEntity>;

    /**
     * @description State of the page (pending, ok, error, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @memberof BankTransactionAssociationFormPage
     */
    public state$ = new BehaviorSubject<string>('pending');

    /**
     * @description Transaction data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionAssociationFormPage
     */
    public transaction?: BankTransactionEntity;

    /**
     * @description List of subscriptions to unsubscribe when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankTransactionAssociationFormPage
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description List of steps for the stepper component in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {OmedomStep[]}
     * @memberof BankTransactionAssociationFormPage
     */
    public steps: OmedomStep[] = [
        new OmedomStep({
            id: 'patrimony',
            label: 'Bien ou société',
            canGoNext: () => {
                const form = this.getForm('patrimony');

                return form.controls.propertyUID.value || form.controls.societyUID.value;
            }
        }),
        new OmedomStep({
            id: 'category',
            label: 'Catégorie',
            canGoNext: () => {
                const form = this.getForm('category');

                return form.controls.category.value;
            }
        }),
        new OmedomStep({
            id: 'details',
            label: 'Details',
            canGoNext: () => {
                const form = this.getForm('details');

                return form.valid;
            }
        }),
        new OmedomStep({
            id: 'recurrence',
            label: 'Récurrence',
            canGoNext: () => {
                return true;
            }
        }),
        new OmedomStep({
            id: 'confirmation',
            label: 'Confirmation',
            canGoNext: () => {
                return this.saveState$.value === 'ok';
            }
        }),
    ];

    /**
     * @description The selected step of the stepper component in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {OmedomStep}
     * @memberof BankTransactionAssociationFormPage
     */
    public selectedStep: OmedomStep = this.steps[0];

    /**
     * @description List of forms for each step of the form in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {FormGroup[]}
     * @memberof BankTransactionAssociationFormPage
     */
    public forms: { stepId: string, form: FormGroup; }[] = [];

    /**
     * @description State of the save button (pending, ok, error, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @memberof BankTransactionAssociationFormPage
     */
    public saveState$ = new BehaviorSubject<string>('ok');

    constructor(
        private userService: UserService,
        private route: ActivatedRoute,
        private bankTransactionService: BankTransactionService,
        private navController: NavController,
        private toastController: ToastController
    ) { }

    ngOnInit(): void {
        this.user$ = this.userService.user$;

        this.getTransaction();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     * @description Get the transaction data from the database using the transaction UID from the route params
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @private
     * @param {string} transactionUID
     * @memberof BankTransactionAssociationFormPage
     */
    private getTransaction(): void {
        this.subscriptions.push(
            this.route.params.pipe(
                switchMap((params: { uid?: string; }) => {
                    const { uid } = params;

                    // Check if the transaction UID is valid
                    if (!uid) {
                        this.state$.next('error');

                        return of();
                    }

                    return this.bankTransactionService._get(uid);
                })
            ).subscribe((transaction: BankTransactionEntity | undefined) => {
                this.transaction = transaction;

                // Check if the transaction is valid
                if (!transaction) {
                    this.state$.next('error');

                    return;
                }

                this.initForms();
                this.state$.next('ok');
            })
        );
    }

    /**
     * @description Initialize the forms for each step of the form in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @private
     * @memberof BankTransactionAssociationFormPage
     */
    private initForms(): void {
        const patrimonyForm = new FormGroup({
            propertyUID: new FormControl<string>(''),
            societyUID: new FormControl<string>(''),
        });

        // Subscribe to the patrimony form changes to update category form
        this.subscriptions.push(
            patrimonyForm.valueChanges.subscribe(() => {
                const categoryForm = this.getForm('category');

                categoryForm.controls.category.reset();
            })
        );

        const categoryForm = new FormGroup({
            category: new FormControl<AllChargeCategories | AllIncomeCategories>(null, Validators.required),
        });

        const detailsForm = new FormGroup({
            treasuryUID: new FormControl<string>(''),
            isNewTreasury: new FormControl<boolean>(false),
            periodicity: new FormControl<string>(''),
            notes: new FormControl<string>(''),
        });

        // Subscribe to the details form changes to toggle the isNewTreasury control
        this.subscriptions.push(
            detailsForm.controls.treasuryUID.valueChanges.subscribe((treasuryUID: string) => {
                if (detailsForm.controls.isNewTreasury.value !== !treasuryUID) {
                    detailsForm.controls.isNewTreasury.setValue(!treasuryUID);
                }

                if (treasuryUID) {
                    detailsForm.controls.periodicity.reset();
                    detailsForm.controls.notes.reset();
                }
            })
        );

        // If isNewTreasury is true, periodicity and notes are required
        // If isNewTreasury is false, treasuryUID is required
        this.subscriptions.push(
            detailsForm.controls.isNewTreasury.valueChanges.subscribe((isNewTreasury: boolean) => {
                if (isNewTreasury) {
                    detailsForm.controls.periodicity.setValidators(Validators.required);
                    detailsForm.controls.treasuryUID.clearValidators();
                } else {
                    detailsForm.controls.periodicity.clearValidators();
                    detailsForm.controls.treasuryUID.setValidators(Validators.required);
                }

                detailsForm.controls.periodicity.updateValueAndValidity();
                detailsForm.controls.treasuryUID.updateValueAndValidity();
            })
        );

        const reccurenceForm = new FormGroup({
            transactionUIDs: new FormControl<string[]>([]),
        });

        this.forms = [
            { stepId: 'patrimony', form: patrimonyForm },
            { stepId: 'category', form: categoryForm },
            { stepId: 'details', form: detailsForm },
            { stepId: 'recurrence', form: reccurenceForm },
        ];
    }

    /**
     * @description Get the form for a specific step of the form in the page using the step ID as a parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @param {string} stepId
     * @returns {FormGroup}
     * @memberof BankTransactionAssociationFormPage
     */
    public getForm(stepId: string): FormGroup {
        return this.forms.find(form => form.stepId === stepId)?.form || new FormGroup({});
    }

    /**
     * @description Get the asset type to retrieve right categories
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @readonly
     * @type {AssetTypes}
     * @memberof BankTransactionAssociationFormPage
     */
    public get assetType(): AssetTypes {
        const patrimonyForm = this.getForm('patrimony');

        return patrimonyForm.controls.propertyUID.value ? AssetTypes.property : AssetTypes.society;
    }

    /**
     * @description Get the category selected in the form for the category step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @readonly
     * @type {AllChargeCategories | AllIncomeCategories}
     * @memberof BankTransactionAssociationFormPage
     */
    public get category(): AllChargeCategories | AllIncomeCategories {
        const categoryForm = this.getForm('category');

        return categoryForm.controls.category.value;
    }

    /**
     * @description Get the patrimony UID to associate the transaction with a treasury item (charge or income) in the user's account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @readonly
     * @type {string}
     * @memberof BankTransactionAssociationFormPage
     */
    public get patrimonyUID(): string {
        const patrimonyForm = this.getForm('patrimony');

        return patrimonyForm.controls.propertyUID.value || patrimonyForm.controls.societyUID.value;
    }

    /**
     * @description Get the patrimony type to retrieve right treasuries (property or society) in the form for the details step
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 07/05/2024
     * @readonly
     * @type {AssetTypes}
     * @memberof BankTransactionAssociationFormPage
     */
    public get patrimonyType(): AssetTypes {
        const patrimonyForm = this.getForm('patrimony');

        return patrimonyForm.controls.propertyUID.value ? AssetTypes.property : AssetTypes.society;
    }

    /**
     * @description Get the treasury UID to associate the transaction with a treasury item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/05/2024
     * @readonly
     * @type {string}
     * @memberof BankTransactionAssociationFormPage
     */
    public get treasuryUID(): string {
        const detailsForm = this.getForm('details');

        return detailsForm.controls.treasuryUID.value;
    }

    /**
     * @description Get the periodicity of the base of the recurrence
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 16/05/2024
     * @readonly
     * @type {(ChargePeriodicity | IncomePeriodicity)}
     * @memberof BankTransactionAssociationFormPage
     */
    public get periodicity(): ChargePeriodicity | IncomePeriodicity {
        const detailsForm = this.getForm('details');

        return detailsForm.controls.periodicity.value;
    }

    /**
     * @description Save association data in the database using the bank transaction service and the data from the form in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @returns {Promise<void>}
     * @memberof BankTransactionAssociationFormPage
     */
    public async submit(): Promise<void> {
        // Prevent multiple clicks on the save button
        this.saveState$.next('pending');

        const association$ = this.bankTransactionService._associate({
            transactionUID: this.transaction?.uid,
            patrimonyUID: this.patrimonyUID,
            patrimonyType: this.patrimonyType,
            treasuryUID: this.treasuryUID,
            reccurences: this.getForm('recurrence').controls.transactionUIDs.value,
            category: this.category,
            periodicity: this.periodicity,
            notes: this.getForm('details').controls.notes.value,
        })
            .subscribe(
                async () => {
                    this.saveState$.next('ok');

                    // Redirect to the previous page
                    this.navController.back();

                    // Display a success toast
                    const toast = await this.toastController.create({
                        message: 'Transaction associée avec succès',
                        duration: 2000,
                        color: 'success',
                    });

                    await toast.present();
                },
                async () => {
                    this.saveState$.next('ok');

                    // Display an error toast
                    const toast = await this.toastController.create({
                        message: 'Erreur lors de l\'association de la transaction',
                        duration: 2000,
                        color: 'danger',
                    });

                    await toast.present();
                }
            );

        this.subscriptions.push(association$);
    }
}
