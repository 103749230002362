import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
    AfterViewInit,
} from '@angular/core';
import { Story } from '@omedom/data';
import { AnimationController, Animation, ModalController } from '@ionic/angular';

import { AnalyticsService } from '@omedom/services';

@Component({
    templateUrl: './story-display.page.html',
    styleUrls: ['./story-display.page.scss'],
})
export class StoryDisplayPage implements OnInit, AfterViewInit {
    @Input() selectedIndex: number;

    @Input() stories: Story[];

    @Input() canNavigate = true;

    @ViewChild('omedomStoryDetail', { read: ElementRef, static: false })
    storyEl: ElementRef;

    selectedStory: Story;
    /**
     * @description Animate apparition and disparition of a story
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof StoryDisplayPage
     */
    private fadeOutRight: Animation;
    /**
     * @description Animate apparition and disparition of a story
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof StoryDisplayPage
     */
    private fadeInLeft: Animation;

    constructor(
        private modalController: ModalController,
        private animationCtrl: AnimationController,
        private cdRef: ChangeDetectorRef,
        private analyticsService: AnalyticsService
    ) {}
    /**
     * @description maximum duration of a swipe animation
     * @author ANDRE Felix
     * @memberof StoryDisplayPage
     */
    maxSwipeDuration = 300;
    /**
     * @description minimum duration of a swipe animation
     * @author ANDRE Felix
     * @memberof StoryDisplayPage
     */
    minSwipeDuration = 100;

    ngOnInit(): void {
        this.selectedStory = this.stories[this.selectedIndex];
    }

    ngAfterViewInit(): void {
        this.fadeOutRight = this.animationCtrl
            .create()
            .addElement(this.storyEl.nativeElement)
            .duration(this.maxSwipeDuration)
            .fromTo('opacity', '1', '0');

        this.fadeInLeft = this.animationCtrl
            .create()
            .addElement(this.storyEl.nativeElement)
            .duration(this.maxSwipeDuration)
            .fromTo('opacity', '0', '1');
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Story Display Page');
    }

    async updateSelectedIndex(newIndex: number, startAnimation = 0): Promise<void> {
        const swipeRight = newIndex > this.selectedIndex;
        const direction = swipeRight ? 'reverse' : 'normal';
        if (newIndex >= 0 && newIndex < this.stories.length) {
            const duration = this.calculDuration(startAnimation, 350);
            if (!swipeRight) {
                await this.fadeOutRight
                    .direction(direction)
                    .fromTo('transform', `translateX(${startAnimation}px)`, 'translateX(350px)')
                    .duration(duration)
                    .play();
                this.selectedIndex = newIndex;
                this.selectedStory = this.stories[this.selectedIndex];
                await this.fadeInLeft
                    .direction(direction)
                    .fromTo('transform', 'translateX(-350px)', 'translateX(0px)')
                    .duration(300)
                    .play();
            } else {
                await this.fadeInLeft
                    .direction(direction)
                    .fromTo('transform', 'translateX(-350px)', `translateX(${startAnimation}px)`)
                    .duration(duration)
                    .play();
                this.selectedIndex = newIndex;
                this.selectedStory = this.stories[this.selectedIndex];
                await this.fadeOutRight
                    .direction(direction)
                    .fromTo('transform', `translateX(0px)`, 'translateX(350px)')
                    .duration(300)
                    .play();
            }
            this.cdRef.detectChanges();
            this.fadeOutRight.stop();
            this.fadeInLeft.stop();
        }
    }
    /**
     * @description calcul the rest duration of a swipe animation
     * @author ANDRE Felix
     * @param {number} deltaX
     * @param {number} maxMouvment
     * @returns {*}  {number}
     * @memberof StoryDisplayPage
     */
    calculDuration(deltaX = 0, maxMouvment: number): number {
        if (deltaX === 0) {
            return this.maxSwipeDuration;
        }
        const tempDuration = Math.round(
            (1 - Math.abs(deltaX) / maxMouvment) * this.maxSwipeDuration
        );
        const duration = tempDuration < 100 ? this.minSwipeDuration : tempDuration;
        return duration;
    }
    /**
     * @description when swipe is fired, update story to right or left
     * @author ANDRE Felix
     * @memberof StoryDisplayPage
     */
    onSwipe(deltaX: number): void {
        if (deltaX < 0) {
            this.updateSelectedIndex(this.selectedIndex + 1, deltaX);
        } else {
            this.updateSelectedIndex(this.selectedIndex - 1, deltaX);
        }
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Change story, or close modal if last story
     * @author ANDRE Felix
     * @private
     * @param {boolean} storyPassed click on yes button
     * @memberof StoryDisplayPage
     */
    protected nextStory(storyPassed: boolean): void {
        if (this.stories.length === this.selectedIndex + 1) {
            // C'était la dernière story, on ferme la fenêtre.
            this.dismiss().then();
        }

        // if (this.selectedIndex === this.stories.length - 1) {
        //     // C'était la dernière story de la liste, mais il reste quand même d'autres stories
        //     this.selectedIndex--;
        // }
        if (storyPassed) {
            this.stories.splice(this.selectedIndex, 1);
        } else {
            this.selectedIndex++;
        }
        this.selectedStory = this.stories[this.selectedIndex];
    }
}
