import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import {
    AllIncomeCategories,
    AssetTypes,
    ChargeAssociateTo,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeCategorySociety,
    IncomeEntity,
    IncomePeriodicity,
    PopupTypes,
    PropertyEntity,
    SelectOption,
    TenantEntity,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    IncomeService,
    PropertyService,
    SmartService,
    SocietyService,
    TenantService,
    UserService,
} from '@omedom/services';
import { FormAssociateToComponent, OmedomFormatNumber, OmedomNumberPipe } from '@omedom/ui';
import { OmedomTreasury } from '@omedom/utils';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { HeaderPreviousType } from '../../../component/header';
import { OmedomRadioOption } from '../../../component/radio';

/* eslint-disable @typescript-eslint/naming-convention */
@Component({
    templateUrl: './income-form.page.html',
    styleUrls: ['./income-form.page.scss'],
})
export class IncomeFormPage implements OnInit, OnDestroy {
    /**
     * @description use to get the data from assotiation input
     * @author ANDRE Felix
     * @type {QueryList<FormAssociateToComponent>}
     * @memberof IncomeFormPage
     */
    @ViewChildren(FormAssociateToComponent) formAssociateTos: QueryList<FormAssociateToComponent>;

    /**
     * @description Active step form state
     * @author Jérémie Lopez
     * @memberof IncomeFormPage
     */
    activeStepForm = 0;

    /**
     * @description User Data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof IncomeFormPage
     */
    user: UserEntity;

    /**
     * @description Designation of the income selected
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeFormPage
     */
    public designation: string;

    /**
     * @description Icon of the income selected
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeFormPage
     */
    public icon: string;

    /**
     * @description Amount of the rent income including charges
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @memberof IncomeFormPage
     */
    public rentWithCharges? = null;

    /**
     * @description Schedule of the income
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeFormPage
     */
    selectedPeriodicity: SelectOption;

    /**
     * @description Link property to income
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeFormPage
     */
    selectedProperty: SelectOption;

    debitDate: string;

    /**
     * @description Start date of the income
     * @author Jérémie Lopez
     * @memberof IncomeFormPage
     */
    startDate: string;

    /**
     * @description End date of the income
     * @author Jérémie Lopez
     * @memberof IncomeFormPage
     */
    endDate: string;

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof IncomeFormPage
     */
    pending$ = new BehaviorSubject<boolean>(false);

    /**
     * @description income Data
     * @author Jérémie Lopez
     * @type {IncomeEntity}
     * @memberof IncomeFormPage
     */
    income?: Partial<IncomeEntity> | IncomeEntity = {};

    incomeCategory = IncomeCategoryProperty;

    periodicityPlaceholder = { label: 'Périodicité' } as SelectOption;

    periodicityOptions$ = of([
        { id: IncomePeriodicity.punctual, label: 'Ponctuelle' } as SelectOption,
        { id: IncomePeriodicity.monthly, label: 'Mensuelle' } as SelectOption,
        {
            id: IncomePeriodicity.bimonthly,
            label: 'Bimestrielle',
        } as SelectOption,
        {
            id: IncomePeriodicity.quarterly,
            label: 'Trimestrielle',
        } as SelectOption,
        {
            id: IncomePeriodicity.halfYearly,
            label: 'Semestrielle',
        } as SelectOption,
        { id: IncomePeriodicity.yearly, label: 'Annuelle' } as SelectOption,
    ]);

    incomePeriodicity = IncomePeriodicity;

    notificationRadioOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    propertyPlaceholder = {
        label: 'Sélectionner',
        icon: 'uil uil-home',
    } as SelectOption;

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    selectedIncomeCategory: IncomeCategoryInfo;

    incomeCategories: IncomeCategoryInfo[];

    isCategorySelectedByRoute = false;

    headerPreviousType = HeaderPreviousType;

    propertyUid: string;

    societyUid: string;

    property: PropertyEntity;

    private subscription: Subscription;

    /**
     * @description if true the previous componenet was income list : used to go back to the proper view with date
     * @author Hanane Djeddal
     * @type {string}
     * @memberof IncomeFormPage
     */
    previousComponent: string;

    /**
     * @description used to display/retrieve the debit date in the form as day only
     * @author Hanane Djeddal
     * @type {number}
     * @memberof IncomeFormPage
     */
    private _displayDebitDate: string = null;
    get displayDebitDate() {
        if (this._displayDebitDate === null && this.startDate) {
            const startdate_toDate = new Date(this.startDate);
            return startdate_toDate.getDate().toString();
        } else {
            return this._displayDebitDate;
        }
    }

    set displayDebitDate(date: string) {
        this._displayDebitDate = date;
    }

    /**
     * @description If true use nav.back() after submit (no specific url is given), else go back via a URL
     * @author Hanane Djeddal
     * @type {boolean}
     * @memberof IncomeFormPage
     */
    routeBack: boolean;

    /**
     * @description contains URL of the previous view, retrieved via router link
     * @author Hanane Djeddal
     * @type {string}
     * @memberof IncomeFormPage
     */
    previousUrl: string;

    /**
     * @description Recieved through routing, when adding an Aid income from tenant form
     * @author Hanane Djeddal
     * @type {TenantEntity}
     * @memberof IncomeFormPage
     */
    tenant: TenantEntity;

    /**
     * @description Recieved through routing
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Navigation}
     * @memberof IncomeFormPage
     */
    navigation: Navigation;

    isOptionPropertyReady = false;

    assetType: AssetTypes;

    lotToAssociate: ChargeAssociateTo[] = [];

    isSelectedPropertyBuilding = false;

    isSelectedPropertySociety = false;

    /**
     * @description Properties where we can assotiate the charge (ex: lot in building)
     * @author ANDRE Felix
     * @type {SelectOption[]}
     * @memberof IncomeFormPage
     */
    propertiesToAssociate: SelectOption[] = [];

    /**
     * @description income associate to other properties (ex: lot in building)
     * @author ANDRE Felix
     * @type {ChargeAssociateTo[]}
     * @memberof IncomeFormPage
     */
    associatedTo: ChargeAssociateTo[] = [];
    /**
     * @description Use to check the total percentage,
     * @author ANDRE Felix
     * @memberof IncomeFormPage
     */
    isTotalPercentageInRange = true;

    hasSmart = false;

    constructor(
        private navController: NavController,
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private activatedRoute: ActivatedRoute,
        private incomeService: IncomeService,
        private toast: ToastController,
        private numberPipe: OmedomNumberPipe,
        private router: Router,
        private tenantService: TenantService,
        private modalController: ModalController,
        private analyticsService: AnalyticsService,
        private smartService: SmartService
    ) {}

    ngOnInit(): void {
        this.analyticsService.logEvent('Income form opened');

        const smart$ = this.smartService.hasSmart$;

        this.incomeCategories = Object.values(IncomeCategoryProperty).map(
            (x) => new IncomeCategoryInfo(x)
        );

        const category$ = this.activatedRoute.queryParams.pipe(map((params) => params.income));
        const assetType$ = this.activatedRoute.queryParams.pipe(map((params) => params.assetType));
        const previousComponent$ = this.activatedRoute.queryParams.pipe(
            map((params) => params.list)
        );
        const tenant$ = this.activatedRoute.queryParams.pipe(map((params) => params.tenant));
        const property$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get('propertyUid'))
        );
        const society$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get('societyUid'))
        );
        const user$ = this.userService.user$.pipe(take(1));
        this.previousUrl = this.router.url.replace('income/', '').replace('form', '');

        this.subscription = combineLatest([
            category$,
            property$,
            user$,
            society$,
            previousComponent$,
            tenant$,
            assetType$,
            smart$,
        ]).subscribe(
            async ([
                category,
                propertyUid,
                user,
                societyUid,
                previousComponent,
                tenant,
                assetType,
                hasSmart,
            ]) => {
                //for routing back from form to proper date:
                if (previousComponent) {
                    this.previousUrl = this.previousUrl.replace('?list=true', '');
                }

                this.hasSmart = hasSmart;

                const incomeCategoryInfo = category
                    ? new IncomeCategoryInfo(IncomeCategoryProperty[category])
                    : null;

                if (category) {
                    this.routeBack = true;
                }

                if (tenant) {
                    this.tenant = await this.tenantService.get(tenant);
                }

                this.propertyUid = propertyUid;
                this.societyUid = societyUid;
                this.activeStepForm = incomeCategoryInfo ? 1 : 0;
                this.isCategorySelectedByRoute = !!incomeCategoryInfo;
                this.previousComponent = previousComponent;
                this.assetType = assetType as AssetTypes;
                this.setCategories(assetType);

                this.user = user;
                if (propertyUid) {
                    this.property = await this.propertyService.get(propertyUid);
                }
                await this.setPropertyOptions(user);

                if (!!this.propertyUid) {
                    this.selectedProperty = this.propertyOptions$.value.find(
                        (x) => x.id === this.propertyUid
                    );
                }
                this.updateIsSelectedAsset();

                this.updatePropertiesToAssociate();
                this.isOptionPropertyReady = true;

                if (incomeCategoryInfo) {
                    this.onCategorySelected(incomeCategoryInfo);
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private setCategories(assetType: AssetTypes) {
        let incomeToCategorize: AllIncomeCategories[] = Object.values(IncomeCategoryProperty);

        switch (assetType) {
            // case AssetTypes.property: {
            //     chargeToCategorize = Object.values(ChargeCategoryProperty);
            // }
            // case AssetTypes.society: {
            //     // We replace chargecategory
            //     chargeToCategorize = Object.values(ChargeCategorySociety);
            // }
            case AssetTypes.society: {
                // (chargeToCategorize = Object.values(ChargeCategorySociety)),
                incomeToCategorize = [
                    ...incomeToCategorize,
                    ...Object.values(IncomeCategorySociety),
                ];
            }
        }

        this.incomeCategories = incomeToCategorize.map((x) => new IncomeCategoryInfo(x));
    }

    private async setPropertyOptions(user: UserEntity) {
        if (!!this.societyUid) {
            const currentSociety = await this.societyService.get(this.societyUid);
            const currentSocietyOption = [
                {
                    id: currentSociety.uid,
                    label: currentSociety.name,
                    image: currentSociety.photo,
                    icon: !currentSociety.photo ? 'uil uil-suitcase' : undefined,
                    assetType: AssetTypes.society,
                },
            ] as SelectOption[];
            let propertyOfSocietyOptions: SelectOption[] = [];

            if (!this.isAssetOptionsForSociety()) {
                propertyOfSocietyOptions = (
                    await this.propertyService.getSocietyPropertiesOptions(this.societyUid)
                ).filter((x) => x.isAccesible);
            }
            if (propertyOfSocietyOptions.length === 0) {
                this.selectedProperty = currentSocietyOption[0];
            }

            this.propertyOptions$.next([...currentSocietyOption, ...propertyOfSocietyOptions]);
        } else if (this.property?.lotsUID && this.property?.lotsUID.length >= 0) {
            const buildingOption = await this.propertyService.getBuildingPropertiesOptions(
                this.propertyUid
            );
            this.propertyOptions$.next(buildingOption);

            if (buildingOption.length === 1) {
                // if there is only a building as selected option, we put it as selected
                this.selectedProperty = buildingOption[0];
            }
        } else {
            const propertyOptions = (
                await this.propertyService.getUserPropertiesAndSharedAsAdminOptions(user.uid)
            ).filter((x) => x.isAccesible);

            if (!this.hasSmart) {
                this.propertyOptions$.next(propertyOptions);
                this.selectedProperty = propertyOptions[0];
                return;
            }

            const societyOptions = await this.societyService.getUserSocietiesOptions(user.uid);
            this.propertyOptions$.next([...societyOptions, ...propertyOptions]);
        }
    }

    private isAssetOptionsForSociety() {
        if (!this.selectedIncomeCategory) {
            return false;
        }
        const societyCategory = Object.values(IncomeCategorySociety);

        for (const category of societyCategory) {
            if (category === this.selectedIncomeCategory.category) {
                return true;
            }
        }
        return false;
    }

    /**
     * @description Reset the form
     * @author Jérémie Lopez
     * @private
     * @memberof IncomeFormPage
     */
    private reset(): void {
        this.activeStepForm = 0;
        this.isCategorySelectedByRoute = false;

        this.income = {};

        this.startDate = null;
        this.endDate = null;

        this.selectedProperty = null;
        this.selectedPeriodicity = null;
        this.selectedIncomeCategory = null;
    }

    onCategorySelected(category: IncomeCategoryInfo): void {
        this.selectedIncomeCategory = category;
        this.income.category = category.category;
        if (this.user) {
            this.setPropertyOptions(this.user);
        }
        this.activeStepForm = 1;
    }

    /**
     * @description Navigate back
     * @author Jérémie Lopez Felix Andre (refacto)
     * @memberof PropertyDataFormPage
     */
    back(date?: Date): void {
        if (history.state.back) {
            this.navController.back();
            return;
        }
        if (this.routeBack || !date || !this.previousUrl) {
            this.navController.pop();
            return;
        }

        if (this.previousComponent) {
            // form was called from list of incomes
            this.router.navigate([this.previousUrl + 'income/list/' + date]);
            return;
        }

        if (this.propertyUid) {
            // form was called from a property view
            this.router.navigate([this.previousUrl], {
                queryParams: {
                    tab: 'income',
                    date,
                },
            });
        } else if (this.societyUid) {
            this.previousUrl = this.previousUrl.replace('/?assetType=society', '');
            // form was called from a society view
            this.router.navigate([this.previousUrl], {
                queryParams: {
                    tab: 'income',
                    date,
                },
            });
        } else {
            this.router.navigate([this.previousUrl + '/income/'], {
                queryParams: {
                    date,
                },
            });
        }
    }

    formatAmountNumber(newValue: string, type?: string) {
        OmedomFormatNumber.formatOmedomInput(newValue, this.numberPipe, type || 'amount');
    }

    /**
     * @description Create or edit an income
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof IncomeFormPage
     */
    public async submit(): Promise<void> {
        this.analyticsService.logEvent('Income form submitted');

        this.pending$.next(true);
        const startdate_toDate = new Date(this.startDate);
        const endDate_toDate = new Date(this.endDate);
        let dateToNavigateAfterSubmit = new Date();

        this.setIncomeWithInputValue();
        this.checkTotalPercentage();

        if (!this.isTotalPercentageInRange) {
            return;
        }

        if (this.income.periodicity === IncomePeriodicity.punctual) {
            dateToNavigateAfterSubmit = new Date(this.debitDate);
            this.setIncomeWhenPunctual(dateToNavigateAfterSubmit);
        } else {
            this.correctEndOfMonthDay(startdate_toDate);
        }

        const isPeriodValid = await this.checkIfValidTimeInterval(startdate_toDate, endDate_toDate);
        if (!isPeriodValid) {
            return;
        }

        OmedomTreasury.calculateDateAndCalculateHistory(
            this.income as IncomeEntity,
            this.debitDate,
            this.startDate,
            this.endDate
        );
        if (this.isPeriodicityLonguerThanMonthly()) {
            dateToNavigateAfterSubmit = this.setNavigationDateForLongPeriodicity();
        }

        await this.saveIncomeCreated(dateToNavigateAfterSubmit);
    }

    private setIncomeWithInputValue() {
        this.income.amount = +this.numberPipe.parse(this.income.amount.toString());
        this.income.userUID = this.user.uid;
        this.income.periodicity = this.selectedPeriodicity.id;

        if (
            this.selectedProperty.id === this.societyUid ||
            this.selectedProperty.assetType === AssetTypes.society
        ) {
            // if the income is for a society
            this.income.societyUID = this.selectedProperty.id;
        } else {
            this.income.propertyUID = this.selectedProperty.id;
        }

        if (this.income.rentWithCharges) {
            this.income.rentWithCharges = +this.numberPipe.parse(
                this.income.rentWithCharges.toString()
            );
        }
        this.getAssociatedData();
    }

    getAssociatedData() {
        this.income.associatedTo = [];
        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                this.income.associatedTo.push(formAssociateTo.getInputData());
            });
        }
    }

    private setIncomeWhenPunctual(dateToNavigateAfterSubmit: Date) {
        delete this.income.endDate;
        this.income.isReaded = false;
        this.income.isPayed = dateToNavigateAfterSubmit.getTime() < new Date().getTime();
    }

    private correctEndOfMonthDay(startDate: Date) {
        // if Month day invalid (31 for a month with only 30days, it passes to the first day of the next month)
        this.debitDate = this.displayDebitDate === '31' ? '30' : this.displayDebitDate;
        const datefromstart = new Date(this.startDate);
        datefromstart.setDate(+this.debitDate);
        if (startDate.getMonth() !== datefromstart.getMonth()) {
            datefromstart.setDate(+this.debitDate); //correct the day
        }
        this.debitDate = formatDate(datefromstart, 'YYYY-MM-dd', 'fr');
    }

    private async checkIfValidTimeInterval(startDate: Date, enDate: Date) {
        if (this.endDate && startDate > enDate) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message: 'Veuillez renseigner une date de début antérieure à la date de fin',
            });

            await toast.present();

            this.pending$.next(false);
            return false;
        }
        if (startDate.getFullYear() < this.selectedProperty.purchaseYear) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    "Veuillez renseigner une date de début de contrat postérieure à la date d'acquisition du bien",
            });

            await toast.present();
            this.pending$.next(false);
            return false;
        }
        return true;
    }

    private isPeriodicityLonguerThanMonthly() {
        return (
            this.income.periodicity === IncomePeriodicity.yearly ||
            this.income.periodicity === IncomePeriodicity.halfYearly ||
            this.income.periodicity === IncomePeriodicity.bimonthly ||
            this.income.periodicity === IncomePeriodicity.quarterly
        );
    }

    private setNavigationDateForLongPeriodicity() {
        if (this.income.history?.length > 1) {
            return this.income.history
                .sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime())[0]
                .date.toDate();
        } else if (this.income.startDate) {
            return new Date(this.income.startDate.toDate());
        } else {
            return new Date();
        }
    }

    private async saveIncomeCreated(dateToNavigateAfterSubmit: Date) {
        try {
            await this.incomeService.create(this.income);
            if (this.tenant) {
                this.updateTenant();
            }
            this.reset();

            const popupValidated = this.isPopupValidated();

            if (!!this.user.bridgeUUID || popupValidated) {
                this.displaySuccessToast(dateToNavigateAfterSubmit);
            }
        } catch (error) {
            this.displayErrorToast(error);
        }
    }

    private async updateTenant() {
        this.tenant.helpUID = this.income.uid;
        await this.tenantService.update(this.tenant);
    }

    private isPopupValidated() {
        this.user.popups?.filter((popup) => {
            if (
                popup.name === 'To banking connexion' &&
                popup.version === 1 &&
                popup.type === PopupTypes.redirection &&
                popup.isValidated === true
            ) {
                return true;
            } else {
                return false;
            }
        }).length > 0;
    }

    private async displaySuccessToast(dateToNavigate) {
        const toast = await this.toast.create({
            position: 'top',
            color: 'primary',
            duration: 4000,
            message: 'Vous avez enregistré un revenu.',
        });
        await toast.present();
        this.pending$.next(false);
        this.back(dateToNavigate);
    }

    async handleAssociateIncomeTo() {
        // TODO actually, when we create a charge globally, it's complicated to had the
        // functionnality to associate charge (in building and lot) with actual code, and
        // keep something readable and understandable
        if (!(this.property || this.societyUid)) {
            return;
        }
        this.updateIsSelectedAsset();

        if (!this.property && this.selectedProperty.id) {
            this.property = await this.propertyService.get(this.selectedProperty.id);
        }
        if (this.isSelectedPropertyBuilding || this.isSelectedPropertySociety) {
            this.updatePropertiesToAssociate();
        }
    }

    updatePropertiesToAssociate() {
        if (!this.selectedProperty || !this.property) {
            return;
        }
        this.propertiesToAssociate = this.propertyOptions$.value.filter((property) => {
            if (property.id === this.societyUid || property.id === this.property.uid) {
                return false;
            }
            return true;
        });
        this.lotToAssociate = this.propertiesToAssociate.map((propertyOption) => {
            return {
                propertyUid: propertyOption.id,
                propertyLabel: propertyOption.label,
                percentage: 0,
                amount: 0,
            };
        });
    }

    updateIsSelectedAsset() {
        this.isSelectedPropertyBuilding = this.selectedProperty?.assetType === AssetTypes.building;
        this.isSelectedPropertySociety = this.selectedProperty?.assetType === AssetTypes.society;
    }

    onPercentageChange(val: Partial<ChargeAssociateTo>) {
        let totalPourcentage = 0;
        let currentModificatedForm: FormAssociateToComponent;
        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                totalPourcentage += formAssociateTo.percentage;
                if (formAssociateTo.propertyInfo.propertyUid === val.propertyUid) {
                    currentModificatedForm = formAssociateTo;
                }
            });
        }
        this.checkValueIsInPercentageRange(totalPourcentage);

        if (totalPourcentage > 100) {
            const maxPossibleValue = 100 - (totalPourcentage - val.percentage);
            currentModificatedForm.percentageChange(maxPossibleValue);
            this.checkValueIsInPercentageRange(maxPossibleValue);
        }
    }

    checkTotalPercentage() {
        let totalPourcentage = 0;

        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                totalPourcentage += formAssociateTo.percentage;
            });
        }
        this.checkValueIsInPercentageRange(totalPourcentage);
    }

    checkValueIsInPercentageRange(value: number) {
        if (value > 100 || value < 0) {
            this.isTotalPercentageInRange = false;
        } else {
            this.isTotalPercentageInRange = true;
        }
    }

    private async displayErrorToast(error: any) {
        console.error(error);
        const toast = await this.toast.create({
            position: 'top',
            color: 'danger',
            duration: 4000,
            message: "Une erreur s'est produite, veuillez réessayer plus tard.",
        });
        await toast.present();
        this.pending$.next(false);
    }
}
