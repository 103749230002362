import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { IncomeCategoryProperty, LeaseEntity, RoleState, TenantEntity } from '@omedom/data';
import { IncomeService } from '@omedom/services';

import { TenantFormModalComponent } from '../../tenant-form/tenant-form-modal.component';

@Component({
    selector: 'omedom-lease-form-tenant',
    templateUrl: './tenant.component.html',
    styleUrls: ['./tenant.component.scss'],
})
export class TenantComponent implements OnInit {
    @Input() lease: Partial<LeaseEntity> = {};

    @Input() tenants: Partial<TenantEntity>[] = [];

    @Input({ required: true }) roleState?: RoleState;

    @Input() canAddAid: boolean = true;

    currentTenant: Partial<TenantEntity> = {};

    tenantEdit: Partial<TenantEntity> = {};

    edit: boolean = false;

    afterAddAid: boolean = false;

    toUpdateAfterAid?: number;

    constructor(
        private modalController: ModalController,
        private router: Router,
        private incomeService: IncomeService
    ) {}

    async ngOnInit(): Promise<void> {
        if (!this.lease.propertyUID) return;
        const aids = await this.incomeService.getIncomesByCategoryByPropertyIds(
            IncomeCategoryProperty.aid,
            [this.lease.propertyUID]
        );
        const orderedAids = aids.sort(
            (a, b) =>
                new Date((b.created as any).seconds * 1000).getTime() -
                new Date((a.created as any).seconds * 1000).getTime()
        );
        if (this.toUpdateAfterAid) {
            this.tenants[this.toUpdateAfterAid].helpUID = orderedAids[0].uid;
        }
    }

    public async openModal(index?: number): Promise<void> {
        if (!this.edit) {
            this.tenantEdit = {};
            this.tenantEdit.warrantor = {
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
            };
        }
        const modal = await this.modalController.create({
            component: TenantFormModalComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: { lease: this.lease, tenant: this.tenantEdit },
        });

        await modal.present();
        modal.onDidDismiss().then((x) => {
            if (x.data === 'saved') {
                this.currentTenant = this.tenantEdit;

                if (this.edit && (index || index === 0)) {
                    this.tenants[index] = this.tenantEdit;
                }
                this.saveTenant();
            } else {
                this.edit = false;
            }
        });
    }

    public async saveTenant(): Promise<void> {
        if (!this.edit) {
            this.tenants.push(this.currentTenant);
        }
        this.edit = false;
    }
    public removeTenant(index: number): void {
        this.tenants.splice(index, 1);
    }

    public editTenant(index: number): void {
        this.currentTenant = this.tenants[index];
        this.tenantEdit = { ...this.currentTenant };
        this.edit = true;
        this.openModal(index);
    }

    public async addAid(i: number): Promise<void> {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                income: IncomeCategoryProperty.aid,
            },
            state: { back: true },
        };

        await this.router.navigate(
            [`/tabs/property/info/${this.lease.propertyUID}/income/form`],
            navigationExtras
        );

        this.afterAddAid = true;
        this.toUpdateAfterAid = i;
    }
}
