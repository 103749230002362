import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Story, Mode } from '@omedom/data';

@Component({
    selector: 'omedom-movement-card',
    templateUrl: './movement-card.component.html',
    styleUrls: ['./movement-card.component.scss'],
})
export class MovementCardComponent implements OnInit {
    @Input() story?: Story;

    @Input() isPropertyCard = false;

    @Input() isSocietyStory?: boolean;

    @Input() isBuildingStory?: boolean;

    @Input() futureCard?: boolean;

    @Input() mode: Mode = Mode.app;

    @Input() deactivateNoSmart?: boolean;

    @Output() storyClicked = new EventEmitter();

    protected allMode = Mode;

    public now: Date = new Date();

    @HostListener('click')
    onClick(): void {

        this.storyClicked.next(null);

    }
    constructor() { }

    ngOnInit() { }
}
