import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProEntity } from '@omedom/data';
import { ProService, UserService } from '@omedom/services';
import { Subscription, filter, of, switchMap } from 'rxjs';

@Component({
    selector: 'omedom-directory-pro-list',
    templateUrl: './directory-pro-list.component.html',
    styleUrls: ['./directory-pro-list.component.scss'],
})
export class DirectoryProListComponent implements OnInit {
    /**
     * @description list of Pro, i don't know which one for the moment
     * @author ANDRE Felix
     * @type {ProEntity[]}
     * @memberof DirectoryProListComponent
     */
    pros: ProEntity[] = [];

    /**
     * @description pros filtered depending on search
     * @author ANDRE Felix
     * @memberof DirectoryProListComponent
     */
    prosFiltered: ProEntity[] = [];

    subscriptions: Subscription[] = [];
    constructor(
        private userService: UserService,
        private proService: ProService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        const user$ = this.userService.user$
            .pipe(
                filter((user) => !!user),
                switchMap((user) => {
                    // TODO: get real data, list of pro, maybe link to the user
                    // if (user.proUID) {
                    return this.proService._list();
                    // return this.proService._get(user.proUID);
                    // }
                    return of([]);
                })
            )
            .subscribe((pro) => {
                if (pro) {
                    this.pros = pro;
                    this.prosFiltered = this.pros;
                }
            });
    }

    detailClicked(proUID: string) {
        if (!proUID) {
            return;
        }
        this.router.navigate([`pro-detail`], {
            relativeTo: this.route,
            queryParams: { proUID },
        });
    }

    searchChanged(searchValue: string) {
        if (searchValue === '' || searchValue === undefined) {
            this.prosFiltered = this.pros;
        }

        this.prosFiltered = this.pros.filter((pro) => {
            const isInProfession = pro.position?.toLowerCase().includes(searchValue);
            const isInExpertiseField = this.isIncludeInExpertiseField(pro, searchValue);

            return !!isInProfession || !!isInExpertiseField;
        });
    }

    isIncludeInExpertiseField(pro: ProEntity, searchValue: string) {
        if (!pro?.expertiseField || pro.expertiseField.length === 0) {
            return false;
        }
        const indexFind = pro.expertiseField.findIndex((expertiseField) => {
            return expertiseField.toLowerCase().includes(searchValue);
        });
        if (indexFind === -1) {
            return false;
        }
        return true;
    }
}
