// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charges-container {
  border-left: 2px solid #eeeeee;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluY29tZS1saXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksOEJBQUE7QUFDSiIsImZpbGUiOiJpbmNvbWUtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jaGFyZ2VzLWNvbnRhaW5lciB7XG4gICAgYm9yZGVyLWxlZnQ6IDJweCBzb2xpZCAjZWVlZWVlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/income/containers/income-list/income-list.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;AACJ;AACA,wVAAwV","sourcesContent":[".charges-container {\n    border-left: 2px solid #eeeeee;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
