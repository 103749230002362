import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChargeEntity, IncomeEntity } from '@omedom/data';
import { OmedomTreasury } from '@omedom/utils';
import { OmedomDateType } from '../../../component/date';

@Component({
    selector: 'app-widget-treasury',
    templateUrl: './widget-treasury.component.html',
    styleUrls: ['./widget-treasury.component.scss'],
})
export class WidgetTreasuryComponent implements OnChanges {
    @Input() charges: ChargeEntity[] = [];

    @Input() incomes: IncomeEntity[] = [];

    @Input() currentDate: Date;

    totalCharge = 0;

    totalIncome = 0;

    omedomDateType = OmedomDateType;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.charges || changes.incomes) {
            this.updateData();
        }
    }

    dateChange(date: Date): void {
        this.currentDate = date.getUTCFirstDayOfMonth();
        this.updateData();
    }

    private updateData(): void {
        if (this.charges && this.incomes) {
            const startDate = this.currentDate.getUTCFirstDayOfMonth();
            const endDate = this.currentDate.getUTCLastDayOfMonth();

            const charges = OmedomTreasury.filterTreasury(
                this.charges,
                startDate.getUTCDateWithoutTime(),
                endDate.getUTCDateWithoutTime()
            );
            this.totalCharge = charges.sumBy((x) =>
                OmedomTreasury.getMonthAmount(x, this.currentDate)
            );

            const incomes = OmedomTreasury.filterTreasury(
                this.incomes,
                startDate.getUTCDateWithoutTime(),
                endDate.getUTCDateWithoutTime()
            );
            this.totalIncome = incomes.sumBy((x) =>
                OmedomTreasury.getMonthAmount(x, this.currentDate)
            );
        }
    }
}
