import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-toggle-with-icon',
    templateUrl: './toggle-with-icon.component.html',
    styleUrls: ['./toggle-with-icon.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleWithIconComponent),
            multi: true,
        },
    ],
})
export class ToggleWithIconComponent implements ControlValueAccessor {
    /**
     * @description Left icon on the toggle
     * @type {string}
     */
    @Input() public switchContent1: { icon?: string, text?: string };
    @Input() public switchContent2: { icon?: string, text?: string };

    value: boolean;
    private onChangeCallback: any;
    private isDisabled: boolean;

    writeValue(newValue: boolean): void {
        this.value = newValue;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void { }

    setDisabledState?(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    toggle(): void {
        const newValue = !this.value;
        this.writeValue(newValue);
        this.onChangeCallback(newValue);
    }
}
