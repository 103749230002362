/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import {
    CategoryInfo,
    ChargeEntity,
    IncomeEntity,
    PropertiesFilter,
    PropertyEntity,
    SelectOption,
    UserEntity,
} from '@omedom/data';
import { PropertyService, UserService } from '@omedom/services';
import { OmedomChart, OmedomDoughnutChart, OmedomTreasury } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { OmedomChartLegend } from '../../component/chart-legend';
import { OmedomDateType } from '../../component/date';
import { OmedomSubTab } from '../../component/sub-tab';

@Injectable()
export abstract class TreasuryInfo<TEntity extends ChargeEntity | IncomeEntity, TCategory>
    implements OnInit, OnDestroy
{
    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

    user: UserEntity;

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({
            id: 1,
            label: 'Comptabilité automatique',
            popover: 'Fonctionnalité bientôt disponible dans votre abonnement Smart.',
            disabled: true,
        }),
        new OmedomSubTab({ id: 2, label: 'Comptabilité manuelle' }),
    ];

    selectedSubTab: OmedomSubTab;

    doughnutChartData = OmedomDoughnutChart.doughnutChartData;

    doughnutChartOptions = OmedomDoughnutChart.doughnutChartOptions;

    total = 0;

    chartLegends: OmedomChartLegend[] = [];

    omedomDateType = OmedomDateType;

    categoryInfo: CategoryInfo<TCategory>;

    private treasury: TEntity[] = [];

    protected properties: PropertyEntity[] = [];

    private subscriptions: Subscription[] = [];

    totalAmount: number;

    totalAmountPayed: number;

    totalAmountNotPayed: number;

    selectedProperties: SelectOption[] = [];

    currentDate: Date;

    protected constructor(
        private userService: UserService,
        private navController: NavController,
        private activatedRoute: ActivatedRoute,
        private propertyService: PropertyService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    abstract get queryParamsName(): string;

    /**
     *
     *
     * @memberof TreasuryInfo
     */
    ngOnInit(): void {
        this.selectedProperties = PropertiesFilter.filteredProperties;
        this.selectedSubTab = this.subTabs[1];
        this.currentDate = new Date();
        const route$ = this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams[this.queryParamsName]) {
                this.categoryInfo = this.loadCategory(queryParams[this.queryParamsName]);
            }
            if (queryParams.date) {
                this.currentDate = new Date(queryParams.date);
            }

            const user$ = this.userService.user$.pipe(take(1)).subscribe(async (user) => {
                this.user = user;
                this.propertyService
                    ._getUserPropertiesAndSharedAccessible(user.uid)
                    .subscribe(async (properties) => {
                        this.properties = properties;
                        this.treasury = await this.loadTreasury(
                            this.user.uid,
                            this.categoryInfo.category
                        );

                        this.treasury = this.treasury.filter((x) =>
                            properties.some((property) => property.uid === x.propertyUID)
                        );

                        this.updateGraphData(this.currentDate);
                    });
                // this.properties =
                //     await this.propertyService.getUserPropertiesAndSharedAccessible(user.uid);
                // this.treasury = await this.loadTreasury(this.user.uid, this.categoryInfo.category);

                // this.treasury = this.treasury.filter((x) =>
                //     this.properties.some((property) => property.uid === x.propertyUID)
                // );

                // this.updateGraphData(this.currentDate);
            });

            this.subscriptions.push(user$);
        });

        this.subscriptions.push(route$);
    }

    /**
     * @description updates graph and data when the filter has been changed (through select app)
     */
    async updateFilter() {
        this.treasury = await this.loadTreasury(this.user.uid, this.categoryInfo.category);
        this.updateGraphData(this.currentDate);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    dateChange(date: Date) {
        this.currentDate = date;
        // this.router.navigate([], {
        //     relativeTo: this.route,
        //     queryParams: {
        //         date,
        //     },
        //     queryParamsHandling: 'merge',
        // });
        this.updateGraphData(date);
    }

    back(): void {
        this.navController.pop();
    }

    updateGraphData(date: Date) {
        const startDate = date.getUTCFirstDayOfMonth();
        const endDate = date.getUTCLastDayOfMonth();

        const filteredData = OmedomTreasury.filterTreasury<TEntity>(
            this.treasury,
            startDate.getUTCDateWithoutTime(),
            endDate.getUTCDateWithoutTime()
        );
        const amount = this.getAmountsProperty(filteredData, startDate);

        this.total = amount.sumBy((x) => x.amount);
        this.totalAmount = this.total;
        this.totalAmountPayed = amount.filter((x) => !!x.isPayed).sumBy((x) => x.amount);
        this.totalAmountNotPayed = amount.filter((x) => !x.isPayed).sumBy((x) => x.amount);

        amount.sort((a, b) => {
            if (!!a.isPayed && !b.isPayed) {
                return -1;
            } else if (!a.isPayed && !!b.isPayed) {
                return 1;
            }
            return a.propertyName.localeCompare(b.propertyName);
        });

        const treasuriesGrouped = amount.reduce((tbt, treasury) => {
            const key = treasury.propertyUID + !!treasury.isPayed;
            if (!tbt[key]) {
                tbt[key] = {
                    propertyUID: treasury.propertyUID,
                    amount: 0,
                    percent: 0,
                    isPayed: !!treasury.isPayed,
                    category: treasury.category,
                };
            }
            tbt[key].amount += treasury.amount;
            tbt[key].percent = Math.round((tbt[key].amount * 100) / this.total);
            return tbt;
        }, {});

        const treasuryList: {
            propertyUID: string;
            amount: number;
            percent: number;
            isPayed: boolean;
            category: string;
        }[] = Object.values(treasuriesGrouped);

        this.chartLegends = treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.category];
            return new OmedomChartLegend({
                label: this.properties.find((property) => x.propertyUID === property.uid)?.name,
                // percent: x.percent,
                amount: x.amount,
                color: !!x.isPayed ? color : OmedomChart.hatchColor(color, 'legend'),
            });
        });

        const colorsWithSaturation = treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.category];
            return !!x.isPayed ? color : OmedomChart.hatchColor(color);
        });

        this.doughnutChartData.labels = treasuryList.map(
            (x) => this.properties.find((property) => x.propertyUID === property.uid)?.name
        );
        this.doughnutChartData.datasets[0].data = treasuryList.map((x) => x.percent);
        this.doughnutChartData.datasets[0].backgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBackgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBorderColor = colorsWithSaturation;

        if (treasuryList.length === 1) {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: OmedomDoughnutChart.doughnutChartOptions.spacing,
                elements: OmedomDoughnutChart.doughnutChartOptions.elements,
            };
        }

        this.baseChart.update();
    }

    private getAmountsProperty(
        entities: TEntity[],
        startDate: Date
    ): {
        propertyUID: string;
        propertyName: string;
        amount: number;
        isPayed: boolean;
        category: string;
    }[] {
        return entities.map((entity) => ({
            propertyUID: entity.propertyUID,
            propertyName: this.properties.find((property) => entity.propertyUID === property.uid)
                ?.name,
            amount: Math.abs(OmedomTreasury.getMonthAmount(entity, startDate)),
            isPayed: OmedomTreasury.isTreasuryPayed(entity, startDate, new Date()),
            category: entity.category,
        }));
    }

    abstract loadCategory(category: string): CategoryInfo<TCategory>;

    abstract loadTreasury(userUid: string, category: TCategory): Promise<TEntity[]>;
}
