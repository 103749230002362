import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController, Platform, ToastController } from '@ionic/angular';
import {
    AllChargeCategories,
    AssetTypes,
    ChargeAssociateTo,
    ChargeCategoryBuilding,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeCategorySociety,
    ChargeEntity,
    ChargePeriodicity,
    PopupTypes,
    PropertyEntity,
    PropertyType,
    SelectOption,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    PropertyService,
    PropertyTutorialService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { FormAssociateToComponent, OmedomFormatNumber, OmedomIsAuthorisedPipe, OmedomNumberPipe } from '@omedom/ui';
import { OmedomTreasury } from '@omedom/utils';
import { ShepherdService } from 'angular-shepherd';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import Step from 'shepherd.js/src/types/step';
import { HeaderPreviousType } from '../../../component/header';
import { OmedomRadioOption } from '../../../component/radio';

@Component({
    templateUrl: './charge-form.page.html',
    styleUrls: ['./charge-form.page.scss'],
})
export class ChargeFormPage implements OnInit, OnDestroy {
    /**
     * @description use to get the data from assotiation input
     * @author ANDRE Felix
     * @type {QueryList<FormAssociateToComponent>}
     * @memberof ChargeFormPage
     */
    @ViewChildren(FormAssociateToComponent) formAssociateTos: QueryList<FormAssociateToComponent>;
    /**
     * @description Active step form state
     * @author Jérémie Lopez
     * @memberof ChargeFormPage
     */
    activeStepForm = 0;

    /**
     * @description User Data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof ChargeFormPage
     */
    user: UserEntity;

    /**
     * @description If true, the app is pending
     * @author Jérémie Lopez
     * @memberof ChargeFormPage
     */
    pending$ = new BehaviorSubject<boolean>(false);

    charge?: Partial<ChargeEntity> | ChargeEntity = {};

    /**
     * @description Schedule of the income
     * @author Jérémie Lopez
     * @type {string}
     * @memberof IncomeFormPage
     */
    selectedPeriodicity: SelectOption;

    /**
     * @description Property UID to redirect after charge created
     * @author ANDRE Felix
     * @type {string}
     * @memberof PropertyTutorialService
     */
    public propertyUID?: string;

    periodicityPlaceholder = { label: 'Périodicité' } as SelectOption;

    /**
     * @description used to display/retrieve the debit date in the form as day only
     * @author Hanane Djeddal
     * @type {number}
     * @memberof ChargeFormPage
     */
    private _displayDebitDate: string = null;
    get displayDebitDate() {
        if (this._displayDebitDate === null && this.startDate) {
            const startdate_toDate = new Date(this.startDate);
            return startdate_toDate.getDate().toString();
        } else {
            return this._displayDebitDate;
        }
    }

    set displayDebitDate(date: string) {
        this._displayDebitDate = date;
    }

    public chargeForm: UntypedFormGroup;

    periodicityOptions$ = of([
        { id: ChargePeriodicity.punctual, label: 'Ponctuelle' } as SelectOption,
        { id: ChargePeriodicity.monthly, label: 'Mensuelle' } as SelectOption,
        {
            id: ChargePeriodicity.bimonthly,
            label: 'Bimestrielle',
        } as SelectOption,
        {
            id: ChargePeriodicity.quarterly,
            label: 'Trimestrielle',
        } as SelectOption,
        {
            id: ChargePeriodicity.halfYearly,
            label: 'Semestrielle',
        } as SelectOption,
        { id: ChargePeriodicity.yearly, label: 'Annuelle' } as SelectOption,
    ]);

    chargePeriodicity = ChargePeriodicity;

    notificationRadioOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    selectedProperty: SelectOption;

    propertyPlaceholder = {
        label: 'Sélectionner',
        icon: 'uil uil-home',
    } as SelectOption;

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    selectedChargeCategory: ChargeCategoryInfo;

    chargeCategories: ChargeCategoryInfo[];

    debitDate: string;

    startDate: string;

    endDate: string;

    isCategorySelectedByRoute = false;

    headerPreviousType = HeaderPreviousType;

    propertyUid: string;

    societyUid: string;

    property: PropertyEntity;

    private subscription: Subscription;

    /**
     * @description used to position tutorial popups
     * @author Hanane Djeddal
     * @type {number}
     * @memberof ChargeFormPage
     */
    offset: number;

    /**
     * @description used to hidethe tabs bar of the app
     * @author Hanane Djeddal
     * @memberof ChargeFormPage
     */
    @ViewChild('tabsBar') tabsBar;

    /**
     * @description if true the previous componenet was charge list : used to go back to the proper view with date
     * @author Hanane Djeddal
     * @type {string}
     * @memberof ChargeFormPage
     */
    previousComponent: string;

    /**
     * @description If true use nav.back() after submit (no specific url is given), else go back via a URL
     * @author Hanane Djeddal
     * @type {boolean}
     * @memberof ChargeFormPage
     */
    routeBack: boolean;

    /**
     * @description contains URL of the previous view, retrieved via router link
     * @author Hanane Djeddal
     * @type {string}
     * @memberof ChargeFormPage
     */
    previousUrl: string;

    isOptionPropertyReady = false;

    assetType: AssetTypes;

    /**
     * @description charge associate to other properties (ex: lot in building)
     * @author ANDRE Felix
     * @type {ChargeAssociateTo[]}
     * @memberof ChargeFormPage
     */
    associatedTo: ChargeAssociateTo[] = [];

    /**
     * @description Properties where we can assotiate the charge (ex: lot in building)
     * @author ANDRE Felix
     * @type {SelectOption[]}
     * @memberof ChargeFormPage
     */
    propertiesToAssociate: SelectOption[] = [];

    lotToAssociate: ChargeAssociateTo[] = [];

    isSelectedPropertyBuilding = false;

    isSelectedPropertySociety = false;

    /**
     * @description Use to check the total percentage,
     * @author ANDRE Felix
     * @memberof ChargeFormPage
     */
    isTotalPercentageInRange = true;

    /**
     * @description Used to prevent displaying the society in the property options when the user doesn't have the appropriate subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 17/06/2024
     * @private
     * @memberof ChargeFormPage
     */
    private canManageSociety = false;

    constructor(
        private navController: NavController,
        private userService: UserService,
        private propertyService: PropertyService,
        private activatedRoute: ActivatedRoute,
        private chargeService: ChargeService,
        private toast: ToastController,
        private numberPipe: OmedomNumberPipe,
        private readonly shepherdService: ShepherdService,
        private router: Router,
        private platform: Platform,
        protected propertyTutorialService: PropertyTutorialService,
        private analyticsService: AnalyticsService,
        private societyService: SocietyService,
        private omedomIsAuthorisedPipe: OmedomIsAuthorisedPipe,
    ) { }

    async ngOnInit(): Promise<void> {

        this.omedomIsAuthorisedPipe.transform('accessManageSociety').subscribe((isAuthorised) => {
            this.canManageSociety = isAuthorised;
        });

        this.analyticsService.logEvent('charge form opened');

        const category$ = this.activatedRoute.queryParams.pipe(map((params) => params.charge));
        const assetType$ = this.activatedRoute.queryParams.pipe(map((params) => params.assetType));

        const previousComponent$ = this.activatedRoute.queryParams.pipe(
            map((params) => params.list)
        );
        const property$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get('propertyUid'))
        );
        const society$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get('societyUid'))
        );
        const user$ = this.userService.user$.pipe(take(1));
        this.previousUrl = this.router.url.replace('charge/', '').replace('form', '');

        this.subscription = combineLatest([
            category$,
            property$,
            user$,
            society$,
            previousComponent$,
            assetType$,
        ]).subscribe(
            async ([
                category,
                propertyUid,
                user,
                societyUid,
                previousComponent,
                assetType,
            ]) => {
                //for routing back from form to proper date:
                if (previousComponent) {
                    this.previousUrl = this.previousUrl.replace('?list=true', '');
                }

                const chargeCategoryInfo = category
                    ? new ChargeCategoryInfo(
                        category as ChargeCategoryProperty | ChargeCategoryBuilding
                    )
                    : null;

                if (category) {
                    this.routeBack = true;
                }

                this.propertyUid = propertyUid;
                this.societyUid = societyUid;
                this.activeStepForm = chargeCategoryInfo ? 1 : 0;
                this.isCategorySelectedByRoute = !!chargeCategoryInfo;
                this.previousComponent = previousComponent;
                this.assetType = assetType as AssetTypes;

                this.user = user;
                if (propertyUid) {
                    this.property = await this.propertyService.get(propertyUid);
                    if (this.property?.type === PropertyType.immeuble) {
                        this.assetType = AssetTypes.building;
                    }
                }
                this.setCategories(this.assetType);

                await this.setPropertyOptions(user);

                if (!!this.propertyUid) {
                    this.selectedProperty = this.propertyOptions$.value.find(
                        (x) => x.id === this.propertyUid
                    );
                }
                this.updateIsSelectedAsset();

                this.isOptionPropertyReady = true;
                this.updatePropertiesToAssociate();
                if (chargeCategoryInfo) {
                    this.onCategorySelected(chargeCategoryInfo);
                }

                let tutorialFirstStep;
                this.propertyTutorialService.tutorialFirstStep$.subscribe(
                    (started) => (tutorialFirstStep = started)
                );
                if (
                    this.propertyUid &&
                    !this.user?.finishedTutorials?.property &&
                    !tutorialFirstStep
                ) {
                    if (!this.shepherdService.isActive) {
                        this.launchTutorial();
                    } else {
                        const tempChargeCategoryInfo = new ChargeCategoryInfo(
                            ChargeCategoryProperty[ChargeCategoryProperty.insurance]
                        );
                        this.onCategorySelected(tempChargeCategoryInfo);
                        this.selectedPeriodicity = {
                            id: ChargePeriodicity.monthly,
                            label: 'Mensuelle',
                        } as SelectOption;
                        this.activeStepForm = 1;
                    }
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    private setCategories(assetType: AssetTypes) {
        let chargeToCategorize: AllChargeCategories[] = Object.values(ChargeCategoryProperty);

        switch (assetType) {
            // case AssetTypes.property: {
            //     chargeToCategorize = Object.values(ChargeCategoryProperty);
            // }
            case AssetTypes.building:
                // (chargeToCategorize = Object.values(ChargeCategorySociety)),
                chargeToCategorize = [
                    ...chargeToCategorize,
                    ...Object.values(ChargeCategoryBuilding),
                ];
                break;

            case AssetTypes.society:
                // (chargeToCategorize = Object.values(ChargeCategorySociety)),
                chargeToCategorize = [
                    ...chargeToCategorize,
                    ...Object.values(ChargeCategorySociety),
                ];
                break;
        }

        this.chargeCategories = chargeToCategorize.map((x) => new ChargeCategoryInfo(x));
    }

    private async setPropertyOptions(user: UserEntity) {
        if (!!this.societyUid) {
            const currentSociety = await this.societyService.get(this.societyUid);
            const currentSocietyOption = [
                {
                    id: currentSociety.uid,
                    label: currentSociety.name,
                    image: currentSociety.photo,
                    icon: !currentSociety.photo ? 'uil uil-suitcase' : undefined,
                    assetType: AssetTypes.society,
                },
            ] as SelectOption[];
            let propertyOfSocietyOptions: SelectOption[] = [];

            if (!this.isAssetOptionsForSociety()) {
                propertyOfSocietyOptions = (
                    await this.propertyService.getSocietyPropertiesOptions(this.societyUid)
                ).filter((x) => x.isAccesible);
            }
            if (propertyOfSocietyOptions.length === 0) {
                this.selectedProperty = currentSocietyOption[0];
            }

            this.propertyOptions$.next([...currentSocietyOption, ...propertyOfSocietyOptions]);
        } else if (this.property?.lotsUID && this.property?.lotsUID.length >= 0) {
            const buildingOption = await this.propertyService.getBuildingPropertiesOptions(
                this.propertyUid
            );
            this.propertyOptions$.next(buildingOption);
            if (buildingOption.length === 1) {
                // if there is only a building as selected option, we put it as selected
                this.selectedProperty = buildingOption[0];
            }
        } else {
            const propertyOptions = (
                await this.propertyService.getUserPropertiesAndSharedAsAdminOptions(user.uid)
            ).filter((x) => x.isAccesible);

            if (!this.canManageSociety) {
                this.propertyOptions$.next(propertyOptions);
                this.selectedProperty = propertyOptions[0];
                return;
            }

            const societyOptions = await this.societyService.getUserSocietiesOptions(user.uid);
            this.propertyOptions$.next([...societyOptions, ...propertyOptions]);
        }
    }

    private isAssetOptionsForSociety() {
        if (!this.selectedChargeCategory) {
            return false;
        }
        const societyCategory = Object.values(ChargeCategorySociety);

        for (const category of societyCategory) {
            if (category === this.selectedChargeCategory.category) {
                return true;
            }
        }
        return false;
    }

    /**
     * @description Reset the form
     * @author Jérémie Lopez
     * @private
     * @memberof ChargeFormPage
     */
    private reset(): void {
        this.activeStepForm = 0;
        this.isCategorySelectedByRoute = false;

        this.charge = {};

        this.startDate = null;
        this.endDate = null;

        this.selectedProperty = null;
        this.selectedPeriodicity = null;
        this.selectedChargeCategory = null;
    }

    onCategorySelected(category: ChargeCategoryInfo): void {
        this.selectedChargeCategory = category;
        this.charge.category = category.category;
        if (this.user) {
            this.setPropertyOptions(this.user);
        }
        this.activeStepForm = 1;
    }

    /**
     * @description Navigate back
     * @author Jérémie Lopez Felix Andre (refacto)
     * @memberof PropertyDataFormPage
     */
    back(date?: Date): void {
        if (this.routeBack || !date || !this.previousUrl) {
            this.navController.pop();
            return;
        }

        if (this.previousComponent) {
            // form was called from list of incomes
            this.router.navigate([this.previousUrl + 'charge/list/' + date]);
            return;
        }

        if (this.propertyUid) {
            // form was called from a property view
            this.router.navigate([this.previousUrl], {
                queryParams: {
                    tab: 'charge',
                    date,
                },
            });
        } else if (this.societyUid) {
            this.previousUrl = this.previousUrl.replace('/?assetType=society', '');
            // form was called from a society view
            this.router.navigate([this.previousUrl], {
                queryParams: {
                    tab: 'charge',
                    date,
                },
            });
        } else {
            this.router.navigate([this.previousUrl + '/charge/'], {
                queryParams: {
                    date,
                },
            });
        }
    }

    formatAmountNumber(newValue: string) {
        OmedomFormatNumber.formatOmedomInput(newValue, this.numberPipe, 'amount');
    }

    /**
     * @description Create or edit an charge
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof ChargeFormPage
     */
    async submit(): Promise<void> {
        this.analyticsService.logEvent('charge form submitted');
        this.pending$.next(true);
        const startdate_toDate = new Date(this.startDate);
        const endDate_toDate = this.endDate ? new Date(this.endDate) : null;
        let dateToNavigateAfterSubmit = new Date();
        this.setChargeWithInputValue();
        this.checkTotalPercentage();

        //used to redirect charge list page to the proper date when periodicity: punctual, annual, quarterly

        if (this.charge.periodicity === ChargePeriodicity.punctual) {
            dateToNavigateAfterSubmit = new Date(this.debitDate);

            this.setChargeWhenPunctual(dateToNavigateAfterSubmit);
        } else {
            this.correctEndOfMonthDay(startdate_toDate);
        }

        const isPeriodValid = await this.checkIfValidTimeInterval(startdate_toDate, endDate_toDate);
        if (!isPeriodValid) {
            return;
        }

        OmedomTreasury.calculateDateAndCalculateHistory(
            this.charge as ChargeEntity,
            this.debitDate,
            this.startDate,
            this.endDate
        );
        if (this.isPeriodicityLonguerThanMonthly()) {
            dateToNavigateAfterSubmit = this.setNavigationDateForLongPeriodicity();
        }
        this.saveChargeCreated(dateToNavigateAfterSubmit);
    }

    private setChargeWithInputValue() {
        this.charge.amount = +this.numberPipe.parse(this.charge.amount.toString());
        this.charge.userUID = this.user.uid;
        this.charge.periodicity = this.selectedPeriodicity.id;
        if (
            this.selectedProperty.id === this.societyUid ||
            this.selectedProperty.assetType === AssetTypes.society
        ) {
            // if the charge is for a society
            this.charge.societyUID = this.selectedProperty.id;
        } else {
            this.charge.propertyUID = this.selectedProperty.id;
        }
        this.getAssociatedData();
    }

    getAssociatedData() {
        this.charge.associatedTo = [];
        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                this.charge.associatedTo.push(formAssociateTo.getInputData());
            });
        }
    }

    private setChargeWhenPunctual(dateToNavigateAfterSubmit: Date) {
        dateToNavigateAfterSubmit = new Date(this.debitDate);
        delete this.charge.endDate;
        this.charge.isReaded = false;
        this.charge.isPayed = dateToNavigateAfterSubmit.getTime() < new Date().getTime();
    }

    private correctEndOfMonthDay(startDate: Date) {
        this.debitDate = this.displayDebitDate === '31' ? '30' : this.displayDebitDate;
        const datefromstart = new Date(this.startDate);
        datefromstart.setDate(+this.debitDate);

        // if Month day invalid (31 for a month with only 30days, it passes to the first day of the next month)
        if (startDate.getMonth() !== datefromstart.getMonth()) {
            datefromstart.setDate(+this.debitDate); //correct the day
        }
        this.debitDate = formatDate(datefromstart, 'YYYY-MM-dd', 'fr');
    }

    private async checkIfValidTimeInterval(startDate: Date, endDate: Date) {
        if (this.endDate && startDate > endDate) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    'Veuillez renseigner une date de début de contrat antérieure à la date de fin',
            });

            await toast.present();

            this.pending$.next(false);
            return false;
        }
        if (startDate.getFullYear() < this.selectedProperty.purchaseYear) {
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message:
                    "Veuillez renseigner une date de début de contrat postérieure à la date d'acquisition du bien",
            });

            await toast.present();
            this.pending$.next(false);
            return false;
        }
        return true;
    }

    private isPeriodicityLonguerThanMonthly() {
        return (
            this.charge.periodicity === ChargePeriodicity.yearly ||
            this.charge.periodicity === ChargePeriodicity.halfYearly ||
            this.charge.periodicity === ChargePeriodicity.bimonthly ||
            this.charge.periodicity === ChargePeriodicity.quarterly
        );
    }

    private setNavigationDateForLongPeriodicity() {
        if (this.charge.history?.length > 1) {
            return this.charge.history
                .sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime())[0]
                .date.toDate();
        } else if (this.charge.startDate) {
            return new Date(this.charge.startDate.toDate());
        } else {
            return new Date();
        }
    }

    private async saveChargeCreated(dateToNavigateAfterSubmit: Date) {
        try {
            await this.chargeService.create(this.charge);

            this.reset();

            const popupValidated = this.isPopupValidated();

            if (!!this.user.bridgeUUID || popupValidated) {
                this.displaySuccessToast(dateToNavigateAfterSubmit);
            }
        } catch (error) {
            this.displayErrorToast(error);
        }
    }

    private isPopupValidated() {
        this.user.popups?.filter((popup) => {
            if (
                popup.name === 'To banking connexion' &&
                popup.version === 1 &&
                popup.type === PopupTypes.redirection &&
                popup.isValidated === true
            ) {
                return true;
            } else {
                return false;
            }
        }).length > 0 || false;
    }

    private async displaySuccessToast(dateToNavigate) {
        const toast = await this.toast.create({
            position: 'top',
            color: 'primary',
            duration: 4000,
            message: 'Vous avez enregistré une charge.',
        });
        await toast.present();
        this.pending$.next(false);
        this.back(dateToNavigate);
    }

    async handleAssociateChargeTo() {
        // TODO actually, when we create a charge globally, it's complicated to had the
        // functionnality to associate charge (in building and lot) with actual code, and
        // keep something readable and understandable
        if (!(this.property || this.societyUid)) {
            return;
        }

        this.updateIsSelectedAsset();
        if (!this.property && this.selectedProperty.id) {
            this.property = await this.propertyService.get(this.selectedProperty.id);
        }
        if (this.isSelectedPropertyBuilding || this.isSelectedPropertySociety) {
            this.updatePropertiesToAssociate();
        }
    }

    updatePropertiesToAssociate() {
        if (!this.selectedProperty || !this.property) {
            return;
        }
        this.propertiesToAssociate = this.propertyOptions$.value.filter((property) => {
            if (property.id === this.societyUid || property.id === this.property.uid) {
                return false;
            }
            return true;
        });
        this.lotToAssociate = this.propertiesToAssociate.map((propertyOption) => {
            return {
                propertyUid: propertyOption.id,
                propertyLabel: propertyOption.label,
                percentage: 0,
                amount: 0,
            };
        });
    }

    updateIsSelectedAsset() {
        this.isSelectedPropertyBuilding = this.selectedProperty?.assetType === AssetTypes.building;
        this.isSelectedPropertySociety = this.selectedProperty?.assetType === AssetTypes.society;
    }

    onPercentageChange(val) {
        let totalPourcentage = 0;
        let currentModificatedForm: FormAssociateToComponent;
        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                totalPourcentage += formAssociateTo.percentage;
                if (formAssociateTo.propertyInfo.propertyUid === val.propertyUid) {
                    currentModificatedForm = formAssociateTo;
                }
            });
        }
        this.checkValueIsInPercentageRange(totalPourcentage);

        if (totalPourcentage > 100) {
            const maxPossibleValue = 100 - (totalPourcentage - val.percentage);
            currentModificatedForm.percentageChange(maxPossibleValue);
            this.checkValueIsInPercentageRange(maxPossibleValue);
        }
    }

    checkTotalPercentage() {
        let totalPourcentage = 0;

        if (this.formAssociateTos) {
            this.formAssociateTos.forEach((formAssociateTo) => {
                totalPourcentage += formAssociateTo.percentage;
            });
        }
        this.checkValueIsInPercentageRange(totalPourcentage);
    }

    checkValueIsInPercentageRange(value: number) {
        if (value > 100 || value < 0) {
            this.isTotalPercentageInRange = false;
        } else {
            this.isTotalPercentageInRange = true;
        }
    }
    private async displayErrorToast(error: any) {
        const toast = await this.toast.create({
            position: 'top',
            color: 'danger',
            duration: 4000,
            message: "Une erreur s'est produite, veuillez réessayer plus tard.",
        });
        await toast.present();
        this.pending$.next(false);
    }

    /**
     * @description Tutotial functons
     * @author Hanane Djeddal
     * @function getSelectTreasuryTypeStep
     * @function getAmountFieldStep
     * @function getPeriodicityFieldStep
     * @function getScheduleAlertStep
     * @function getShowChargeStep
     * @function getShowIncomeStep
     * @function getShowLiquidityStep
     * @memberof ChargeFormPage
     */

    launchTutorial(): void {
        this.offset = this.platform.height() - 20;
        this.shepherdService.modal = true;
        this.shepherdService.defaultStepOptions = {
            canClickTarget: false,
            arrow: false,
        };
        this.shepherdService.addSteps([
            this.getSelectTreasuryTypeStep(),
            this.getAmountFieldStep(),
            this.getPeriodicityFieldStep(),
            // this.getScheduleAlertStep(),
            this.getShowChargeStep(),
            this.getShowIncomeStep(),
            this.getShowLiquidityStep(),
        ]);

        // On utilise un settimeout, sinon les boutons et le message ne s'affichent pas
        setTimeout(() => this.shepherdService.start());
    }

    private getSelectTreasuryTypeStep(): Step.StepOptions {
        return {
            id: 'treasuryTypeTuto',
            attachTo: {
                element: '#treasuryTypeTuto',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -400] } }],
            // },
            when: {
                show() {
                    document.getElementById('tabsBar').setAttribute('hidden', 'true');
                },
            },
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Choisissez le type de charge que vous souhaitez enregistrer. </span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },

                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        const chargeCategoryInfo = new ChargeCategoryInfo(
                            ChargeCategoryProperty[ChargeCategoryProperty.insurance]
                        );
                        this.onCategorySelected(chargeCategoryInfo);

                        setTimeout(() => this.shepherdService.next());
                    },
                },
            ],
        };
    }

    // Tuto step: amount field
    private getAmountFieldStep(): Step.StepOptions {
        return {
            id: 'fieldsTuto',
            attachTo: {
                element: '#fieldsTuto',
                on: 'top',
            },
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Assurez-vous que votre charge ou revenu est associé(e) au bon bien. Indiquez un montant (obligatoire) à votre charge ou revenu.</span>',
            // '<span class="tutorial-text">Indiquez un montant (obligatoire) et une désignation (facultatif) pour ajouter plus de détails à votre charge ou revenu.</span>',
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: () => {
                        this.router
                            .navigate([
                                '/tabs/property/info/' + this.selectedProperty.id + '/charge/form',
                            ])
                            .then(() => {
                                this.activeStepForm = 0;
                                setTimeout(() => this.shepherdService.back());
                            });
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: () => this.shepherdService.next(),
                },
            ],
        };
    }

    // Tuto step: periodicity
    // Vous disposez de plusieurs type de périodicités (ponctuelle, mensuelle, bimestrielle, trimestrielle et annuelle), la périodicité ponctuelle ne sera prise en compte qu'une seule fois.
    private getPeriodicityFieldStep(): Step.StepOptions {
        return {
            id: 'periodicityStepTuto',
            attachTo: {
                element: '#periodicityStepTuto',
                on: 'bottom-start',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 20] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Vous pouvez sélectionner une périodicité récurrente ou ponctuelle pour des charges ou revenus n’arrivant qu’une seule fois.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: async () => {
                        const chargeCategoryInfo = new ChargeCategoryInfo(
                            ChargeCategoryProperty.insurance
                        );
                        this.onCategorySelected(chargeCategoryInfo);

                        setTimeout(() => this.shepherdService.back());
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'charge',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next(), 200));
                    },
                },
            ],
        };
    }

    // Tuto step: schedule alert
    private getScheduleAlertStep(): Step.StepOptions {
        return {
            id: 'scheduleAlertStepTuto',
            attachTo: {
                element: '#scheduleAlertStepTuto',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
            // },
            when: {
                show() {
                    document.getElementById('scheduleAlertStepTuto').scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                },
            },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Programmez une alerte sur des mouvements récurrents. Retrouvez les sur votre tableau de bord.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: () => {
                        this.shepherdService.back();
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'charge',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next(), 200));
                    },
                },
            ],
        };
    }

    // Tuto step: show charge
    private getShowChargeStep(): Step.StepOptions {
        return {
            id: 'showChargeStep',
            attachTo: {
                element: '#showChargeStep',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -this.offset] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Ici, la magie opère ! Tous les jours, le diagramme se met à jour, selon les mouvements passés.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: async () => {
                        this.router
                            .navigate([
                                '/tabs/property/info/' + this.selectedProperty.id + '/charge/form',
                            ])
                            .then(() => {
                                setTimeout(() => this.shepherdService.back(), 400);
                            });
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'income',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next()));
                    },
                },
            ],
        };
    }
    // Tuto step: show income
    private getShowIncomeStep(): Step.StepOptions {
        return {
            id: 'showIncomeStep',
            attachTo: {
                element: '#showIncomeStep',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -this.offset] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Il en est de même pour l’onglet Revenus.</span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Passer</span>',
                    action: () => this.cancelTutorial(),
                    classes: 'cancelButton',
                },
                {
                    text: '<i class="uil uil-angle-left"></i><span>Précédent</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.selectedProperty.id], {
                                queryParams: {
                                    tab: 'charge',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.back()));
                    },
                },
                {
                    text: '<i class="uil uil-angle-right"></i><span>Suivant</span>',
                    action: async () => {
                        await this.router
                            .navigate(['/tabs/property/info/' + this.propertyUid], {
                                queryParams: {
                                    tab: 'liquidity',
                                },
                            })
                            .then(() => setTimeout(() => this.shepherdService.next()));
                    },
                },
            ],
        };
    }

    // Tuto step: show liquidity
    private getShowLiquidityStep(): Step.StepOptions {
        return {
            id: 'showLiquidityStep',
            attachTo: {
                element: '#showLiquidityStep',
                on: 'top',
            },
            // popperOptions: {
            //     modifiers: [{ name: 'offset', options: { offset: [0, -this.offset] } }],
            // },
            text:
                '<i class="uil uil-info-circle"></i>' +
                '<span class="tutorial-text">Visualisez les montants de vos mouvements et vos liquidités par mois et par bien. <br> Vérifiez la courbe de votre trésorerie cumulée pour anticiper des débits futurs (courbe bleue). </span>',
            modalOverlayOpeningPadding: 5,
            modalOverlayOpeningRadius: 10,
            buttons: [
                // {
                //     text: '<i class="uil uil-angle-right"></i><span>Précedent</span>',
                //     action: async () => {
                //         await this.router
                //             .navigate(['/tabs/property/info/' + this.propertyUid], {
                //                 queryParams: {
                //                     tab: 'income',
                //                 },
                //             })
                //             .then(() => setTimeout(() => this.shepherdService.back()));
                //     },
                // },
                {
                    text: '<i class="uil uil-angle-double-right"></i><span>Débuter maintenant l’aventure</span>',
                    action: () => {
                        this.cancelTutorial();
                    },
                },
            ],
        };
    }
    private async cancelTutorial(): Promise<void> {
        document.getElementById('tabsBar').removeAttribute('hidden');
        this.shepherdService.cancel();
        await this.userService.update({
            finishedTutorials: {
                ...this.user.finishedTutorials,
                property: true,
            },
            uid: this.user.uid,
        });

        await this.router.navigate(['/tabs/property']);
        // this.modalController.dismiss();
        // await this.router.navigate(['/tabs/property/info/' + this.propertyUid], {
        //     queryParams: {
        //         tab: 'charge',
        //     },
        // });
    }
}
