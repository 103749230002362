import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'omedom-modal-suggest-subscription',
    templateUrl: './modal-suggest-subscription.component.html',
    // styleUrls: ['../../assets/style/dialog.scss'],
})
export class ModalSuggestSubscriptionComponent {
    /**
     * @description Modal controller instance to close the modal from the component itself
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 06/06/2024
     * @private
     * @type {ModalController}
     * @memberof ModalSuggestSubscriptionComponent
     */
    @Input()
    private modalControllerParentInstance?: ModalController;

    @Input()
    public message: string = 'Pour accéder à cette fonctionnalité, changez d\'abonnement.';

    @Input()
    public buttonText: string = 'Changer mon abonnement';

    constructor(
        private modalController: ModalController,
    ) { }

    public async close() {
        this.modalController.dismiss();
        this.modalControllerParentInstance?.dismiss();
    }
}
