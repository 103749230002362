// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omedom-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: auto;
  width: 100%;
}
.omedom-page-container .omedom-page-container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.incomes-container {
  border-left: 2px solid #eeeeee;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluY29tZS1saXN0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksYUFBQTtFQUNBLHNCQUFBO0VBQ0EsZ0JBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQUNKO0FBQ0k7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxtQkFBQTtBQUNSOztBQUdBO0VBQ0ksOEJBQUE7QUFBSiIsImZpbGUiOiJpbmNvbWUtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5vbWVkb20tcGFnZS1jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBtaW4taGVpZ2h0OiAxMDAlO1xuICAgIG1hcmdpbjogYXV0bztcbiAgICB3aWR0aDogMTAwJTtcblxuICAgIC5vbWVkb20tcGFnZS1jb250YWluZXItY29udGVudCB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgfVxufVxuXG4uaW5jb21lcy1jb250YWluZXIge1xuICAgIGJvcmRlci1sZWZ0OiAycHggc29saWQgI2VlZWVlZTtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/income-list/income-list.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACR;;AAGA;EACI,8BAAA;AAAJ;AACA,o1BAAo1B","sourcesContent":[".omedom-page-container {\n    display: flex;\n    flex-direction: column;\n    min-height: 100%;\n    margin: auto;\n    width: 100%;\n\n    .omedom-page-container-content {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}\n\n.incomes-container {\n    border-left: 2px solid #eeeeee;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
