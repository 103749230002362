import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
    ChargeEntity,
    ChargePeriodicity,
    IncomeEntity,
    IncomePeriodicity,
    PropertyEntity,
    UserEntity,
} from '@omedom/data';
import { OmedomChart, OmedomDoughnutChart, OmedomRentability, OmedomTreasury } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'app-widget-events',
    templateUrl: './widget-events.component.html',
    styleUrls: ['./widget-events.component.scss'],
})
export class WidgetEventsComponent implements OnChanges {
    @Input() incomes: IncomeEntity[] = [];

    @Input() charges: ChargeEntity[] = [];

    @Input() properties: PropertyEntity[] = [];

    @Input() user: UserEntity;

    @Input() currentDate: Date;

    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

    cards: Array<{ type: 'liquidity' | 'rentability'; value: number }>;

    rentability = 0;

    liquidity = 0;

    rental = 0;

    doughnutChartData = { ...OmedomDoughnutChart.doughnutChartData }; // Allways use spread operator to avoid reference;

    doughnutChartOptions = {
        ...OmedomDoughnutChart.doughnutChartOptions,
        spacing: 0,
        elements: { arc: { borderWidth: 0, borderRadius: 0 } },
    };

    constructor() {
        this.doughnutChartOptions.animation = {
            duration: 0,
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.charges || changes.incomes) {
            this.updateData();
        }
    }

    private async updateData(): Promise<void> {
        this.calculateRent();
        this.calculateLiquidity();
        await this.calculateRentability();
        this.cards = [
            {
                type: 'liquidity',
                value: this.liquidity,
            },
            {
                type: 'rentability',
                value: this.rentability,
            },
        ];
    }

    private calculateRent(): void {
        this.rental = 0;

        if (!this.incomes) {
            this.doughnutChartData.datasets[0].data = [0];
            return;
        }
        const partialRents = OmedomTreasury.getPartialRents(
            this.incomes,
            this.currentDate,
            this.properties
        );

        const payedRents = partialRents.filter((filteredRents) => filteredRents.isPayed);

        this.rental =
            partialRents.length !== 0
                ? (this.rental = Math.round((payedRents.length / partialRents.length) * 100))
                : 0;
        this.rental = this.rental ?? 0;
        this.doughnutChartData.datasets[0].data = [this.rental, 100 - this.rental];
        this.doughnutChartData.datasets[0].backgroundColor = [
            OmedomChart.green,
            OmedomChart.backgroundColor,
        ];
        this.doughnutChartData.datasets[0].hoverBackgroundColor = [
            OmedomChart.green,
            OmedomChart.backgroundColor,
        ];
        this.doughnutChartData.labels = ['Perçu', 'Non perçu'];

        if (this.rental === 100 || this.rental === 0) {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 20 } },
            };
        }

        this.baseChart?.update();
    }

    private calculateLiquidity(): void {
        if (!this.incomes || !this.charges) {
            this.liquidity = 0;
            return;
        }

        const chargeAmount = this.charges?.sumBy((charge) => {
            if (charge.periodicity === ChargePeriodicity.punctual) {
                return charge.amount;
            }

            return charge.history
                ? charge.history?.filter((h) => !h.isDeleted && h.isPayed).sumBy((h) => h.amount)
                : 0;
        });

        const incomeAmount = this.incomes?.sumBy((income) => {
            if (income.periodicity === IncomePeriodicity.punctual) {
                return income.amount;
            }

            return income.history
                ? income.history?.filter((h) => !h.isDeleted && h.isPayed).sumBy((h) => h.amount)
                : 0;
        });
        this.liquidity = incomeAmount - chargeAmount;
    }

    private async calculateRentability(): Promise<void> {
        if (!this.incomes) {
            this.rentability = 0;
            return;
        }

        const date = new Date().toUTC().getLastDayOfMonth();

        this.rentability = OmedomRentability.getPropertiesGrossRentability(
            this.properties,
            this.incomes,
            date
        );
    }
}
