// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  overflow-y: auto;
  height: 100%;
}
:host-header {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host-header button {
  width: 100%;
}
:host omedom-chat-user-card {
  border-bottom: 1.5px solid #666666;
  padding-bottom: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQtY2xpZW50LWxpc3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxnQkFBQTtFQUNBLFlBQUE7QUFDSjtBQUNJO0VBQ0ksc0JBQUE7RUFDQSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSxtQkFBQTtBQUNSO0FBQVE7RUFDSSxXQUFBO0FBRVo7QUFDSTtFQUNJLGtDQUFBO0VBQ0Esb0JBQUE7QUFDUiIsImZpbGUiOiJjaGF0LWNsaWVudC1saXN0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIG92ZXJmbG93LXk6IGF1dG87XG4gICAgaGVpZ2h0OiAxMDAlO1xuXG4gICAgJi1oZWFkZXIge1xuICAgICAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmZmO1xuICAgICAgICBwYWRkaW5nOiAxMHB4O1xuICAgICAgICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICAgICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICAgICAgYnV0dG9uIHtcbiAgICAgICAgICAgIHdpZHRoOiAxMDAlO1xuICAgICAgICB9XG4gICAgfVxuICAgIG9tZWRvbS1jaGF0LXVzZXItY2FyZCB7XG4gICAgICAgIGJvcmRlci1ib3R0b206IDEuNXB4IHNvbGlkICM2NjY2NjY7XG4gICAgICAgIHBhZGRpbmctYm90dG9tOiAxMHB4O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/chat-client-list/chat-client-list.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;AACJ;AACI;EACI,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACR;AAAQ;EACI,WAAA;AAEZ;AACI;EACI,kCAAA;EACA,oBAAA;AACR;AACA,4/BAA4/B","sourcesContent":[":host {\n    overflow-y: auto;\n    height: 100%;\n\n    &-header {\n        background-color: #fff;\n        padding: 10px;\n        border-radius: 10px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        button {\n            width: 100%;\n        }\n    }\n    omedom-chat-user-card {\n        border-bottom: 1.5px solid #666666;\n        padding-bottom: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
