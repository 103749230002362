/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable no-unused-vars */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankEntity, LoanEntity } from '@omedom/data';
import { BankService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

/**
 * @description Layout of the loan card
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/06/2024
 * @enum {number}
 */
export enum LoanCardLayout {
    DEFAULT = 'default',
    DASHBOARD = 'dashboard',
    MOBILE = 'mobile',
}

@Component({
    selector: 'omedom-loan-card',
    templateUrl: './loan-card.container.html',
    styleUrls: ['./loan-card.container.scss'],
})
export class LoanCardContainer implements OnInit {
    /**
     * @description Layout of the loan card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {LoanCardLayout}
     * @memberof LoanCardContainer
     */
    @Input()
    public layout: LoanCardLayout = LoanCardLayout.DEFAULT;

    /**
     * @description Loan entity to display in the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {LoanEntity}
     * @memberof LoanCardContainer
     */
    @Input({ required: true })
    public loan!: LoanEntity;

    /**
     * @description Bank entity to display in the card (optional, only if the loan has a bank associated to it)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @type {BankEntity}
     * @memberof LoanCardContainer
     */
    public bank?: BankEntity;

    /**
     * @description Show the bank name in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Input()
    public showBankName = true;

    /**
     * @description Show the borrowed capital in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Input()
    public showBorrowedCapital = true;

    /**
     * @description Show the interest rate in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Input()
    public showInterestRate = true;

    /**
     * @description Show the progress bar of the loan repayment in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Input()
    public showProgress = true;

    /**
     * @description Show the informations of the loan in the card or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Input()
    public showInformations = true;

    /**
     * @description Show the footer of the card (with the "evolution" and "see more" buttons) or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 28/06/2024
     * @memberof LoanCardContainer
     */
    @Input()
    public showFooter = true;

    /**
     * @description Event emitted when the user click on the "evolution" button of the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Output()
    public onEvolutionClick = new EventEmitter<void>();

    /**
     * @description Event emitted when the user click on the "see more" button of the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    @Output()
    public onSeeMoreClick = new EventEmitter<void>();

    /**
     * @description State of the component (ok, loading, error) to display the right content
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @memberof LoanCardContainer
     */
    public state$ = new BehaviorSubject<'ok' | 'loading' | 'error'>('ok');

    /**
     * @description Layout of the loan card (enum) to use in the template (HTML) file
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/06/2024
     * @protected
     * @memberof LoanCardContainer
     */
    protected LoanCardLayout = LoanCardLayout;

    constructor(
        private readonly bankService: BankService,
        private readonly ref: ElementRef
    ) { }

    /**
     * @description Percentage of the refunded capital of the loan
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 28/06/2024
     * @readonly
     * @type {number}
     * @memberof LoanCardContainer
     */
    public get refunded(): number {
        // If the loan is not defined, we return 0
        if (!this.loan) {
            return 0;
        }

        // If the loan has been refunded, we return 100
        if (this.loan.remainingCapital === 0) {
            return 100;
        }

        // Check if the loan has a borrowed capital
        // And if the borrowed capital is greater than 0
        // Otherwise, we return 0
        if (!this.loan.borrowedCapital || this.loan.borrowedCapital === 0) {
            return 0;
        }

        // Check if the loan has a remaining capital
        // And if the remaining capital is greater than 0
        // Otherwise, we return 0
        if (!this.loan.remainingCapital || this.loan.remainingCapital === 0) {
            return 0;
        }

        // If the remaining capital is greater than the borrowed capital
        // We return 100
        if (this.loan.remainingCapital > this.loan.borrowedCapital) {
            return 100;
        }

        // Otherwise, we return the percentage of the refunded capital
        return Math.round((this.loan.borrowedCapital - this.loan.remainingCapital) / this.loan.borrowedCapital * 100);
    }


    async ngOnInit(): Promise<void> {
        // Check if the loan is defined
        if (!this.loan) {
            this.state$.next('error');
            return;
        }

        // Initial state of the component
        this.state$.next('loading');

        // If the loan has a bank associated to it
        // And if the bank is not already set
        // Then we fetch the bank from firebase
        if (this.loan.bankID && !this.bank) {
            this.bank = await this.bankService.getBankFromID(this.loan.bankID);
        }

        // Add the layout class to the component
        this.ref.nativeElement.classList.add(`layout-${this.layout}`);

        // Final state of the component
        this.state$.next('ok');
    }
}
