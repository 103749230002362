// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-popover::part(content) {
  width: clamp(50%, 374px, 90%);
}

app-popover,
omedom-popover {
  position: absolute;
  right: 0;
  top: 0;
}

.content-info-icon {
  color: #cd493a;
}

.omedom-popover .omedom-popover-container {
  display: flex;
  align-items: center;
  padding: 20px;
}
.omedom-popover .omedom-popover-container .omedom-popover-content {
  margin-left: 12px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndhcm5pbmctcG9wb3Zlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLDZCQUFBO0FBQ0o7O0FBRUE7O0VBRUksa0JBQUE7RUFDQSxRQUFBO0VBQ0EsTUFBQTtBQUNKOztBQUVBO0VBQ0ksY0FBQTtBQUNKOztBQUVJO0VBQ0ksYUFBQTtFQUNBLG1CQUFBO0VBQ0EsYUFBQTtBQUNSO0FBQ1E7RUFDSSxpQkFBQTtBQUNaIiwiZmlsZSI6Indhcm5pbmctcG9wb3Zlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1wb3BvdmVyOjpwYXJ0KGNvbnRlbnQpIHtcbiAgICB3aWR0aDogY2xhbXAoNTAlLCAzNzRweCwgOTAlKTtcbn1cblxuYXBwLXBvcG92ZXIsXG5vbWVkb20tcG9wb3ZlciB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHJpZ2h0OiAwO1xuICAgIHRvcDogMDtcbn1cblxuLmNvbnRlbnQtaW5mby1pY29uIHtcbiAgICBjb2xvcjogI2NkNDkzYTtcbn1cbi5vbWVkb20tcG9wb3ZlciB7XG4gICAgLm9tZWRvbS1wb3BvdmVyLWNvbnRhaW5lciB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIHBhZGRpbmc6IDIwcHg7XG5cbiAgICAgICAgLm9tZWRvbS1wb3BvdmVyLWNvbnRlbnQge1xuICAgICAgICAgICAgbWFyZ2luLWxlZnQ6IDEycHg7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/warning-popover/warning-popover.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;;EAEI,kBAAA;EACA,QAAA;EACA,MAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEI;EACI,aAAA;EACA,mBAAA;EACA,aAAA;AACR;AACQ;EACI,iBAAA;AACZ;AACA,o/BAAo/B","sourcesContent":["ion-popover::part(content) {\n    width: clamp(50%, 374px, 90%);\n}\n\napp-popover,\nomedom-popover {\n    position: absolute;\n    right: 0;\n    top: 0;\n}\n\n.content-info-icon {\n    color: #cd493a;\n}\n.omedom-popover {\n    .omedom-popover-container {\n        display: flex;\n        align-items: center;\n        padding: 20px;\n\n        .omedom-popover-content {\n            margin-left: 12px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
