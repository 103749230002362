import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OmedomChartLegend } from '@omedom/ui';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
    selector: 'app-canvas-charge-income-container',
    templateUrl: './canvas-charge-income-container.component.html',
    styleUrls: ['./canvas-charge-income-container.component.scss'],
})
export class CanvasChargeIncomeContainerComponent implements OnInit {
    @Input() selectedSubTabId: any;

    @Input() hasAtLeastOneEntity: boolean;

    @Input() isCharge = true;

    @Input() editable: boolean;

    @Input() doughnutChartData: ChartData<'doughnut'>;

    @Input() doughnutChartOptions: ChartOptions<'doughnut'>;

    @Input() chartLegends: OmedomChartLegend;

    /**
     * @description Total of charges or incomes
     * @author ANDRE Felix
     * @type {number}
     * @memberof CanvasChargeIncomeContainerComponent
     */
    @Input() total: number;

    @Output() buttonClicked = new EventEmitter();

    constructor() { }

    ngOnInit() { }
}
