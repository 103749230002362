import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivityZone, ProEntity, ProValue } from '@omedom/data';

@Component({
    selector: 'omedom-pro-record-detail',
    templateUrl: './pro-record-detail.component.html',
    styleUrls: ['./pro-record-detail.component.scss'],
})
export class ProRecordDetailComponent implements OnChanges {
    @Input({ required: true }) pro!: ProEntity;

    // For the moment, we hide these values for user, but still create in the pro
    // proExpertiseField: ExpertiseField | string = '';
    proActivityZone: ActivityZone | string = '';
    proValues: ProValue | string = '';

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pro'] && changes['pro'].currentValue) {
            // this.proExpertiseField = this.setProExpertiseField(this.pro);
            this.proActivityZone = this.setProZone(this.pro);
            this.proValues = this.setProValues(this.pro);
        }
    }

    // setProExpertiseField(pro: ProEntity | undefined) {
    //     if (!pro || !pro.expertiseField || pro.expertiseField.length === 0) {
    //         return '';
    //     }
    //     if (pro.expertiseField.length === 1) {
    //         return pro.expertiseField[0];
    //     }
    //     return pro.expertiseField.join(', ');
    // }

    setProZone(pro: ProEntity | undefined) {
        if (!pro || !pro.activityZone || pro.activityZone.length === 0) {
            return '';
        }
        if (pro.activityZone.length === 1) {
            return pro.activityZone[0];
        }
        return pro.activityZone.join(', ');
    }

    setProValues(pro: ProEntity | undefined) {
        if (!pro || !pro.values || pro.values.length === 0) {
            return '';
        }
        if (pro.values.length === 1) {
            return pro.values[0];
        }
        return pro.values.join(', ');
    }
}
