// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stars {
  display: inline-block;
}

.star {
  font-size: 20px;
  color: lightgray;
}

.pointer {
  cursor: pointer;
}

.star.filled {
  color: gold;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0YXItcmF0ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLHFCQUFBO0FBQ0o7O0FBQ0E7RUFDSSxlQUFBO0VBQ0EsZ0JBQUE7QUFFSjs7QUFBQTtFQUNJLGVBQUE7QUFHSjs7QUFBQTtFQUNJLFdBQUE7QUFHSiIsImZpbGUiOiJzdGFyLXJhdGUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuc3RhcnMge1xuICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbn1cbi5zdGFyIHtcbiAgICBmb250LXNpemU6IDIwcHg7XG4gICAgY29sb3I6IGxpZ2h0Z3JheTtcbn1cbi5wb2ludGVyIHtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbi5zdGFyLmZpbGxlZCB7XG4gICAgY29sb3I6IGdvbGQ7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/star-rate/star-rate.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AACA;EACI,eAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ;;AAAA;EACI,WAAA;AAGJ;AACA,4kBAA4kB","sourcesContent":[".stars {\n    display: inline-block;\n}\n.star {\n    font-size: 20px;\n    color: lightgray;\n}\n.pointer {\n    cursor: pointer;\n}\n\n.star.filled {\n    color: gold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
