import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from '@omedom/data';
import { SocietyService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-asset-filter-society',
    templateUrl: './asset-filter-society.component.html',
    styleUrls: ['./asset-filter-society.component.scss'],
})
export class AssetFilterSocietyComponent implements OnInit {
    @Input() societyUid: string;

    @Output() assetSelectedChange = new EventEmitter();

    societyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    allSocietyOptions: SelectOption[] = [];

    constructor(private societyService: SocietyService) {}

    async ngOnInit() {
        this.allSocietyOptions = await this.societyService.getSocietyOptions(this.societyUid);
        this.societyOptions$.next(this.allSocietyOptions);
    }
}
