import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-smart-popover',
    templateUrl: './smart-popover.component.html',
    styleUrls: ['./smart-popover.component.scss'],
})
export class SmartPopoverComponent implements OnInit {
    @Input() text: string;

    constructor() {}

    ngOnInit() {}
}
