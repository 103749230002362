// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.ion-dirty.ion-invalid,
ion-item.ion-touched.ion-invalid {
  color: #ff5641;
  border: 1.5px solid #ff5641;
  border-radius: 10px;
}
ion-item.ion-dirty.ion-invalid i,
ion-item.ion-touched.ion-invalid i {
  color: #ff5641;
}

ion-item.ion-dirty.ion-invalid.omedom-form-item-desktop,
ion-item.ion-touched.ion-invalid.omedom-form-item-desktop {
  color: #ff5641;
  border: none !important;
  border-radius: 0 !important;
}
ion-item.ion-dirty.ion-invalid.omedom-form-item-desktop i,
ion-item.ion-touched.ion-invalid.omedom-form-item-desktop i {
  color: #ff5641;
}

sup {
  color: #ff5641;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOztFQUVJLGNBQUE7RUFDQSwyQkFBQTtFQUNBLG1CQUFBO0FBQ0o7QUFBSTs7RUFDSSxjQUFBO0FBR1I7O0FBQ0E7O0VBRUksY0FBQTtFQUNBLHVCQUFBO0VBQ0EsMkJBQUE7QUFFSjtBQURJOztFQUNJLGNBQUE7QUFJUjs7QUFBQTtFQUNJLGNBQUE7RUFDQSxnQkFBQTtBQUdKIiwiZmlsZSI6ImlucHV0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWl0ZW0uaW9uLWRpcnR5Lmlvbi1pbnZhbGlkLFxuaW9uLWl0ZW0uaW9uLXRvdWNoZWQuaW9uLWludmFsaWQge1xuICAgIGNvbG9yOiAjZmY1NjQxO1xuICAgIGJvcmRlcjogMS41cHggc29saWQgI2ZmNTY0MTtcbiAgICBib3JkZXItcmFkaXVzOiAxMHB4O1xuICAgIGkge1xuICAgICAgICBjb2xvcjogI2ZmNTY0MTtcbiAgICB9XG59XG5cbmlvbi1pdGVtLmlvbi1kaXJ0eS5pb24taW52YWxpZC5vbWVkb20tZm9ybS1pdGVtLWRlc2t0b3AsXG5pb24taXRlbS5pb24tdG91Y2hlZC5pb24taW52YWxpZC5vbWVkb20tZm9ybS1pdGVtLWRlc2t0b3Age1xuICAgIGNvbG9yOiAjZmY1NjQxO1xuICAgIGJvcmRlcjogbm9uZSAhaW1wb3J0YW50O1xuICAgIGJvcmRlci1yYWRpdXM6IDAgIWltcG9ydGFudDtcbiAgICBpIHtcbiAgICAgICAgY29sb3I6ICNmZjU2NDE7XG4gICAgfVxufVxuXG5zdXAge1xuICAgIGNvbG9yOiAjZmY1NjQxO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/input/input.component.scss"],"names":[],"mappings":"AAAA;;EAEI,cAAA;EACA,2BAAA;EACA,mBAAA;AACJ;AAAI;;EACI,cAAA;AAGR;;AACA;;EAEI,cAAA;EACA,uBAAA;EACA,2BAAA;AAEJ;AADI;;EACI,cAAA;AAIR;;AAAA;EACI,cAAA;EACA,gBAAA;AAGJ;AACA,4kCAA4kC","sourcesContent":["ion-item.ion-dirty.ion-invalid,\nion-item.ion-touched.ion-invalid {\n    color: #ff5641;\n    border: 1.5px solid #ff5641;\n    border-radius: 10px;\n    i {\n        color: #ff5641;\n    }\n}\n\nion-item.ion-dirty.ion-invalid.omedom-form-item-desktop,\nion-item.ion-touched.ion-invalid.omedom-form-item-desktop {\n    color: #ff5641;\n    border: none !important;\n    border-radius: 0 !important;\n    i {\n        color: #ff5641;\n    }\n}\n\nsup {\n    color: #ff5641;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
