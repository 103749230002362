import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonModal, IonRefresher, Platform } from '@ionic/angular';
import {
    ConfigurationEntity,
    PropertiesDto,
    PropertyEntity,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    ConfigurationService,
    PropertyService,
    PropertyTutorialService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { ShepherdService } from 'angular-shepherd';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
    templateUrl: './property-list.page.html',
    styleUrls: ['./property-list.page.scss'],
})
export class PropertyListPage implements OnInit, OnDestroy {
    /**
     * @description User Data
     * @author Jérémie Lopez
     * @type {UserEntity}
     * @memberof PropertyListPage
     */
    user: UserEntity;

    /**
     * @description List of properties owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {PropertyEntity[]}
     * @memberof PropertyListPage
     */
    properties: PropertiesDto = {
        properties: [],
        nbProperty: 0,
    };

    /**
     * @description List of societies owned by the user or shared to him
     * @author Jérémie Lopez
     * @type {SocietyEntity[]}
     * @memberof PropertyListPage
     */
    societies: SocietyEntity[] = [];

    /**
     * @description Access to pull to refresh
     * @author Jérémie Lopez
     * @type {IonRefresher}
     * @memberof PropertyListPage
     */
    @ViewChild('refresher', { static: false })
    refresher: IonRefresher;

    @ViewChild('smartModalProperty', { static: true }) smartModalProperty: IonModal;
    /**
     * @description List of subscriptions to unsub after destroy component
     * @author Jérémie Lopez
     * @private
     * @type {Subscription[]}
     * @memberof PropertyListPage
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description If true, the view has been init a first time
     * @author Jérémie Lopez
     * @private
     * @memberof PropertyListPage
     */
    private firstViewInit = false;

    /**
     * @description Used to share properties to a Pro, when clicked, we hide button for adding properties
     * @description Selectable mode (default: false) (used to display a checkbox on each item to select them and do an action on them)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 10/10/2023
     * @memberof PropertyListComponent
     */
    static selectable = false;

    /**
     * @description User Configurationcto give access regarding the subscription plan of the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @type {ConfigurationEntity}
     * @memberof PropertyListPage
     */
    private userConfiguration: ConfigurationEntity;

    isLoading = true;

    constructor(
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private userService: UserService,
        private platform: Platform,
        protected propertyTutorialService: PropertyTutorialService,
        private readonly shepherdService: ShepherdService,
        private analyticsService: AnalyticsService,
        private router: Router,
        private configurationService: ConfigurationService
    ) {
        PropertyListPage.selectable = false;
    }

    get selectable(): boolean {
        return PropertyListPage.selectable;
    }

    async ngOnInit(): Promise<void> {
        const userConfiguration$ = this.configurationService.getUserConfiguration();

        combineLatest([this.userService.user$, userConfiguration$]).subscribe(
            async ([user, userConfiguration]) => {
                this.user = user;

                this.userConfiguration = userConfiguration;

                this.searchEntities();
                this.firstViewInit = true;
                // // Check if the user has at least one property
                // this.propertyService.hasAtLeastOneProperty$.subscribe((hasAtLeastOneProperty) => {
                //     this.hasAtLeastOneProperty = hasAtLeastOneProperty;
                // });
                const accessibleProperties = this.properties?.properties.filter((property) =>
                    this.propertyService.isPropertyAccessible(property as PropertyEntity)
                );

                this.isLoading = false;

                if (
                    !this.shepherdService.isActive &&
                    this.properties?.properties.length &&
                    accessibleProperties.length &&
                    !this.user?.finishedTutorials?.property
                ) {
                    this.propertyTutorialService.launchTutorial(accessibleProperties[0].uid);
                }
            }
        );

        //this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description If true, the app is in desktop mode
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof PropertyListPage
     */
    public get isDesktop(): boolean {
        return this.platform.is('desktop');
    }

    /**
     * @description Ionic Hook Life cycle when the page will be display an other time
     * @author Jérémie Lopez
     * @memberof PropertyListPage
     */
    async ionViewWillEnter() {
        if (!this.shepherdService.isActive && this.firstViewInit) {
            this.searchEntities();
        }
        // Update user info if already get
        if (this.user) {
            this.user = await this.userService.get(this.user.uid);
        }

        const accessibleProperties = this.properties?.properties?.filter((property) =>
            this.propertyService.isPropertyAccessible(property as PropertyEntity)
        );

        if (
            !this.shepherdService.isActive &&
            accessibleProperties?.length &&
            !this.user?.finishedTutorials?.property
        ) {
            this.propertyTutorialService.launchTutorial(accessibleProperties[0].uid);
        }
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Property List Page');
    }

    _searchProperties(): Observable<PropertyEntity[]> {
        const properties$ = this.user?.uid
            ? this.propertyService._getUserPropertiesAndShared(this.user.uid)
            : of([] as PropertyEntity[]);

        return properties$;
    }

    _searchSocieties(): Observable<SocietyEntity[]> {
        const societies$ = this.user?.uid
            ? this.societyService._search([
                {
                    where: 'userUID',
                    operator: '==',
                    value: this.user.uid,
                },
            ])
            : of([] as SocietyEntity[]);

        const sharedSocieties$ = this.userService.user$.pipe(
            switchMap((user) => {
                if (user && user.sharedSocietiesUID && user.sharedSocietiesUID.length > 0) {
                    const societiesObservables: Observable<SocietyEntity>[] = [];
                    user.sharedSocietiesUID.forEach((uid) => {
                        if (!uid) {
                            return;
                        }
                        societiesObservables.push(this.societyService._get(uid));
                    });
                    return combineLatest(societiesObservables);
                } else {
                    return of([]);
                }
            })
        );

        return combineLatest([societies$, sharedSocieties$]).pipe(
            switchMap(([societies, sharedSocieties]) => {
                return of([...societies, ...sharedSocieties]);
            })
        );
    }

    /**
     * @description Search entities after or not refresh data
     * @author Jérémie Lopez
     * @param {*} [fromRefresher]
     * @return {*}  {Promise<void>}
     * @memberof PropertyListPage
     */
    searchEntities(fromRefresher?: any): void {
        // this.properties = await this.searchProperties();
        const properties$ = this._searchProperties();
        properties$.subscribe((properties) => {
            this.properties.properties = properties || [];
            this.properties.nbProperty = (properties || []).filter(
                (property) => property?.userUID === this.user?.uid
            ).length;
        });
        // this.societies = await this.searchSocieties();
        const societies$ = this._searchSocieties();
        societies$.subscribe((societies) => {
            this.societies = societies || [];
        });

        // await Promise.all([this.searchProperties(), this.searchSocieties()]);

        if (fromRefresher) {
            this.refresher.complete();
        }
    }

    createSocietyClicked() {
        this.router.navigate(['/tabs/property/society/form']);
    }

    createPropertyClicked() {
        this.router.navigate(['/tabs/property/form']);
    }

    /**
     * @description Check if user can create a property or not (based on subscription plan) and return a boolean value
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @returns {*}  {boolean}
     * @memberof PropertyListPage
     */
    public canCreateProperty() {
        // unauthorized if user have numer of property >= to subscription plan
        return (
            this.userConfiguration &&
            (this.userConfiguration.propertyLimit === -1 ||
                this.properties?.properties.length <= this.userConfiguration.propertyLimit)
        );
    }

    openSmartModal() {
        this.smartModalProperty.present(); // Utilisez la méthode present() pour ouvrir la modal
    }

    hasPropertyOrSociety() {
        return this.properties?.properties.length > 0 || this.societies.length > 0;
    }
}
