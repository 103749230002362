// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  width: 100%;
}

.form-stepper-button-container {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: 20px;
  margin-top: 40px;
  position: sticky;
  bottom: 20px;
  z-index: 10;
}

:host {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  opacity: 0px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxlYXNlLWZvcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxXQUFBO0FBQ0o7O0FBQ0E7RUFDSSxXQUFBO0VBQ0EsYUFBQTtFQUNBLHdCQUFBO0VBQ0Esb0JBQUE7RUFDQSxTQUFBO0VBQ0EsZ0JBQUE7RUFDQSxnQkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0FBRUo7O0FBQUE7RUFDSSxXQUFBO0VBQ0EsT0FBQTtFQUNBLGFBQUE7RUFDQSxzQkFBQTtFQUNBLDJCQUFBO0VBQ0EsU0FBQTtFQUNBLFlBQUE7QUFHSiIsImZpbGUiOiJsZWFzZS1mb3JtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiZm9ybSB7XG4gICAgd2lkdGg6IDEwMCU7XG59XG4uZm9ybS1zdGVwcGVyLWJ1dHRvbi1jb250YWluZXIge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzdHJldGNoO1xuICAgIGFsaWduLWl0ZW1zOiBzdHJldGNoO1xuICAgIGdhcDogMjBweDtcbiAgICBtYXJnaW4tdG9wOiA0MHB4O1xuICAgIHBvc2l0aW9uOiBzdGlja3k7XG4gICAgYm90dG9tOiAyMHB4O1xuICAgIHotaW5kZXg6IDEwO1xufVxuOmhvc3Qge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGZsZXg6IDE7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIGp1c3RpZnktY29udGVudDogZmxleC1zdGFydDtcbiAgICBnYXA6IDIwcHg7XG4gICAgb3BhY2l0eTogMHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/lease-form/lease-form.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AACA;EACI,WAAA;EACA,aAAA;EACA,wBAAA;EACA,oBAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AAAA;EACI,WAAA;EACA,OAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,SAAA;EACA,YAAA;AAGJ;AACA,gjCAAgjC","sourcesContent":["form {\n    width: 100%;\n}\n.form-stepper-button-container {\n    width: 100%;\n    display: flex;\n    justify-content: stretch;\n    align-items: stretch;\n    gap: 20px;\n    margin-top: 40px;\n    position: sticky;\n    bottom: 20px;\n    z-index: 10;\n}\n:host {\n    width: 100%;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 20px;\n    opacity: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
