// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.range-container {
  display: inline-block;
}

.avg-value {
  font-weight: 900;
  font-size: 1.2em;
}

.limit-value {
  color: lightgray;
  font-style: italic;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRpc3BsYXktcmFuZ2UtcHJpY2UuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxxQkFBQTtBQUNKOztBQUdBO0VBQ0ksZ0JBQUE7RUFDQSxnQkFBQTtBQUFKOztBQUdBO0VBQ0ksZ0JBQUE7RUFDQSxrQkFBQTtBQUFKIiwiZmlsZSI6ImRpc3BsYXktcmFuZ2UtcHJpY2UuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucmFuZ2UtY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gICAgLy8gbWluLXdpZHRoOiAxMzBweDtcbn1cblxuLmF2Zy12YWx1ZSB7XG4gICAgZm9udC13ZWlnaHQ6IDkwMDtcbiAgICBmb250LXNpemU6IDEuMmVtO1xufVxuXG4ubGltaXQtdmFsdWUge1xuICAgIGNvbG9yOiBsaWdodGdyYXk7XG4gICAgZm9udC1zdHlsZTogaXRhbGljO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/valuation/display-range-price/display-range-price.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAGA;EACI,gBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,kBAAA;AAAJ;AACA,4oBAA4oB","sourcesContent":[".range-container {\n    display: inline-block;\n    // min-width: 130px;\n}\n\n.avg-value {\n    font-weight: 900;\n    font-size: 1.2em;\n}\n\n.limit-value {\n    color: lightgray;\n    font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
