/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import {
    AllChargeCategories,
    AllIncomeCategories,
    CategoryInfo,
    ChargeEntity,
    IncomeEntity,
    OmedomDateType,
    PropertiesFilter,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomChart, OmedomDoughnutChart, OmedomTreasury } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';
import { combineLatest, Subscription } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { OmedomChartLegend } from '../components/chart-legend';
import { OmedomSubTab } from '../components/sub-tab/omedom-sub-tab';

@Injectable()
export abstract class TreasuryInfo<TEntity extends ChargeEntity | IncomeEntity, TCategory>
    implements OnInit, OnDestroy
{
    @ViewChild(BaseChartDirective) baseChart?: BaseChartDirective;

    user!: UserEntity;

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({
            id: 1,
            label: 'Comptabilité automatique',
            popover: 'Fonctionnalité bientôt disponible dans votre abonnement Smart.',
            disabled: true,
        }),
        new OmedomSubTab({ id: 2, label: 'Comptabilité manuelle' }),
    ];

    selectedSubTab: OmedomSubTab = this.subTabs[0];

    doughnutChartData = OmedomDoughnutChart.doughnutChartData;

    doughnutChartOptions = OmedomDoughnutChart.doughnutChartOptions;

    total = 0;

    chartLegends: OmedomChartLegend[] = [];

    omedomDateType = OmedomDateType;

    categoryInfo?: CategoryInfo<TCategory>;

    private treasury: TEntity[] = [];

    protected properties: PropertyEntity[] = [];

    protected societies: SocietyEntity[] = [];

    private subscriptions: Subscription[] = [];

    totalAmount: number = 0;

    totalAmountPayed: number = 0;

    totalAmountNotPayed: number = 0;

    selectedProperties: SelectOption[] = [];

    selectedSocieties: SelectOption[] = [];

    currentDate: Date = new Date();

    protected constructor(
        private userService: UserService,
        private navController: NavController,
        private activatedRoute: ActivatedRoute,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    abstract get queryParamsName(): string;

    /**
     *
     *
     * @memberof TreasuryInfo
     */
    ngOnInit(): void {
        this.selectedProperties = PropertiesFilter.filteredProperties;
        this.selectedSocieties = PropertiesFilter.filteredSocieties;
        this.selectedSubTab = this.subTabs[1];
        this.currentDate = new Date();
        const route$ = this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (queryParams[this.queryParamsName]) {
                this.categoryInfo = this.loadCategory(queryParams[this.queryParamsName]);
            }
            if (queryParams['date']) {
                this.currentDate = new Date(queryParams['date']);
            }

            const user$ = this.userService.user$
                .pipe(
                    filter((user) => !!user),
                    take(1),
                    switchMap((user) => {
                        this.user = user;
                        return combineLatest([
                            this.propertyService._getUserPropertiesAndSharedAccessible(user.uid),
                            this.societyService._getUserSocietiesAndShared(user.uid),
                        ]);
                    })
                )
                .subscribe(async ([properties, societies]) => {
                    this.properties = properties;
                    this.societies = societies;
                    if (this.categoryInfo) {
                        this.treasury = await this.loadTreasury(
                            this.user.uid,
                            this.categoryInfo.category
                        );

                        this.treasury = this.treasury.filter(
                            (x) =>
                                properties.some((property) => property.uid === x.propertyUID) ||
                                societies.some((society) => society.uid === x.societyUID)
                        );

                        this.updateGraphData(this.currentDate);
                    }
                });

            this.subscriptions.push(user$);
        });

        this.subscriptions.push(route$);
    }

    /**
     * @description updates graph and data when the filter has been changed (through select app)
     */
    async updateFilter() {
        if (!this.categoryInfo) {
            return;
        }
        this.treasury = await this.loadTreasury(this.user.uid, this.categoryInfo.category);
        this.updateGraphData(this.currentDate);
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    dateChange(date: Date) {
        this.currentDate = date;
        // this.router.navigate([], {
        //     relativeTo: this.route,
        //     queryParams: {
        //         date,
        //     },
        //     queryParamsHandling: 'merge',
        // });
        this.updateGraphData(date);
    }

    back(): void {
        this.navController.pop();
    }

    updateGraphData(date: Date) {
        const startDate = date.getUTCFirstDayOfMonth();
        const endDate = date.getUTCLastDayOfMonth();

        const filteredData = OmedomTreasury.filterTreasury<TEntity>(
            this.treasury,
            startDate.getUTCDateWithoutTime(),
            endDate.getUTCDateWithoutTime()
        );
        const amount = this.getAmountsProperty(filteredData, startDate);

        this.total = amount.sumBy((x) => x.amount);
        this.totalAmount = this.total;
        this.totalAmountPayed = amount.filter((x) => !!x.isPayed).sumBy((x) => x.amount);
        this.totalAmountNotPayed = amount.filter((x) => !x.isPayed).sumBy((x) => x.amount);

        amount.sort((a, b) => {
            if (!!a.isPayed && !b.isPayed) {
                return -1;
            } else if (!a.isPayed && !!b.isPayed) {
                return 1;
            }
            return a.assetName.localeCompare(b.assetName);
        });

        const treasuriesGrouped = amount.reduce((tbt, treasury) => {
            const key = treasury.assetUID + !!treasury.isPayed;
            if (!tbt[key]) {
                tbt[key] = {
                    propertyUID: treasury.assetUID,
                    amount: 0,
                    percent: 0,
                    isPayed: !!treasury.isPayed,
                    category: treasury.category,
                };
            }
            tbt[key].amount += treasury.amount;
            tbt[key].percent = Math.round((tbt[key].amount * 100) / this.total);
            return tbt;
        }, {} as { [key: string]: { propertyUID: string; amount: number; percent: number; isPayed: boolean; category: string } });

        const treasuryList: {
            propertyUID: string;
            amount: number;
            percent: number;
            isPayed: boolean;
            category: string;
        }[] = Object.values(treasuriesGrouped);

        this.chartLegends = treasuryList.map((x) => {
            const color =
                OmedomChart.colorByTreasury[x.category as keyof typeof OmedomChart.colorByTreasury];
            return new OmedomChartLegend({
                label:
                    this.properties.find((property) => x.propertyUID === property.uid)?.name ??
                    this.societies.find((society) => x.propertyUID === society.uid)?.name ??
                    '',

                // percent: x.percent,
                amount: x.amount,
                color: !!x.isPayed ? color : OmedomChart.hatchColor(color, 'legend').toString(),
            });
        });

        const colorsWithSaturation = treasuryList.map((x) => {
            const color =
                OmedomChart.colorByTreasury[x.category as keyof typeof OmedomChart.colorByTreasury];
            return !!x.isPayed ? color : OmedomChart.hatchColor(color);
        });

        this.doughnutChartData.labels = treasuryList.map(
            (x) =>
                this.properties.find((property) => x.propertyUID === property.uid)?.name ??
                this.societies.find((society) => x.propertyUID === society.uid)?.name ??
                ''
        );
        this.doughnutChartData.datasets[0].data = treasuryList.map((x) => x.percent);
        this.doughnutChartData.datasets[0].backgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBackgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBorderColor = colorsWithSaturation;

        if (treasuryList.length === 1) {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: OmedomDoughnutChart.doughnutChartOptions.spacing,
                elements: OmedomDoughnutChart.doughnutChartOptions.elements,
            };
        }

        this.baseChart?.update();
    }

    private getAmountsProperty(
        entities: TEntity[],
        startDate: Date
    ): {
        assetUID: string;
        assetName: string;
        amount: number;
        isPayed: boolean;
        category: AllChargeCategories | AllIncomeCategories;
    }[] {
        return entities.map((entity) => ({
            assetUID: entity.propertyUID || entity.societyUID || '',
            assetName:
                this.properties.find((property) => entity.propertyUID === property.uid)?.name ||
                this.societies.find((society) => entity.societyUID === society.uid)?.name ||
                '',
            amount: Math.abs(OmedomTreasury.getMonthAmount(entity, startDate)),
            isPayed: OmedomTreasury.isTreasuryPayed(entity, startDate, new Date()),
            category: entity.category,
        }));
    }

    abstract loadCategory(category: string): CategoryInfo<TCategory>;

    abstract loadTreasury(userUid: string, category: TCategory): Promise<TEntity[]>;
}
