import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SelectOption, optGroupOptions } from '@omedom/data';

@Component({
    selector: 'app-select-bottom-sheet',
    templateUrl: './select-bottom-sheet.component.html',
    styleUrls: ['./select-bottom-sheet.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectBottomSheetComponent implements OnInit {
    /**
     * @description The title of the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {string}
     * @memberof SelectBottomSheetComponent
     */
    @Input()
    public selectTitle?: string;

    /**
     * @description True if we can select multiple options, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectBottomSheetComponent
     */
    @Input()
    public isMultiple: boolean = false;

    /**
     * @description Number Limit of option selectable (only for multiple selection)
     * @author ANDRE Felix
     * @type {number}
     * @memberof SelectBottomSheetComponent
     */
    @Input() selectionNumberLimit?: number;

    /**
     * @description Diplay the footer of the select component, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectBottomSheetComponent
     */
    @Input()
    public footer: boolean = false;

    /**
     * @description Display the detailed options, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectBottomSheetComponent
     */
    @Input()
    public detailedOptions = false;

    /**
     * @description Options already selected, use to fill the isSelected in SelectedOption (only for multiple selection)
     * @author ANDRE Felix
     * @type {string[]}
     * @memberof SelectBottomSheetComponent
     */
    @Input() selectedOptionsIds?: string[];

    /**
     * @description Used for optgroup select
     * @author ANDRE Felix
     * @type {optGroupOptions[]}
     * @memberof SelectBottomSheetComponent
     */
    @Input() optGroupOptions?: optGroupOptions[];

    /**
     * @description Set the options to display in the select component and the selected options in the select component, empty array if no option is selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectBottomSheetComponent
     */
    @Input()
    public set options(value: SelectOption[]) {
        this._options = value;
        this.selectedOptions = value?.length ? value.filter((x) => x.isSelected) : [];
    }

    /**
     * @description The options to display in the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @private
     * @type {SelectOption[]}
     * @memberof SelectBottomSheetComponent
     */
    private _options: SelectOption[] = [];

    /**
     * @description If the number of options selected reach the limit
     * @author ANDRE Felix
     * @memberof SelectBottomSheetComponent
     */
    isLimitReached = false;

    /**
     * @description The selected options in the select component, empty array if no option is selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @private
     * @type {SelectOption[]}
     * @memberof SelectBottomSheetComponent
     */
    private selectedOptions: SelectOption[] = [];

    // TODO: Remove this and find a better way to display the detailed options
    // public treasuryByDay: { day: Date; treasury: any; }[];

    constructor(private modalController: ModalController) {}

    /**
     * @description The options to display in the select component, empty array if no option is selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @readonly
     * @type {SelectOption[]}
     * @memberof SelectBottomSheetComponent
     */
    public get options(): SelectOption[] {
        return this._options;
    }

    ngOnInit(): void {
        // TODO: Remove this and find a better way to display the detailed options
        // if (this.detailedOptions) {
        //     const treasuryByDay = this._options.reduce(
        //         (tbt, treasury) => ({
        //             ...tbt,
        //             [treasury.date.toString()]: [
        //                 ...(tbt[treasury.date.toString()] || []),
        //                 treasury,
        //             ],
        //         }),
        //         {}
        //     );
        //     const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
        //         (a, b) => new Date(a).getTime() - new Date(b).getTime()
        //     );
        //     this.treasuryByDay = sortedTreasuryByDay.map((date) => ({
        //         day: new Date(date),
        //         treasury: treasuryByDay[date],
        //     }));
        // }
    }

    /**
     * @description Dismiss the select component and return the selected options if there is at least one selected option, null otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @returns {*}  {Promise<void>}
     * @memberof SelectBottomSheetComponent
     */
    public async dismissSelect(): Promise<void> {
        await this.modalController.dismiss(
            this.selectedOptions?.length > 0 ? this.selectedOptions : null
        );
    }

    /**
     * @description Dismiss the select component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @returns {*}  {Promise<void>}
     * @memberof SelectBottomSheetComponent
     */
    public async cancel(): Promise<void> {
        await this.modalController.dismiss();
    }

    /**
     * @description Handle the option clicked event
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @param {SelectOption} option
     * @memberof SelectBottomSheetComponent
     */
    public optionClicked(option: SelectOption): void {
        // Stringify the option to compare it with the selected options
        const json = JSON.stringify(option);

        // Find the index of the option in the selected options
        const index = this.selectedOptions.findIndex((x) => JSON.stringify(x) === json);
        // Why not this code:
        //const index = this.selectedOptions.findIndex((x) => x.id === option.id);

        // If the option is already selected, remove it from the selected options
        if (index >= 0) {
            this.selectedOptions.splice(index, 1);
            this.isLimitReached = false;
        } else if (this.isMultiple) {
            if (
                this.selectionNumberLimit &&
                this.selectedOptions.length >= this.selectionNumberLimit
            ) {
                this.isLimitReached = true;
                return;
            } else {
                // If the option is not selected and the select component is multiple, add it to the selected options
                this.selectedOptions.push(option);
                this.isLimitReached = false;
            }
        } else {
            // If the option is not selected and the select component is not multiple, remove all the selected options and add the option to the selected options
            this.cleanOptionsIsSelected();
            this.selectedOptions = [];
            this.selectedOptions.push(option);
            this.dismissSelect().then();
        }

        // Update the isSelected property of the option
        option.isSelected = index < 0;
    }

    private cleanOptionsIsSelected() {
        this.options.forEach((x) => (x.isSelected = false));
        this.optGroupOptions?.forEach((optGroupe) =>
            optGroupe.options.forEach((option) => {
                option.isSelected = false;
            })
        );
    }
}
