import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ReviewEntity, UserEntity } from '@omedom/data';
import { ReviewService, UserService } from '@omedom/services';

interface ReviewForm {
    comment: FormControl<string>;
    isAnonymous?: FormControl<boolean>;
}
@Component({
    selector: 'omedom-add-review',
    templateUrl: './add-review.component.html',
    styleUrls: ['./add-review.component.scss'],
})
export class AddReviewComponent implements OnInit {
    @Input() canAddReview = false;

    @Input({ required: true }) proUID!: string;

    @Input() review?: ReviewEntity;

    /**
     * @description Rate ive by client to Pro
     * @author ANDRE Felix
     * @memberof AddReviewComponent
     */
    rate?: number;

    currentUser?: UserEntity;

    reviewForm?: FormGroup<ReviewForm>;

    constructor(
        private reviewService: ReviewService,
        private userService: UserService,
        private modalController: ModalController,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit() {
        const user$ = this.userService.user$.subscribe((user) => {
            if (user) {
                this.currentUser = user;
            }
        });
        this.setForm(this.review);
        this.setReview();
    }

    setForm(review: ReviewEntity | undefined) {
        this.reviewForm = this.formBuilder.group<ReviewForm>({
            comment: new FormControl<string>(review?.comment ?? '', {
                nonNullable: true,
                validators: [Validators.required],
            }),
            isAnonymous: new FormControl<boolean>(review?.isAnonymous ?? false, {
                nonNullable: true,
                validators: [],
            }),
        });
    }

    setReview() {
        // Use for update a review, but not used for the moment
        if (!this.review) {
            return;
        }
        this.rate = this.review?.rate ?? undefined;
    }

    async onSubmit() {
        if (this.review) {
            // Not sure if we can update a review for the moment
            this.updateComment();
            return;
        }
        this.saveNewComment();

        await this.cancel();
    }

    async saveNewComment() {
        if (!this.currentUser) {
            return;
        }
        const data = this.reviewForm?.getRawValue();
        const isAnonymous = data?.isAnonymous;
        const name = isAnonymous ? '' : this.currentUser.name;
        const firstname = isAnonymous ? '' : this.currentUser.firstname;
        // const comment;
        const review = {
            comment: data?.comment,
            rate: this.rate,
            proUID: this.proUID,
            userUID: this.currentUser.uid,
            isAnonymous: isAnonymous,
            name,
            firstname,
        };

        await this.reviewService.create(review);
    }

    async updateComment() {}

    async cancel() {
        await this.modalController.dismiss();
    }

    rateChange(rate: number) {
        if (!rate) {
            return;
        }
        this.rate = rate;
    }

    isFormValid() {
        if (!this.reviewForm) {
            return false;
        }
        return this.reviewForm.valid && this.rate;
    }
}
