import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { Platform, ToastController } from '@ionic/angular';
import {
    BankAccountEntity,
    bankErrorMessages,
    BankItemEntity,
    BankStatusCode,
    UserEntity,
} from '@omedom/data';
import { BankAccountService, BankItemService, UserService } from '@omedom/services';
import { elementAnimation, listAnimation } from '@omedom/ui';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HeaderPreviousType } from '../../../component/header';

@Component({
    selector: 'omedom-bank-form',
    templateUrl: './bank-form.page.html',
    styleUrls: ['./bank-form.page.scss'],
    animations: [
        elementAnimation,
        listAnimation
    ]
})
export class BankFormPage implements OnInit, OnDestroy {
    /**
     * @description Previous type for the header component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public previousType = HeaderPreviousType;

    /**
     * @description Title of the component in the header component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public title$ = new BehaviorSubject<string>('Ajout d\'une banque');

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public state$ = new BehaviorSubject<string>('pending');

    /**
     * @description State of the edition button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public editionState$ = new BehaviorSubject<string>('ok');

    /**
     * @description Message in the edition button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public editionMessage$ = new BehaviorSubject<string>('Modifier ma connexion');

    /**
     * @description State of the delete button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public deleteState$ = new BehaviorSubject<string>('ok');

    /**
     * @description Message in the delete button
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public deleteMessage$ = new BehaviorSubject<string>('Supprimer ma banque');

    /**
     * @description State of the save button in the form page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public saveState$ = new BehaviorSubject<string>('ok');

    /**
     * @description Message in the save button in the form page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public saveMessage$ = new BehaviorSubject<string>('Confirmer');

    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {Observable<UserEntity>}
     * @memberof BankFormPage
     */
    public user$: Observable<UserEntity>;

    /**
     * @description Bank item data to display in the form page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {BankItemEntity}
     * @memberof BankFormPage
     */
    public item?: BankItemEntity;

    /**
     * @description Bank accounts linked to the bank item to display in the form page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {BankAccountEntity[]}
     * @memberof BankFormPage
     */
    public accounts?: BankAccountEntity[] = [];

    /**
     * @description Bank item id to display in the form page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {string}
     * @memberof BankFormPage
     */
    public select?: string;

    /**
     * @description Error messages from the bank's API when the status code is different from 0 (BankStatusCode.OK)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public bankErrorMessages = bankErrorMessages;

    /**
     * @description Form for each account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {FormControl[]}
     * @memberof BankFormPage
     */
    public forms: FormControl[];

    /**
     * @description Delete subscriptions after destroy
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @type {Subscription[]}
     * @memberof BankFormPage
     */
    private subscriptions: Subscription[] = [];

    constructor(
        private userService: UserService,
        private bankItemService: BankItemService,
        private bankAccountService: BankAccountService,
        private activatedRoute: ActivatedRoute,
        private platform: Platform,
        private toastController: ToastController,
        private router: Router
    ) {
        this.select = this.activatedRoute.snapshot.queryParamMap.get('select');

        this.setTitle();
    }

    ngOnInit(): void {
        this.user$ = this.userService.user$;

        this.getItem();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private setTitle(): void {
        // Check if the select is undefined
        // If true, set the title to 'Ajout d'une banque
        if (!this.select) {
            this.title$.next('Ajout d\'une banque');

            return;
        }

        // Check if the select is lastItem
        // If true, set the title to 'Ajout d'une banque
        // If false, set the title to 'Modification d'une banque
        if (this.select === 'lastItem') {
            this.title$.next('Ajout d\'une banque');
        } else {
            this.title$.next('Modification d\'une banque');
        }
    }

    /**
     * @description Return error message of the item if exist
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @readonly
     * @type {string}
     * @memberof BankFormPage
     */
    public get errorMessage(): string {
        return this.bankErrorMessages.find(message => message.status === this.item?.status)?.message ||
            this.item?.statusCodeDescription ||
            '';
    }

    /**
     * @description Get the bank item to display in the form page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @returns {void}
     * @memberof BankFormPage
     */
    private getItem(): void {
        // Check if the item id is defined
        if (!this.select) {
            this.state$.next('empty');

            return;
        }

        // Check if the select is lastItem
        // If true, get the last item of the user
        // If false, get the item by id
        if (this.select === 'lastItem') {
            this.subscriptions.push(
                this.user$.pipe(
                    switchMap(user => {
                        if (user) {
                            return this.bankItemService._getUserBankLastItem(user.uid).pipe(
                                tap(item => this.retrieveItem(item))
                            );
                        } else {
                            this.state$.next('error');

                            return of();
                        }
                    })
                ).subscribe((item: BankItemEntity | undefined) => {
                    if (item?.uid) {
                        this.item = item;

                        this.getAccounts();
                    } else {
                        this.state$.next('empty');
                    }
                })
            );

            return;
        } else if (typeof this.select === 'string') {
            this.subscriptions.push(
                this.bankItemService._get(this.select).pipe(
                    tap(item => this.retrieveItem(item))
                ).subscribe((item: BankItemEntity | undefined) => {
                    if (item?.uid) {
                        this.item = item;

                        this.getAccounts();
                    } else {
                        this.state$.next('empty');
                    }
                })
            );

            return;
        }

        // If the select is not valid, set the state to error
        this.state$.next('error');
    }

    /**
     * @description Get the bank accounts linked to the bank item to display in the form page in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @returns {void}
     * @memberof BankFormPage
     */
    private getAccounts(): void {
        // Check if the item is defined
        if (!this.item) {
            return;
        }

        this.subscriptions.push(
            this.user$.pipe(
                switchMap(user => this.bankAccountService._getBankAccountsFromItem(this.item, user.uid))
            ).subscribe(accounts => {
                // Check if accounts is defined
                if (!accounts) {
                    return;
                }

                // Check if accounts is already defined
                if (this.accounts.length > 0) {
                    return;
                }

                this.accounts = accounts;

                this.initForms();
            })
        );
    }

    /**
     * @description Initialize the forms for each account linked to the bank item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @memberof BankFormPage
     */
    private initForms(): void {
        if (this.accounts) {
            this.forms = this.accounts.map((account) => new FormControl({
                value: account.name,
                disabled: false
            }));
        }
    }

    /**
     * @description Catch the item and check its status to update the state of the component in real time in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @private
     * @returns {MonoTypeOperatorFunction<BankItemEntity>}
     * @memberof BankFormPage
     */
    private retrieveItem(item: BankItemEntity | undefined): void {
        // Check status of the item
        if (item?.status === BankStatusCode.OK) {
            this.state$.next('ok');
        } else {
            this.state$.next('error');
        }
    }

    /**
     * @description Edit the bank item by generating a bank sync session and redirecting the user to the bank website to connect his account
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @returns {Promise<void>}
     * @memberof BankFormPage
     */
    public async editItem(): Promise<void> {
        // Check if item is defined
        if (!this.item) {
            const toast = await this.toastController.create({
                message: 'La connexion à votre banque semble ne plus exister. Veuillez réessayer.',
                duration: 3000,
                color: 'danger',
            });

            toast.present();

            return;
        }

        // Init state
        this.editionState$.next('pending');

        // Init message
        this.editionMessage$.next('Génération de la session...');

        try {
            // Generate bank sync session
            const url = await this.bankItemService.generateBankEditSession(this.item);

            // Redirect user to url
            this.editionMessage$.next('Redirection vers la session...');

            if (this.platform.is('capacitor')) {
                await Browser.open({ url });
            } else {
                window.location.href = url;
            }
        } catch (error) {
            const toast = await this.toastController.create({
                message: 'Une erreur est survenue lors de la génération de la session de synchronisation. Veuillez réessayer.',
                duration: 3000,
                color: 'danger',
            });

            toast.present();
        }

        // Reset state
        this.editionState$.next('ok');

        // Reset message
        this.editionMessage$.next('Modifier ma connexion');
    }

    /**
     * @description Delete the bank item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @returns {Promise<void>}
     * @memberof BankFormPage
     */
    public async deleteItem(): Promise<void> {
        // Check if item is defined
        if (!this.item) {
            const toast = await this.toastController.create({
                message: 'La connexion à votre banque semble ne plus exister. Veuillez réessayer.',
                duration: 3000,
                color: 'danger',
            });

            toast.present();

            return;
        }

        // Init state
        this.deleteState$.next('pending');

        // Init message
        this.deleteMessage$.next('Suppression de la connexion...');

        try {
            await this.bankItemService.delete(this.item.uid);

            const toast = await this.toastController.create({
                message: 'La connexion à votre banque a bien été supprimée.',
                duration: 3000,
                color: 'warning',
            });

            toast.present();

            // Redirect user to the bank page
            this.router.navigate(['/bank']);
        } catch (error) {
            const toast = await this.toastController.create({
                message: 'Une erreur est survenue lors de la suppression de la connexion à votre banque. Veuillez réessayer.',
                duration: 3000,
                color: 'danger',
            });

            toast.present();
        }

        // Reset state
        this.state$.next('empty');
    }

    /**
     * @description Navigate back to bank list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankFormPage
     */
    public back(): void {
        this.router.navigate(['/tabs/bank']);
    }

    /**
     * @description Save the accounts name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @returns {Promise<void>}
     * @memberof BankFormPage
     */
    public async saveAccounts(): Promise<void> {
        // Init save state
        this.saveState$.next('pending');

        // Init save message
        this.saveMessage$.next('Enregistrement...');

        // Save accounts
        try {
            for (let index = 0; index < this.accounts.length; index++) {
                const account = this.accounts[index];
                const form = this.forms[index];

                // Update account name
                account.name = form.value;

                this.saveMessage$.next(`Enregistrement... (${index + 1}/${this.accounts.length})`);

                await this.bankAccountService.update(account);
            }
        } catch (error) {
            const toast = await this.toastController.create({
                message: 'Une erreur s\'est produite lors de l\'enregistrement des comptes. Veuillez réessayer.',
                duration: 3000,
                color: 'danger'
            });

            await toast.present();

            return;
        }

        if (this.select === 'lastItem') {
            await this.router.navigate(['/tabs/bank']);

            return;
        }

        // Display success message
        const toast = await this.toastController.create({
            message: 'Les comptes ont bien été enregistrés.',
            duration: 3000,
            color: 'success'
        });

        await toast.present();

        // Navigate to bank list
        this.back();

        // Reset state
        this.saveState$.next('ok');

        // Reset message
        this.saveMessage$.next('Confirmer');
    }
}
