import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubscriptionSlideEssentialComponent } from './containers/subscription-slide-essential/subscription-slide-essential.component';
import { SubscriptionSlideSmartComponent } from './containers/subscription-slide-smart/subscription-slide-smart.component';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { SubscriptionSlidePremiumComponent } from './containers/subscription-slide-premium/subscription-slide-premium.component';

@NgModule({
    declarations: [
        SubscriptionSlideEssentialComponent,
        SubscriptionSlideSmartComponent,
        SubscriptionSlidePremiumComponent,
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, OmedomComponentModule],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        SubscriptionSlideEssentialComponent,
        SubscriptionSlideSmartComponent,
        SubscriptionSlidePremiumComponent,
    ],
})
export class SharedModule {}
