import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from './popover/popover.component';
import { IonicModule } from '@ionic/angular';
import { TabComponent } from './tab/tab.component';
import { SubTabComponent } from './sub-tab/sub-tab.component';
import { InfoComponent } from './info/info.component';
import { RadioComponent } from './radio/radio.component';
import { ToggleComponent } from './toggle/toggle.component';
import { FormsModule } from '@angular/forms';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';
import { DateComponent } from './date/date.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { StepperComponent } from './stepper/stepper.component';
import { InputComponent } from './input/input.component';
import { ActionComponent } from './action/action.component';
import { SuggestSmartComponent } from './suggest-smart/suggest-smart.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { AlertComponent } from './alert/alert.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { PropertyFilterItemComponent } from './property-filter/property-filter-item/property-filter-item.component';
import { SmartPopoverComponent } from './smart-popover/smart-popover.component';
import { AssetFilterComponent } from './property-filter/asset-filter.component';
import { PropertySavedComponent } from './property-saved/property-saved.component';
import { WarningPopoverComponent } from './warning-popover/warning-popover.component';
import { TreasuryCardComponent } from './treasury-card/treasury-card.component';
import { StickerClicComponent } from './sticker-clic/sticker-clic.component';
import { FormSectionTitleComponent } from './form-section-title/form-section-title.component';
import { ToggleWithIconComponent } from './toggle-with-icon/toggle-with-icon.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { ValidationModalComponent } from './validation-modal/validation-modal.component';
import { CreateFirstItemButtonComponent } from './create-first-item-button/create-first-item-button.component';
import { CanvasChargeIncomeContainerComponent } from './canvas-charge-income-container/canvas-charge-income-container.component';
import { CanvasHeaderComponent } from './canvas-header/canvas-header.component';
import { AssetFilterSocietyComponent } from './property-filter/asset-filter-society/asset-filter-society.component';
import { AssetFilterGenericComponent } from './property-filter/asset-filter-generic/asset-filter-generic.component';
import { AssetFilterBuildingComponent } from './property-filter/asset-filter-building/asset-filter-building.component';
import {
    SelectComponent,
    SelectOptionComponent,
    SelectBottomSheetComponent
} from './select';
import { NgChartsModule } from 'ng2-charts';
import { UiModule } from '@omedom/ui';

@NgModule({
    declarations: [
        PopoverComponent,
        TabComponent,
        SubTabComponent,
        InfoComponent,
        RadioComponent,
        ToggleComponent,
        ChartLegendComponent,
        DateComponent,
        ToggleComponent,
        HeaderComponent,
        StepperComponent,
        InputComponent,
        ActionComponent,
        SuggestSmartComponent,
        ShowHidePasswordComponent,
        AlertComponent,
        DatePickerComponent,
        AssetFilterComponent,
        PropertyFilterItemComponent,
        SmartPopoverComponent,
        PropertySavedComponent,
        WarningPopoverComponent,
        TreasuryCardComponent,
        StickerClicComponent,
        FormSectionTitleComponent,
        ToggleWithIconComponent,
        DropdownComponent,
        ValidationModalComponent,
        CreateFirstItemButtonComponent,
        CanvasChargeIncomeContainerComponent,
        CanvasHeaderComponent,
        AssetFilterSocietyComponent,
        AssetFilterGenericComponent,
        AssetFilterBuildingComponent,
        SelectComponent,
        SelectOptionComponent,
        SelectBottomSheetComponent,
    ],
    exports: [
        PopoverComponent,
        TabComponent,
        SubTabComponent,
        InfoComponent,
        RadioComponent,
        ToggleComponent,
        HeaderComponent,
        StepperComponent,
        InputComponent,
        ChartLegendComponent,
        DateComponent,
        HeaderComponent,
        ActionComponent,
        SuggestSmartComponent,
        ShowHidePasswordComponent,
        AlertComponent,
        DatePickerComponent,
        AssetFilterComponent,
        PropertyFilterItemComponent,
        SmartPopoverComponent,
        PropertySavedComponent,
        TreasuryCardComponent,
        WarningPopoverComponent,
        StickerClicComponent,
        FormSectionTitleComponent,
        ToggleWithIconComponent,
        DropdownComponent,
        CreateFirstItemButtonComponent,
        CanvasChargeIncomeContainerComponent,
        CanvasHeaderComponent,
        ValidationModalComponent,
        AssetFilterSocietyComponent,
        AssetFilterGenericComponent,
        AssetFilterBuildingComponent,
        SelectComponent,
        SelectOptionComponent,
        SelectBottomSheetComponent,
    ],

    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        IonicModule,
        UiModule,
        NgChartsModule,
    ],
})
export class OmedomComponentModule { }
