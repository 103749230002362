// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
  max-height: 87vh !important;
  box-sizing: border-box;
  overflow-y: auto;
  height: 87vh !important;
}

.title {
  display: flex;
  justify-content: space-between;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.width-auto {
  width: auto !important;
}

.error-message {
  color: red;
  font-style: italic;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1ib3R0b20tc2hlZXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0VBQ0EsV0FBQTtFQUNBLDJCQUFBO0VBQ0Esc0JBQUE7RUFDQSxnQkFBQTtFQUNBLHVCQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0EsOEJBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0FBQ0o7O0FBRUE7RUFDSSxzQkFBQTtBQUNKOztBQUVBO0VBQ0ksVUFBQTtFQUNBLGtCQUFBO0FBQ0oiLCJmaWxlIjoic2VsZWN0LWJvdHRvbS1zaGVldC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICB3aWR0aDogMTAwJTtcbiAgICBtYXgtaGVpZ2h0OiA4N3ZoICFpbXBvcnRhbnQ7XG4gICAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xuICAgIGhlaWdodDogODd2aCAhaW1wb3J0YW50O1xufVxuXG4udGl0bGUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBzcGFjZS1iZXR3ZWVuO1xufVxuXG4udmVydGljYWwtY2VudGVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG59XG5cbi53aWR0aC1hdXRvIHtcbiAgICB3aWR0aDogYXV0byAhaW1wb3J0YW50O1xufVxuXG4uZXJyb3ItbWVzc2FnZSB7XG4gICAgY29sb3I6IHJlZDtcbiAgICBmb250LXN0eWxlOiBpdGFsaWM7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/select/bottom-sheet/select-bottom-sheet.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,2BAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,UAAA;EACA,kBAAA;AACJ;AACA,4mCAA4mC","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n    max-height: 87vh !important;\n    box-sizing: border-box;\n    overflow-y: auto;\n    height: 87vh !important;\n}\n\n.title {\n    display: flex;\n    justify-content: space-between;\n}\n\n.vertical-center {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.width-auto {\n    width: auto !important;\n}\n\n.error-message {\n    color: red;\n    font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
