import { Component, Input } from '@angular/core';
import { LoanEntity } from '@omedom/data';

import { LoanCardLayout } from '../..';
import { elementAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-loan-confirmation-step',
    templateUrl: './loan-confirmation-step.container.html',
    styleUrls: ['./loan-confirmation-step.container.scss'],
    animations: [
        elementAnimation
    ],
})
export class LoanConfirmationStepContainer {
    /**
     * @description Loan entity input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {LoanEntity}
     * @memberof LoanConfirmationStepContainer
     */
    @Input({ required: true })
    public loan!: LoanEntity;

    /**
     * @description Layout of the loan card component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @memberof LoanConfirmationStepContainer
     */
    @Input()
    public layout = LoanCardLayout.MOBILE;
}
