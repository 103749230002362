import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-trial-offer-end-modal',
    templateUrl: './trial-offer-end-modal.component.html',
    styleUrls: ['./trial-offer-end-modal.component.scss'],
})

// TODO: Not used any more. To delete or re use ???
export class TrialOfferEndModalComponent implements OnInit {
    @Input() offerName: string;

    public displayInformations = { essential: false, smart: false };

    constructor(
        private modalController: ModalController,
        private router: Router
    ) { }

    ngOnInit(): void { }

    public async dismiss(): Promise<void> {
        await this.modalController.dismiss();
    }

    public goSmart(): void {
        this.router.navigateByUrl(
            '/tabs/menu/subscription/change-subscription'
        );
        this.dismiss();
    }

    public displayInfo(toShow): void {
        this.displayInformations[toShow] = !this.displayInformations[toShow];
    }
}
