// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.footer {
  display: flex;
  gap: 20px;
}

button {
  width: -moz-fit-content;
  width: fit-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZGFsLWNvbmZpcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsU0FBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLFNBQUE7QUFDSjs7QUFFQTtFQUNJLHVCQUFBO0VBQUEsa0JBQUE7QUFDSiIsImZpbGUiOiJtb2RhbC1jb25maXJtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xufVxuXG4uY29udGFpbmVyIHtcbiAgICBwYWRkaW5nOiA0MHB4O1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDQwcHg7XG59XG5cbi5mb290ZXIge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZ2FwOiAyMHB4O1xufVxuXG5idXR0b24ge1xuICAgIHdpZHRoOiBmaXQtY29udGVudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/modal-confirm/modal-confirm.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;AACJ;;AAEA;EACI,uBAAA;EAAA,kBAAA;AACJ;AAEA,wtBAAwtB","sourcesContent":[":host {\n    display: block;\n}\n\n.container {\n    padding: 40px;\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n}\n\n.footer {\n    display: flex;\n    gap: 20px;\n}\n\nbutton {\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
