import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { ChargeListComponent } from './containers/charge-list/charge-list.component';
import { RouterModule } from '@angular/router';
import { ChargeFormPage } from './pages/charge-form/charge-form.page';
import { ChargeInfoPage } from './pages/charge-info/charge-info.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { ChargeEditPage } from './pages/charge-edit/charge-edit.page';
import { UiModule } from '@omedom/ui';

@NgModule({
    declarations: [ChargeListComponent, ChargeFormPage, ChargeInfoPage, ChargeEditPage],
    exports: [ChargeFormPage, ChargeInfoPage],
    providers: [DatePipe],
    imports: [
        IonicModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgChartsModule,
        OmedomComponentModule,
        UiModule,
    ],
})
export class ChargeModule {}
