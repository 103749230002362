import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ReccomendProComponent } from '../reccomend-pro/reccomend-pro.component';

@Component({
    selector: 'omedom-add-pro-card',
    templateUrl: './add-pro-card.component.html',
    styleUrls: ['./add-pro-card.component.scss'],
})
export class AddProCardComponent implements OnInit {
    constructor(private modalController: ModalController) {}

    ngOnInit() {}

    public async recomendPro() {
        const modal = await this.modalController.create({
            component: ReccomendProComponent,
        });
        await modal.present();
    }
}
