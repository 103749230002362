import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
// import { ConfidentialityComponent } from '@omedom/ui';
import { ConfidentialityComponent } from '../confidentiality/confidentiality.component';
@Component({
    selector: 'app-share-conditions',
    templateUrl: './share-conditions.component.html',
    styleUrls: ['./share-conditions.component.scss'],
})
export class ShareConditionsComponent {
    public isModal?: boolean;

    constructor(public modalController: ModalController) {}

    public async showMention(mention: string): Promise<void> {
        // let component;
        // I think this code was used with condition, but not now
        // switch (mention) {
        //     case 'confidentiality':
        //         component = ConfidentialityComponent;
        //         break;
        //     default:
        //         break;
        // }
        const modal = await this.modalController.create({
            component: ConfidentialityComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                modalController: this.modalController,
                isModal: true,
            },
        });
        await modal.present();
    }
}
