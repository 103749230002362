import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiModule } from '@omedom/ui';

import { BankModule } from '../bank/bank.module';
import { ChargeModule } from '../charge/charge.module';
import { OmedomCommonModule } from '../common';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { IncomeModule } from '../income/income.module';
import { TreasuryLiquidityComponent } from './containers/liquidity/treasury-liquidity.component';
import { TreasuryHomePage } from './pages/treasury-home/treasury-home.page';
import { TreasuryRoutingModule } from './treasury-routing.module';

@NgModule({
    declarations: [TreasuryHomePage, TreasuryLiquidityComponent],
    imports: [
        CommonModule,
        TreasuryRoutingModule,
        ChargeModule,
        IncomeModule,
        ReactiveFormsModule,
        FormsModule,
        OmedomComponentModule,
        OmedomCommonModule,
        IonicModule,
        BankModule,
        UiModule,
    ],
    exports: [TreasuryLiquidityComponent],
})
export class TreasuryModule { }
