// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  overflow-y: auto;
  height: 100%;
}
:host-header {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:host-header button {
  width: 100%;
}
:host omedom-chat-card {
  border-bottom: 1.5px solid #666666;
  padding-bottom: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYXQtbGlzdC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGdCQUFBO0VBQ0EsWUFBQTtBQUNKO0FBQ0k7RUFDSSxzQkFBQTtFQUNBLGFBQUE7RUFDQSxtQkFBQTtFQUNBLGFBQUE7RUFDQSx1QkFBQTtFQUNBLG1CQUFBO0FBQ1I7QUFBUTtFQUNJLFdBQUE7QUFFWjtBQUNJO0VBQ0ksa0NBQUE7RUFDQSxvQkFBQTtBQUNSIiwiZmlsZSI6ImNoYXQtbGlzdC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xuICAgIGhlaWdodDogMTAwJTtcblxuICAgICYtaGVhZGVyIHtcbiAgICAgICAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZjtcbiAgICAgICAgcGFkZGluZzogMTBweDtcbiAgICAgICAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIGJ1dHRvbiB7XG4gICAgICAgICAgICB3aWR0aDogMTAwJTtcbiAgICAgICAgfVxuICAgIH1cbiAgICBvbWVkb20tY2hhdC1jYXJkIHtcbiAgICAgICAgYm9yZGVyLWJvdHRvbTogMS41cHggc29saWQgIzY2NjY2NjtcbiAgICAgICAgcGFkZGluZy1ib3R0b206IDEwcHg7XG4gICAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/chat-list/chat-list.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;AACJ;AACI;EACI,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACR;AAAQ;EACI,WAAA;AAEZ;AACI;EACI,kCAAA;EACA,oBAAA;AACR;AACA,g+BAAg+B","sourcesContent":[":host {\n    overflow-y: auto;\n    height: 100%;\n\n    &-header {\n        background-color: #fff;\n        padding: 10px;\n        border-radius: 10px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        button {\n            width: 100%;\n        }\n    }\n    omedom-chat-card {\n        border-bottom: 1.5px solid #666666;\n        padding-bottom: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
