import {
    AllChargeCategories,
    AllIncomeCategories,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargeHistory,
    ChargePeriodicity,
    ChartDoughnutData,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomeFuturPayment,
    IncomeHistory,
    IncomePeriodicity,
    PropertyEntity,
    SocietyEntity,
    TreasuryStreamer,
} from '@omedom/data';
import { Timestamp } from 'firebase/firestore';

import { OmedomChart } from './chart';

/**
 * @description Utils for treasury
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @export
 * @class Treasury
 */
export class OmedomTreasury {
    /**
     * @description Retrieve charges and incomes between two dates
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @template T extends ChargeEntity | IncomeEntity
     * @param {T[]} entities Entities to filter
     * @param {Date} startDate Start date
     * @param {Date} endDate End date
     * @return {T[]}  Array of entities
     * @memberof Treasury
     * @example
     * const charges = OmedomTreasury.filterTreasury<ChargeEntity>(charges, startDate, endDate);
     * const incomes = OmedomTreasury.filterTreasury<IncomeEntity>(incomes, startDate, endDate);
     */
    public static filterTreasury<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        startDate: Date,
        endDate: Date
    ): T[] {
        const filteredEntities = entities.filter((entity) => {
            if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
                return entity.debitDate?.toDate().between(startDate, endDate);
            }

            const monthHistory = entity.history?.filter((x) =>
                x.date.toDate().between(startDate, endDate)
            );
            const hasHistoryForMonth = !!monthHistory?.length;

            if (hasHistoryForMonth) {
                return monthHistory.some((x) => !x.isDeleted);
            }

            const futurPaymentDeleted = entity.futurPayment?.some(
                (x) =>
                    x.date.toDate().toUTC().between(startDate, startDate.getUTCLastDayOfMonth()) &&
                    x.isDeleted
            );

            if (futurPaymentDeleted) {
                return false;
            }

            const hasFuturPayment = entity.futurPayment?.some(
                (x) =>
                    ((x.date.toDate().getUTCFirstDayOfMonth().getTime() <=
                        startDate.getUTCFirstDayOfMonth().getTime() &&
                        x.isForNext) ||
                        x.date
                            .toDate()
                            .toUTC()
                            .between(startDate, startDate.getUTCLastDayOfMonth())) &&
                    x.date.toDate().getMonth() === startDate.getMonth() &&
                    x.date.toDate().getFullYear() === startDate.getFullYear()
            );

            const isDeleted = entity.isDeleted;
            const isEndDateBeforeStartDate =
                (entity.endDate?.toDate()?.getUTCFirstDayOfMonth()?.getTime() ?? Infinity) <
                startDate.getUTCFirstDayOfMonth().getTime();

            // If the entity is deleted or the end date is before the start date, we return false
            if (isDeleted || isEndDateBeforeStartDate) {
                return false;
            }

            // If the entity has no history for the month and no futur payment, we check if the next history date is in the month
            if (
                !hasHistoryForMonth &&
                !hasFuturPayment &&
                entity.nextHistoryDate &&
                entity.nextHistoryDate.toDate().getFirstDayOfMonth().getTime() <=
                    startDate.getFirstDayOfMonth().getTime()
            ) {
                const chargeEndDate = entity.endDate?.toDate().toUTC();

                const nextHistoryDate = entity.nextHistoryDate.toDate().between(startDate, endDate)
                    ? entity.nextHistoryDate.toDate()
                    : this.getMonthHistoryDate(entity, endDate);

                return (
                    nextHistoryDate.between(startDate, endDate) &&
                    (!chargeEndDate || chargeEndDate.getUTCLastDayOfMonth() >= endDate)
                );
            }

            return hasHistoryForMonth || hasFuturPayment;
        });

        return filteredEntities;
    }

    /**
     * @description Map charges and incomes to a specific type of object with amount, category and isPayed properties
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @template T extends ChargeEntity | IncomeEntity
     * @param {T[]} entities Entities to map
     * @param {Date} startDate Start date
     * @param {Date} endDate End date
     * @return {*}  {
     *         category: ChargeCategoryProperty | IncomeCategoryProperty;
     *         amount: number;
     *         isPayed: boolean;
     *     }[]}
     * @memberof Treasury
     * @example
     * const charges = OmedomTreasury.getByType<ChargeEntity>(charges, startDate, endDate);
     * const incomes = OmedomTreasury.getByType<IncomeEntity>(incomes, startDate, endDate);
     */
    public static getByType<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        startDate: Date,
        endDate: Date
    ): {
        category: AllChargeCategories | AllIncomeCategories;
        amount: number;
        isPayed: boolean;
    }[] {
        return entities.map((entity: ChargeEntity | IncomeEntity) => ({
            category: entity.category,
            amount: Math.abs(this.getMonthAmount(entity, startDate)),
            isPayed: this.isTreasuryPayed(entity, startDate, endDate),
        }));
    }

    /**
     * @description Return true if the charge of income is payed between two dates (if not punctual, check history between dates), false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} startDate Start date
     * @param {Date} endDate End date
     * @return {*}  {boolean}
     * @memberof Treasury
     * @example
     * const isPayed = OmedomTreasury.isTreasuryPayed(charge, startDate, endDate);
     * const isPayed = OmedomTreasury.isTreasuryPayed(income, startDate, endDate);
     */
    public static isTreasuryPayed(
        entity: ChargeEntity | IncomeEntity,
        startDate: Date,
        endDate: Date
    ): boolean {
        if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            const now = new Date().getDateWithoutTime();

            if (entity.debitDate) {
                return entity.debitDate?.toDate() <= now;
            } else {
                return false;
            }
        }

        return (
            entity.history?.some((x) => x.isPayed && x.date.toDate().between(startDate, endDate)) ??
            false
        );
    }

    /**
     * @description Return the history date of a charge or income for a specific month
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @return {Date} History date or next history date
     * @memberof Treasury
     * @example
     * const historyDate = OmedomTreasury.getMonthHistoryDate(charge, date);
     */
    public static getMonthHistoryDate(entity: ChargeEntity | IncomeEntity, date: Date): Date {
        let periodicityMonth: number;
        let debitDate = entity.debitDate?.toDate()?.toUTC();

        // Search for futur payment in the month
        const futurPayment = entity.futurPayment?.find(
            (x) =>
                (x.date.toDate().getUTCFirstDayOfMonth().getTime() <=
                    date.getUTCFirstDayOfMonth().getTime() &&
                    x.isForNext) ||
                x.date.toDate().toUTC().between(date, date.getUTCLastDayOfMonth())
        );

        // If futur payment found, set debit date to futur payment date
        if (futurPayment) {
            debitDate = futurPayment.date.toDate().toUTC();
        }

        // Set periodicity month
        switch (entity.periodicity) {
            case ChargePeriodicity.bimonthly:
            case IncomePeriodicity.bimonthly:
                periodicityMonth = 2;
                break;

            case ChargePeriodicity.quarterly:
            case IncomePeriodicity.quarterly:
                periodicityMonth = 3;
                break;

            case ChargePeriodicity.halfYearly:
            case IncomePeriodicity.halfYearly:
                periodicityMonth = 6;
                break;

            case ChargePeriodicity.yearly:
            case IncomePeriodicity.yearly:
                periodicityMonth = 12;
                break;

            default:
                periodicityMonth = 1;
                break;
        }

        // Calculate next date index
        const nextDateIndex =
            (debitDate?.getUTCFirstDayOfMonth()?.getUTCDiffMonth(date?.getUTCFirstDayOfMonth()) ??
                0) / periodicityMonth;

        // Return date of the history month
        return this.addMonthFromPeriodicity(entity, debitDate ?? date, Math.round(nextDateIndex));
    }

    /**
     * @description Return the amount of a charge or income for a specific month
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @return {*}  {number}
     * @memberof Treasury
     * @example
     * const amount = OmedomTreasury.getMonthData(charge, date).amount;
     * const notes = OmedomTreasury.getMonthData(income, date).notes;
     */
    private static getMonthData(
        entity: ChargeEntity | IncomeEntity,
        date: Date
    ): { amount: number; notes: string; rentWithCharges?: number } {
        const startDate = date.getUTCFirstDayOfMonth();
        const endDate = date.getUTCLastDayOfMonth();
        const year = date.getFullYear();
        const isRent = entity.category === IncomeCategoryProperty.rent;
        let monthData: {
            amount: number;
            rentWithCharges?: number;
            notes: string;
            isSentReceipt?: boolean;
            isSentRelaunch?: boolean;
        };

        // Get histories between first day of month and last day of month
        let history = entity?.history?.filter(
            (x) => !x.isDeleted && x.date.toDate().between(startDate, endDate)
        );

        // Get futur payments between first day of month and last day of month
        let futurPayments = entity?.futurPayment?.filter(
            (x) =>
                !x.isDeleted &&
                !x.isForNext &&
                x.date.toDate().getMonth() === startDate.getMonth() &&
                x.date.toDate().getFullYear() === year
        );

        // If no futur payments found, search for futur payments in the past that was set for next month
        if (!futurPayments?.length) {
            futurPayments = entity?.futurPayment?.filter(
                (x) =>
                    (!x.isDeleted &&
                        x.date.toDate().getUTCFirstDayOfMonth().getTime() <= startDate.getTime() &&
                        x.isForNext) ||
                    x.date.toDate().between(startDate, endDate)
            );
            if (futurPayments && futurPayments.length > 1) {
                futurPayments = [
                    futurPayments.sort(
                        (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
                    )[0],
                ];
            }
        }

        // If futur payments found, return the sum of all futur payments and the notes of the last futur payment
        if (futurPayments?.length) {
            monthData = {
                amount: futurPayments.sumBy((h) => Math.abs(h.amount)),
                notes: futurPayments[0].notes ?? '',
            };
            if (isRent) {
                const rentWithCharges = futurPayments.sumBy((h: IncomeFuturPayment) =>
                    h.rentWithCharges ? Math.abs(h.rentWithCharges) : 0
                );
                monthData.rentWithCharges = rentWithCharges;
            }
            return monthData;
        }

        // If no history found, search for history in the month
        if (!history?.length) {
            history = entity?.history?.filter(
                (x) =>
                    !x.isDeleted &&
                    // x.date.toDate().getUTCFirstDayOfMonth().getTime() <= startDate.getTime()) && // TODO: ??? peviously ||
                    x.date.toDate().between(startDate, endDate)
            );

            if (history && history.length > 1) {
                history = [
                    history.sort(
                        (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
                    )[0],
                ];
            }
        }

        // If history found, return the sum of all history
        if (history?.length) {
            monthData = {
                amount: history.sumBy((h) => Math.abs(h.amount)),
                notes: history[0].notes ?? '',
            };

            if (isRent) {
                const rentWithCharges = history.sumBy((h: IncomeHistory) =>
                    h.rentWithCharges ? Math.abs(h.rentWithCharges) : 0
                );
                monthData.rentWithCharges = rentWithCharges;
            }
            return monthData;
        }

        // Return amount of the entity
        monthData = {
            amount: Math.abs(entity?.amount ?? 0),
            notes: entity?.notes ?? '',
        };
        if (entity.category === IncomeCategoryProperty.rent) {
            if ((entity as IncomeEntity)?.rentWithCharges) {
                const rentWithCharges = Math.abs((entity as IncomeEntity)?.rentWithCharges ?? 0);
                if (typeof rentWithCharges === 'number') {
                    monthData.rentWithCharges = rentWithCharges;
                }
            }
            monthData.isSentReceipt = (entity as IncomeEntity).isSentReceipt;
            monthData.isSentRelaunch = (entity as IncomeEntity).isSentRelaunch;
        }

        return monthData;
    }

    /**
     * @description Return the contractual rent of a charge or income for a specific month
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @return {*}  {number}
     * @memberof Treasury
     * @example
     * const amount = OmedomTreasury.getMonthAmount(charge, date);
     * const amount = OmedomTreasury.getMonthAmount(income, date);
     */
    public static getMonthSentEmail(entity: IncomeEntity, date: Date): number {
        const monthRentWithCharges = this.getMonthData(entity, date).rentWithCharges ?? 0;
        return monthRentWithCharges;
    }

    /**
     * @description Return the contractual rent of a charge or income for a specific month
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @return {*}  {number}
     * @memberof Treasury
     * @example
     * const amount = OmedomTreasury.getMonthRentWithCharges(income, date);
     */
    public static getMonthRentWithCharges(entity: IncomeEntity, date: Date): number {
        const monthRentWithCharges = this.getMonthData(entity, date).rentWithCharges ?? 0;
        return monthRentWithCharges;
    }

    /**
     * @description Return the amount of a charge or income for a specific month
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @return {*}  {number}
     * @memberof Treasury
     * @example
     * const amount = OmedomTreasury.getMonthAmount(charge, date);
     * const amount = OmedomTreasury.getMonthAmount(income, date);
     */
    public static getMonthAmount(entity: ChargeEntity | IncomeEntity, date: Date): number {
        const monthAmount = this.getMonthData(entity, date).amount;
        return monthAmount;
    }

    /**
     * @description Return the notes of a charge or income for a specific month
     * @author Jérémie Lopez
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @return {*}  {string}
     * @memberof Treasury
     * @example
     * const notes = OmedomTreasury.getMonthNotes(charge, date);
     * const notes = OmedomTreasury.getMonthNotes(income, date);
     */
    public static getMonthNotes(entity: ChargeEntity | IncomeEntity, date: Date): string {
        const monthNotes = this.getMonthData(entity, date).notes;
        return monthNotes;
    }

    /**
     * @description Return the date of the charge or income that it will be paid for a specific month (only work for futur payments and non-periodic charges or incomes)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to check
     * @param {Date} date Date of the day to retrieve first day of month and last day of month
     * @param {number} [index=1] Index of the month to check
     * @return {Date} Next date of the charge or income
     * @memberof Treasury
     * @example
     * const date = new Date();
     * const index = 2; // The after next month
     *
     * const nextDate = OmedomTreasury.getNextDateFromPeriodicity(charge, date, index);
     * const nextDate = OmedomTreasury.getNextDateFromPeriodicity(income, date, index);
     */
    public static addMonthFromPeriodicity(
        entity: ChargeEntity | IncomeEntity,
        date: Date,
        index: number = 1
    ): Date {
        let nbMonths: number;
        switch (entity.periodicity) {
            case ChargePeriodicity.monthly:
            case IncomePeriodicity.monthly:
                nbMonths = index;
                break;

            case ChargePeriodicity.bimonthly:
            case IncomePeriodicity.bimonthly:
                nbMonths = 2 * index;
                break;

            case ChargePeriodicity.quarterly:
            case IncomePeriodicity.quarterly:
                nbMonths = 3 * index;
                break;

            case ChargePeriodicity.halfYearly:
            case IncomePeriodicity.halfYearly:
                nbMonths = 6 * index;
                break;

            case ChargePeriodicity.yearly:
            case IncomePeriodicity.yearly:
                nbMonths = 12 * index;
                break;

            case ChargePeriodicity.punctual:
            case IncomePeriodicity.punctual:
            default:
                return date;
        }

        const firstDay = date.getUTCFirstDayOfMonth();
        const firstDayWithAddedMonth = firstDay.addUTCMonths(nbMonths);
        let nextDate = date.addUTCMonths(nbMonths);

        if (firstDayWithAddedMonth.toString() !== nextDate.getUTCFirstDayOfMonth().toString()) {
            nextDate = firstDayWithAddedMonth.getUTCLastDayOfMonth();
            nextDate = nextDate.getUTCDateWithoutTime();
        }

        return nextDate;
    }

    /**
     * @description Calculate history and nextHistory of a charge or income
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to calculate
     * @param {string} debitDate Debit date of the entity in string format
     * @param {string} startDate Start date of the entity in string format
     * @param {string} [endDate] End date of the entity in string format
     * @param {boolean} [edit] If the entity is edited
     * @return {void}
     * @memberof Treasury
     * @example
     * OmedomTreasury.calculateDateAndCalculateHistory(charge, '2020-01-01', '2020-01-01', '2022-08-11');
     * OmedomTreasury.calculateDateAndCalculateHistory(income, '2020-01-01', '2020-01-01', '2022-08-11');
     */
    public static calculateDateAndCalculateHistory(
        entity: ChargeEntity | IncomeEntity,
        debitDate: string,
        startDate?: string,
        endDate?: string,
        edit?: boolean
    ): void {
        // Check if debit date, start date and end date are defined
        if (!debitDate) {
            return;
        }

        // Retrieve debit date
        const debitDateUtc = new Date(debitDate).toUTC();

        entity.debitDate = Timestamp.fromDate(debitDateUtc);

        // Retrieve start date
        if (startDate) {
            entity.startDate = Timestamp.fromDate(new Date(startDate).toUTC());
        }

        // Retrieve end date
        let endDateUtc: Date | undefined;
        if (endDate) {
            endDateUtc = new Date(endDate).toUTC();
            entity.endDate = Timestamp.fromDate(endDateUtc);
        }

        // If the entity is punctual, we don't calculate nextHistoryDate and history
        if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            return;
        }

        // If the entity is periodic, we calculate nextHistoryDate and history
        // Retrieve current date
        const now = new Date().getUTCDateWithoutTime();

        if (debitDateUtc > now && !edit) {
            // If the debit date is in the futur, we set the nextHistoryDate with the debit date
            entity.nextHistoryDate = Timestamp.fromDate(debitDateUtc);
        } else {
            // Else we calculate the nextHistoryDate and history
            this.calculateNextHistoryDate(entity, debitDateUtc, now, endDateUtc, edit);

            this.calculateHistory(entity, debitDateUtc, now, endDateUtc);
        }
    }

    /**
     * @description Calculate next hisotry date of a charge or income
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to calculate
     * @param {Date} startDateUtc Start date of the entity in UTC
     * @param {Date} endDateUtc End date of the entity in UTC
     * @param {Date} nowUtc Current date in UTC
     * @param {boolean} [edit] If the entity is edited
     * @memberof Treasury
     * @example
     * const startDate = new Date('2020-01-01').toUTC();
     * const endDate = new Date('2020-08-01').toUTC();
     * const now = new Date('2020-06-01').toUTC()
     *
     * OmedomTreasury.calculateNextHistoryDate(charge, startDate, endDate, now);
     * OmedomTreasury.calculateNextHistoryDate(income, startDate, endDate, now);
     */
    private static calculateNextHistoryDate(
        entity: ChargeEntity | IncomeEntity,
        startDateUtc: Date,
        nowUtc: Date,
        endDateUtc?: Date,
        edit?: boolean
    ): void {
        let index = 1;
        let nextDateUtc = startDateUtc;

        // Calculate next history date until it's in the futur or until the end date
        while (
            nextDateUtc.getTime() < nowUtc.getTime() &&
            (!endDateUtc || nextDateUtc < endDateUtc)
        ) {
            nextDateUtc = this.addMonthFromPeriodicity(entity, startDateUtc, index);
            index++;
        }

        // Set next history date if the entity is not edited
        if (!edit) {
            entity.nextHistoryDate = Timestamp.fromDate(nextDateUtc);
        }
    }

    /**
     * @description Calculate history of a charge or income
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @private
     * @static
     * @param {ChargeEntity | IncomeEntity} entity Entity to calculate
     * @param {Date} startDateUtc Start date of the entity in UTC
     * @param {Date} nowUtc Current date in UTC
     * @param {Date} [endDateUtc] End date of the entity in UTC
     * @memberof Treasury
     * @example
     * const startDate = new Date('2020-01-01').toUTC();
     * const endDate = new Date('2020-08-01').toUTC();
     * const now = new Date('2020-06-01').toUTC()
     *
     * OmedomTreasury.calculateHistory(charge, startDate, endDate, now);
     * OmedomTreasury.calculateHistory(income, startDate, endDate, now);
     */
    private static calculateHistory(
        entity: ChargeEntity | IncomeEntity,
        startDateUtc: Date,
        nowUtc: Date,
        endDateUtc?: Date
    ): void {
        let index = 1;
        let nextDateUtc = startDateUtc;

        // Calculate history until it's in the futur or until the end date
        while (
            nextDateUtc.getTime() <= nowUtc.getTime() &&
            (!endDateUtc || nextDateUtc < endDateUtc)
        ) {
            // If history is not defined, we create it
            if (!entity.history) {
                entity.history = [];
            }

            // Create history
            const entityHistory: ChargeHistory | IncomeHistory = {
                amount: Math.abs(entity.amount),
                date: Timestamp.fromDate(nextDateUtc),
                isPayed: true,
                isDeleted: false,
            };

            if (typeof (entity as IncomeEntity).rentWithCharges === 'number') {
                (entityHistory as IncomeHistory).rentWithCharges = Math.abs(
                    (entity as IncomeEntity).rentWithCharges ?? 0
                );
            }

            // If the entity has a designation, we add it to the history
            if (entity.designation) {
                entityHistory.designation = entity.designation;
            }

            // If the entity has notes, we add it to the history
            if (entity.notes) {
                entityHistory.notes = entity.notes;
            }

            // If the entity has notes, we add it to the history
            if ((entity as IncomeEntity).rentWithCharges) {
                (entityHistory as IncomeHistory).rentWithCharges = (
                    entity as IncomeEntity
                ).rentWithCharges;
            }

            // Push history to the entity
            entity.history.push(entityHistory);

            // Calculate next history date
            nextDateUtc = this.addMonthFromPeriodicity(entity, startDateUtc, index);

            // Increment index
            index++;
        }
    }

    /**
     * @description Return the next treasury of a list of charges or incomes depending on the date
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {(ChargeEntity | IncomeEntity)[]} entities List of charges or incomes
     * @param {Date} currentDate Date to compare
     * @return {ChargeEntity | IncomeEntity} Next treasury
     * @memberof Treasury
     * @example
     * const charges = await chargeService.search({...});
     * const incomes = await incomeService.search({...});
     *
     * const nextCharge = OmedomTreasury.getNextTreasury(charges, new Date());
     * const nextIncome = OmedomTreasury.getNextTreasury(incomes, new Date());
     */
    public static getNextTreasury(
        entities: (ChargeEntity | IncomeEntity)[],
        currentDate: Date
    ): ChargeEntity | IncomeEntity {
        return this.getAllNextTreasury(entities, currentDate)[0];
    }

    /**
     * @description Return all next treasury of a list of charges or incomes depending on the date
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @static
     * @param {(ChargeEntity | IncomeEntity)[]} entities List of charges or incomes
     * @param {Date} currentDate Date to compare
     * @return {(ChargeEntity | IncomeEntity)[]} All next treasury of the list
     * @memberof Treasury
     * @example
     * const charges = await chargeService.search({...});
     * const incomes = await incomeService.search({...});
     *
     * const nextCharges = OmedomTreasury.getAllNextTreasury(charges, new Date());
     * const nextIncomes = OmedomTreasury.getAllNextTreasury(incomes, new Date());
     */
    public static getAllNextTreasury(
        entities: (ChargeEntity | IncomeEntity)[],
        currentDate: Date
    ): (ChargeEntity | IncomeEntity)[] {
        // Check if entities exist and if it's empty
        if (!entities || entities.length === 0) {
            return [];
        }

        // Check if current date is defined
        if (!currentDate) {
            currentDate = new Date();
        }

        // Filter deleted entities
        const notDeleted = entities.filter((x) => !x.isDeleted);

        // Filter entities with end date in the futur
        const futureTreasury = notDeleted.filter((x) => {
            const res = x.endDate ? x.endDate.toDate() > currentDate : true;
            return res;
        });

        // Sort entities by next history date
        const orderedTreasury = futureTreasury.sort(
            (a: ChargeEntity | IncomeEntity, b: ChargeEntity | IncomeEntity) => {
                // If next history date is not defined, we use debit date
                const aNextDate = a.nextHistoryDate
                    ? a.nextHistoryDate.toDate()
                    : a.debitDate?.toDate() ?? currentDate;
                const bNextDate = b.nextHistoryDate
                    ? b.nextHistoryDate.toDate()
                    : b.debitDate?.toDate() ?? currentDate;

                // Make the difference between the next history date and the current date
                const t = aNextDate.getTime() - currentDate.getTime();
                const x = bNextDate.getTime() - currentDate.getTime();

                // Return the difference
                return t - x;
            }
        );

        // Return the list of next treasury
        return orderedTreasury;
    }

    /**
     * @description Return infos about treasury for a list of charges or incomes depending on a range of date
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/08/2023
     * @static
     * @param {(ChargeEntity | IncomeEntity)[]} entities List of charges or incomes
     * @param {Date} startDate Start date of the range
     * @param {Date} endDate End date of the range
     * @returns {{
     *    totalAmount: number;
     *    totalAmountPayed: number;
     *    totalAmountNotPayed: number;
     * }} Infos about treasury
     * @memberof Treasury
     * @example
     * const charges = await chargeService.search({...});
     * const incomes = await incomeService.search({...});
     *
     * const startDate = new Date(2021, 0, 1);
     * const endDate = new Date(2021, 11, 31);
     *
     * const chargesInfos = OmedomTreasury.getTreasuryTotalInfo(charges, startDate, endDate);
     * const incomesInfos = OmedomTreasury.getTreasuryTotalInfo(incomes, startDate, endDate);
     */
    public static getTreasuryTotalInfo(
        entities: (ChargeEntity | IncomeEntity)[],
        startDate: Date,
        endDate: Date
    ): {
        totalAmount: number;
        totalAmountPayed: number;
        totalAmountNotPayed: number;
    } {
        // Filter entities in the range of date
        const filteredData = this.filterTreasury(
            entities,
            startDate.getUTCDateWithoutTime(),
            endDate.getUTCDateWithoutTime()
        );

        // Get amount of entities in the range of date
        const amount = this.getByType(filteredData, startDate, endDate);

        // Calculate totals amounts
        const totalsAmounts = {
            totalAmount: amount.sumBy((x) => x.amount),
            totalAmountPayed: amount.filter((x) => x.isPayed).sumBy((x) => x.amount),
            totalAmountNotPayed: amount.filter((x) => !x.isPayed).sumBy((x) => x.amount),
        };

        // Return totals amounts
        return totalsAmounts;
    }

    /**
     * @description Get treasury in a period of time (between two dates) and return it as an array of Story
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/08/2023
     * @static
     * @template T
     * @param {T[]} entities
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {boolean} isCharge
     * @returns {*} Story for treasury in a period of time
     * @memberof Treasury
     * @example
     * const charges = await chargeService.search({ ... });
     * const incomes = await incomeService.search({ ... });
     *
     * const startDate = new Date('2021-01-01');
     * const endDate = new Date('2021-12-31');
     *
     * const treasury = OmedomTreasury.getTreasuryInPeriod(charges, incomes, startDate, endDate, true);
     * const treasury = OmedomTreasury.getTreasuryInPeriod(incomes, incomes, startDate, endDate, false);
     */
    public static getTreasuryInPeriod<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        startDate: Date,
        endDate: Date,
        isCharge: boolean
    ): TreasuryStreamer[] {
        // Filter entities in the range of date
        const filteredData = this.filterTreasury<T>(entities, startDate, endDate);

        // Init array of treasury by month
        const treasuryByMonth: TreasuryStreamer[] = [];

        // Loop on filtered data
        filteredData.forEach((entity) => {
            // Get history in the range of date
            const history: ChargeHistory[] | IncomeHistory[] | undefined = entity.history?.filter(
                (x) => x.date.toDate().between(startDate, endDate)
            );

            // Get future payments in the range of date
            const futurePayments = entity.futurPayment?.filter(
                (x) =>
                    (x.date.toDate().getFirstDayOfMonth().getTime() <=
                        startDate.getFirstDayOfMonth().getTime() &&
                        x.isForNext) ||
                    (x.date.toDate().between(startDate, endDate) && !x.isForNext)
            );

            // Deconstruct entity
            const {
                uid,
                category,
                periodicity,
                propertyUID,
                societyUID,
                userUID,
                isDeleted,
                designation,
            } = entity;

            // If history exists
            if (history?.length) {
                const currentTreasury = history.map((currentHistory) => {
                    let thisTreasury: /**ChargeHistory + uid | IncomeHistory + uid**/ {
                        uid: string;
                        amount: number;
                        category: AllChargeCategories | AllIncomeCategories;
                        debitDate: Date;
                        isPayed: boolean;
                        isReaded: boolean;
                        notes: string | undefined;
                        designation: string | undefined;
                        notification: boolean | undefined;
                        periodicity: IncomePeriodicity | ChargePeriodicity;
                        propertyUID?: string;
                        societyUID?: string;
                        isDeleted: boolean;
                        isCharge: boolean;
                        userUID: string;
                        isSentReceipt?: boolean;
                        isSentRelaunch?: boolean;
                        rentWithCharges?: number;
                    } = {
                        uid,
                        amount: Math.abs(currentHistory.amount),
                        category,
                        debitDate: currentHistory.date.toDate(),
                        isPayed: currentHistory.isPayed ?? false,
                        isReaded: currentHistory.isReaded ?? false,
                        notes: currentHistory.notes,
                        designation,
                        notification: entity.notification,
                        periodicity,
                        propertyUID,
                        societyUID,
                        isDeleted: isDeleted ?? false,
                        isCharge,
                        userUID,
                    };

                    if (category === IncomeCategoryProperty.rent) {
                        thisTreasury = {
                            ...thisTreasury,
                            isSentReceipt: (currentHistory as IncomeHistory).isSentReceipt,
                            isSentRelaunch: (currentHistory as IncomeHistory).isSentRelaunch,
                            rentWithCharges: (currentHistory as IncomeHistory).rentWithCharges,
                        };
                    }
                    return thisTreasury;
                });

                treasuryByMonth.push(...currentTreasury);
            }
            // If no history, get future payments
            else {
                if (futurePayments?.length) {
                    let currentTreasury = futurePayments.slice(-1).map((futurePayment) => {
                        return {
                            uid,
                            amount: Math.abs(futurePayment.amount),
                            category,
                            debitDate:
                                entity.periodicity === 'punctual'
                                    ? entity.debitDate?.toDate() ??
                                      this.getMonthHistoryDate(entity, startDate.toUTC())
                                    : this.getMonthHistoryDate(entity, startDate.toUTC()),
                            isPayed: false,
                            isReaded: false,
                            note: futurePayment.notes,
                            designation,
                            periodicity,
                            propertyUID,
                            societyUID,
                            isDeleted: isDeleted ?? false,
                            isCharge,
                        };
                    });

                    treasuryByMonth.push(...currentTreasury);
                } else {
                    // If no history and no future payments, the treasury is punctual // TODO: test that and change it if needed
                    treasuryByMonth.push({
                        uid,
                        amount: this.getMonthAmount(entity, startDate),
                        category,
                        debitDate:
                            entity.periodicity === 'punctual'
                                ? entity.debitDate?.toDate() ??
                                  this.getMonthHistoryDate(entity, startDate.toUTC())
                                : this.getMonthHistoryDate(entity, startDate.toUTC()),
                        isPayed: entity.isPayed ?? false,
                        isReaded: entity.isReaded ?? false,
                        notes: this.getMonthNotes(entity, startDate),
                        designation,
                        periodicity,
                        propertyUID,
                        societyUID,
                        isDeleted: isDeleted ?? false,
                        isCharge,
                    });
                }
            }
        });

        // Return treasury by month array
        return treasuryByMonth;
    }

    /**
     * @description Transform treasury streamer model to treasury (ChargeEntity or IncomeEntity) array
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/08/2023
     * @static
     * @param {TreasuryStreamer[]} treasury Streamer model
     * @returns {*}  {((ChargeEntity | IncomeEntity)[])}
     * @memberof OmedomTreasury
     * @example
     * const treasury = OmedomTreasury.transformStreamerModelToTreasury(treasuryStreamerModel);
     */
    public static transformStreamerModelToTreasury(
        treasuries: TreasuryStreamer[]
    ): (ChargeEntity | IncomeEntity)[] {
        return treasuries.map((treasury) => {
            const {
                uid,
                amount,
                category,
                debitDate,
                isPayed,
                isReaded,
                notes,
                designation,
                periodicity,
                propertyUID,
                societyUID,
                isDeleted,
                isCharge,
                userUID,
                notification,
            } = treasury;

            const entity = isCharge ? new ChargeEntity({}) : new IncomeEntity({});

            entity.uid = uid;
            entity.amount = amount;
            entity.category = category;
            entity.debitDate = Timestamp.fromDate(debitDate);
            entity.isPayed = isPayed;
            entity.isReaded = isReaded;
            entity.notes = notes;
            entity.designation = designation;
            entity.periodicity = periodicity;
            if (propertyUID) {
                entity.propertyUID = propertyUID;
            }
            if (societyUID) {
                entity.societyUID = societyUID;
            }
            entity.isDeleted = isDeleted;
            entity.userUID = userUID ?? '';
            entity.notification = notification;

            // Set the isSentReceipt and isSentRelaunch if the treasury is an income rent
            if (treasury.category === IncomeCategoryProperty.rent) {
                (entity as IncomeEntity).isSentReceipt = treasury.isSentReceipt;
                (entity as IncomeEntity).isSentRelaunch = treasury.isSentRelaunch;
                (entity as IncomeEntity).rentWithCharges = treasury.rentWithCharges;
            }
            return entity;
        });
    }

    /**
     * @description Sort treasury by day and return it as an array of Story by day
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/08/2023
     * @static
     * @param {(ChargeEntity | IncomeEntity)[]} entities
     * @returns {*}
     * @memberof OmedomTreasury
     * @example
     * const charges = await chargeService.search({ ... });
     * const incomes = await incomeService.search({ ... });
     *
     * const treasurySortedByDay = OmedomTreasury.sortTreasuryByDay([...charges, ...incomes]);
     */
    public static sortTreasuryByDay(
        entities: (ChargeEntity | IncomeEntity)[]
    ): { day: Date; treasury: (ChargeEntity | IncomeEntity)[] }[] {
        const treasuryByDay = entities.reduce(
            (tbt: { [key: string]: (ChargeEntity | IncomeEntity)[] }, treasury) => {
                // Check if debit date exists
                if (!treasury.debitDate) {
                    return tbt;
                }

                // Create an object with debit date as key and treasury as value
                return {
                    ...tbt,
                    [treasury.debitDate?.toDate()?.toString()]: [
                        ...(tbt[treasury.debitDate?.toDate()?.toString()] || []),
                        treasury,
                    ],
                };
            },
            {}
        );

        // Sort treasury by day
        const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );

        // Return treasury by day array
        return sortedTreasuryByDay.map((date) => ({
            day: new Date(date),
            treasury: treasuryByDay[date],
        }));
    }

    /**
     * @description Sort treasury streamer by day and return it as an array of Story by day
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 01/08/2023
     * @static
     * @param {TreasuryStreamer[]} entities
     * @returns {*}
     * @memberof OmedomTreasury
     * @example
     * const charges = await chargeService.search({ ... });
     * const incomes = await incomeService.search({ ... });
     *
     * const streamersSortedByDay = OmedomTreasury.sortStreamerByDay([...charges, ...incomes]);
     */
    public static sortStreamerByDay(
        entities: TreasuryStreamer[]
    ): { day: Date; treasury: TreasuryStreamer[] }[] {
        const treasuryByDay = entities.reduce(
            (tbt: { [key: string]: TreasuryStreamer[] }, treasury) => {
                // Check if debit date exists
                if (!treasury.debitDate) {
                    return tbt;
                }

                // Create an object with debit date as key and treasury as value
                return {
                    ...tbt,
                    [treasury.debitDate?.toString()]: [
                        ...(tbt[treasury.debitDate.toString()] || []),
                        treasury,
                    ],
                };
            },
            {}
        );

        // Sort treasury by day
        const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );

        // Return treasury by day array
        return sortedTreasuryByDay.map((date) => ({
            day: new Date(date),
            treasury: treasuryByDay[date],
        }));
    }

    /**
     * @description Return chart data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @static
     * @template T
     * @param {T[]} entities
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {*}  {ChartDoughnutData[]}
     * @memberof OmedomTreasury
     */
    public static getChartData<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        startDate: Date,
        endDate: Date,
        properties?: PropertyEntity[]
    ): ChartDoughnutData[] {
        const filteredData = this.filterTreasury<T>(entities, startDate, endDate);

        // Check if filtered data is empty
        if (!filteredData.length) {
            return [];
        }

        const data = filteredData.reduce((acc, entity, i) => {
            const { category } = entity;
            const amount = Math.abs(this.getAmount(entity, startDate, endDate));
            const isPayed = this.isTreasuryPayed(entity, startDate, endDate);
            const property = properties?.find((x) => x.uid === entity.propertyUID);

            const index = acc.findIndex(
                (x) =>
                    x.label ===
                    (property ? property.name : this.transformCategoryToLabel(category).label)
            );

            if (index !== -1) {
                acc[index].value += amount;
            } else {
                // If property, create color from name string; else select color by category
                const propertyIndex = properties?.findIndex((x) => x.uid === entity.propertyUID);
                const propertyColor = OmedomChart.colors[(propertyIndex ?? 0) + 1];
                const color = property
                    ? propertyColor
                    : // TODO colorByTreasury must implement color of AllchargeCategories
                      OmedomChart.colorByTreasury[category as AllChargeCategories] ?? '#04151c';

                if (amount > 0) {
                    acc.push({
                        label: property
                            ? property.name
                            : this.transformCategoryToLabel(category).label,
                        value: amount,
                        color,
                        hatch: !isPayed,
                    });
                }
            }

            return acc;
        }, [] as ChartDoughnutData[]);

        // Sort data by value
        data.sort((a, b) => b.value - a.value);

        return data;
    }

    /**
     * @description Translate category to label (ChargeCategoryInfo or IncomeCategoryInfo) depending on the category passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @static
     * @param {string} category
     * @returns {*}  {(ChargeCategoryInfo | IncomeCategoryInfo)}
     * @memberof OmedomTreasury
     */
    public static transformCategoryToLabel(
        category: string
    ): ChargeCategoryInfo | IncomeCategoryInfo {
        if (category in IncomeCategoryProperty) {
            return new IncomeCategoryInfo(
                IncomeCategoryProperty[category as keyof typeof IncomeCategoryProperty]
            );
        }

        return new ChargeCategoryInfo(
            ChargeCategoryProperty[category as keyof typeof ChargeCategoryProperty]
        );
    }

    /**
     * @description Return if the treasury is payed for the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @static
     * @param {(ChargeEntity | IncomeEntity)} entity
     * @param {Date} date
     * @returns {*}  {boolean}
     * @memberof OmedomTreasury
     */
    public static isPayed(entity: ChargeEntity | IncomeEntity, date: Date): boolean {
        if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            return entity.isPayed ?? false;
        }

        const history = this.getMonthHistory(entity, date);

        return history?.isPayed ?? false;
    }

    /**
     * @description Return the history of the month of the date passed in parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @static
     * @param {(ChargeEntity | IncomeEntity)} entity
     * @param {Date} date
     * @returns {*}  {(ChargeHistory | IncomeHistory | undefined)}
     * @memberof OmedomTreasury
     */
    public static getMonthHistory(
        entity: ChargeEntity | IncomeEntity,
        date: Date
    ): ChargeHistory | IncomeHistory | undefined {
        if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            return undefined;
        }

        const history = entity.history?.find((x) => {
            const sameMonth = x.date.toDate().getMonth() === date.getMonth();
            const sameYear = x.date.toDate().getFullYear() === date.getFullYear();

            return sameMonth && sameYear;
        });

        return history;
    }

    public static getAmount(
        entity: ChargeEntity | IncomeEntity,
        startDate: Date,
        endDate: Date
    ): number {
        const now = new Date().getUTCDateWithoutTime();

        let amount = 0;

        // If entity is punctual, return amount of the entity
        if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            return Math.abs(entity.amount ?? 0);
        }

        // Get histories between first day of month and last day of month
        let history = entity?.history?.filter(
            (x) => !x.isDeleted && x.date.toDate().between(startDate, endDate)
        );

        // Get futur payments between first day of month and last day of month
        let futurPayments = entity?.futurPayment?.filter(
            (x) =>
                !x.isDeleted && !x.isForNext && x.date.toDate().getFullYear() === now.getFullYear()
        );

        // If no futur payments found, search for futur payments in the past that was set for next month
        if (!futurPayments?.length) {
            futurPayments = entity?.futurPayment?.filter(
                (x) =>
                    (!x.isDeleted &&
                        x.date.toDate().getUTCFirstDayOfMonth().getTime() <= startDate.getTime() &&
                        x.isForNext) ||
                    x.date.toDate().between(startDate, endDate)
            );
            if (futurPayments && futurPayments.length > 1) {
                futurPayments = [
                    futurPayments.sort(
                        (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
                    )[0],
                ];
            }
        }

        // If futur payments found, return the sum of all futur payments
        if (futurPayments?.length) {
            amount += futurPayments.sumBy((h) => Math.abs(h.amount));
        }

        // If no history found, search for history in the month
        if (!history?.length) {
            history = entity?.history?.filter(
                (x) =>
                    (!x.isDeleted &&
                        x.date.toDate().getUTCFirstDayOfMonth().getTime() <= startDate.getTime()) ||
                    x.date.toDate().between(startDate, endDate)
            );

            if (history && history.length > 1) {
                history = [
                    history.sort(
                        (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
                    )[0],
                ];
            }
        }

        // If history found, return the sum of all history
        if (history?.length) {
            amount += history.sumBy((h) => Math.abs(h.amount));
        }

        // Return amount of the entity
        return Math.abs(amount ?? entity?.amount ?? 0);
    }

    public static getLastHistoryAmount(entity: ChargeEntity | IncomeEntity): number {
        const lastHistory = this.getLastHistory(entity);
        let amount = 0;

        if (lastHistory) {
            amount = lastHistory.amount;
        } else {
            amount = entity.amount ?? 0;
        }
        return amount;
    }

    public static getLastHistoryDate(entity: ChargeEntity | IncomeEntity): Date {
        const lastHistory = this.getLastHistory(entity);
        let date = new Date();

        if (lastHistory) {
            date = lastHistory.date.toDate();
        } else {
            date = entity.debitDate ? entity.debitDate.toDate() : new Date();
        }
        return date;
    }

    public static getLastHistorySentEmail(
        entity: IncomeEntity
    ): { isSentReceipt: boolean; isSentRelaunch: boolean } | void {
        if (entity.category !== IncomeCategoryProperty.rent) {
            return;
        }

        const lastHistory: IncomeHistory | undefined = this.getLastHistory(entity);
        const sentEmail = {
            isSentReceipt: false,
            isSentRelaunch: false,
        };

        if (lastHistory) {
            const { isSentReceipt, isSentRelaunch } = lastHistory;
            sentEmail.isSentReceipt = !!isSentReceipt;
            sentEmail.isSentRelaunch = !!isSentRelaunch;
        }

        return sentEmail;
    }

    public static getLastHistory(
        entity: ChargeEntity | IncomeEntity
    ): ChargeHistory | IncomeHistory | undefined {
        if (entity.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            return undefined;
        }
        return entity.history?.find((history) => {
            if (!entity.lastHistoryDate) {
                return false;
            }
            return history.date.seconds === entity.lastHistoryDate.seconds;
        });
    }

    public static getPartialRents(
        incomes: IncomeEntity[],
        currentDate: Date,
        properties: PropertyEntity[]
    ): TreasuryStreamer[] {
        // let incomes = lodash.cloneDeep(this.incomes) as IncomeEntity[];

        // only rental incomes not deleted and with a property
        const rentIncomes = incomes.filter((income) => {
            const isCategoryRent = income.category === IncomeCategoryProperty.rent;
            const isNotDeleted = !income.isDeleted;
            const isPropertyExists = properties.find((p) => p.uid === income.propertyUID);
            return isCategoryRent && isNotDeleted && isPropertyExists;
        });

        // only rentIncomes in the current month
        const partialIncome = this.getTreasuryInPeriod(
            rentIncomes,
            currentDate.getUTCFirstDayOfMonth(),
            currentDate.getUTCLastDayOfMonth(),
            false
        );

        return partialIncome;
    }

    public static getTreasuryInPeriodWithPropertiesAndSocieties(
        treasuries: (ChargeEntity | IncomeEntity)[],
        currentDate: Date,
        properties: PropertyEntity[],
        societies: SocietyEntity[],
        isCharge: boolean
    ): TreasuryStreamer[] {
        // Filter treasuries
        const filteredTreasuries = treasuries.filter((treasury) => {
            const isNotDeleted = !treasury.isDeleted;
            const isPropertyExists = properties.find((p) => p.uid === treasury.propertyUID);
            const isSocietyExists = societies.find((s) => s.uid === treasury.societyUID);
            return isNotDeleted && (isPropertyExists || isSocietyExists);
        });

        // Get partial treasuries in the period
        const partialTreasuries = this.getTreasuryInPeriod(
            filteredTreasuries,
            currentDate.getUTCFirstDayOfMonth(),
            currentDate.getUTCLastDayOfMonth(),
            isCharge
        );

        return partialTreasuries;
    }
}
