// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div {
  height: 50px;
  display: flex;
  align-items: center;
  border: 1.5px solid rgba(238, 238, 238, 0.9333);
  border-radius: 10px;
}
:host div i {
  color: rgb(212, 214, 215);
  margin-left: 10px;
  margin-right: 10px;
}
:host div.selected {
  border: 1.5px solid rgb(0, 194, 154);
}
:host div.selected i {
  color: rgb(0, 194, 154);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInF1ZXN0aW9ucy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDSTtFQUNJLFlBQUE7RUFDQSxhQUFBO0VBQ0EsbUJBQUE7RUFDQSwrQ0FBQTtFQUNBLG1CQUFBO0FBQVI7QUFFUTtFQUNJLHlCQUFBO0VBQ0EsaUJBQUE7RUFDQSxrQkFBQTtBQUFaO0FBSUk7RUFDSSxvQ0FBQTtBQUZSO0FBSVE7RUFDSSx1QkFBQTtBQUZaIiwiZmlsZSI6InF1ZXN0aW9ucy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXYge1xuICAgICAgICBoZWlnaHQ6IDUwcHg7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgICAgIGJvcmRlcjogMS41cHggc29saWQgcmdiYSgyMzgsIDIzOCwgMjM4LCAwLjkzMzMpO1xuICAgICAgICBib3JkZXItcmFkaXVzOiAxMHB4O1xuXG4gICAgICAgIGkge1xuICAgICAgICAgICAgY29sb3I6IHJnYmEoMjEyLCAyMTQsIDIxNSwgMSk7XG4gICAgICAgICAgICBtYXJnaW4tbGVmdDogMTBweDtcbiAgICAgICAgICAgIG1hcmdpbi1yaWdodDogMTBweDtcbiAgICAgICAgfVxuICAgIH1cblxuICAgIGRpdi5zZWxlY3RlZCB7XG4gICAgICAgIGJvcmRlcjogMS41cHggc29saWQgcmdiYSgwLCAxOTQsIDE1NCwgMSk7XG5cbiAgICAgICAgaSB7XG4gICAgICAgICAgICBjb2xvcjogcmdiYSgwLCAxOTQsIDE1NCwgMSk7XG4gICAgICAgIH1cbiAgICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/user-registration-process/components/registration-third-step/components/questions/questions.component.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,+CAAA;EACA,mBAAA;AAAR;AAEQ;EACI,yBAAA;EACA,iBAAA;EACA,kBAAA;AAAZ;AAII;EACI,oCAAA;AAFR;AAIQ;EACI,uBAAA;AAFZ;AACA,wiCAAwiC","sourcesContent":[":host {\n    div {\n        height: 50px;\n        display: flex;\n        align-items: center;\n        border: 1.5px solid rgba(238, 238, 238, 0.9333);\n        border-radius: 10px;\n\n        i {\n            color: rgba(212, 214, 215, 1);\n            margin-left: 10px;\n            margin-right: 10px;\n        }\n    }\n\n    div.selected {\n        border: 1.5px solid rgba(0, 194, 154, 1);\n\n        i {\n            color: rgba(0, 194, 154, 1);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
