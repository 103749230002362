import {
    Component,
    Input,
    // OnInit,
} from '@angular/core';
import {
    PropertyEntity,
    PropertyType,
    SocietyEntity,
    PropertyTab,
    AssetType,
    AssetTypes,
    AssetCardLayout,
} from '@omedom/data';

import { Router } from '@angular/router';

@Component({
    selector: 'omedom-asset-slider',
    templateUrl: './asset-slider.component.html',
    styleUrls: ['./asset-slider.component.scss'],
})
export class AssetSliderComponent
// implements OnInit
{
    /**
     * @description List of assets (properties, societies) to display in the story slider
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 10/06/2024
     * @type {((PropertyEntity | SocietyEntity)[])}
     * @memberof AssetSliderComponent
     */
    @Input() assets: (PropertyEntity | SocietyEntity)[] | undefined;

    @Input() residency: boolean = true;

    protected assetCardLayout = AssetCardLayout;

    protected assetTypes = AssetTypes;

    /**
     * @description
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/08/2024
     * @type {boolean}
     * @memberof AssetSliderComponent
     */
    focus: boolean = true;

    constructor(
        private router: Router,
    ) { }

    /**
     * @description This function is called when the user scrolls the story list
     * @author Jérémie Lopez
     * @param {*} event
     * @memberof AssetSliderComponent
     */
    onScroll(event: any): void {
        this.focus = event.target.scrollLeft > 0;
    }

    public getAssertcon(asset: PropertyEntity | SocietyEntity): string {
        let icon = 'uil uil-question';
        switch (this.getAssetType(asset)) {
            case 'property':
                icon = asset.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
                break;
            case 'society':
                icon = 'uil uil-suitcase';
                break;
            default:
                icon = 'uil uil-question';
        }
        return icon
    }

    public assetClicked(asset: AssetType): any {
        let router;
        switch (this.getAssetType(asset)) {
            case 'property':
                router = this.router.navigate(['/tabs/property/info/', asset.uid], {
                    queryParams: { tab: PropertyTab.info },
                });
                break;
            case 'society':
                router = this.router.navigate(['/tabs/property/society/info/', asset.uid]);
                break;
            default:
                return;
        }
        return router;
    }

    protected getAssetType(asset: AssetType): AssetTypes {
        const type = asset.toSelectOption!().assetType!;
        return type;
    }
}
