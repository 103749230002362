import { Component, Input, OnInit, Output } from '@angular/core';
import { IonModal, ModalController, ToastController } from '@ionic/angular';
import { SocietyEntity, SocietyMember, SocietyStatusMember, UserEntity } from '@omedom/data';
import { SocietyService } from '@omedom/services';

import { SocietyMemberFormComponent } from '../../society/containers/society-member-form/society-member-form.component';

@Component({
    selector: 'app-member-card',
    templateUrl: './member-card.component.html',
    styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
    /**
     * @description  Member data
     * @author Jérémie Lopez
     * @type {SocietyMember}
     * @memberof MemberCardComponent
     */
    @Input()
    public member: SocietyMember;

    /**
     * @description Get the member index in the list
     * @author Jérémie Lopez
     * @type {number}
     * @memberof MemberCardComponent
     */
    @Input()
    public index: number;

    /**
     * @description If true, the user can edit the member
     * @author Jérémie Lopez
     * @memberof MemberCardComponent
     */
    @Input()
    public canEditMember = false;

    /**
     * @description Societ Entity data
     * @author Jérémie Lopez
     * @private
     * @type {SocietyEntity}
     * @memberof MemberCardComponent
     */
    @Input()
    private society: SocietyEntity;

    user: UserEntity;
    /**
     * @description Determine if this member can be deleted
     * @author ANDRE Felix
     * @memberof MemberCardComponent
     */
    canMemberBeDelete = false;

    constructor(
        private societyService: SocietyService,
        private toastController: ToastController,
        private modalController: ModalController
    ) {}

    ngOnInit(): void {
        this.canMemberBeDelete =
            !this.member.isCreator && this.member.status !== SocietyStatusMember.manager;
    }

    async openDeleteModal(modal: IonModal): Promise<void> {
        await modal.present();
    }

    /**
     * @description Delete the member from the society
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MemberCardComponent
     */
    public async delete(modal?: IonModal): Promise<void> {
        this.society.members.splice(this.index, 1);
        this.removeSocietySharing();

        await this.societyService.update(this.society);

        const toast = await this.toastController.create({
            position: 'top',
            message: 'Le membre a bien été supprimé',
            duration: 2000,
            color: 'primary',
        });

        await toast.present();
        modal.dismiss();
    }

    private removeSocietySharing() {
        const sharingIndexToDelete = this.society.sharing.findIndex((share) => {
            return share.email === this.member.email;
        });
        this.society.sharing.splice(sharingIndexToDelete, 1);
    }

    /**
     * @description Open the modal to edit the member
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MemberCardComponent
     */
    public async edit(): Promise<void> {
        const modal = await this.modalController.create({
            component: SocietyMemberFormComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            backdropDismiss: true,
            componentProps: {
                member: this.member,
                society: this.society,
                sharingIndex: this.index,
            },
        });
        await modal.present();
    }
}
