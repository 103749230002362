import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'omedom-sidenav-item',
    templateUrl: './sidenav-item.component.html',
    styleUrls: ['./sidenav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        role: 'listitem',
    },
})
export class SidenavItemComponent {
    /**
     * @description Icon name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {string}
     * @memberof SidenavItemComponent
     */
    @Input()
    public icon?: string;

    /**
     * @description Label name
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {string}
     * @memberof SidenavItemComponent
     */
    @Input()
    public label?: string;

    @Input()
    public isOmedomIcon: boolean = false;

    public get iconMask() {
        return `url("/assets/icon/${this.icon}.svg") no-repeat center`;
    }

    constructor() {}
}
