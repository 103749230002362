import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicencePage } from './page/licence.page';
import { UiModule } from '@omedom/ui';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { OmedomComponentModule } from '../component/omedom-component.module';

@NgModule({
    declarations: [LicencePage],
    imports: [CommonModule, UiModule, IonicModule, RouterModule, OmedomComponentModule],
    exports: [],
})
export class LicenceModule {}
