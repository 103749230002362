import { SelectOption } from '../models';
import { RestEntity } from './rest.entity';

/**
 * @description Pro entity (company)
 * @author Jérémie Lopez
 * @export
 * @class ProEntity
 * @extends {RestEntity}
 */
export class ProEntity extends RestEntity {
    /**
     * @description User UID of the pro entity (userUID of the user who created the pro entity)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    userUID?: string;

    /**
     * @description SIRET number of the pro entity (unique identifier of the pro entity in France) (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    siret?: string;

    /**
     * @description Name of the company
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    name!: string;

    /**
     * @description Address of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    address?: string;

    /**
     * @description Competences of the company
     * @author ANDRE Felix
     * @type {ExpertiseField[]}
     * @memberof ProEntity
     */
    expertiseField?: ExpertiseField[];

    /**
     * @description Postal code of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    postalCode?: string;

    /**
     * @description City of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    city?: string;

    /**
     * @description Country of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    country?: string;

    /**
     * @description Phone number of the contact of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    phone!: string;

    /**
     * @description Email of the contact of the company
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    email!: string;

    /**
     * @description Website of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    website?: string;

    /**
     * @description Logo of the company (optional field) (URL of the logo image in the storage bucket of Firebase)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    logo!: string;

    /**
     * @description Description of the company (optional field)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ProEntity
     */
    description?: string;

    /**
     * @description User guide URL of the company (optional field) (URL of the user guide in the storage bucket of Firebase)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/10/2023
     * @type {string}
     * @memberof ProEntity
     */
    userGuideURL?: string;

    /**
     * @description True if the pro has access to pro space
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/10/2023
     * @type {boolean}
     * @memberof ProEntity
     */
    space?: boolean;

    /**
     * @description Quotation number of the pro
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/10/2023
     * @type {string}
     * @memberof ProEntity
     */
    quotationNumber!: string;

    /**
     * @description Contract number of the pro
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/10/2023
     * @type {string}
     * @memberof ProEntity
     */
    contractNumber!: string;

    /**
     * @description Signature contract date
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 13/10/2023
     * @type {Date}
     * @memberof ProEntity
     */
    signatureContractDate!: Date;

    /**
     * @description Initial number of months for licences on create
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {number}
     * @memberof ProEntity
     */
    initialNumberOfMonths?: number;

    /**
     * @description Number of licence available to send
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {number}
     * @memberof ProEntity
     */
    licencesAvailable?: number;

    /**
     * @description Values of the pro (honnêteté, accompagnement, ...)
     * @author ANDRE Felix
     * @type {ProValue[]}
     * @memberof ProEntity
     */
    values?: ProValue[];

    /**
     * @description Name of the pro (human)
     * @author ANDRE Felix
     * @type {string}
     * @memberof ProEntity
     */
    proName?: string;

    /**
     * @descriptionFirstName of the pro (human)
     * @author ANDRE Felix
     * @type {string}
     * @memberof ProEntity
     */
    proFirstName?: string;

    /**
     * @description Avatar of a pro
     * @author ANDRE Felix
     * @type {string}
     * @memberof ProEntity
     */
    avatar?: string;

    /**
     * @description Position of the user in the pro company
     * @author ANDRE Felix
     * @type {UserPosition}
     * @memberof ProEntity
     */
    public position?: ProPosition;

    /**
     * @description Rating of the pro by user
     * @author ANDRE Felix
     * @type {number}
     * @memberof ProEntity
     */
    rate?: number;

    /**
     * @description is pro following ethic charter
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof ProEntity
     */
    isEthicCharter?: boolean;

    activityZone?: ActivityZone[];
}

export enum ExpertiseField {
    assetAssessment = 'Bilan patrimonial',
    taxPptimization = 'Optimisation fiscale',
    successionPlanning = 'Optimisation successorale',
    investmentOptimization = 'Optimisation placements financiers',
    retirementPlanning = 'Préparation à la retraite',
}

export const ExpertiseFieldOptions: SelectOption[] = [
    {
        label: ExpertiseField.assetAssessment,
        id: ExpertiseField.assetAssessment,
    },
    {
        label: ExpertiseField.taxPptimization,
        id: ExpertiseField.taxPptimization,
    },
    {
        label: ExpertiseField.successionPlanning,
        id: ExpertiseField.successionPlanning,
    },
    {
        label: ExpertiseField.investmentOptimization,
        id: ExpertiseField.investmentOptimization,
    },
    {
        label: ExpertiseField.retirementPlanning,
        id: ExpertiseField.retirementPlanning,
    },
];

export enum ProPosition {
    wealthManagementAdvisor = 'Conseiller en gestion de patrimoine',
    interiorDesigner = "Architecte d'intérieur",
    creditCounselingService = 'Service de conseil en crédit',
}

export const proPositionOptions: SelectOption[] = [
    {
        label: ProPosition.wealthManagementAdvisor,
        id: ProPosition.wealthManagementAdvisor,
    },
    {
        label: ProPosition.interiorDesigner,
        id: ProPosition.interiorDesigner,
    },
    {
        label: ProPosition.creditCounselingService,
        id: ProPosition.creditCounselingService,
    },
];

export enum ProValue {
    honesty = 'Honnêteté',
    pedagogy = 'Pédagogie',
    reactivity = 'Réactivité',
    security = 'Sécurité',
    transparency = 'Transparence',
}

export const proValueOptions: SelectOption[] = [
    {
        label: ProValue.honesty,
        id: ProValue.honesty,
    },
    {
        label: ProValue.pedagogy,
        id: ProValue.pedagogy,
    },
    {
        label: ProValue.reactivity,
        id: ProValue.reactivity,
    },
    {
        label: ProValue.security,
        id: ProValue.security,
    },
    {
        label: ProValue.transparency,
        id: ProValue.transparency,
    },
];

export enum ActivityZone {
    onSite = 'Sur site',
    remotly = 'À distance',
}

export const activityZoneOptions: SelectOption[] = [
    {
        label: ActivityZone.onSite,
        id: ActivityZone.onSite,
    },
    {
        label: ActivityZone.remotly,
        id: ActivityZone.remotly,
    },
];
