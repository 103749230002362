import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IncomeCategoryProperty, IncomeEntity, PropertyEntity } from '@omedom/data';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PropertyService } from './property.service';
import { RestService } from './rest.service';
import { SocietyService } from './society.service';

@Injectable({
    providedIn: 'root',
})
export class IncomeService extends RestService<IncomeEntity> {
    protected override builder = IncomeEntity;

    constructor(
        protected override firestore: AngularFirestore,
        protected propertyService: PropertyService,
        protected societyService: SocietyService
    ) {
        super(firestore, 'incomes');
    }

    /**
     * @description Returns all incomes created by user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID user UID
     * @returns {Promise<IncomeEntity[]>} Incomes of the user
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getUserIncomes(userUID);
     */
    public async getUserIncomes(userUID: string): Promise<IncomeEntity[]> {
        // Get user properties
        const properties = await this.propertyService.getUserPropertiesAndShared(userUID);

        const societies = await this.societyService.getUserSocietiesAndShared(userUID);

        // Get incomes of each property
        const propertiesIncomes = await Promise.all(
            properties.map(async (property) => await this.getIncomesByProperty(property.uid))
        );

        const societiesIncomes = await Promise.all(
            societies.map((society) => this.getIncomesBySociety(society.uid))
        );

        // Flatten incomes array
        const propertiesIncomesFlatten = propertiesIncomes.reduce(
            (acc, curr) => acc.concat(curr),
            []
        );
        const societiesIncomesFlatten = societiesIncomes.reduce(
            (acc, curr) => acc.concat(curr),
            []
        );

        // Remove duplicate incomes
        const incomes = [...propertiesIncomesFlatten, ...societiesIncomesFlatten].filter(
            (income, index, incomesArray) => {
                return (
                    incomesArray.findIndex((IcomeToCompare) => {
                        return IcomeToCompare.uid === income.uid;
                    }) === index
                );
            }
        );

        return incomes;
    }

    public _getUserIncomes(userUID: string): Observable<IncomeEntity[]> {
        const properties$ = this.propertyService._getUserPropertiesAndSharedAccessible(userUID);

        const societies$ = this.societyService._getUserSocietiesAndShared(userUID);

        return combineLatest([properties$, societies$]).pipe(
            switchMap(([properties, societies]) => {
                const incomesByProperties$ = properties.map((property) => {
                    if (!property?.uid) {
                        return of([] as IncomeEntity[]);
                    }
                    return this._getIncomesByProperty(property.uid);
                });

                const incomesBySocieties$ = societies.map((society) => {
                    if (!society?.uid) {
                        return of([] as IncomeEntity[]);
                    }
                    return this._search([
                        { where: 'societyUID', operator: '==', value: society.uid },
                    ]);
                });

                const propertiesIncomes$ =
                    incomesByProperties$ && incomesByProperties$.length > 0
                        ? combineLatest(incomesByProperties$).pipe(map((incomes) => incomes.flat()))
                        : of([] as IncomeEntity[]);

                const societiesIncomes$ =
                    incomesBySocieties$ && incomesBySocieties$.length > 0
                        ? combineLatest(incomesBySocieties$).pipe(map((incomes) => incomes.flat()))
                        : of([] as IncomeEntity[]);

                return combineLatest([propertiesIncomes$, societiesIncomes$]).pipe(
                    map(([propertiesIncomes, societiesIncomes]) => {
                        const allIncomes = [...propertiesIncomes, ...societiesIncomes];
                        return allIncomes.filter((income, index, incomesArray) => {
                            return (
                                incomesArray.findIndex((incomeToCompare) => {
                                    return incomeToCompare.uid === income.uid;
                                }) === index
                            );
                        });
                    })
                );
            })
        );
    }

    /**
     * @description Get Charge of user properties and also charge of shared properties
     * @author ANDRE Felix
     * @param {string} userUID
     * @param {ChargeCategoryProperty} category
     * @returns {*}  {Promise<ChargeEntity[]>}
     * @memberof ChargeService
     */
    public async getUserAndShareIncomesByCategory(
        userUID: string,
        category: IncomeCategoryProperty
    ): Promise<IncomeEntity[]> {
        const userAndShareCharges = this.getUserIncomes(userUID);
        const filterByCategory = (await userAndShareCharges).filter(
            (income) => income.category === category
        );
        return filterByCategory;
    }

    /**
     * @description Returns all incomes created by user and filtered by category
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID user UID
     * @param {IncomeCategoryProperty} category Income category
     * @returns {Promise<IncomeEntity[]>} Incomes of the user
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getUserIncomesByCategory(userUID, IncomeCategoryProperty.Rent);
     */
    public async getUserIncomesByCategory(
        userUID: string,
        category: IncomeCategoryProperty
    ): Promise<IncomeEntity[]> {
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'category', operator: '==', value: category },
        ]);
    }

    /**
     * @description Returns all incomes created by user and filtered by property UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @param {string} propertyUID Property UID
     * @returns {Promise<IncomeEntity[]>} Incomes of the user
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getUserIncomesByProperty(userUID, propertyUID);
     */
    public async getUserIncomesByProperty(
        userUID: string,
        propertyUID: string
    ): Promise<IncomeEntity[]> {
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'propertyUID', operator: '==', value: propertyUID },
        ]);
    }

    /**
     * @description Returns all incomesof a property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID Property UID
     * @returns {Promise<IncomeEntity[]>}
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getIncomesByProperty(propertyUID);
     */
    public async getIncomesByProperty(propertyUID: string): Promise<IncomeEntity[]> {
        return await this.search([{ where: 'propertyUID', operator: '==', value: propertyUID }]);
    }

    /**
     * @description Returns all incomes of a property (Observable version)
     * @author Brisset Killian
     * @date 23/05/2024
     * @param {string} propertyUID
     * @returns {*}  {Observable<IncomeEntity[]>}
     * @memberof IncomeService
     * @example
     * const incomes$ = await incomeService._getIncomesByProperty(propertyUID);
     */
    public _getIncomesByProperty(propertyUID: string): Observable<IncomeEntity[]> {
        return this._search([{ where: 'propertyUID', operator: '==', value: propertyUID }]);
    }

    /**
     * @description Returns all incomes of properties (Observable version) and filter duplicate incomes
     * @author Brisset Killian
     * @date 18/06/2024
     * @param {string[]} propertyUIDs
     * @returns {*}  {Observable<IncomeEntity[]>}
     * @memberof IncomeService
     */
    public _getIncomesByProperties(propertyUIDs: string[]): Observable<IncomeEntity[]> {
        if (!propertyUIDs || propertyUIDs.length < 1) {
            return of([]);
        }
        const properties$ = propertyUIDs.map((propertyUID) => {
            if (!propertyUID) {
                return of([] as IncomeEntity[]);
            }
            return this._getIncomesByProperty(propertyUID);
        });

        return combineLatest(properties$).pipe(
            map((incomes) => {
                const allIncomes = incomes.flat();

                const filteredIncomes = allIncomes.filter((income) => income?.uid);

                return filteredIncomes.filter((income, index, incomesArray) => {
                    return (
                        incomesArray.findIndex((incomeToCompare) => {
                            return incomeToCompare.uid === income.uid;
                        }) === index
                    );
                });
            })
        );
    }

    /**
     * @description Returns all incomes of a society by its UID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} societyUID Society UID
     * @returns {Promise<IncomeEntity[]>} Incomes of the society
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getIncomesBySociety(societyUID);
     */
    public async getIncomesBySociety(societyUID: string): Promise<IncomeEntity[]> {
        // Get properties of the society
        const properties = await this.propertyService.getPropertiesBySociety(societyUID);

        const societyIncomes = await this.search([
            { where: 'societyUID', operator: '==', value: societyUID },
        ]);
        // Get incomes of each property
        const propertiesIncomes = await Promise.all(
            properties.map(async (property) => await this.getIncomesByProperty(property.uid))
        );

        // Flatten incomes array
        const propertiesIncomesFlatten = propertiesIncomes.reduce(
            (acc, curr) => acc.concat(curr),
            []
        );

        // Remove duplicate incomes
        const incomes = [...propertiesIncomesFlatten, ...societyIncomes].filter(
            (income, index, incomesArray) => {
                return (
                    incomesArray.findIndex((IcomeToCompare) => {
                        return IcomeToCompare.uid === income.uid;
                    }) === index
                );
            }
        );
        return incomes;
    }

    public _getIncomesBySociety(societyUID: string): Observable<IncomeEntity[]> {
        return this._search([{ where: 'societyUID', operator: '==', value: societyUID }]);
    }

    /**
     * @description Returns all incomes of a user in a society
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID
     * @param {string} societyUID
     * @returns {Promise<IncomeEntity[]>} Incomes of the user in the society
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getUserIncomesBySociety(userUID, societyUID);
     */
    public async getUserIncomesBySociety(
        userUID: string,
        societyUID: string
    ): Promise<IncomeEntity[]> {
        // Get properties of the society
        const properties = await this.propertyService.getPropertiesBySociety(societyUID);

        // Get incomes of each property
        const incomes = await Promise.all(
            properties.map(
                async (property) => await this.getUserIncomesByProperty(userUID, property.uid)
            )
        );

        // Flatten incomes array
        return incomes.reduce((acc, curr) => acc.concat(curr), []);
    }

    /**
     * @description Returns all incomes of properties
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string[]} propertyUIDs
     * @returns {Promise<IncomeEntity[]>}
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getIncomesByPropertyIds(propertyUIDs);
     */
    public async getUserIncomesByPropertyIds(propertyUIDs: string[]): Promise<IncomeEntity[]> {
        if (!propertyUIDs.length) return [];
        return await this.search([{ where: 'propertyUID', operator: 'in', value: propertyUIDs }]);
    }

    /**
     * @description return all incomes of societies
     * @author ANDRE Felix
     * @param {string[]} societyUIDs
     * @returns {*}  {Promise<IncomeEntity[]>}
     * @memberof IncomeService
     */
    public async getUserIncomesBySocietyIds(societyUIDs: string[]): Promise<IncomeEntity[]> {
        if (!societyUIDs.length) return [];
        return await this.search([{ where: 'societyUID', operator: 'in', value: societyUIDs }]);
    }

    /**
     * @description Returns all incomes created by user of properties filtered by category and property UIDs
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID User UID
     * @param {IncomeCategoryProperty} category Income category
     * @param {string[]} propertyUIDs Property UIDs
     * @returns {Promise<IncomeEntity[]>} Incomes of the user
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getUserIncomesByCategoryByPropertyIds(
     *    userUID,
     *    IncomeCategoryProperty.Rent,
     *    propertyUIDs
     * );
     */
    public async getUserIncomesByCategoryByPropertyIds(
        userUID: string,
        category: IncomeCategoryProperty,
        propertyUIDs: string[]
    ): Promise<IncomeEntity[]> {
        if (!propertyUIDs.length) return [];
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'category', operator: '==', value: category },
            { where: 'propertyUID', operator: 'in', value: propertyUIDs },
        ]);
    }

    /**
     * @description Returns all incomes of properties filtered by category and property UIDs
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {IncomeCategoryProperty} category Income category
     * @param {string[]} propertyUIDs Property UIDs
     * @returns {Promise<IncomeEntity[]>} Incomes of the user
     * @memberof IncomeService
     * @example
     * const incomes = await incomeService.getIncomesByCategoryByPropertyIds(
     *   IncomeCategoryProperty.Rent,
     *   propertyUIDs
     * );
     */
    public async getIncomesByCategoryByPropertyIds(
        category: IncomeCategoryProperty,
        propertyUIDs: string[]
    ): Promise<IncomeEntity[]> {
        if (!propertyUIDs.length) return [];
        return await this.search([
            { where: 'category', operator: '==', value: category },
            { where: 'propertyUID', operator: 'in', value: propertyUIDs },
        ]);
    }

    /**
     * @description Returns all incomes of properties filtered by category and property UIDs (Observable version) and filter duplicate incomes
     * @author Brisset Killian
     * @date 10/07/2024
     * @param {IncomeCategoryProperty} category
     * @param {string[]} societyUIDs
     * @returns {*}  {Promise<IncomeEntity[]>}
     * @memberof IncomeService
     */
    public async getIncomesByCategoryBySocietyIds(
        category: IncomeCategoryProperty,
        societyUIDs: string[]
    ): Promise<IncomeEntity[]> {
        if (!societyUIDs.length) return [];
        return await this.search([
            { where: 'category', operator: '==', value: category },
            { where: 'societyUID', operator: 'in', value: societyUIDs },
        ]);
    }

    /**
     * @description Returns all incomes of properties filtered by category and property UIDs (Observable version)
     * @author Brisset Killian
     * @date 27/06/2024
     * @param {IncomeCategoryProperty} category
     * @param {string[]} propertyUIDs
     * @returns {*}  {Observable<IncomeEntity[]>}
     * @memberof IncomeService
     */
    public _getIncomesByCategoryByPropertyIds(
        category: IncomeCategoryProperty,
        propertyUIDs: string[]
    ): Observable<IncomeEntity[]> {
        if (!propertyUIDs.length) return of([]);
        return this._search([
            { where: 'category', operator: '==', value: category },
            { where: 'propertyUID', operator: 'in', value: propertyUIDs },
        ]);
    }

    /**
     * @description Get all income of a building and its lots
     * @author ANDRE Felix
     * @param {PropertyEntity} buildings
     * @returns {*}
     * @memberof IncomeService
     */
    public async getIncomesByBuildingsLots(building: PropertyEntity): Promise<IncomeEntity[]> {
        const buildingIncomes = await this.search([
            {
                where: 'propertyUID',
                operator: '==',
                value: building.uid,
            },
        ]);
        if (!building.lotsUID || building.lotsUID.length === 0) {
            return buildingIncomes;
        }
        const lots = await Promise.all(
            building.lotsUID.map(async (lotUid) => {
                if (!lotUid) {
                    return;
                }
                try {
                    const propertyLot = await this.propertyService.get(lotUid);

                    if (!propertyLot?.uid) {
                        return;
                    }
                    return await this.search([
                        {
                            where: 'propertyUID',
                            operator: '==',
                            value: lotUid,
                        },
                    ]);
                } catch (err) {
                    console.error(err);
                    return;
                }
            })
        );

        const flatLots = lots.flat().filter((x) => x);
        return [...buildingIncomes, ...flatLots] as IncomeEntity[];
    }
}
