import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-suggest-smart',
    templateUrl: './suggest-smart.component.html',
    styleUrls: ['./suggest-smart.component.scss'],
})
export class SuggestSmartComponent implements OnInit {
    @Input() text: string;

    isAppleDevice: boolean;

    constructor(private router: Router) {}

    ngOnInit() {}

    public goSmart(): void {
        this.router.navigateByUrl(
            '/tabs/menu/subscription/change-subscription'
        );
    }
}
