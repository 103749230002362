import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ProEntity } from '@omedom/data';

@Component({
    selector: 'omedom-directory-card',
    templateUrl: './directory-card.component.html',
    styleUrls: ['./directory-card.component.scss'],
})
export class DirectoryCardComponent implements OnInit {
    // @HostListener('click', ['$event'])
    // onHostClick(event: MouseEvent) {
    //     console.log('Élément hôte cliqué!');
    //     this.cardClicked.emit(this.pro.uid);
    // }
    @Input({ required: true })
    pro!: ProEntity;

    // @Output() cardClicked = new EventEmitter();

    @Output() detailClicked = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    onDetailClicked(event: MouseEvent) {
        event.stopPropagation();
        this.detailClicked.emit(this.pro.uid);
    }
}
