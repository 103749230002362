// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.container {
  padding: 40px;
}

.licence-display {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licence-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.licence-form,
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button {
  width: -moz-fit-content;
  width: fit-content;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxpY2VuY2Utc2VuZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGNBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFNBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFFBQUE7QUFDSjs7QUFFQTs7RUFFSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxTQUFBO0FBQ0o7O0FBRUE7RUFDSSx1QkFBQTtFQUFBLGtCQUFBO0FBQ0oiLCJmaWxlIjoibGljZW5jZS1zZW5kLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xufVxuXG4uY29udGFpbmVyIHtcbiAgICBwYWRkaW5nOiA0MHB4O1xufVxuXG4ubGljZW5jZS1kaXNwbGF5IHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAyMHB4O1xufVxuXG4ubGljZW5jZS1pbmZvIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiA0cHg7XG59XG5cbi5saWNlbmNlLWZvcm0sXG5mb3JtIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAyMHB4O1xufVxuXG5idXR0b24ge1xuICAgIHdpZHRoOiBmaXQtY29udGVudDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/licence/licence-send/licence-send.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;;AAEA;;EAEI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,uBAAA;EAAA,kBAAA;AACJ;AAEA,w/BAAw/B","sourcesContent":[":host {\n    display: block;\n}\n\n.container {\n    padding: 40px;\n}\n\n.licence-display {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.licence-info {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.licence-form,\nform {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\nbutton {\n    width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
