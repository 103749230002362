import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, Input } from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';
import { Renderer2 } from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    host: {
        '(document:click)': 'onClick($event)',
    },
})
export class DropdownComponent implements OnInit, AfterViewInit {
    @Input() iconClass: string;

    @Input() isButtonBackgroundTransparent = false;

    @Input() label: string;
    /**
     * @description display dropdown for only buttons
     * @author ANDRE Felix
     * @memberof DropdownComponent
     */
    @Input() onlyButtons = false;

    /**
     * @description Does rotation with transition the icon
     * @author ANDRE Felix
     * @type {false}
     * @memberof DropdownComponent
     */
    @Input() rotationAnimate = false;

    /**
     * @description Element de la liste du dropdown
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof DropdownComponent
     */
    @ViewChild('omedomDropdown', { static: false }) dropdownListEl: ElementRef;
    /**
     * @description Element du bouton du dropdown
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof DropdownComponent
     */
    @ViewChild('dropdownIcon', { static: false }) dropdownButtonEl: ElementRef;

    /**
     * @description Element du bouton du dropdown
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof DropdownComponent
     */
    @ViewChild('dropdownButton', { static: false }) dropdownIconEl: ElementRef;
    /**
     * @description Affiche ou non le contenu de la dropdown
     * @author ANDRE Felix
     * @private
     * @memberof DropdownComponent
     */
    private isDropdownVisible = false;
    /**
     * @description anime la disparition du contenu du dropdown
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof DropdownComponent
     */
    private fadeOut: Animation;
    /**
     * @description  anime l'apparition du contenu du dropdown
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof DropdownComponent
     */
    private fadeIn: Animation;

    /**
     * @description  anime une rotation sur l'icone du dropdown
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof DropdownComponent
     */
    private rotate: Animation;

    private MIN_SPACE_BETWEEN_DROPDOWN_AND_BOTTOM = 200;

    constructor(
        private animationCtrl: AnimationController,
        private hostEl: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.fadeOut = this.animationCtrl
            .create()
            .addElement(this.dropdownListEl.nativeElement)
            .duration(200)
            .fromTo('opacity', '1', '0.2');

        this.fadeIn = this.animationCtrl
            .create()
            .addElement(this.dropdownListEl.nativeElement)
            .duration(200)
            .fromTo('opacity', '0.2', '1');
        this.rotate = this.animationCtrl
            .create()
            .addElement(this.dropdownButtonEl.nativeElement)
            .duration(200)
            .fromTo('transform', 'rotate(0)', 'rotate(180deg)');
    }

    /**
     * @description change la visibilite du dropdown
     * @author ANDRE Felix
     * @returns {*}  {Promise<void>}
     * @memberof DropdownComponent
     */
    async toggleVisibility(): Promise<void> {
        this.renderer.addClass(this.dropdownListEl.nativeElement, this.dropdownDirection());
        if (this.isDropdownVisible) {
            this.toInvisibleAnimation();
        } else {
            this.toVisibleAnimation();
        }
        this.renderer.removeClass(this.dropdownListEl.nativeElement, 'dropdown-display-upward');
        this.renderer.removeClass(this.dropdownListEl.nativeElement, 'dropdown-display-downward');
    }

    private async toVisibleAnimation() {
        if (this.rotationAnimate) {
            await this.rotate.direction('normal').play();
        }
        this.isDropdownVisible = true;
        await this.fadeIn.play();
        await this.fadeIn.stop();
    }

    private async toInvisibleAnimation() {
        if (this.rotationAnimate) {
            await this.rotate.direction('reverse').play();
        }
        await this.fadeOut.play();
        this.isDropdownVisible = false;
        await this.fadeOut.stop();
    }

    /**
     * @description determine la direction de l'affichage de la dropdown
     * @author ANDRE Felix
     * @returns {*}  {string}
     * @memberof DropdownComponent
     */
    dropdownDirection(): string {
        const buttomBottomPosition =
            window.innerHeight - this.dropdownButtonEl.nativeElement.getBoundingClientRect().bottom;
        const isBottomLimitReached =
            this.MIN_SPACE_BETWEEN_DROPDOWN_AND_BOTTOM > buttomBottomPosition;
        return isBottomLimitReached ? 'dropdown-display-upward' : 'dropdown-display-downward';
    }

    /**
     * @description ferme la dropdown si click en dehors de la dropdown
     * @author ANDRE Felix
     * @param {any} event
     * @memberOf DropdownComponent
     */
    onClick(event) {
        if (!this.hostEl.nativeElement.contains(event.target)) {
            this.isDropdownVisible = false;
        }
    }
}
