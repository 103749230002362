import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EmailVerificationGuard {
    constructor(
        private router: Router,
        private auth: AngularFireAuth,
        private userService: UserService
    ) { }

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            map((user) => {
                if (!user) {
                    this.router.navigate(['/user/auth']);
                    return false;
                }

                if (user?.emailVerification?.attempts >= 0) {
                    this.router.navigate(['/tunnel/verify-email']);
                    return false;
                }

                return true;
            })
        );
    }
}
