import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PropertyOwnerGuard {
    constructor(private userService: UserService, private router: Router) { }

    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return (
            this.userService.user$.pipe(
                map((user) => {
                    if (!user) {
                        this.router.navigate(['/user/auth']);
                        return false;
                    } else if (
                        user.sharedPropertiesUID?.length > 0 ||
                        user.propertiesUID?.length > 0 ||
                        user.sharedSocietiesUID?.length > 0 ||
                        user.societiesUID?.length > 0 ||
                        user.firstProperty
                    ) {
                        return true;
                    } else {
                        this.router.navigate(['/tunnel/first-property']);
                        return false;
                    }
                })
            ) ?? false
        );
    }
}
