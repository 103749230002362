// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host,
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxvYW4tZHVlLWRhdGUtc3RlcC5jb250YWluZXIuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTs7RUFFSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxTQUFBO0FBQ0oiLCJmaWxlIjoibG9hbi1kdWUtZGF0ZS1zdGVwLmNvbnRhaW5lci5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3QsXG5mb3JtIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiAyMHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/loan/loan-form-steps/loan-due-date-step/loan-due-date-step.container.scss"],"names":[],"mappings":"AAAA;;EAEI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACA,oaAAoa","sourcesContent":[":host,\nform {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
