import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SelectOption } from '@omedom/data';

@Component({
    selector: 'app-select-bottom-sheet',
    templateUrl: './select-bottom-sheet.component.html',
    styleUrls: ['./select-bottom-sheet.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SelectBottomSheetComponent implements OnInit {
    @Input() selectTitle: string;

    @Input() isMultiple: boolean;

    @Input() footer: boolean;

    @Input() detailedOptions = false;

    private _options: SelectOption[];

    private selectedOptions: SelectOption[] = [];

    treasuryByDay: { day: Date; treasury: any }[];

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    @Input()
    set options(value: SelectOption[]) {
        this._options = value;

        this.selectedOptions = value?.length ? value.filter((x) => x.isSelected) : [];
    }

    get options(): SelectOption[] {
        return this._options;
    }

    get totalPrice(): number {
        return this.selectedOptions.length > 0 ? 12.99 + 5 * (this.selectedOptions.length - 1) : 0;
    }

    constructor(private modalController: ModalController) {}

    async dismissSelect(): Promise<void> {
        await this.modalController.dismiss(
            this.selectedOptions?.length > 0 ? this.selectedOptions : null
        );
    }

    async cancel(): Promise<void> {
        await this.modalController.dismiss();
    }

    ngOnInit(): void {
        if (this.detailedOptions) {
            const treasuryByDay = this._options.reduce(
                (tbt, treasury) => ({
                    ...tbt,
                    [treasury.date.toString()]: [
                        ...(tbt[treasury.date.toString()] || []),
                        treasury,
                    ],
                }),
                {}
            );

            const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
                (a, b) => new Date(a).getTime() - new Date(b).getTime()
            );

            this.treasuryByDay = sortedTreasuryByDay.map((date) => ({
                day: new Date(date),
                treasury: treasuryByDay[date],
            }));
        }
    }

    optionClicked(option: SelectOption): void {
        // I suppose, it's in case two options has same id (like property name)
        const json = JSON.stringify(option);
        const index = this.selectedOptions.findIndex((x) => JSON.stringify(x) === json);

        // const index = this.selectedOptions.findIndex((x) => x.id === option.id);

        option.isSelected = index < 0;

        if (index >= 0) {
            this.selectedOptions.splice(index, 1);
        } else if (this.isMultiple) {
            this.selectedOptions.push({ ...option });
        } else {
            this.options.forEach((x) => (x.isSelected = false));
            this.selectedOptions = [];
            this.selectedOptions.push({ ...option });
            this.dismissSelect().then();
        }
    }

    stopPropagation(event: Event) {
        event.stopPropagation();
    }
}
