import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Story } from '@omedom/data';

@Component({
    selector: 'app-income-rent-unpaid',
    templateUrl: './income-rent-unpaid.component.html',
    styleUrls: ['./income-rent-unpaid.component.scss'],
})
export class IncomeRentUnpaidComponent implements OnInit {
    @Input()
    public story: Story;

    constructor(private modalController: ModalController) { }

    ngOnInit(): void { }

    public close(event?: string): void {
        this.modalController.dismiss(event);
    }
}
