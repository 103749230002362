import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivityZone, ExpertiseField, ProEntity, ProValue } from '@omedom/data';

@Component({
    selector: 'omedom-pro-record-detail',
    templateUrl: './pro-record-detail.component.html',
    styleUrls: ['./pro-record-detail.component.scss'],
})
export class ProRecordDetailComponent implements OnInit {
    @Input({ required: true }) pro!: ProEntity;

    proExpertiseField: ExpertiseField | string = '';
    proActivityZone: ActivityZone | string = '';
    proValues: ProValue | string = '';

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pro'] && changes['pro'].currentValue) {
            this.proExpertiseField = this.setProExpertiseField(this.pro);
            this.proActivityZone = this.setProZone(this.pro);
            this.proValues = this.setProValues(this.pro);
        }
    }

    setProExpertiseField(pro: ProEntity | undefined) {
        if (!pro || !pro.expertiseField || pro.expertiseField.length === 0) {
            return '';
        }
        if (pro.expertiseField.length === 1) {
            return pro.expertiseField[0];
        }
        return pro.expertiseField.join(', ');
    }

    setProZone(pro: ProEntity | undefined) {
        if (!pro || !pro.activityZone || pro.activityZone.length === 0) {
            return '';
        }
        if (pro.activityZone.length === 1) {
            return pro.activityZone[0];
        }
        return pro.activityZone.join(', ');
    }

    setProValues(pro: ProEntity | undefined) {
        if (!pro || !pro.values || pro.values.length === 0) {
            return '';
        }
        if (pro.values.length === 1) {
            return pro.values[0];
        }
        return pro.values.join(', ');
    }
}
