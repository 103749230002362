import { WidgetTreasuryType } from './../../widget-treasury-type';
import { OmedomTreasury } from '@omedom/utils';
import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    OnInit,
} from '@angular/core';
import { ChargeEntity, IncomeEntity } from '@omedom/data';
import { de } from 'date-fns/locale';

@Component({
    selector: 'app-widget-total-treasury-amount',
    templateUrl: './widget-total-treasury-amount.component.html',
    styleUrls: ['./widget-total-treasury-amount.component.scss'],
})
export class WidgetTotalTreasuryAmountComponent implements OnChanges, OnInit {
    @Input() treasury: (ChargeEntity | IncomeEntity)[] = [];

    @Input() currentDate: Date;

    @Input() treasuryType: WidgetTreasuryType;

    @Input() deactivateNoSmart = false;

    widgetTreasuryType = WidgetTreasuryType;

    totalAmount = 0;

    totalAmountNotPayed = 0;

    totalAmountPayed = 0;

    icon: string;

    backgroundColor: string;

    ngOnInit(): void {
        this.icon =
            this.treasuryType === WidgetTreasuryType.charges
                ? 'uil uil-chart-pie-alt'
                : 'uil uil-euro-circle';
        this.backgroundColor =
            this.treasuryType === WidgetTreasuryType.charges
                ? 'rgba(255, 86, 65, 0.8)'
                : 'rgba(0, 194, 154, 0.8)';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.treasury && !this.deactivateNoSmart) {
            this.updateData();
        }
    }

    private updateData() {
        if (this.treasury && !this.deactivateNoSmart) {
            const startDate = this.currentDate.getUTCFirstDayOfMonth();
            const endDate = this.currentDate.getUTCLastDayOfMonth();
            const amounts = OmedomTreasury.getTreasuryTotalInfo(
                this.treasury,
                startDate,
                endDate
            );
            this.totalAmount = amounts.totalAmount;
            this.totalAmountNotPayed = amounts.totalAmountNotPayed;
            this.totalAmountPayed = amounts.totalAmountPayed;
        }
    }
}
