import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { SmartGuard } from '../guards/smart.guard';
import {
    BankFormPage,
    BankHomePage,
    BankInfoPage,
    BankTransactionAssociationFormPage,
} from './pages';

const routes: Route[] = [
    {
        path: '',
        component: BankHomePage,
        canActivate: [],
    },
    {
        path: 'form',
        component: BankFormPage,
        canActivate: [SmartGuard],
    },
    {
        path: 'transaction/:uid/association',
        component: BankTransactionAssociationFormPage,
        canActivate: [SmartGuard],
    },
    {
        path: ':uid',
        component: BankInfoPage,
        canActivate: [SmartGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class BankRoutingModule { }
