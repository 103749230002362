import { DatePickerComponent } from './../date-picker/date-picker.component';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OmedomDateType } from './omedom-date-type';

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DateComponent implements OnInit, OnChanges {
    @Input() date: Date = new Date().toUTC();

    /**
     * @description The max date that can be selected in the date picker modal: first day of the month of the max date (e.g. new Date('2200-12-01'))
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {Date}
     * @memberof DateComponent
     * @default new Date('2200-12-01')
     * @example new Date('2200-12-01')
     */
    @Input() maxDate: Date = new Date('2200-12-01');

    @Output() dateChange = new EventEmitter<Date>();

    @ViewChild('dateModal') dateModal;

    displayedDate: string;

    datefrompicker: Date;

    private readonly months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];

    private _type: OmedomDateType;

    get type(): OmedomDateType {
        return this._type;
    }

    @Input()
    set type(value: OmedomDateType) {
        this._type = value;
        this.updateDateInfo();
    }

    // private toInit = true;

    constructor(private modalController: ModalController) { }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(): void {
        this.init();
    }

    /**
     * @description Init the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/09/2023
     * @private
     * @memberof DateComponent
     */
    private init(): void {
        this.date = this.date.getUTCFirstDayOfMonth();
        this.date = this.date.getUTCDateWithoutTime();
        this.updateDateInfo();
    }
    /**
     * @description Open the date modal
     * @author Jérémie Lopez
     * @memberof DateComponent
     */
    public triggerModel() {
        this.dateModal.present();
    }

    /**
     * @description Go to the previous month or year
     * @author Jérémie Lopez
     * @memberof DateComponent
     */
    public previousClicked(): void {
        this.date = this.date.subUTCMonths(this.type === OmedomDateType.month ? 1 : 12);
        this.updateDateInfo();
        this.dateChange.next(this.date);
    }

    /**
 * @description Go to the next month or year
 * @author Jérémie Lopez
 * @memberof DateComponent
 */
    public nextClicked(): void {
        // this.toInit = false;
        this.date = this.date
            .getUTCDateWithoutTime()
            .addUTCMonths(this.type === OmedomDateType.month ? 1 : 12);
        this.updateDateInfo();
        this.dateChange.next(this.date);
    }

    /**
     * @description Update the displayed date info (month or year) according to the type of the date component
     * @author Jérémie Lopez
     * @private
     * @return {*}  {void}
     * @memberof DateComponent
     */
    private updateDateInfo(): void {
        if (!this.date) {
            return;
        }

        const month = this.months[this.date.getMonth()];
        const year = this.date.getUTCFullYear().toString();

        this.displayedDate = this.type === OmedomDateType.month ? month + '  ' + year : year;
    }

    /**
     * @description Open the date picker modal
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof DateComponent
     */
    public async triggerDatePicker(): Promise<void> {
        const modal = await this.modalController.create({
            component: DatePickerComponent,
            initialBreakpoint: 1,
            breakpoints: [1],
            handle: false,
            componentProps: {
                maxDate: this.maxDate,
            },
        });
        await modal.present();
        modal.onDidDismiss().then((x) => {
            if (!x.data) {
                return;
            }
            this.date = x.data;
            this.updateDateInfo();
            this.dateChange.next(this.date);
        });
    }

}
