/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryInfo, ChargeEntity, IncomeEntity, SocietyEntity, UserEntity } from '@omedom/data';
import { UserService } from '@omedom/services';
import { OmedomChart, OmedomDoughnutChart, OmedomTreasury } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';

import { OmedomChartLegend } from '../../component/chart-legend';
import { OmedomDateType } from '../../component/date';
import { OmedomSubTab } from '../../component/sub-tab';

@Injectable()
export abstract class TreasurySociety<TEntity extends ChargeEntity | IncomeEntity, TCategory>
    implements OnInit, OnDestroy
{
    @Input() public society: SocietyEntity;

    @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({
            id: 1,
            label: 'Comptabilité automatique',
            popover: 'Fonctionnalité bientôt disponible dans votre abonnement Smart.',
            disabled: true,
        }),
        new OmedomSubTab({ id: 2, label: 'Comptabilité manuelle' }),
    ];

    selectedSubTab: OmedomSubTab;

    omedomDateType = OmedomDateType;

    total = 0;

    doughnutChartData = OmedomDoughnutChart.doughnutChartData;

    doughnutChartOptions = OmedomDoughnutChart.doughnutChartOptions;

    chartLegends: OmedomChartLegend[] = [];

    treasuryList: {
        categoryInfo: CategoryInfo<any>;
        amount: number;
        percent: number;
        isPayed: boolean;
    }[] = [];

    currentDate = new Date();

    totalAmount = 0;

    totalAmountPayed = 0;

    totalAmountNotPayed = 0;

    /**
     * @description If there is at least one charge or income
     * @author ANDRE Felix
     * @memberof TreasurySociety
     */
    hasAtLeastOneEntity = true;

    isTreasuryLoaded = false;

    private subscription: Subscription;

    private currentUser: UserEntity;

    private entities: TEntity[];

    abstract get redirectedDate(): Date;

    abstract set redirectedDate(d: Date);

    protected constructor(private userService: UserService, protected router: Router) {}

    async ngOnInit(): Promise<void> {
        this.selectedSubTab = this.subTabs[1];

        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.currentUser = user;
            if (this.redirectedDate) {
                this.currentDate = this.redirectedDate.toUTC().getUTCDateWithoutTime();
            } else {
                this.currentDate = new Date().toUTC().getUTCDateWithoutTime();
            }
            await this.updateGraphData();

            this.currentDate = new Date().toUTC().getUTCDateWithoutTime();
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    dateChange(date: Date) {
        this.currentDate = date;
        this.updateGraphData();
    }

    public async updateGraphData(): Promise<void> {
        if (!this.currentUser) {
            return;
        }
        this.entities = await this.loadTreasury(this.currentUser.uid);
        // after charge (income)/ form onInit is not executed so update date here
        if (this.redirectedDate) {
            this.currentDate = this.redirectedDate.toUTC().getUTCDateWithoutTime();
            this.redirectedDate = null;
        }
        const startDate = this.currentDate.getUTCFirstDayOfMonth();
        const endDate = this.currentDate.getUTCLastDayOfMonth();
        let filteredData = [];
        if (this.entities && this.entities.length > 0) {
            filteredData = OmedomTreasury.filterTreasury<TEntity>(
                this.entities,
                startDate.getUTCDateWithoutTime(),
                endDate.getUTCDateWithoutTime()
            );
        }
        const amount = OmedomTreasury.getByType<TEntity>(filteredData, startDate, endDate);
        this.hasAtLeastOneEntity = amount.length > 0;
        this.isTreasuryLoaded = true;

        this.total = amount.sumBy((x) => x.amount);
        this.totalAmount = this.total;
        this.totalAmountPayed = amount.filter((x) => !!x.isPayed).sumBy((x) => x.amount);
        this.totalAmountNotPayed = amount.filter((x) => !x.isPayed).sumBy((x) => x.amount);
        amount.sort((a, b) => {
            if (!!a.isPayed && !b.isPayed) {
                return -1;
            } else if (!a.isPayed && !!b.isPayed) {
                return 1;
            }
            return a.category.localeCompare(b.category);
        });

        const treasuriesGrouped = amount.reduce((tbt, treasury) => {
            const key = treasury.category + !!treasury.isPayed;
            if (!tbt[key]) {
                tbt[key] = {
                    amount: 0,
                    percent: 0,
                    isPayed: !!treasury.isPayed,
                    categoryInfo: this.transformCategoryToLabel(treasury.category),
                };
            }
            tbt[key].amount += treasury.amount;
            tbt[key].percent = Math.round((tbt[key].amount * 100) / this.total);
            return tbt;
        }, {});

        this.treasuryList = Object.values(treasuriesGrouped);

        const colorsWithSaturation = this.treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.categoryInfo.category];
            return x.isPayed ? color : OmedomChart.hatchColor(color);
        });

        this.doughnutChartData.labels = this.treasuryList.map((x) => x.categoryInfo.label);
        this.doughnutChartData.datasets[0].data = this.treasuryList.map((x) => x.percent);
        this.doughnutChartData.datasets[0].backgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBackgroundColor = colorsWithSaturation;
        this.doughnutChartData.datasets[0].hoverBorderColor = colorsWithSaturation;

        if (this.treasuryList.length === 1) {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            this.doughnutChartOptions = {
                ...OmedomDoughnutChart.doughnutChartOptions,
                spacing: OmedomDoughnutChart.doughnutChartOptions.spacing,
                elements: OmedomDoughnutChart.doughnutChartOptions.elements,
            };
        }
        this.chartLegends = this.treasuryList.map((x) => {
            const color = OmedomChart.colorByTreasury[x.categoryInfo.category];
            return new OmedomChartLegend({
                label: x.categoryInfo.label,
                amount: x.amount,
                color: !!x.isPayed ? color : OmedomChart.hatchColor(color, 'legend'),
            });
        });

        if (this.baseChart) {
            this.baseChart.render();
        }
    }

    abstract loadTreasury(userUid: string): Promise<TEntity[]>;

    abstract transformCategoryToLabel(category: string): CategoryInfo<TCategory>;
}
