import { RestEntity } from './rest.entity';

export class ReviewEntity extends RestEntity {
    // Review are for customer reviewing pro

    /**
     * @description rate of the
     * @author ANDRE Felix
     * @type {number}
     * @memberof ReviewEntity
     */
    rate?: number;

    /**
     * @description Comment give by the custome
     * @author ANDRE Felix
     * @type {string}
     * @memberof ReviewEntity
     */
    comment?: string;

    /**
     * @description UID of the pro reviexed
     * @author ANDRE Felix
     * @type {string}
     * @memberof ReviewEntity
     */
    ProUID!: string;

    /**
     * @description UID of the author
     * @author ANDRE Felix
     * @type {string}
     * @memberof ReviewEntity
     */
    userUID!: string;

    /**
     * @description If the author want to be anonymous, Don't know for the moment if we display firstname of anonymous review (if the author is from the hacker group anonymous)
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof ReviewEntity
     */
    isAnonymous?: boolean;

    /**
     * @description Name of the author, use ony if isAnonymous at false
     * @author ANDRE Felix
     * @type {string}
     * @memberof ReviewEntity
     */
    name?: string;

    /**
     * @description firstname of the author, use ony if isAnonymous at false
     * @author ANDRE Felix
     * @type {string}
     * @memberof ReviewEntity
     */
    firstname?: string;
}

interface Review {}
