import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserEntity } from '@omedom/data';
import { UserService } from '@omedom/services';

import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-newcgv-validation-modal',
    templateUrl: 'newCgv-validation-modal.component.html',
    styleUrls: ['newCgv-validation-modal.component.scss'],
})
export class NewCgvValidationModalComponent implements OnInit, OnDestroy {
    private cgvCurrentDate = environment.cgvCurrentDate;

    user: UserEntity;

    constructor(private modalController: ModalController, private userService: UserService) {}

    ngOnInit(): void {
        this.userService.user$.subscribe((user) => {
            this.user = user;
        });
    }

    ngOnDestroy() {}

    public async acceptCgv(): Promise<void> {
        await this.userService.update({
            cgvValidatedVersion: this.cgvCurrentDate,
            uid: this.user.uid,
        });
        await this.modalController.dismiss();
    }
}
