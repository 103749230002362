export class OmedomSubTab {
    id: any;
    label: string;
    icon?: string;
    disabled: boolean;
    popover?: string;

    constructor(init?: Partial<OmedomSubTab>) {
        Object.assign(this, init);
    }
}
