import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
    @Input() maxDate: Date = new Date('2200-12-31');

    /**
 * @description The max date that can be selected in the date picker modal: first day of the month of the max date (e.g. '2200-12-01')
 * @author Didier Pascarel
 * @type {string}
 * @memberof DateComponent
 */
    dateMax: string;

    datefrompicker;
    @ViewChild('datetimepicker') datetimepicker;

    constructor(private modalController: ModalController) {
    }

    ngOnInit(): void {
        this.dateMax = this.maxDate.toISOString().split('T')[0];
    }

    datePicked(value) {
        this.datefrompicker = value;
    }

    async updateConfirmed(): Promise<void> {
        await this.datetimepicker.confirm();
        const date = new Date(
            this.datetimepicker.value
        ).getUTCFirstDayOfMonth();
        await this.modalController.dismiss(date);
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss();
        this.datetimepicker.cancel();
    }
}
