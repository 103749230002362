// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex: 1;
}

.form-title {
  font-size: 18px;
  font-weight: 600;
}

:host {
  width: 100%;
}

.form-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  border: 0;
  background: none;
}

.omedom-sticky-button-container {
  margin-top: 60px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNoYXJlLWZvcm0uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSwyQkFBQTtFQUNBLFNBQUE7RUFDQSxnQkFBQTtFQUNBLE9BQUE7QUFDSjs7QUFFQTtFQUNJLGVBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksV0FBQTtBQUNKOztBQUVBO0VBQ0ksa0JBQUE7RUFDQSxXQUFBO0VBQ0EsU0FBQTtFQUNBLFNBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksZ0JBQUE7QUFDSiIsImZpbGUiOiJzaGFyZS1mb3JtLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmZvcm0ge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gICAgZ2FwOiAyMHB4O1xuICAgIG1hcmdpbi10b3A6IDIwcHg7XG4gICAgZmxleDogMTtcbn1cblxuLmZvcm0tdGl0bGUge1xuICAgIGZvbnQtc2l6ZTogMThweDtcbiAgICBmb250LXdlaWdodDogNjAwO1xufVxuXG46aG9zdCB7XG4gICAgd2lkdGg6IDEwMCU7XG59XG5cbi5mb3JtLWNsb3NlLWJ1dHRvbiB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHJpZ2h0OiAyMHB4O1xuICAgIHRvcDogMjBweDtcbiAgICBib3JkZXI6IDA7XG4gICAgYmFja2dyb3VuZDogbm9uZTtcbn1cblxuLm9tZWRvbS1zdGlja3ktYnV0dG9uLWNvbnRhaW5lciB7XG4gICAgbWFyZ2luLXRvcDogNjBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/share/share-form/share-form.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,SAAA;EACA,gBAAA;EACA,OAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,SAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AACA,okCAAokC","sourcesContent":[".form {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 20px;\n    margin-top: 20px;\n    flex: 1;\n}\n\n.form-title {\n    font-size: 18px;\n    font-weight: 600;\n}\n\n:host {\n    width: 100%;\n}\n\n.form-close-button {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n    border: 0;\n    background: none;\n}\n\n.omedom-sticky-button-container {\n    margin-top: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
