import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SubscriptionService, UserService } from '@omedom/services';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SmartGuard implements CanActivate {
    private readonly ESSENTIAL_PATH = '/tabs/menu/subscription';

    constructor(
        private userService: UserService,
        private subscriptionService: SubscriptionService,
        private router: Router
    ) { }

    canActivate(): Observable<boolean> {
        return this.userService.user$.pipe(
            switchMap(user => {
                // If the user is not logged in, we redirect him to the essential page
                if (!user) {
                    this.router.navigate([this.ESSENTIAL_PATH]);
                    return of(false);
                }

                // If the user is logged in, we check if he has a subscription
                return this.subscriptionService.getSubscriptionByUser(user.uid).pipe(
                    map(subscription => {
                        // If the user does not have a subscription, we redirect him to the essential page
                        if (!subscription) {
                            this.router.navigate([this.ESSENTIAL_PATH]);
                            return false;
                        }

                        // Check if the subscription is active
                        const now = new Date().toUTC();

                        // Split the date
                        const renewDateSplit = subscription.renewDate.split('/');

                        // Create all elements of date
                        const year = Number.parseInt(renewDateSplit[2], 10);
                        const month = Number.parseInt(renewDateSplit[1], 10);
                        const day = Number.parseInt(renewDateSplit[0], 10);

                        const endAt = new Date(year, month - 1, day).toUTC();

                        // If the subscription is expired, we redirect him to the essential page
                        if (endAt < now) {
                            this.router.navigate([this.ESSENTIAL_PATH]);
                            return false;
                        }

                        return true;
                    })
                );
            })
        );
    }
}
