/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

Object.defineProperty(navigator, 'onLine', {
    get() {
        return true;
    },
    set() { },
});

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
