import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';
import { IncomeListComponent } from './containers/income-list/income-list.component';
import { IncomeFormPage } from './pages/income-form/income-form.page';
import { RouterModule } from '@angular/router';
import { IncomeInfoPage } from './pages/income-info/income-info.page';
import { NgChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { IncomeEditPage } from './pages/income-edit/income-edit.page';
import { IncomeRentPage } from './pages/income-rent/income-rent.page';
import { IncomeRentUnpaidComponent } from './containers/income-rent-unpaid/income-rent-unpaid.component';
import { OmedomCommonModule } from '../common/common.module';
import { UiModule } from '@omedom/ui';

@NgModule({
    declarations: [
        IncomeListComponent,
        IncomeFormPage,
        IncomeInfoPage,
        IncomeEditPage,
        IncomeRentUnpaidComponent,
        IncomeRentPage,
    ],
    exports: [IncomeFormPage, IncomeInfoPage, IncomeRentPage],
    imports: [
        IonicModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgChartsModule,
        OmedomComponentModule,
        OmedomCommonModule,
        UiModule,
    ],
})
export class IncomeModule {}
