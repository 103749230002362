import {
    IncomeEntity,
    IncomePeriodicity,
    PropertyEntity,
    PropertyType,
    UseProperty,
} from '@omedom/data';
import { OmedomProperty } from './property';

/**
 * @description Utils for rentability calculation
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class OmedomRentability
 */
export class OmedomRentability {
    /**
     * @description Calculate the gross rentability of properties (except principal and secondary)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @param {PropertyEntity[]} properties Properties to calculate the rentability
     * @param {IncomeEntity[]} incomes Incomes to calculate the rentability
     * @param {Date} dateStart Date to start the calculation
     * @returns {number} Gross rentability of all properties in percent
     * @memberof Rentability
     * @example
     * const properties = await this.propertyService.search({ ... });
     * const incomes = await this.incomeService.search({ ... });
     * const dateStart = new Date(2020, 1, 1);
     *
     * const rentability = Rentability.getPropertiesGrossRentability(properties, incomes, dateStart);
     */
    public static getPropertiesGrossRentability(
        properties: PropertyEntity[],
        incomes: IncomeEntity[],
        dateStart: Date
    ): number {
        // Check if there is no properties or incomes
        if (!incomes?.length || !properties?.length) {
            return 0;
        }

        let totalAmount = 0;
        let acquisitionPriceSum = 0;

        // Filter properties to remove principal and secondary
        const propertiesFiltered = OmedomProperty.excludeResidentialProperties(properties);

        // Calculate the total amount of all properties
        properties.forEach((property) => {
            // Filter incomes to get only incomes of the current property
            const propertyIncomes = incomes.filter((x) => x.propertyUID === property.uid);

            // Calculate the total amount of the current property and add it to the sum
            totalAmount += !!propertyIncomes
                ? propertyIncomes.sumBy((income) =>
                      this.getRentAmountForLastYear(income, dateStart)
                  )
                : 0;

            // Calculate the acquisition price of the current property
            const acquisitionPrice = this.getPropertyAcquisitionPrice(property);

            // Add the acquisition price to the sum
            acquisitionPriceSum += acquisitionPrice;
        });

        if (acquisitionPriceSum === 0) {
            return 0;
        }
        // Calculate the gross rentability
        return (totalAmount / acquisitionPriceSum) * 100;
    }

    /**
     * @description Calculate the gross rentability of a property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @param {PropertyEntity} property Property to calculate the rentability
     * @param {IncomeEntity[]} incomes Incomes to calculate the rentability
     * @param {Date} dateStart Date to start the calculation
     * @returns {number} Gross rentability of the property in percent
     * @memberof Rentability
     * @example
     * const property = await this.propertyService.get(...);
     * const incomes = await this.incomeService.search({ ... });
     * const dateStart = new Date(2020, 1, 1);
     *
     * const rentability = Rentability.getPropertyGrossRentability(property, incomes, dateStart);
     */
    public static getPropertyGrossRentability(
        property: PropertyEntity,
        incomes: IncomeEntity[],
        dateStart: Date
    ): number {
        // Check if there is no property or incomes
        if (!incomes?.length || !property) {
            return 0;
        }

        // Calculate the acquisition price of the property
        const acquisitionPrice = this.getPropertyAcquisitionPrice(property);

        // Check if the acquisition price is 0
        if (acquisitionPrice === 0) {
            return 0;
        }

        // Make a sum of all rents of the property since the dateStart
        const amount = !!incomes
            ? incomes.sumBy((income) => this.getRentAmountForLastYear(income, dateStart))
            : 0;

        // Calculate the gross rentability
        return (amount / acquisitionPrice) * 100;
    }

    /**
     * @description Get the acquisition price of a property (price + notary fees + agency fees or market value if price is null or 0)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @param {PropertyEntity} property Property to get the acquisition price
     * @returns {number} Acquisition price of the property in number
     * @memberof Rentability
     * @example
     * const property = await this.propertyService.get(...);
     *
     * const acquisitionPrice = Rentability.getPropertyAcquisitionPrice(property);
     */
    public static getPropertyAcquisitionPrice(property: PropertyEntity): number {
        const price = property.purchaseDetails?.price;
        const marketValue = property.purchaseDetails?.marketValue ?? 0;
        const notaryFees = property.purchaseDetails?.notaryFees ?? 0;
        const agencyFees = property.purchaseDetails?.agencyFees ?? 0;

        return !!price ? price + notaryFees + agencyFees : marketValue;
    }

    /**
     * @description Get the acquisition price of properties (price + notary fees + agency fees or market value if price is null or 0) of a list of properties
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @param {PropertyEntity[]} properties Properties to get the acquisition price
     * @returns {number} Acquisition price of the properties in number
     * @memberof Rentability
     * @example
     * const properties = await this.propertyService.search({ ... });
     *
     * const acquisitionPrice = Rentability.getPropertiesAcquisitionPrice(properties);
     */
    public static getPropertiesAcquisitionPrice(properties: PropertyEntity[]): number {
        return properties.sumBy((x) => this.getPropertyAcquisitionPrice(x));
    }

    /**
     * @description Get rent amount for the last year since the dateStart
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @param {IncomeEntity} income Income to get the rent amount
     * @param {Date} dateStart Date to start the calculation
     * @returns {number} Rent amount for the last year since the dateStart in number
     * @memberof Rentability
     * @example
     * const income = await this.incomeService.get(...);
     * const dateStart = new Date(2020, 1, 1);
     *
     * const rentAmount = Rentability.getRentAmountForLastYear(income, dateStart);
     */
    public static getRentAmountForLastYear(income: IncomeEntity, dateStart: Date): number {
        // Set the dateEnd to 12 months before the dateStart
        const dateEnd = dateStart.subUTCMonths(12);

        let amount = 0;

        switch (income.periodicity) {
            // If the income is punctual and the debit date is between the dateEnd and the dateStart, return the amount
            case IncomePeriodicity.punctual:
                if (income.debitDate?.toDate().between(dateEnd, dateStart)) {
                    amount = income.amount;
                }

                break;

            // If the income is yearly, return the amount of the last history or the amount of the income
            case IncomePeriodicity.yearly:
                amount = income.history?.length
                    ? income.history[income.history.length - 1].amount
                    : income.amount;
                break;

            // By default return the sum of all history between the dateEnd and the dateStart or the amount of the income
            default:
                const history = income.history?.filter((x) =>
                    x.date
                        .toDate()
                        .between(dateEnd.getUTCLastDayOfMonth(), dateStart.getUTCFirstDayOfMonth())
                );
                amount += history?.length ? history.sumBy((x) => x.amount) : income.amount;
                break;
        }

        // Return the amount
        return amount;
    }
}
