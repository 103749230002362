/* eslint-disable @angular-eslint/contextual-lifecycle */
import { Injectable, OnDestroy, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    CategoryInfo,
    ChargeEntity,
    ChargeListModel,
    ChargePeriodicity,
    ChargeUpdateType,
    IncomeEntity,
    IncomeListModel,
    IncomePeriodicity,
    IncomeUpdateType,
    OmedomDateType,
    PeriodicityInfo,
    PropertyEntity,
    SocietyEntity,
    Story,
    TreasuryListModel,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { ChargeEditTypeComponent, IncomeEditTypeComponent } from '@omedom/ui';
import { OmedomStory, OmedomTreasury } from '@omedom/utils';
import { combineLatest, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export abstract class TreasuryList<
    TEntity extends ChargeEntity | IncomeEntity,
    TCategory,
    TPeriodicity
> implements OnInit, OnDestroy
{
    treasuryByDay: {
        day: Date;
        treasury: TreasuryListModel<TCategory, TPeriodicity>[];
    }[];

    now = new Date();

    currentDate: Date;

    omedomDateType = OmedomDateType;

    editable = true;

    notes: string;

    protected propertyUid: string;

    protected societyUid: string;

    private subscription: Subscription;

    protected properties: PropertyEntity[];

    protected societies: SocietyEntity[] = [];

    private storyModal: HTMLIonModalElement;

    user: UserEntity;

    protected constructor(
        protected userService: UserService,
        protected propertyService: PropertyService,
        protected activatedRoute: ActivatedRoute,
        protected modalController: ModalController,
        private router: Router,
        protected societyService: SocietyService
    ) {}

    ngOnInit(): void {
        const date$ = this.activatedRoute.paramMap.pipe(
            map((queryParams) => {
                return new Date(queryParams.get('date'));
            })
        );

        const property$ = this.activatedRoute.paramMap.pipe(
            map((queryParams) => queryParams.get('propertyUid'))
        );
        const society$ = this.activatedRoute.paramMap.pipe(
            map((queryParams) => queryParams.get('societyUid'))
        );
        const user$ = this.userService.user$;

        const editable$ = this.activatedRoute.fragment.pipe(map((fragment) => fragment === 'view'));

        this.subscription = combineLatest([date$, user$, property$, society$, editable$])
            .pipe(take(1))
            .subscribe(async ([currentDate, user, property, society, editable]) => {
                this.currentDate = currentDate;
                this.propertyUid = property;
                this.societyUid = society;
                this.user = user;
                this.editable = !editable;

                await this.updateData();
            });
    }

    async ionViewWillEnter(): Promise<void> {
        if (this.user) {
            await this.updateData(); // fix refreshing of list
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    abstract loadTreasury(userUid: string): Promise<TEntity[]>;

    abstract getCategoryInfo(category: string): CategoryInfo<TCategory>;

    abstract getPeriodicityInfo(periodicity: string): PeriodicityInfo<TPeriodicity>;

    async dateChange(date: Date): Promise<void> {
        this.currentDate = date;
        await this.updateData();
    }

    protected async showDeleteComponent<T>(
        type: Type<T>,
        // treasuryListModel: TreasuryListModel<TCategory, TPeriodicity>
        treasuryListModel: ChargeListModel | IncomeListModel
    ): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                treasuryListModel,
                label: 't',
            },
        });

        await modal.present();

        modal.onDidDismiss().then(() => {
            this.updateData();
            this.modalController.dismiss();
        });
        return modal;
    }

    protected async showEditComponent<T>(type: Type<T>): Promise<HTMLIonModalElement> {
        const modal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
        });

        await modal.present();

        return modal;
    }

    private async updateData(): Promise<void> {
        this.propertyService
            ._getUserPropertiesAndSharedAccessible(this.user.uid)
            .subscribe(async (properties) => {
                this.properties = properties;
                this.societyService
                    ._getUserSocietiesAndShared(this.user.uid)
                    .subscribe(async (societies) => {
                        this.societies = societies;
                        const allEntities = await this.loadTreasury(this.user.uid);
                        const entities = allEntities.filter((x) => !x.isDeleted);
                        const startDate = this.currentDate.getUTCFirstDayOfMonth();
                        const endDate = this.currentDate.getUTCLastDayOfMonth();
                        const filteredData = OmedomTreasury.filterTreasury<TEntity>(
                            entities,
                            startDate.getUTCDateWithoutTime(),
                            endDate.getUTCDateWithoutTime()
                        );
                        const propertiesTreasuryByMonth = this.getMonthPropertyTreasury(
                            filteredData,
                            startDate,
                            endDate
                        );
                        const societyTreasuryByMonth = await this.getMonthSocietyTreasury(
                            filteredData,
                            startDate,
                            endDate
                        );
                        const treasuryByDay = [
                            ...propertiesTreasuryByMonth,
                            ...societyTreasuryByMonth,
                        ].reduce(
                            (tbt, treasury) => ({
                                ...tbt,
                                [treasury.date.toString()]: [
                                    ...(tbt[treasury.date.toString()] || []),
                                    treasury,
                                ],
                            }),
                            {}
                        );

                        const sortedTreasuryByDay = Object.keys(treasuryByDay).sort(
                            (a, b) => new Date(a).getTime() - new Date(b).getTime()
                        );

                        this.treasuryByDay = sortedTreasuryByDay.map((date) => ({
                            day: new Date(date),
                            treasury: treasuryByDay[date],
                        }));
                    });
            });
    }

    private getMonthPropertyTreasury(
        entities: TEntity[],
        startDate: Date,
        endDate: Date
    ): TreasuryListModel<TCategory, TPeriodicity>[] {
        const treasuryByMonth: TreasuryListModel<TCategory, TPeriodicity>[] = [];
        entities.forEach((entity) => {
            const property = this.properties.find((x) => x.uid === entity.propertyUID);
            if (!property) {
                return;
            }

            const treasury = this.transformEntityInTreasury(entity, property, startDate, endDate);
            treasuryByMonth.push(...treasury);
        });
        return treasuryByMonth;
    }

    private async getMonthSocietyTreasury(
        entities: TEntity[],
        startDate: Date,
        endDate: Date
    ): Promise<TreasuryListModel<TCategory, TPeriodicity>[]> {
        const treasuryByMonth: TreasuryListModel<TCategory, TPeriodicity>[] = [];

        const promises = entities.map(async (entity) => {
            if (!entity.societyUID) {
                return;
            }
            const society = await this.societyService.get(entity.societyUID);

            if (!society) {
                return;
            }

            const treasury = this.transformEntityInTreasury(entity, society, startDate, endDate);

            treasuryByMonth.push(...treasury);
        });

        await Promise.all(promises);

        return treasuryByMonth;
    }

    /**
     * @description Transform a charge or income in treasuryByMonth
     * @author ANDRE Felix
     * @private
     * @param {(ChargeEntity | IncomeEntity)} entity
     * @param {(PropertyEntity | SocietyEntity)} asset
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {*}
     * @memberof TreasuryList
     */
    private transformEntityInTreasury(
        entity: ChargeEntity | IncomeEntity,
        asset: PropertyEntity | SocietyEntity,
        startDate: Date,
        endDate: Date
    ): TreasuryListModel<TCategory, TPeriodicity>[] {
        const history = entity.history?.filter((x) => x.date.toDate().between(startDate, endDate));

        const futurepayments = entity.futurPayment?.filter(
            (futurPayment) =>
                (futurPayment.date.toDate().getFirstDayOfMonth().getTime() <=
                    startDate.getFirstDayOfMonth().getTime() &&
                    futurPayment.isForNext) ||
                (futurPayment.date.toDate().between(startDate, endDate) && !futurPayment.isForNext)
        );

        const commonTreasury = {
            categoryInfo: this.getCategoryInfo(entity.category),
            uid: entity.uid,
            periodicityInfo: this.getPeriodicityInfo(entity.periodicity),
            designation: entity.designation,
            propertyName: asset.name,
            propertyImg: asset.photo,
            propertyOwnerUID: asset.userUID,
            propertyUID: asset.uid,
            withNotif: true,
            userUID: entity.userUID,
            notes: OmedomTreasury.getMonthNotes(entity, startDate),
        };

        if (history?.length) {
            const treasury: TreasuryListModel<TCategory, TPeriodicity>[] = history.map(
                (history) => ({
                    ...commonTreasury,
                    categoryInfo: this.getCategoryInfo(entity.category),
                    amount: Math.abs(history.amount),
                    date: history.date.toDate(),
                    isPayed: history.isPayed,
                })
            );
            return treasury;
        }
        if (futurepayments?.length) {
            const treasury = futurepayments.slice(-1).map((h) => ({
                ...commonTreasury,
                amount: Math.abs(h.amount),
                date:
                    entity.periodicity === 'punctual'
                        ? entity.debitDate.toDate()
                        : OmedomTreasury.getMonthHistoryDate(entity, startDate.toUTC()),
                isPayed: false,
            }));
            return treasury;
        }

        const treasury = [
            {
                ...commonTreasury,
                amount: OmedomTreasury.getMonthAmount(entity, startDate),
                date:
                    entity.periodicity === 'punctual'
                        ? entity.debitDate.toDate()
                        : OmedomTreasury.getMonthHistoryDate(entity, startDate.toUTC()),
                isPayed: OmedomTreasury.isTreasuryPayed(entity, startDate, endDate),
            },
        ];
        return treasury;
    }

    /**
     * @description Open a modal for a Story
     * @author ANDRE Felix
     * @protected
     * @template T
     * @param {Type<T>} type
     * @param {Story} stories
     * @returns {*}  {Promise<HTMLIonModalElement>}
     * @memberof TreasuryList
     */
    protected async showStoryModal<T>(type: Type<T>, story: Story): Promise<HTMLIonModalElement> {
        const treasuryByDayFlat = this.treasuryByDay.flatMap((x) => x.treasury);
        const selectedIndex = treasuryByDayFlat.findIndex((x) => x.uid === story.uid);
        const isCharge = story.isCharge;

        const storiesOfTreasury = treasuryByDayFlat.map((x) =>
            OmedomStory.transformTreasuryModelListToStory(
                x as ChargeListModel | IncomeListModel,
                isCharge
            )
        );

        this.storyModal = await this.modalController.create({
            component: type,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: {
                stories: [...storiesOfTreasury],
                selectedIndex: selectedIndex,
                canNavigate: true,
            },
        });

        await this.storyModal.present();

        this.storyModal.onDidDismiss().then(() => this.updateData());

        return this.storyModal;
    }

    async editClicked(treasury: Story): Promise<void> {
        const treasuryString = treasury.isCharge ? 'charge' : 'income';
        const tresuryUpdateType = treasury.isCharge ? ChargeUpdateType : IncomeUpdateType;
        const treasuryPeriodicity = treasury.isCharge ? ChargePeriodicity : IncomePeriodicity;
        const isPunctual = treasury.periodicityInfo.periodicity === treasuryPeriodicity.punctual;
        if (isPunctual) {
            await this.modalController.dismiss();
            this.router.navigate([
                `/tabs/treasury/${treasuryString}/edit/${treasury.uid}/${tresuryUpdateType.thisOneAndNext}/${treasury.date}`,
            ]);
            await this.modalController.dismiss();
            return;
        }
        let modal: HTMLIonModalElement;
        if (treasury.isCharge) {
            modal = await this.showEditComponent(ChargeEditTypeComponent);
        } else {
            modal = await this.showEditComponent(IncomeEditTypeComponent);
        }
        await modal.present();
        modal.onDidDismiss().then((x) => {
            if (!x.data) {
                return;
            }

            if (this.propertyUid) {
                this.router.navigate([
                    `/tabs/property/info/${this.propertyUid}/${treasuryString}/edit/${treasury.uid}/${x.data}/${treasury.date}`,
                ]);
            } else {
                this.router.navigate([
                    `/tabs/treasury/${treasuryString}/edit/${treasury.uid}/${x.data}/${treasury.date}`,
                ]);
            }
            // Close all modal
            this.modalController.dismiss();
        });
    }
    // async deleteClicked(treasury: Story) {}
}
