import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import {
    Appearance,
    DataTableAction,
    DataTableColumn,
    LicenceEntity,
    Primitive,
    ProEntity,
    PropertyEntity,
    SocietyEntity,
    SubscriptionEntity,
    UserEntity,
} from '@omedom/data';
import { LicenceService, ProService } from '@omedom/services';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModalConfirmComponent } from '../../components';
import {
    LicenceCancelComponent,
    LicenceExchangeComponent,
    LicenceOrderComponent,
    LicenceSendComponent,
} from '../../containers';

@Component({
    selector: 'omedom-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnChanges {
    /**
     * @description Pro data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {ProEntity}
     * @memberof ClientListComponent
     */
    @Input()
    public pro?: ProEntity;

    /**
     * @description Empty message when the pro doesn't have client
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    @Input()
    public emptyMessage: string = `Aucun résultat pour cette recherche.`;

    /**
     * @description Link prefix to redirect to client profil
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    @Input()
    public linkPrefix: string = '/clients';

    /**
     * @description Columns to display in the table (label, key, type, transformer, validator) to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {DataTableColumn<UserEntity>[]}
     * @memberof ClientListComponent
     */
    @Input()
    public columns: DataTableColumn<UserEntity>[] = [
        {
            label: '',
            key: 'avatar',
            type: Primitive.picture,
            sortable: false,
            transformer: (data: UserEntity) => {
                return data.avatar?.startsWith('https') ? data.avatar : 'user-circle';
            },
            width: '70px',
        },
        {
            label: 'Prénom',
            key: 'firstname',
            type: Primitive.string,
            sortable: true,
        },
        {
            label: 'Nom',
            key: 'name',
            type: Primitive.string,
            sortable: true,
        },
        {
            label: 'Mail',
            key: 'email',
            type: Primitive.string,
            sortable: true,
        },
        {
            label: 'Nombre de biens partagés',
            key: 'propertiesUID',
            type: Primitive.number,
            sortable: true,
            transformer: (data: UserEntity) => {
                // Get the properties of the client
                const properties = this.clientsProperties.filter(
                    (property) => property.userUID === data.uid
                );

                // Get the shared properties to pro of the client
                const sharedToPro = properties.filter((property) => property.sharedToPro);

                // Get share to pro condition
                const shareWithProConditions = data.shareWithProConditions ?? false;

                return `${shareWithProConditions ? sharedToPro.length : 0}/${properties.length}`;
            },
            sorter: (data: UserEntity) => {
                return data.propertiesUID?.length ?? 0;
            },
        },
        {
            label: 'Nombre de sociétés partagées',
            key: 'societiesUID',
            type: Primitive.number,
            sortable: true,
            transformer: (data: UserEntity) => {
                // Get the societies of the client
                const societies = this.clientsSocieties.filter(
                    (society) => society.userUID === data.uid
                );

                // Get the shared societies to pro of the client
                const sharedToPro = societies.filter((society) => society.sharedToPro);

                // Get share to pro condition
                const shareWithProConditions = data.shareWithProConditions ?? false;

                return `${shareWithProConditions ? sharedToPro.length : 0}/${societies.length}`;
            },
            sorter: (data: UserEntity) => {
                return data.societiesUID?.length ?? 0;
            },
        },

        {
            label: "Fin d'activation",
            key: 'subscriptionUID',
            type: Primitive.date,
            sortable: true,
            transformer: (data: UserEntity) => {
                return this.getSubscriptionEndDate(data);
            },
        },
    ];

    /**
     * @description Action on the data by row (icon, title, callback, appearance) to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {DataTableAction<UserEntity>[]}
     * @memberof ClientListComponent
     */
    @Input()
    public actions: DataTableAction<UserEntity>[] = [
        {
            icon: 'angle-right-b',
            callback: async (data: UserEntity) => {
                // Route to the client page
                await this.router.navigate([this.linkPrefix, data.uid]);
            },
            appearance: Appearance.secondary,
            minimal: true,
        },
    ];

    /**
     * @description True if we let the possibility to disable the action on the data by row to display in the table, false otherwise (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {boolean}
     * @memberof ClientListComponent
     */
    @Input()
    public useDisabledCondition: boolean = false;

    /**
     * @description True if we display the order button, false otherwise (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {boolean}
     * @memberof ClientListComponent
     */
    @Input()
    public displayOrderButton: boolean = true;

    /**
     * @description List of clients to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @type {Observable<UserEntity[]>}
     * @memberof ClientListComponent
     */
    public clients$: Observable<UserEntity[]> = of([]);

    /**
     * @description Search string to filter the clients list by name or email
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    public search: string = '';

    /**
     * @description List of subscriptions of the clients of the pro (used to get the end date of the subscription of the client)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @private
     * @type {SubscriptionEntity[]}
     * @memberof ClientListComponent
     */
    private clientsSubscription: SubscriptionEntity[] = [];

    /**
     * @description List of properties of the clients of the pro (used to get the number of properties of the client of the pro)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @private
     * @type {PropertyEntity[]}
     * @memberof ClientListComponent
     */
    private clientsProperties: PropertyEntity[] = [];

    /**
     * @description List of societies of the clients of the pro (used to get the number of societies of the client of the pro)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 16/08/2024
     * @private
     * @type {SocietyEntity[]}
     * @memberof ClientListComponent
     */
    private clientsSocieties: SocietyEntity[] = [];

    /**
     * @description Condition to disable the action on the data by row to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @memberof ClientListComponent
     */
    public disabledCondition: (data: UserEntity) => boolean = (user) => {
        const shareWithProConditions: boolean = user.shareWithProConditions ?? false;
        const userProperties: PropertyEntity[] = this.clientsProperties.filter(
            (property) => property.userUID === user.uid
        );
        const numberOfPropertiesSharedWithPro = userProperties.filter(
            (property) => property.sharedToPro
        ).length;

        return !shareWithProConditions || numberOfPropertiesSharedWithPro === 0;
    };

    /**
     * @description Default condition to let possibility to have disabled row
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @memberof ClientListComponent
     */
    public defaultCondition: (data: UserEntity) => boolean = () => false;

    constructor(
        private readonly proService: ProService,
        private readonly router: Router,
        private readonly modalController: ModalController
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        // Check if the pro is defined
        if (!this.pro) {
            return;
        }

        // If pro uid doesn't change, we don't need to get the pro data
        if (
            changes['pro'] &&
            changes['pro'].previousValue?.uid === changes['pro'].currentValue?.uid
        ) {
            return;
        }

        // Get the clients of the pro
        this.clients$ = this.proService._getClients(this.pro.uid).pipe(
            map((data) => {
                // Get the subscriptions
                this.clientsSubscription = data.subscriptions;

                // Get the properties
                this.clientsProperties = data.properties;

                // Get the societies
                this.clientsSocieties = data.societies;

                // Return the clients
                return data.users;
            })
        );
    }

    /**
     * @description Filter the clients by the search string and return the filtered client list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @param {UserEntity[]} clients
     * @returns {UserEntity[]}
     * @memberof ClientListComponent
     */
    public getFilteredClients(clients: UserEntity[]): UserEntity[] {
        const search = clients.filter((client: UserEntity) => {
            return (
                client.firstname?.toLowerCase().includes(this.search.toLowerCase()) ||
                client.name?.toLowerCase().includes(this.search.toLowerCase()) ||
                client.email?.toLowerCase().includes(this.search.toLowerCase())
            );
        });

        return search;
    }

    /**
     * @description Get the end date of the subscription of the client
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/09/2023
     * @param {UserEntity} client
     * @returns {*}  {string}
     * @memberof ClientListComponent
     */
    public getSubscriptionEndDate(client: UserEntity): string {
        // Get the subscriptionUID of the client
        const subscriptionUID = client.subscriptionUID;

        // Check if the subscriptionUID is defined
        if (!subscriptionUID) {
            return 'Non activé';
        }

        // Get the subscription of the client
        const subscription = this.clientsSubscription.find(
            (subscription) => subscription.uid === subscriptionUID
        );

        // Check if the subscription is defined
        if (!subscription) {
            return 'Non activé';
        }

        // Get the end date of the subscription
        const endDate = subscription.renewDate;

        // Check if the end date is defined
        if (!endDate) {
            return 'Non activé';
        }

        // Split the string to get the date
        const split = endDate.split('/');

        // Create date with the string
        const date = new Date(Number(split[2]), Number(split[1]) - 1, Number(split[0]));

        return date.toLocaleDateString();
    }

    /**
     * @description Order licences for the pro
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @returns {Promise<void>}
     * @memberof ClientListComponent
     */
    public async onOrder(): Promise<void> {
        const modal = await this.modalController.create({
            component: LicenceOrderComponent,
        });

        await modal.present();
    }

    /**
     * @description Display a modal to filter the client list by state (used, shared, available)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @returns {Promise<void>}
     * @memberof ClientListComponent
     *
     * @todo Create the component to filter licences
     */
    public async onFilter(): Promise<void> {
        // TODO: Create the component to filter licences;
    }

    /**
     * @description Send licences to the client by email
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @returns {Promise<void>}
     * @memberof ClientListComponent
     */
    public async onSend(): Promise<void> {
        const modal = await this.modalController.create({
            component: LicenceSendComponent,
        });

        await modal.present();
    }
}
