import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { IonicModule } from '@ionic/angular';

import { UiModule } from '@omedom/ui';

@NgModule({
    declarations: [],
    imports: [CommonModule, OmedomComponentModule, IonicModule, UiModule],
    exports: [],
})
export class LevelModule {}
