// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  max-height: 100%;
  overflow-y: auto;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.titles {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #01856e;
}

h3 {
  display: flex;
  justify-content: space-between;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvbmZpZGVudGlhbGl0eS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFdBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLGFBQUE7RUFDQSxTQUFBO0VBQ0EsZ0JBQUE7RUFDQSxnQkFBQTtBQUNKOztBQUVBO0VBQ0ksZ0JBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7QUFDSjs7QUFFQTtFQUNJLGdCQUFBO0VBQ0EsZUFBQTtFQUNBLGlCQUFBO0VBRUEsY0FBQTtBQUFKOztBQUdBO0VBQ0ksYUFBQTtFQUNBLDhCQUFBO0FBQUoiLCJmaWxlIjoiY29uZmlkZW50aWFsaXR5LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIHdpZHRoOiAxMDAlO1xufVxuXG4uY29udGVudCB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICAgIHBhZGRpbmc6IDEwcHg7XG4gICAgZ2FwOiAyMHB4O1xuICAgIG1heC1oZWlnaHQ6IDEwMCU7XG4gICAgb3ZlcmZsb3cteTogYXV0bztcbn1cblxuLnRleHQge1xuICAgIGZvbnQtd2VpZ2h0OiA0MDA7XG4gICAgZm9udC1zaXplOiAxNnB4O1xuICAgIGxpbmUtaGVpZ2h0OiAxOHB4O1xufVxuXG4udGl0bGVzIHtcbiAgICBmb250LXdlaWdodDogNjAwO1xuICAgIGZvbnQtc2l6ZTogMjBweDtcbiAgICBsaW5lLWhlaWdodDogMjNweDtcblxuICAgIGNvbG9yOiAjMDE4NTZlO1xufVxuXG5oMyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/confidentiality/confidentiality.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;AAAJ;AACA,omCAAomC","sourcesContent":[":host {\n    width: 100%;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    padding: 10px;\n    gap: 20px;\n    max-height: 100%;\n    overflow-y: auto;\n}\n\n.text {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 18px;\n}\n\n.titles {\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 23px;\n\n    color: #01856e;\n}\n\nh3 {\n    display: flex;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
