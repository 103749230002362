import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { OmedomStep } from './omedom-step';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class StepperComponent {
    @Input() steps: OmedomStep[] = [];

    @Input() selectedStep: OmedomStep;

    @Output() selectedStepChange = new EventEmitter<OmedomStep>();

    @Output() saveClicked = new EventEmitter();

    selectedStepIndex = 0;

    previousClicked(): void {
        if (this.selectedStepIndex > 0) {
            this.selectedStepIndex--;
            this.updateSelectedStep();
        }
    }

    nextClicked(): void {
        if (this.selectedStepIndex < this.steps.length - 1) {
            this.selectedStepIndex++;
            this.updateSelectedStep();
        }
    }

    private updateSelectedStep(): void {
        this.selectedStep = this.steps[this.selectedStepIndex];
        this.selectedStepChange.next(this.selectedStep);
    }
}
