// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: #04151c;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 15%;
  padding: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxpY2VuY2UucGFnZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0kscUJBQUE7QUFDSjs7QUFDQTtFQUNJLFdBQUE7RUFDQSxhQUFBO0VBQ0EsdUJBQUE7RUFFQSxXQUFBO0VBQ0EsYUFBQTtBQUNKIiwiZmlsZSI6ImxpY2VuY2UucGFnZS5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWNvbnRlbnQge1xuICAgIC0tYmFja2dyb3VuZDogIzA0MTUxYztcbn1cbi5sb2dvLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICAvLyBwbGFjZS1pdGVtczogY2VudGVyO1xuICAgIGhlaWdodDogMTUlO1xuICAgIHBhZGRpbmc6IDIwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/licence/page/licence.page.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AACA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EAEA,WAAA;EACA,aAAA;AACJ;AACA,glBAAglB","sourcesContent":["ion-content {\n    --background: #04151c;\n}\n.logo-container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    // place-items: center;\n    height: 15%;\n    padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
