import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Color, defaultRoleState, RoleState } from '@omedom/data';
import { HeaderDropdownClickService } from '@omedom/services';
import { Subscription } from 'rxjs';

import { AlertComponent } from '../alert/alert.component';
import { OmedomTab } from '../tab';
import { HeaderPreviousType } from './header-previous-type';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
    @Input() label: string;

    @Input() img: string;

    @Input() icon: string;

    @Input() backgroundIcon: string;

    @Input() previousType = HeaderPreviousType.previous;

    @Input() previousUrl: string;

    @Input() useNavController: boolean;

    @Input() tabs: OmedomTab[];

    @Input() selectedTab: OmedomTab;

    @Input() isSociety: boolean;

    @Input() isShared: boolean;

    @Input() roleState: RoleState = defaultRoleState;

    @Input() reduceHeaderPadding = false;

    /**
     * @description affiche ou non le bouton dropdown
     * @author ANDRE Felix
     * @memberof HeaderComponent
     */
    @Input() isEditable = false;

    /**
     *@description: If the header tab contains an information popover
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() popover: string | string[];

    /**
     * @description: If the avatar of the header tab has a background (case of property) or not
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof HeaderComponent
     */
    @Input() avatarWithBg: string;

    @Output() selectedTabChange = new EventEmitter<OmedomTab>();

    headerPreviousType = HeaderPreviousType;

    colors = Color;

    subscription: Subscription;

    constructor(
        private navController: NavController,
        private modalController: ModalController,
        private headerDropdownClickService: HeaderDropdownClickService,
        private route: ActivatedRoute
    ) {}

    get hasNavigation(): boolean {
        return !!this.previousUrl || this.useNavController;
    }

    back(): void {
        if (window.history.state && window.history.state.navigationId > 0) {
            this.navController.back();
        } else {
            this.navController.pop();
        }
    }

    async exitClicked(label: string, icon: string, img: string) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        modal.present();
    }

    navigateTo(url: string): void {
        this.navController.navigateBack(url);
    }
    /**
     * @description appel a un service pour afficher la page d'edition d'une propriété
     * @author ANDRE Felix
     * @memberof HeaderComponent
     */
    EditClicked(): void {
        this.headerDropdownClickService.editClicked(this.route);
    }
    /**
     * @description affiche la modal de suppression d'un bien
     * @author ANDRE Felix
     * @memberof HeaderComponent
     */
    deleteClicked(): void {
        this.headerDropdownClickService.deleteClicked();
    }
}
