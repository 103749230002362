import { NotificationEntity } from './notification.entity';

export enum ProAlertType {
    BANK_ACCOUNT_UPPER_THAN = 'bank_account_upper_than',
    BANK_ACCOUNT_LOWER_THAN = 'bank_account_lower_than',
    RENTAL_RENTABILITY_LOWER_THAN = 'rental_rentability_lower_than',
    RENTAL_RENTABILITY_UPPER_THAN = 'rental_rentability_upper_than',
    ASSETS_VALUATION_LOWER_THAN = 'assets_valuation_lower_than',
    ASSETS_VALUATION_UPPER_THAN = 'assets_valuation_upper_than',
    CLIENT_BIRTHDAY = 'client_birthday',
    DEFAULT = 'default',
}

export type PartialNotificationEntity = Partial<NotificationEntity>;

export class ProAlertEntity extends NotificationEntity {
    /**
     * @description Type of the alert
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {string}
     * @memberof ProAlertEntiy
     */
    type!: ProAlertType;

    /**
     * @description UID of the client linked to the alert
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {string}
     * @memberof ProAlertEntiy
     */
    clientUID!: string;

    /**
     * @description Name of the client linked to the alert
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {string}
     * @memberof ProAlertEntity
     */
    clientName?: string;

    /**
     * @description Firstname of the client linked to the alert
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {string}
     * @memberof ProAlertEntity
     */
    clientFirstname?: string;

    /**
     * @description Email of the client linked to the alert
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {string}
     * @memberof ProAlertEntity
     */
    clientEmail?: string;
}
