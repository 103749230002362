// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  width: 100%; /* Largeur de la barre de progression */
}

.progress-bar {
  display: flex;
  height: 5px;
  background-color: #ffffff;
}

.progress-step {
  width: 33.33%;
  background-color: #FFBE00;
}

.progress-step:nth-child(2) {
  background-color: #0076C8;
}

.progress-step:nth-child(3) {
  background-color: #E2E2E2;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2dyZXNzLWJhci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFdBQUEsRUFBQSx1Q0FBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLFdBQUE7RUFDQSx5QkFBQTtBQUNKOztBQUVBO0VBQ0ksYUFBQTtFQUNBLHlCQUFBO0FBQ0o7O0FBRUE7RUFDSSx5QkFBQTtBQUNKOztBQUVBO0VBQ0kseUJBQUE7QUFDSiIsImZpbGUiOiJwcm9ncmVzcy1iYXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIucHJvZ3Jlc3MtY29udGFpbmVyIHtcbiAgICB3aWR0aDogMTAwJTsgLyogTGFyZ2V1ciBkZSBsYSBiYXJyZSBkZSBwcm9ncmVzc2lvbiAqL1xufVxuXG4ucHJvZ3Jlc3MtYmFyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGhlaWdodDogNXB4O1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNmZmZmZmY7XG59XG5cbi5wcm9ncmVzcy1zdGVwIHtcbiAgICB3aWR0aDogMzMuMzMlO1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNGRkJFMDA7XG59XG5cbi5wcm9ncmVzcy1zdGVwOm50aC1jaGlsZCgyKSB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogIzAwNzZDODtcbn1cblxuLnByb2dyZXNzLXN0ZXA6bnRoLWNoaWxkKDMpIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjRTJFMkUyO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/user-registration-process/components/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA,EAAA,uCAAA;AACJ;;AAEA;EACI,aAAA;EACA,WAAA;EACA,yBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;AACA,w7BAAw7B","sourcesContent":[".progress-container {\n    width: 100%; /* Largeur de la barre de progression */\n}\n\n.progress-bar {\n    display: flex;\n    height: 5px;\n    background-color: #ffffff;\n}\n\n.progress-step {\n    width: 33.33%;\n    background-color: #FFBE00;\n}\n\n.progress-step:nth-child(2) {\n    background-color: #0076C8;\n}\n\n.progress-step:nth-child(3) {\n    background-color: #E2E2E2;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
