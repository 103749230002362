import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ReviewEntity } from '@omedom/data';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ReviewService extends RestService<ReviewEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'reviews');
    }

    _getProReviews(proUID: string): Observable<ReviewEntity[]> {
        return this._search([{ where: 'proUID', operator: '==', value: proUID }]);
    }
}
