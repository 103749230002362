import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SelectOption } from '@omedom/data';
import { Observable, Subscription } from 'rxjs';

import { SelectBottomSheetComponent } from './bottom-sheet/select-bottom-sheet.component';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @Input() options$: Observable<SelectOption[]>;

    @Input() placeholder: SelectOption;

    @Input() isMultiple: boolean;

    @Input() footer: boolean;

    @Input() label: string;

    @Input() required: boolean;

    @Input() detailedOptions = false;

    @Input() boldLabel: boolean;

    @Output() selectExited = new EventEmitter();

    @HostBinding('class.disabled') isDisabled: boolean;

    value: SelectOption | SelectOption[];

    isOpen: boolean;

    selectedOption: SelectOption;

    private onChangeCallback: any;

    private options: SelectOption[];

    private subscription: Subscription;

    constructor(private modalController: ModalController) { }

    @HostListener('click')
    async onClick(): Promise<void> {
        if (this.isDisabled) {
            return;
        }

        const modal = await this.modalController.create({
            component: SelectBottomSheetComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: {
                options: this.options,
                selectTitle: this.placeholder?.label,
                isMultiple: this.isMultiple,
                footer: this.footer,
                detailedOptions: this.detailedOptions,
            },
        });

        modal.onDidDismiss().then((x) => {
            if (x.data) {
                this.selectionChange(x?.data);
            }
            if (x.role === 'backdrop') {
                const optionsSelected = this.options.filter((x) => x.isSelected);
                this.selectionChange(optionsSelected);
            }
        });

        await modal.present();
    }

    ngOnInit(): void {
        this.subscription = this.options$.subscribe((x) => (this.options = x));
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    validate(): null {
        return undefined;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void { }

    writeValue(newValue: SelectOption | SelectOption[]): void {
        if (newValue !== this.value) {
            this.value = newValue;

            if (newValue instanceof Array && newValue.length) {
                const firstValue = newValue[0];
                this.selectedOption = {
                    label: `${firstValue.label} ${newValue.length > 1 ? `+${newValue.length - 1}` : ''
                        }`,
                    id: null,
                    image: firstValue.image,
                    imageAlt: firstValue.imageAlt,
                    icon: firstValue.icon,
                    isSelected: true,
                };
            } else {
                this.selectedOption = newValue as SelectOption;
            }

            if (this.selectedOption) {
                this.selectedOption.isSelected = true;
            }
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    clearSelectionClicked(): void {
        if (Array.isArray(this.value)) {
            this.value?.forEach((x) => (x.isSelected = false));
        } else if (this.value) {
            this.value.isSelected = false;
        }

        this.writeValue(undefined);
        this.onChangeCallback(undefined);
    }

    private selectionChange(selection: SelectOption[]): void {
        if (!selection) {
            this.writeValue(undefined);
            this.onChangeCallback(undefined);
            return;
        }

        let value: SelectOption | SelectOption[];

        if (selection.length === 0) {
            value = this.isMultiple ? selection : undefined;
        } else {
            value = this.isMultiple ? selection : selection[0];
        }

        this.writeValue(value);
        this.onChangeCallback(value);
        this.selectExited.emit(value);
    }
}
