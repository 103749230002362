import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetEventsComponent } from './containers/widget-events/widget-events.component';
import { WidgetTreasuryComponent } from './containers/widget-treasury/widget-treasury.component';
import { WidgetNextMovementsComponent } from './containers/widget-next-movements/widget-next-movements.component';
import { OmedomComponentModule } from '../component/omedom-component.module';
import { NgChartsModule } from 'ng2-charts';
import { RouterModule } from '@angular/router';
import { WidgetTotalTreasuryAmountComponent } from './containers/widget-total-treasury-amount/widget-total-treasury-amount.component';
import { WidgetMovementsSummaryComponent } from './containers/widget-movements-summary/widget-movements-summary.component';
import { WidgetRentabilityComponent } from './containers/widget-rentability/widget-rentability.component';
import { UiModule } from '@omedom/ui';

@NgModule({
    declarations: [
        WidgetEventsComponent,
        WidgetTreasuryComponent,
        WidgetNextMovementsComponent,
        WidgetTotalTreasuryAmountComponent,
        WidgetMovementsSummaryComponent,

        WidgetRentabilityComponent,
    ],
    imports: [CommonModule, UiModule, OmedomComponentModule, NgChartsModule, RouterModule],
    exports: [
        WidgetNextMovementsComponent,
        WidgetTreasuryComponent,
        WidgetEventsComponent,
        WidgetTotalTreasuryAmountComponent,
        WidgetMovementsSummaryComponent,

        WidgetRentabilityComponent,
    ],
})
export class WidgetModule {}
