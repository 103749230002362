import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class WelcomeGuard {
    constructor(private userService: UserService, private router: Router) { }

    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return (
            this.userService.user$.pipe(
                map((user) => {
                    if (!user) {
                        this.router.navigate(['/user/auth']);
                        return false;
                    }

                    this.userService.getAuthState().then((state) => {
                        if (state) {
                            if (user.firstVisit) {
                                return true;
                            } else {
                                this.router.navigate(['/tunnel/welcome']);
                                return false;
                            }
                        }
                    });
                })
            ) ?? false
        );
    }
}
