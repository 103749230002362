import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import {
    AllChargeCategories,
    ChargeCategoryBuilding,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeCategorySociety,
    ChargeEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    PropertyService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { TreasuryInfo } from '@omedom/ui';

@Component({
    templateUrl: './charge-info.page.html',
    styleUrls: ['./charge-info.page.scss'],
})
export class ChargeInfoPage extends TreasuryInfo<ChargeEntity, AllChargeCategories> {
    constructor(
        userService: UserService,
        navController: NavController,
        activatedRoute: ActivatedRoute,
        propertyService: PropertyService,
        societyService: SocietyService,
        router: Router,
        route: ActivatedRoute,
        private chargeService: ChargeService,
        private routerService: Router,
        private analyticsService: AnalyticsService
    ) {
        super(
            userService,
            navController,
            activatedRoute,
            propertyService,
            societyService,
            router,
            route
        );
    }

    get queryParamsName(): string {
        return 'charge';
    }

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Charge Info Page');
    }

    loadCategory(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(
            category as ChargeCategoryProperty | ChargeCategoryBuilding | ChargeCategorySociety
        );
    }

    loadTreasury(userUid: string, category: ChargeCategoryProperty): Promise<ChargeEntity[]> {
        if (this.selectedProperties.length > 0 || this.selectedSocieties.length > 0) {
            const chargesProperties = this.chargeService.getChargesByCategoryByPropertyIds(
                category,
                this.selectedProperties.map((x) => x.id)
            );
            const chargesSocieties = this.chargeService.getChargesByCategoryBySocietyIds(
                category,
                this.selectedSocieties.map((x) => x.id)
            );
            return Promise.all([chargesProperties, chargesSocieties]).then((values) => {
                return values.flat();
            });
        } else {
            const chargesProperties = this.chargeService.getChargesByCategoryByPropertyIds(
                category,
                this.properties.map((x) => x.uid)
            );
            const chargesSocieties = this.chargeService.getChargesByCategoryBySocietyIds(
                category,
                this.societies.map((x) => x.uid)
            );

            return Promise.all([chargesProperties, chargesSocieties]).then((values) => {
                return values.flat();
            });
        }
    }

    addChargeClicked(): void {
        this.routerService.navigate(['/tabs/treasury/charge/form'], {
            queryParams: {
                charge: this.categoryInfo.category,
            },
        });
    }
}
