// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.search button {
  margin: 0;
}
.search omedom-input {
  flex: 1;
}

.list {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvY3VtZW50cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxzQkFBQTtFQUNBLFNBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSxtQkFBQTtFQUNBLDJCQUFBO0VBQ0EsU0FBQTtBQUNKO0FBQ0k7RUFDSSxTQUFBO0FBQ1I7QUFFSTtFQUNJLE9BQUE7QUFBUjs7QUFJQTtFQUNJLE9BQUE7RUFDQSxhQUFBO0VBQ0EsNERBQUE7RUFDQSxTQUFBO0FBREoiLCJmaWxlIjoiZG9jdW1lbnRzLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBnYXA6IDIwcHg7XG59XG5cbi5zZWFyY2gge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gICAgZ2FwOiAyMHB4O1xuXG4gICAgYnV0dG9uIHtcbiAgICAgICAgbWFyZ2luOiAwO1xuICAgIH1cblxuICAgIG9tZWRvbS1pbnB1dCB7XG4gICAgICAgIGZsZXg6IDE7XG4gICAgfVxufVxuXG4ubGlzdCB7XG4gICAgZmxleDogMTtcbiAgICBkaXNwbGF5OiBncmlkO1xuICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogcmVwZWF0KGF1dG8tZmlsbCwgbWlubWF4KDIwMHB4LCAxZnIpKTtcbiAgICBnYXA6IDIwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/containers/property/property-profil/documents/documents.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,SAAA;AACJ;AACI;EACI,SAAA;AACR;AAEI;EACI,OAAA;AAAR;;AAIA;EACI,OAAA;EACA,aAAA;EACA,4DAAA;EACA,SAAA;AADJ;AACA,ggCAAggC","sourcesContent":[":host {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.search {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 20px;\n\n    button {\n        margin: 0;\n    }\n\n    omedom-input {\n        flex: 1;\n    }\n}\n\n.list {\n    flex: 1;\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n    gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
