import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DocumentEntity, SocietyEntity } from '@omedom/data';
import { DocumentService } from '@omedom/services';

@Component({
    selector: 'omedom-society-profil-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class SocietyProfilDocumentsComponent implements OnInit {
    @Input()
    public society?: SocietyEntity;

    public documents: DocumentEntity[] = [];

    constructor(
        private readonly documentService: DocumentService,
        private readonly elementRef: ElementRef<HTMLElement>
    ) {}

    async ngOnInit(): Promise<void> {
        // Check if society is defined
        if (!this.society) {
            return;
        }

        // Get documents of the society
        this.documents = await this.documentService.getSocietyDocuments(this.society.uid);

        // Sort documents by date
        this.documents = this.documents.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });
    }

    public downloadDocument(document: DocumentEntity): void {
        // Check if document is defined
        if (!document) {
            return;
        }

        // Download the document
        const url = document.fileURL;

        // Create a link
        const link = window.document.createElement('a');
        link.href = url;
        link.download = document.name;
        link.target = '_blank';
        link.click();
    }
}
