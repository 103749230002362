import { Timestamp } from 'firebase/firestore';

import { RestEntity } from './rest.entity';

/**
 * @description Price of a licence per month (in euros)
 * @export
 * @const LICENCE_PRICE
 * @type {number}
 */
export const LICENCE_PRICE = 2.8;

/**
 * @description Licence entity
 * @author Jérémie Lopez
 * @export
 * @class LicenceEntity
 * @extends {RestEntity}
 */
export class LicenceEntity extends RestEntity {
    /**
     * @description Pro UID of the licence entity (uid of the pro who created the licence entity)
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LicenceEntity
     */
    proUID!: string;

    /**
     * @description User who use the licence
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LicenceEntity
     */
    userUID?: string;

    /**
     * @description Email user who use the licence
     * @author Jérémie Lopez
     * @type {string}
     * @memberof LicenceEntity
     */
    userEmail!: string;

    /**
     * @description If the licence is used or not
     * @author Jérémie Lopez
     * @type {boolean}
     * @memberof LicenceEntity
     */
    used?: boolean;

    /**
     * @description Date of the use of the licence (optional field) (if the licence is used, this field is filled with the date of the use of the licence, if not, this field is empty)
     * @author Jérémie Lopez
     * @type {Date}
     * @memberof LicenceEntity
     */
    usedAt?: Date;

    /**
     * @description Number of month of subscription created
     * @author Jérémie Lopez
     * @type {number}
     * @memberof LicenceEntity
     */
    numberOfMonths!: number;

    /**
     * @description Date that the licence was sended
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {Date}
     * @memberof LicenceEntity
     */
    sendedAt?: Date;

    /**
     * @description If the licence was seen by the user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/02/2024
     * @type {boolean}
     * @memberof LicenceEntity
     */
    seenByUser?: boolean;

    /**
     * @description if the licence was validated by the user
     * @author ANDRE Felix
     * @memberof LicenceEntity
     */
    validatedByClient!: boolean;

    /**
     * @description if the licence is waiting for client validation
     * @author ANDRE Felix
     * @type {boolean}
     * @memberof LicenceEntity
     */
    validationPending!: boolean;

    constructor(data?: Partial<LicenceEntity>) {
        super(data);

        if (this.sendedAt instanceof Timestamp) {
            this.sendedAt = new Date(this.sendedAt.seconds * 1000).toUTC();
        }

        if (this.usedAt instanceof Timestamp) {
            this.usedAt = new Date(this.usedAt.seconds * 1000).toUTC();
        }
    }

    /**
     * @description Get number of used licence in an array
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/01/2024
     * @static
     * @param {LicenceEntity[]} licences Array of licence entity
     * @returns {number}
     * @memberof LicenceEntity
     */
    public static getNumberOfUsedLicences(licences: LicenceEntity[]): number {
        return licences.filter((licence) => licence.used).length;
    }

    /**
     * @description Get number of shared licence in an array
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/01/2024
     * @static
     * @param {LicenceEntity[]} licences
     * @returns {number}
     * @memberof LicenceEntity
     */
    public static getNumberOfSharedLicences(licences: LicenceEntity[]): number {
        return licences.filter((licence) => !licence.used).length;
    }

    /**
     * @description Get price of licences per year and month
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @static
     * @param {number} numberOfLicence
     * @returns {{ pricePerMonth: number, pricePerYear: number; }}
     * @memberof LicenceEntity
     */
    public static getPriceOfLicences(numberOfLicence: number): {
        pricePerMonth: number;
        pricePerYear: number;
    } {
        const pricePerMonth = Math.round(LICENCE_PRICE * numberOfLicence * 100) / 100;

        // Round the price to 2 decimals
        const pricePerYear = Math.round(pricePerMonth * 12 * 100) / 100;

        return { pricePerMonth, pricePerYear };
    }

    /**
     * @description Has licence been refused by the client (now, Licence aren't deleted when refused by client)
     * @author ANDRE Felix
     * @static
     * @param {LicenceEntity} licence
     * @returns {*}
     * @memberof LicenceEntity
     */
    public static isLicenceRefusedByClient(licence: LicenceEntity) {
        const licencePending = licence.validationPending;
        const licenceValidate = licence.validatedByClient;

        if (!licencePending && !licenceValidate) {
            return true;
        }
        return false;
    }
}
