import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
    IncomeCategoryProperty,
    IncomeEntity,
    LeaseEntity,
    SelectOption,
    TenantEntity,
} from '@omedom/data';
import { IncomeService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-tenant-form',
    templateUrl: './tenant-form-modal.component.html',
    styleUrls: ['./tenant-form-modal.component.scss'],
})
export class TenantFormModalComponent implements OnInit {
    @Input({ required: true }) lease!: Partial<LeaseEntity>;
    @Input() tenant: Partial<TenantEntity> = {};

    public emailRegex: string = OmedomRegex.emailRegex;
    public phoneRegex = OmedomRegex.phoneRegex;
    public aid: IncomeEntity[] = [];

    /**
     * @description Display warrantor form after collapse
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public displayGarant = new BehaviorSubject<boolean>(false);
    /**
     * @description Display aid info after collapse
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public displayaid = new BehaviorSubject<boolean>(false);

    /**
     * @description Placeholder of select component
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public aidPlaceholder = {
        id: null,
        label: 'Choisir dans ma liste des aides',
    } as SelectOption;

    public selectedAid?: string;
    /**
     * @description List type of aid incomes in the select component
     * @author Hanane Djeddal
     * @memberof TenantComponent
     */
    public aidOptions: SelectOption[] = [] as SelectOption[];
    edit: boolean = false;
    afterAddAid: boolean = false;

    constructor(private incomeService: IncomeService, private modalController: ModalController) {}

    async ngOnInit(): Promise<void> {
        if (this.lease.propertyUID) {
            this.aid = await this.incomeService.getIncomesByCategoryByPropertyIds(
                IncomeCategoryProperty.aid,
                [this.lease.propertyUID]
            );
        }

        this.aidOptions = this.aid.map(
            (income) =>
                ({
                    id: income.uid,
                    label: `Aide ${income.designation ? '(' + income.designation + ')' : ''} ${
                        income.amount
                    }€`,
                    icon: 'uil uil-euro-circle',
                } as SelectOption)
        );

        if (!this.tenant.uid) {
            if (this.lease.uid) {
                this.tenant.leaseUID = this.lease.uid;
            }
        } else {
            this.selectedAid = this.aidOptions.find((x) => x.id === this.tenant.helpUID)?.id;
        }
    }

    public async saveTenant(): Promise<void> {
        if (this.tenant.helpUID || this.selectedAid) {
            this.tenant.helpUID = this.selectedAid ? this.selectedAid : '';
        }
        await this.modalController.dismiss('saved');
    }

    async dismiss(): Promise<void> {
        await this.modalController.dismiss('cancelled');
    }

    public async addAid(): Promise<void> {
        await this.modalController.dismiss({
            income: IncomeCategoryProperty.aid,
            lease: this.lease,
        });
    }
}
