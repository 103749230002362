import { DocumentEntity } from '@omedom/data';

export interface PDFCreatedDocument {
    documentURL: string;
    documentID: string;
    documentName?: string;
}
export interface PDFCreated {
    document: DocumentEntity;
    documentURL: string;
    documentName?: string;
}

export interface PDFResponse {
    success: boolean;
    data:
        | PDFResponseData // if PDFResponse.succes is true display the document
        | string; // if PDFResponse.succes is false display the error message
}

export interface PDFResponseData {
    document: {
        date: number;
        extension: string;
        extensionName: string;
        fileURL: string;
        fileName: string;
        name: string;
        propertyUID: string;
        clientUID: string;
        societyUID: string;
        userUID: string;
        type: string;
        weight: number;
    };
}

export enum PDFUsage {
    receiptAndRelaunch,
    propertyInfo,
    summaryClient,
}

export enum PDFFunctions {
    receiptAndRelaunch = 'generatePDFReceiptAndRelaunch',
    propertyInfo = 'generatePDFPropertyInformations',
    summaryClient = 'generatePDFSummaryClient',
}
