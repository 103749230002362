import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ChargeCategoryProperty, ChargeEntity, PropertyEntity } from '@omedom/data';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PropertyService } from './property.service';
import { RestService } from './rest.service';
import { SocietyService } from './society.service';

@Injectable({
    providedIn: 'root',
})
export class ChargeService extends RestService<ChargeEntity> {
    protected override builder = ChargeEntity;

    constructor(
        protected override firestore: AngularFirestore,
        protected propertyService: PropertyService,
        protected societyService: SocietyService
    ) {
        super(firestore, 'charges');
    }

    /**
     * @description Get all charges of a user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} userUID User uid
     * @returns {Promise<ChargeEntity[]>} Charges of the user
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getUserCharges(userUID);
     */
    public async getUserCharges(userUID: string): Promise<ChargeEntity[]> {
        // Get all properties of the user
        const properties = await this.propertyService.getUserPropertiesAndShared(userUID);

        const societies = await this.societyService.getUserSocietiesAndShared(userUID);

        // Get all charges of the properties
        const propertiesCharges = await Promise.all(
            properties.map((property) => this.getChargesByProperty(property.uid))
        );

        const societiesCharges = await Promise.all(
            societies.map((society) => this.getChargesBySociety(society.uid))
        );

        // Flatten the array
        const propertiesChargesFlatten = propertiesCharges.reduce(
            (acc, curr) => acc.concat(curr),
            []
        );
        const societiesChargesFlatten = societiesCharges.reduce(
            (acc, curr) => acc.concat(curr),
            []
        );

        // Remove duplicate charges
        const charges = [...propertiesChargesFlatten, ...societiesChargesFlatten].filter(
            (income, index, chargesArray) => {
                return (
                    chargesArray.findIndex((IcomeToCompare) => {
                        return IcomeToCompare.uid === income.uid;
                    }) === index
                );
            }
        );

        return charges;
    }

    public _getUserCharges(userUID: string): Observable<ChargeEntity[]> {
        const properties$ = this.propertyService._getUserPropertiesAndSharedAccessible(userUID);

        const societies$ = this.societyService._getUserSocietiesAndShared(userUID);

        return combineLatest([properties$, societies$]).pipe(
            switchMap(([properties, societies]) => {
                const chargesByProperties$ = properties.map((property) => {
                    if (!property?.uid) {
                        return of([] as ChargeEntity[]);
                    }
                    return this._getChargesByProperty(property.uid);
                });

                const chargesBySocieties$ = societies.map((society) => {
                    if (!society?.uid) {
                        return of([] as ChargeEntity[]);
                    }
                    return this._getChargesBySociety(society.uid);
                });

                const propertiesCharges$ =
                    chargesByProperties$ && chargesByProperties$.length > 0
                        ? combineLatest(chargesByProperties$).pipe(map((charges) => charges.flat()))
                        : of([] as ChargeEntity[]);

                const societiesCharges$ =
                    chargesBySocieties$ && chargesBySocieties$.length > 0
                        ? combineLatest(chargesBySocieties$).pipe(map((charges) => charges.flat()))
                        : of([] as ChargeEntity[]);

                return combineLatest([propertiesCharges$, societiesCharges$]).pipe(
                    map(([propertiesCharges, societiesCharges]) => {
                        const allCharges = [...propertiesCharges, ...societiesCharges];
                        return allCharges.filter((charge, index, chargesArray) => {
                            return (
                                chargesArray.findIndex((chargeToCompare) => {
                                    return chargeToCompare.uid === charge.uid;
                                }) === index
                            );
                        });
                    })
                );
            })
        );
    }

    /**
     * @description Get charge by property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} propertyUID Property uid
     * @returns {Promise<ChargeEntity[]>} Charges of the property
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getChargesByProperty(propertyUID);
     */
    public async getChargesByProperty(propertyUID: string): Promise<ChargeEntity[]> {
        return await this.search([{ where: 'propertyUID', operator: '==', value: propertyUID }]);
    }

    /**
     * @description Get charges by property UID (Observable version)
     * @author Brisset Killian
     * @date 23/05/2024
     * @param {string} propertyUID
     * @returns {*}  {Observable<ChargeEntity[]>}
     * @memberof ChargeService
     * @example
     * const charges$ = chargeService._getChargesByProperty(propertyUID);
     */
    public _getChargesByProperty(propertyUID: string): Observable<ChargeEntity[]> {
        return this._search([{ where: 'propertyUID', operator: '==', value: propertyUID }]);
    }

    /**
     * @description Get charges by properties UID (Observable version)
     * @author Brisset Killian
     * @date 18/06/2024
     * @param {string[]} propertyUIDs
     * @returns {*}  {Observable<ChargeEntity[]>}
     * @memberof ChargeService
     */
    public _getChargesByProperties(propertyUIDs: string[]): Observable<ChargeEntity[]> {
        if (!propertyUIDs || propertyUIDs.length < 1) {
            return of([]);
        }

        const properties$ = propertyUIDs.map((propertyUID) => {
            if (!propertyUID) {
                return of([] as ChargeEntity[]);
            }

            return this._getChargesByProperty(propertyUID);
        });

        return combineLatest(properties$).pipe(
            map((charges) => {
                const allCharges = charges.flat();

                const filteredCharges = allCharges.filter((charge) => charge?.uid);

                return filteredCharges.filter((charge, index, chargesArray) => {
                    return (
                        chargesArray.findIndex((chargeToCompare) => {
                            return chargeToCompare.uid === charge.uid;
                        }) === index
                    );
                });
            })
        );
    }

    /**
     * @description Get Charge of user properties and also charge of shared properties
     * @author ANDRE Felix
     * @param {string} userUID
     * @param {ChargeCategoryProperty} category
     * @returns {*}  {Promise<ChargeEntity[]>}
     * @memberof ChargeService
     */
    public async getUserAndShareChargesByCategory(
        userUID: string,
        category: ChargeCategoryProperty
    ): Promise<ChargeEntity[]> {
        const userAndShareCharges = this.getUserCharges(userUID);
        const filterByCategory = (await userAndShareCharges).filter(
            (charge) => charge.category === category
        );
        return filterByCategory;
    }

    /**
     * @description Get charges of a user by category
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} userUID User uid
     * @param {ChargeCategoryProperty} category Category of the charge
     * @returns {Promise<ChargeEntity[]>} Charges by category of the user
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getUserChargesByCategory(userUID, ChargeCategoryProperty.Electricity);
     */
    public async getUserChargesByCategory(
        userUID: string,
        category: ChargeCategoryProperty
    ): Promise<ChargeEntity[]> {
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'category', operator: '==', value: category },
        ]);
    }

    /**
     * @description Get charges of a user and property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} userUID User uid
     * @param {string} propertyUID Property uid
     * @returns {Promise<ChargeEntity[]>} Charges of the user and property
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getUserChargesByProperty(userUID, propertyUID);
     */
    public async getUserChargesByProperty(
        userUID: string,
        propertyUID: string
    ): Promise<ChargeEntity[]> {
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'propertyUID', operator: '==', value: propertyUID },
        ]);
    }

    /**
     * @description Get charges of society
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} societyUID Society uid
     * @returns {Promise<ChargeEntity[]>} Charges of the society
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getChargesBySociety(societyUID);
     */
    public async getChargesBySociety(societyUID: string): Promise<ChargeEntity[]> {
        // Get all properties of the society
        const properties = await this.propertyService.search([
            { where: 'societyUID', operator: '==', value: societyUID },
        ]);

        const societyCharges = await this.search([
            { where: 'societyUID', operator: '==', value: societyUID },
        ]);

        // Get all charges of the properties
        const propertiesCharges = await Promise.all(
            properties.map((property) => this.getChargesByProperty(property.uid))
        );

        const propertiesChargesFlatten = propertiesCharges.reduce(
            (acc, curr) => acc.concat(curr),
            []
        );

        // Remove duplicate charges
        const charges = [...propertiesChargesFlatten, ...societyCharges].filter(
            (income, index, chargesArray) => {
                return (
                    chargesArray.findIndex((IcomeToCompare) => {
                        return IcomeToCompare.uid === income.uid;
                    }) === index
                );
            }
        );

        return charges;

        // // Flatten the array
        // return [...societyCharges, ...propertiesChargesFlatten];
    }

    public _getChargesBySociety(societyUID: string): Observable<ChargeEntity[]> {
        return this._search([{ where: 'societyUID', operator: '==', value: societyUID }]);
    }

    /**
     * @description Get charges of a user and society
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} userUID User uid
     * @param {string} societyUID Society uid
     * @returns {Promise<ChargeEntity[]>} Charges of the user and society
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getUserChargesBySociety(userUID, societyUID);
     */
    public async getUserChargesBySociety(
        userUID: string,
        societyUID: string
    ): Promise<ChargeEntity[]> {
        // Get all properties of the society
        const properties = await this.propertyService.search([
            { where: 'societyUID', operator: '==', value: societyUID },
        ]);

        // Get all charges of the properties and user
        const charges = await Promise.all(
            properties.map((property) => this.getUserChargesByProperty(userUID, property.uid))
        );

        // Flatten the array
        return charges.reduce((acc, curr) => acc.concat(curr), []);
    }

    /**
     * @description Get charges of list of properties
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string[]} propertyUIDs List of property uid
     * @returns {Promise<ChargeEntity[]>} Charges of list of properties
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getChargesByPropertyIds(propertyUIDs);
     */
    public async getUserChargesByPropertyIds(propertyUIDs: string[]): Promise<ChargeEntity[]> {
        if (!propertyUIDs.length) return [];
        return await this.search([{ where: 'propertyUID', operator: 'in', value: propertyUIDs }]);
    }

    /**
     * @description Retuen all charges of societies
     * @author ANDRE Felix
     * @param {string[]} societyUIDs
     * @returns {*}  {Promise<ChargeEntity[]>}
     * @memberof ChargeService
     */
    public async getUserChargesBySocietyIds(societyUIDs: string[]): Promise<ChargeEntity[]> {
        if (!societyUIDs.length) return [];
        return await this.search([{ where: 'societyUID', operator: 'in', value: societyUIDs }]);
    }

    /**
     * @description Get charges of a user and list of properties by category
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {string} userUID User uid
     * @param {ChargeCategoryProperty} category Category of the charge
     * @param {string[]} propertyUIDs List of property uid
     * @returns {Promise<ChargeEntity[]>} Charges by category of the user and properties
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getUserChargesByCategoryByPropertyIds(userUID, category, propertyUIDs);
     */
    public async getUserChargesByCategoryByPropertyIds(
        userUID: string,
        category: ChargeCategoryProperty,
        propertyUIDs: string[]
    ): Promise<ChargeEntity[]> {
        if (!propertyUIDs.length) return [];
        return await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { where: 'category', operator: '==', value: category },
            { where: 'propertyUID', operator: 'in', value: propertyUIDs },
        ]);
    }

    /**
     * @description Get charges of a user and list of properties by category and property ids
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @param {ChargeCategoryProperty} category Category of the charge
     * @param {string[]} propertyUIDs List of property uid
     * @returns {Promise<ChargeEntity[]>} Charges by category of properties
     * @memberof ChargeService
     * @example
     * const charges = await chargeService.getChargesByCategoryByPropertyIds(category, propertyUIDs);
     */
    public async getChargesByCategoryByPropertyIds(
        category: ChargeCategoryProperty,
        propertyIds: string[]
    ): Promise<ChargeEntity[]> {
        if (!propertyIds.length) return [];
        return await this.search([
            { where: 'category', operator: '==', value: category },
            { where: 'propertyUID', operator: 'in', value: propertyIds },
        ]);
    }

    /**
     * @description Get charges of a user and list of societies by category and society ids
     * @param {ChargeCategoryProperty} category
     * @param {string[]} societyIds
     * @returns {Promise<ChargeEntity[]>}
     * @memberof ChargeService
     */
    public async getChargesByCategoryBySocietyIds(
        category: ChargeCategoryProperty,
        societyIds: string[]
    ): Promise<ChargeEntity[]> {
        if (!societyIds.length) return [];
        return await this.search([
            { where: 'category', operator: '==', value: category },
            { where: 'societyUID', operator: 'in', value: societyIds },
        ]);
    }

    /**
     * @description Get all income of a building and its lots
     * @author ANDRE Felix
     * @param {PropertyEntity} buildings
     * @returns {*}
     * @memberof ChargeService
     */
    public async getChargesByBuildingsLots(building: PropertyEntity): Promise<ChargeEntity[]> {
        const buildingCharges = await this.search([
            {
                where: 'propertyUID',
                operator: '==',
                value: building.uid,
            },
        ]);
        if (!building.lotsUID || building.lotsUID.length === 0) {
            return buildingCharges;
        }
        const lots = await Promise.all(
            building.lotsUID.map(async (lotUid) => {
                if (!lotUid) {
                    return;
                }
                try {
                    const propertyLot = await this.propertyService.get(lotUid);

                    if (!propertyLot?.uid) {
                        return;
                    }
                    return await this.search([
                        {
                            where: 'propertyUID',
                            operator: '==',
                            value: lotUid,
                        },
                    ]);
                } catch (err) {
                    console.error(err);
                    return;
                }
            })
        );

        const flatLots = lots.flat().filter((x) => x);
        return [...buildingCharges, ...flatLots] as ChargeEntity[];
    }
}
