// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

h3 {
  display: flex;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: stretch;
  align-items: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRvY3VtZW50LXNvcnQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxhQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsMkJBQUE7QUFDSjs7QUFFQTtFQUNJLGtCQUFBO0VBQ0EsU0FBQTtFQUNBLFdBQUE7RUFDQSxlQUFBO0FBQ0o7O0FBRUE7RUFDSSxhQUFBO0VBQ0EsbUJBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7RUFDQSx3QkFBQTtFQUNBLG1CQUFBO0FBQ0oiLCJmaWxlIjoiZG9jdW1lbnQtc29ydC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBwYWRkaW5nOiAyMHB4O1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG59XG5cbi5jbG9zZSB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHRvcDogMjBweDtcbiAgICByaWdodDogMjBweDtcbiAgICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbmgzIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG59XG5cbi5idXR0b24tY29udGFpbmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGp1c3RpZnktY29udGVudDogc3RyZXRjaDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./libs/ui/src/lib/components/document-sort/document-sort.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,wBAAA;EACA,mBAAA;AACJ;AACA,48BAA48B","sourcesContent":[":host {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}\n\n.close {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    cursor: pointer;\n}\n\nh3 {\n    display: flex;\n    align-items: center;\n}\n\n.button-container {\n    display: flex;\n    justify-content: stretch;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
