import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TenantEntity } from '@omedom/data';
import { FileService } from '@omedom/services';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'omedom-pdf-preview',
    templateUrl: './pdf-preview.component.html',
    styleUrls: ['./pdf-preview.component.scss'],
})
export class PdfPreviewComponent implements OnInit {
    @Input() fileURL = '';
    tenant?: TenantEntity;

    public sanitizeURL?: SafeResourceUrl;

    public isAndroid = this.platform.is('android') || false;

    public sanitized$ = new BehaviorSubject<boolean>(false);

    constructor(
        private fileService: FileService,
        private platform: Platform,
        private sanitizer: DomSanitizer
    ) { }

    async ngOnInit() {
        if (this.fileURL) {
            this.sanitizeURL = await this.sanitizer.bypassSecurityTrustResourceUrl(
                this.fileURL + '#toolbar=0'
            );
            this.sanitized$.next(true);
        }
    }

    public async displayPDF(url: string): Promise<void> {
        await this.fileService.displayPDF(url);
    }
}
