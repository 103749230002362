import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ViewDidEnter } from '@ionic/angular';
import {
    ChargeEntity,
    IncomeEntity,
    PropertiesFilter,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import { AnalyticsService, RefreshService, UserService } from '@omedom/services';
import { LiquidityComponent } from '@omedom/ui';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-treasury-liquidity',
    templateUrl: './treasury-liquidity.component.html',
    styleUrls: ['./treasury-liquidity.component.scss'],
})
export class TreasuryLiquidityComponent implements OnChanges, OnDestroy, ViewDidEnter {
    @ViewChild('appLiquidity') appLiquidity: LiquidityComponent;

    @Input() charges: ChargeEntity[] = [];

    @Input() incomes: IncomeEntity[] = [];

    filteredCharges: ChargeEntity[] = [];

    filteredIncomes: IncomeEntity[] = [];

    private refreshSubscription: Subscription;

    private subscription: Subscription;

    selectedProperties: SelectOption[] = [];

    selectedSocieties: SelectOption[] = [];

    user: UserEntity;

    @Input() properties: PropertyEntity[] = [];

    @Input() societies: SocietyEntity[] = [];

    @Input() hideFilter: boolean = false;

    constructor(
        private userService: UserService,
        private refreshService: RefreshService,
        private analyticsService: AnalyticsService,
        private router: Router
    ) {}

    ngOnChanges(): void {
        this.selectedProperties = PropertiesFilter.getFilteredProperties();
        this.selectedSocieties = PropertiesFilter.getFilteredSocieties();

        this.refreshSubscription = this.refreshService.viewDidEnter$.subscribe(() =>
            this.updateData()
        );
        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.user = user;
            this.updateData();
        });
    }

    ngOnDestroy(): void {
        this.refreshSubscription?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    ionViewDidEnter(): void {
        this.refreshService.viewDidEnter$.next();
        this.analyticsService.setCurrentScreen('Treasury liquidity graph');
    }

    private async updateData() {
        if (!this.user) {
            return;
        }

        this.selectedProperties = PropertiesFilter.getFilteredProperties();
        this.selectedSocieties = PropertiesFilter.getFilteredSocieties();

        const isFilteredUsed = this.selectedProperties.length || this.selectedSocieties.length;

        let propertiesUids: string[] = [];

        let societiesUids: string[] = [];

        if (isFilteredUsed) {
            propertiesUids = this.selectedProperties.map((x) => x.id);
            societiesUids = this.selectedSocieties.map((x) => x.id);
        } else {
            propertiesUids = this.properties.map((x) => x.uid);
            societiesUids = this.societies.map((x) => x.uid);
        }

        this.filteredCharges = this.charges.filter(
            (x) => propertiesUids.includes(x.propertyUID) || societiesUids.includes(x.societyUID)
        );

        this.filteredIncomes = this.incomes.filter(
            (x) => propertiesUids.includes(x.propertyUID) || societiesUids.includes(x.societyUID)
        );

        this.appLiquidity.charges = this.filteredCharges;
        this.appLiquidity.incomes = this.filteredIncomes;
    }

    async updateFilter() {
        this.updateData();
    }

    forecastClicked() {
        // /tabs/menu/subscription/manage
        this.router.navigate(['/tabs/menu/subscription/manage']);
    }
}
