import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[appOmedomNumber]' })
export class OmedomNumberDirective {
    private ionInput: any;

    constructor(element: ElementRef) {
        this.ionInput = element.nativeElement;
    }

    @HostListener('keypress', ['$event'])
    onInput(event: any) {
        const pattern = /^\d+,?\d*$/;
        const inputChar = String.fromCharCode(
            event.which ? event.which : event.keyCode
        );
        const value = this.ionInput.getElementsByTagName('input')[0].value;
        const regex = new RegExp(' ', 'g');
        const fullValue = `${value}${inputChar}`.replace(regex, '');

        if (!pattern.test(fullValue)) {
            // invalid character, prevent input
            event.preventDefault();
            return false;
        }

        return true;
    }
}
