import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { OmedomEnvironment } from '@omedom/environment';
import { UserService } from '@omedom/services';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(private userService: UserService, private router: Router) {}

    // canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //     return (
    //         this.userService.user$.pipe(
    //             map((user) => {
    //                 if (user) {
    //                     return true;
    //                 } else {
    //                     const firstVisit = window.localStorage.getItem('firstVisit');
    //                     if (firstVisit === 'true' || firstVisit === null) {
    //                         this.router.navigate(['/user/welcome']);
    //                         return false;
    //                     } else {
    //                         this.router.navigate(['/user/auth']);
    //                         return false;
    //                     }
    //                 }
    //             })
    //         ) ?? false
    //     );
    // }

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userService.user$.pipe(
            map((user) => {
                if (!user) {
                    return false;
                }
                if (user.proUID && OmedomEnvironment.currentEnvionment === 'production') {
                    return false;
                }
                return true;
            }),
            tap((canActivate) => {
                if (canActivate) {
                    return true;
                } else {
                    const firstVisit = window.localStorage.getItem('firstVisit');
                    if (firstVisit === 'true' || firstVisit === null) {
                        this.router.navigate(['/user/welcome']);
                        return false;
                    } else {
                        this.router.navigate(['/user/auth']);
                        return false;
                    }
                }
            })
        );
    }
}
