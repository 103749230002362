// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

h2,
p {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

p {
  margin-top: 0;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVwZGF0ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGFBQUE7RUFDQSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSwyQkFBQTtBQUNKOztBQUVBOztFQUVJLFdBQUE7RUFDQSxrQkFBQTtFQUNBLG1CQUFBO0VBQ0EsZ0JBQUE7QUFDSjs7QUFFQTtFQUNJLGFBQUE7QUFDSiIsImZpbGUiOiJ1cGRhdGUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgcGFkZGluZzogMjBweDtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAganVzdGlmeS1jb250ZW50OiBmbGV4LXN0YXJ0O1xufVxuXG5oMixcbnAge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICBtYXJnaW4tYm90dG9tOiAyMHB4O1xuICAgIG1hcmdpbi10b3A6IDEwcHg7XG59XG5cbnAge1xuICAgIG1hcmdpbi10b3A6IDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/common/update/update.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACJ;;AAEA;;EAEI,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;AACA,wtBAAwtB","sourcesContent":[":host {\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}\n\nh2,\np {\n    width: 100%;\n    text-align: center;\n    margin-bottom: 20px;\n    margin-top: 10px;\n}\n\np {\n    margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
