import { DatePipe, formatDate } from '@angular/common';
import { Injectable, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    Category,
    CategoryInfo,
    ChargeEntity,
    ChargeFuturPayment,
    ChargeHistory,
    ChargePeriodicity,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomeFuturPayment,
    IncomeHistory,
    IncomePeriodicity,
    SelectOption,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomTreasury } from '@omedom/utils';
import { OmedomFormatNumber, OmedomNumberPipe } from '@omedom/ui';
import { Timestamp } from 'firebase/firestore';

import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChargeUpdateType, IncomeUpdateType } from '@omedom/data';
import { HeaderPreviousType } from '../../component/header';
import { OmedomRadioOption } from '@omedom/ui';
import { FormAssociateToComponent } from '@omedom/ui';
@Injectable()
export abstract class TreasuryEdit<TEntity extends ChargeEntity | IncomeEntity, TCategory>
    implements OnInit, OnDestroy
{
    /**
     * @description use to get the data from assotiation input
     * @author ANDRE Felix
     * @type {QueryList<FormAssociateToComponent>}
     * @memberof ChargeFormPage
     */
    @ViewChildren(FormAssociateToComponent) formAssociateTos: QueryList<FormAssociateToComponent>;

    /**
     * @description This is the charge or income
     * @author ANDRE Felix
     * @type {TEntity}
     * @memberof TreasuryEdit
     */
    treasury: TEntity;

    currentDate: Date;

    debitDate: string;

    startDate: string;

    endDate: string;

    displayDebitDate;

    amount: string;

    rentWithCharges?: string;

    notes?: string;

    selectedPeriodicity: SelectOption;

    periodicityPlaceholder = { label: 'Périodicité' } as SelectOption;

    periodicityOptions$ = of([]);

    selectedProperty: SelectOption;

    propertyPlaceholder = {
        label: 'Sélectionner',
        icon: 'uil uil-home',
    } as SelectOption;

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    selectedTreasuryCategory: CategoryInfo<TCategory>;

    headerPreviousType = HeaderPreviousType;

    updateType: ChargeUpdateType | IncomeUpdateType;

    notificationRadioOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    private subscription: Subscription;

    private user: UserEntity;

    protected constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private numberPipe: OmedomNumberPipe,
        private datePipe: DatePipe,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        const treasuryUid$ = this.activatedRoute.paramMap.pipe(
            map((queryParams) => queryParams.get('uid'))
        );

        const treasuryDate$ = this.activatedRoute.paramMap.pipe(
            map((queryParams) => new Date(queryParams.get('date')))
        );

        const type$ = this.activatedRoute.paramMap.pipe(
            map((queryParams) => +queryParams.get('type') as ChargeUpdateType | IncomeUpdateType)
        );

        const user$ = this.userService.user$;

        this.subscription = combineLatest([treasuryUid$, treasuryDate$, user$, type$]).subscribe(
            async ([treasuryUid, date, user, type]) => {
                this.user = user;
                this.currentDate = date;
                this.updateType = type;

                const treasury = await this.getTreasury(treasuryUid);
                const societiesOptions = await this.societyService.getUserSocietiesAndSharedOptions(
                    user.uid
                );
                const propertieOptions = (
                    await this.propertyService.getUserPropertiesAndSharedOptions(user.uid)
                ).filter((x) => x.isAccesible);

                const assetsOptions = [...propertieOptions, ...societiesOptions];
                this.propertyOptions$.next(assetsOptions);
                this.selectedProperty = assetsOptions.find((x) => {
                    return x.id === treasury.propertyUID || x.id === treasury.societyUID;
                });

                this.selectedTreasuryCategory = this.getCategoryInfo(treasury.category);
                this.startDate = treasury.startDate
                    ? this.datePipe.transform(treasury.startDate.toDate(), 'YYYY-MM-dd')
                    : undefined;
                this.endDate = treasury.endDate
                    ? this.datePipe.transform(treasury.endDate.toDate(), 'YYYY-MM-dd')
                    : undefined;

                const history = treasury.history?.find((x) =>
                    x.date
                        .toDate()
                        .between(
                            this.currentDate.getUTCFirstDayOfMonth(),
                            this.currentDate.getUTCLastDayOfMonth()
                        )
                );

                const debitDateRaw = history
                    ? history.date.toDate()
                    : OmedomTreasury.getMonthHistoryDate(treasury, this.currentDate);

                this.debitDate = this.datePipe.transform(debitDateRaw, 'y-MM-dd');

                this.amount = this.numberPipe.transform(
                    +OmedomTreasury.getMonthAmount(treasury, this.currentDate).toString()
                );

                // get rentWithCharges only for rent icomes
                if (treasury.category === IncomeCategoryProperty.rent) {
                    this.rentWithCharges =
                        this.numberPipe.transform(
                            +OmedomTreasury.getMonthRentWithCharges(
                                treasury,
                                this.currentDate
                            ).toString()
                        ) || null;
                }

                this.notes = OmedomTreasury.getMonthNotes(treasury, this.currentDate);

                const periodicityOptions = this.buildPeriodicityOptions(treasury);

                this.periodicityOptions$ = of(periodicityOptions);
                this.selectedPeriodicity = periodicityOptions.find(
                    (x) => x.id === treasury.periodicity
                );

                this.treasury = treasury;

                if (treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual) {
                    this.displayDebitDate = debitDateRaw.getDate().toString();
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    formatAmountNumber(newValue: string, type?: string) {
        OmedomFormatNumber.formatOmedomInput(newValue, this.numberPipe, type || 'amount');
    }

    async submit(): Promise<void> {
        // If priodicity monthly the debit date is just the day.
        if (this.treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual) {
            this.displayDebitDate = this.displayDebitDate === '31' ? '30' : this.displayDebitDate;
            let datefromstart = new Date(new Date(this.startDate).setDate(+this.displayDebitDate));
            // if Month day invalid (31 for a month with only 30days, it passes to the first day of the next month)
            if (new Date(this.startDate).getMonth() !== datefromstart.getMonth()) {
                datefromstart = new Date(datefromstart.setDate(+this.displayDebitDate)); //correct the day
            }
            this.debitDate = formatDate(datefromstart, 'YYYY-MM-dd', 'fr');
        }
        const debitDate = new Date(this.debitDate).toUTC();
        const now = new Date().toUTC();

        this.treasury.associatedTo = this.getAssociatedData();

        if (this.treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual) {
            this.updatePunctual(debitDate);
        } else {
            switch (this.updateType) {
                case ChargeUpdateType.onlyThisOne:
                case IncomeUpdateType.onlyThisOne:
                    this.updateOnlyThisOne(debitDate, now);
                    break;

                case ChargeUpdateType.thisOneAndNext:
                case IncomeUpdateType.thisOneAndNext:
                    this.updateThisOneAndNext(debitDate, now);
                    break;

                case ChargeUpdateType.all:
                case IncomeUpdateType.all:
                    this.updateAllSeries(debitDate, now);
                    break;
            }
        }
        this.treasury.futurPayment?.sort(
            (a, b) => a.date.toDate().getTime() - b.date.toDate().getTime()
        );
        await this.updateEntity(this.treasury);
    }

    getAssociatedData() {
        if (!this.formAssociateTos) {
            return [];
        }
        const associateInputData = [];
        this.formAssociateTos.forEach((formAssociateTo) => {
            associateInputData.push(formAssociateTo.getInputData());
        });
        return associateInputData;
    }

    private updatePunctual(debitDate: Date): void {
        // update amount
        this.treasury.amount = Math.abs(+this.numberPipe.parse(this.amount.toString()));

        // update notes only if not empty
        if (this.notes) {
            this.treasury.notes = this.notes;
        }

        // update rentWithCharges only for rent incomes
        if (this.treasury.category === IncomeCategoryProperty.rent && this.rentWithCharges) {
            (this.treasury as IncomeEntity).rentWithCharges = Math.abs(
                +this.numberPipe.parse(this.rentWithCharges.toString())
            );
        }

        this.treasury.periodicity = this.selectedPeriodicity.id;
        this.treasury.debitDate = Timestamp.fromDate(debitDate);
    }

    private updateAllSeries(debitDate: Date, now: Date): void {
        this.treasury.history = [];
        this.treasury.futurPayment = [];

        if (debitDate.getTime() >= now.getUTCDateWithoutTime().getTime()) {
            this.addFuturHistory(debitDate, now, true);
        } else {
            // update amount
            this.treasury.amount = Math.abs(+this.numberPipe.parse(this.amount.toString()));

            // update notes only if not empty
            if (this.notes) {
                this.treasury.notes = this.notes;
            }

            // update rentWithCharges only for rent incomes
            if (this.treasury.category === IncomeCategoryProperty.rent && this.rentWithCharges) {
                (this.treasury as IncomeEntity).rentWithCharges = Math.abs(
                    +this.numberPipe.parse(this.rentWithCharges.toString())
                );
            }

            this.treasury.periodicity = this.selectedPeriodicity.id;

            OmedomTreasury.calculateDateAndCalculateHistory(
                this.treasury,
                this.debitDate,
                this.startDate,
                this.endDate
            );
        }
    }
    /**
     * @description
     * @author Jérémie Lopez
     * @private
     * @param {Date} debitDate
     * @param {Date} now
     * @memberof TreasuryEdit
     */
    private updateOnlyThisOne(debitDate: Date, now: Date): void {
        // Find history for this charge or income for selected month
        const history = this.treasury?.history
            ? this.treasury?.history.find(
                  (x) => x.date.toDate().getTime() === this.currentDate.getTime()
              )
            : null;
        // Find futurPayment for this charge or income for selected month
        this.treasury.futurPayment = this.treasury.futurPayment
            ?.filter((x) => {
                const date = new Date((x.date as any).seconds * 1000);
                return date.getTime() > new Date().getDateWithoutTime().getTime();
            })
            .map((x) => {
                const date = new Date((x.date as any).seconds * 1000);
                return date.getTime() === this.currentDate.getTime() ? null : x;
            })
            .filter((x) => x !== null);

        const startHistoryDate = new Date(this.currentDate.setDate(debitDate.getDate()));
        if (history) {
            // On est dans le passé, on modifie l'historique ou on incrémente la prochaine date de débit.
            const index = this.treasury.history.findIndex((ch) => ch.date === history.date);

            if (debitDate.getTime() >= now.getUTCDateWithoutTime().getTime()) {
                // Si la date de débit est dans le futur et qu'on avait un historique pour ce mois, on le supprime et on met à jour la date du prochain historique
                this.treasury.history.splice(index, 1);
                this.treasury.nextHistoryDate = Timestamp.fromDate(debitDate);
                this.addFuturHistory(debitDate, now, false);
            } else {
                // Sinon on met à jour l'historique
                const currentHistory = this.treasury.history[index];

                // update date
                currentHistory.date = Timestamp.fromDate(startHistoryDate);

                // update amount
                currentHistory.amount = Math.abs(+this.numberPipe.parse(this.amount.toString()));

                // update notes only if not empty
                if (this.notes) {
                    currentHistory.notes = this.notes;
                }

                // update rentWithCharges only for rent incomes
                if (this.treasury.category === IncomeCategoryProperty.rent) {
                    (currentHistory as IncomeHistory).rentWithCharges = Math.abs(
                        +this.numberPipe.parse(this.rentWithCharges.toString())
                    );
                }
                this.treasury.history[index] = currentHistory;
            }
        } else {
            // Il n'y a pas d'historique, donc on est dans le futur

            // 1 - Vérifier isForNext = true à la date à modifier, si oui on recherche un slot libre après cette date pour transférer le futur payment

            // search future paiement for the month to update
            this.treasury.futurPayment?.find((x) => {
                x.date.toDate().getFirstDayOfMonth().getTime() ===
                    startHistoryDate.getFirstDayOfMonth().getTime();
            });

            if (debitDate.getTime() > now.getUTCDateWithoutTime().getTime()) {
                this.addFuturHistory(debitDate, now, false);
            } else if (debitDate.getTime() === now.getUTCDateWithoutTime().getTime()) {
                this.addFuturHistory(startHistoryDate, now, false);
            } else {
                if (startHistoryDate.getTime() >= now.getUTCDateWithoutTime().getTime()) {
                    this.addFuturHistory(startHistoryDate, now, false);
                } else {
                    this.treasury.nextHistoryDate = Timestamp.fromDate(debitDate);
                    const history: ChargeHistory | IncomeHistory = {
                        amount: Math.abs(+this.numberPipe.parse(this.amount.toString())),
                        date: Timestamp.fromDate(startHistoryDate),
                        isPayed: true,
                        isReaded: true,
                        isDeleted: false,
                    };

                    // update notes only if not empty
                    if (this.notes) {
                        history.notes = this.notes;
                    }

                    // update rentWithCharges only for rent incomes
                    if (this.treasury.category === IncomeCategoryProperty.rent) {
                        (history as IncomeHistory).rentWithCharges = Math.abs(
                            +this.numberPipe.parse(this.rentWithCharges.toString())
                        );
                    }

                    // Add history
                    this.treasury.history.push(history);
                }
            }
        }
    }

    private updateThisOneAndNext(debitDate: Date, now: Date): void {
        this.treasury.history
            ?.filter((x) => x.date.toDate().getTime() >= this.currentDate.getTime())
            .forEach((h) => {
                const index = this.treasury.history.findIndex((ch) => ch.date === h.date);

                if (index >= 0) {
                    this.treasury.history.splice(index, 1);
                    this.treasury.nextHistoryDate = Timestamp.fromDate(debitDate);
                }
            });

        this.treasury.futurPayment
            ?.filter((x) => x.date.toDate().getTime() >= this.currentDate.getTime())
            .forEach((h) => {
                const index = this.treasury.futurPayment.findIndex((ch) => ch.date === h.date);

                if (index >= 0) {
                    this.treasury.futurPayment.splice(index, 1);
                }
            });

        // Add end date if exist
        if (!!this.endDate) {
            const endDateUtc = new Date(this.endDate).toUTC();
            this.treasury.endDate = Timestamp.fromDate(endDateUtc);
        }

        const startHistoryDate = new Date(this.currentDate.setDate(debitDate.getDate()));
        if (debitDate.getTime() >= now.getUTCDateWithoutTime().getTime()) {
            this.addFuturHistory(debitDate, now, true);
        } else {
            if (startHistoryDate.getTime() >= now.getUTCDateWithoutTime().getTime()) {
                this.addFuturHistory(startHistoryDate, now, true);
            } else {
                // update amount
                this.treasury.amount = Math.abs(+this.numberPipe.parse(this.amount.toString()));

                // update periodicity
                this.treasury.periodicity = this.selectedPeriodicity.id;

                // update notes only if not empty
                if (this.notes) {
                    this.treasury.notes = this.notes;
                }

                // update rentWithCharges only for rent incomes
                if (
                    this.treasury.category === IncomeCategoryProperty.rent &&
                    this.rentWithCharges
                ) {
                    (this.treasury as IncomeEntity).rentWithCharges = Math.abs(
                        +this.numberPipe.parse(this.rentWithCharges.toString())
                    );
                }

                OmedomTreasury.calculateDateAndCalculateHistory(
                    this.treasury,
                    startHistoryDate.toString(),
                    this.startDate,
                    this.endDate,
                    true
                );
                const debitDateUtc = new Date(debitDate).toUTC();
                this.treasury.debitDate = Timestamp.fromDate(debitDateUtc);
            }
        }
    }

    private addFuturHistory(debitDate: Date, now: Date, isForNext: boolean): void {
        if (!this.treasury.futurPayment) {
            this.treasury.futurPayment = [];
        }
        let futurHistory: ChargeFuturPayment | IncomeFuturPayment = {
            date: Timestamp.fromDate(debitDate),
            amount: Math.abs(+this.numberPipe.parse(this.amount.toString())),
            isForNext,
            isDeleted: false,
        };

        // update notes only if not empty
        if (this.notes) {
            futurHistory.notes = this.notes.toString();
        }

        // update rentWithCharges only for rent incomes
        if (this.treasury.category === IncomeCategoryProperty.rent && this.rentWithCharges) {
            (futurHistory as IncomeFuturPayment).rentWithCharges = Math.abs(
                +this.numberPipe.parse(this.rentWithCharges.toString())
            );
        }

        // Delete futur history if already exist
        const index = this.treasury.futurPayment.findIndex((ch) => ch.date === futurHistory.date);
        if (index >= 0) {
            this.treasury.futurPayment.splice(index, 1);
        }

        // update the treasuryin database
        this.treasury.futurPayment.push(futurHistory);

        if (debitDate.getFirstDayOfMonth().getTime() === now.getFirstDayOfMonth().getTime()) {
            this.treasury.nextHistoryDate = Timestamp.fromDate(debitDate);
        }
    }

    abstract getTreasury(treasuryUid: string): Promise<TEntity>;

    abstract buildPeriodicityOptions(treasury: TEntity): SelectOption[];

    abstract getCategoryInfo(category: string): CategoryInfo<TCategory>;

    abstract updateEntity(treasury: Partial<TEntity>): Promise<void>;
}
