import { formatDate } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
    ChargeCategoryProperty,
    ChargeCategoryInfo,
    ChargeEntity,
    IncomeCategoryProperty,
    IncomeCategoryInfo,
    IncomeEntity,
    UserEntity,
} from '@omedom/data';
import { PropertyService } from '@omedom/services';
import { OmedomTreasury } from '@omedom/utils';

import { OmedomDateType } from '../../../component/date';

@Component({
    selector: 'app-widget-next-movements',
    templateUrl: './widget-next-movements.component.html',
    styleUrls: ['./widget-next-movements.component.scss'],
})
export class WidgetNextMovementsComponent implements OnChanges {
    @Input() incomes: IncomeEntity[] = [];

    @Input() charges: ChargeEntity[] = [];

    @Input() user: UserEntity;

    @Input() currentDate: Date;

    omedomDateType = OmedomDateType;

    nextChargeAmount = 0;
    nextChargeIcon = 'uil uil-question';

    nextIncomeAmount = 0;
    nextIncomeIcon = 'uil uil-question';
    chargePropertyLabel = '--';
    chargePropertyImg: string;
    incomePropertyLabel = '--';
    incomePropertyImg: string;
    propertyIcon = 'uil uil-question';
    nextChargeDate: any = '--';

    nextIncomeDate: any = '--';

    constructor(private readonly propertyService: PropertyService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.charges || changes.incomes) {
            this.updateData();
        }
    }

    getChargeCategoryInfo(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(category as ChargeCategoryProperty);
    }

    getIncomeCategoryInfo(category: string): IncomeCategoryInfo {
        return new IncomeCategoryInfo(category as IncomeCategoryProperty);
    }

    private updateData() {
        const startDate = this.currentDate;
        if (this.charges) {
            const nextCharge = OmedomTreasury.getNextTreasury(
                this.charges,
                startDate.getUTCDateWithoutTime()
            );

            if (nextCharge) {
                this.nextChargeAmount = OmedomTreasury.getMonthAmount(nextCharge, startDate) * -1;

                const chargeDate = nextCharge.nextHistoryDate
                    ? nextCharge.nextHistoryDate
                    : nextCharge.debitDate;
                if (nextCharge.futurPayment) {
                    if (
                        chargeDate.toDate().getMonth() ===
                        nextCharge.futurPayment[0].date.toDate().getMonth()
                    ) {
                        this.nextChargeAmount = nextCharge.futurPayment[0].amount;
                    }
                }

                this.nextChargeDate = formatDate(chargeDate.toDate(), 'dd/MM', 'fr');

                this.nextChargeIcon = this.getChargeCategoryInfo(nextCharge.category).icon;

                this.propertyService
                    .getUserPropertiesByIds([nextCharge.propertyUID], nextCharge.userUID)
                    .then((properties) => {
                        this.propertyIcon = 'uil uil-home';
                        this.chargePropertyLabel = properties[0].name;
                        this.chargePropertyImg = properties[0].photo;
                    });
            }
        }

        if (this.incomes) {
            const nextIncome = OmedomTreasury.getNextTreasury(
                this.incomes,
                startDate.getUTCDateWithoutTime()
            );

            if (nextIncome) {
                this.nextIncomeAmount = OmedomTreasury.getMonthAmount(nextIncome, startDate);

                const incomeDate = nextIncome.nextHistoryDate
                    ? nextIncome.nextHistoryDate
                    : nextIncome.debitDate;
                if (nextIncome.futurPayment) {
                    if (
                        incomeDate.toDate().getMonth() ===
                        nextIncome.futurPayment[0].date.toDate().getMonth()
                    ) {
                        this.nextIncomeAmount = nextIncome.futurPayment[0].amount;
                    }
                }

                this.nextIncomeDate = formatDate(incomeDate.toDate(), 'dd/MM', 'fr');

                this.nextIncomeIcon = this.getIncomeCategoryInfo(nextIncome.category).icon;

                this.propertyService
                    .getUserPropertiesByIds([nextIncome.propertyUID], nextIncome.userUID)
                    .then((properties) => {
                        this.incomePropertyLabel = properties[0].name;
                        this.incomePropertyImg = properties[0].photo;
                    });
            }
        }
    }
}
