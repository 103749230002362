import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-warning-popover',
    templateUrl: './warning-popover.component.html',
    styleUrls: ['./warning-popover.component.scss'],
})
export class WarningPopoverComponent implements OnInit {
    @Input() content: string;

    popoverId: string;

    constructor() {}

    ngOnInit() {
        // Id générée en mode random pour les cas où on a plusieurs popover sur la même page
        this.popoverId = Math.random().toString(36).substring(2);
    }
}
