import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator,
} from '@angular/forms';

@Component({
    selector: 'app-toggle',
    templateUrl: './toggle.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ToggleComponent),
            multi: true,
        },
    ],
})
export class ToggleComponent implements ControlValueAccessor, Validator {
    @Input() label: string;

    value: boolean;

    private onChangeCallback: any;

    private isDisabled: boolean;

    validate(): null {
        return undefined;
    }

    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    registerOnTouched(): void {}

    writeValue(newValue: boolean): void {
        if (newValue !== this.value) {
            this.value = newValue;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        // Ne fonctionne que si c'est du template driven
        this.isDisabled = isDisabled;
    }

    valueChange(value: any) {
        this.writeValue(value);
        this.onChangeCallback(value);
    }
}
