// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item.ion-dirty.ion-invalid,
ion-item.ion-touched.ion-invalid {
  color: #ff5641;
  border: 1.5px solid #ff5641;
  border-radius: 10px;
}
ion-item.ion-dirty.ion-invalid i,
ion-item.ion-touched.ion-invalid i {
  color: #ff5641;
}

sup {
  color: #ff5641;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImlucHV0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBOztFQUVJLGNBQUE7RUFDQSwyQkFBQTtFQUNBLG1CQUFBO0FBQ0o7QUFBSTs7RUFDSSxjQUFBO0FBR1I7O0FBQ0E7RUFDSSxjQUFBO0VBQ0EsZ0JBQUE7QUFFSiIsImZpbGUiOiJpbnB1dC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImlvbi1pdGVtLmlvbi1kaXJ0eS5pb24taW52YWxpZCxcbmlvbi1pdGVtLmlvbi10b3VjaGVkLmlvbi1pbnZhbGlkIHtcbiAgICBjb2xvcjogI2ZmNTY0MTtcbiAgICBib3JkZXI6IDEuNXB4IHNvbGlkICNmZjU2NDE7XG4gICAgYm9yZGVyLXJhZGl1czogMTBweDtcbiAgICBpIHtcbiAgICAgICAgY29sb3I6ICNmZjU2NDE7XG4gICAgfVxufVxuXG5zdXAge1xuICAgIGNvbG9yOiAjZmY1NjQxO1xuICAgIGZvbnQtd2VpZ2h0OiA2MDA7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/component/input/input.component.scss"],"names":[],"mappings":"AAAA;;EAEI,cAAA;EACA,2BAAA;EACA,mBAAA;AACJ;AAAI;;EACI,cAAA;AAGR;;AACA;EACI,cAAA;EACA,gBAAA;AAEJ;AACA,wqBAAwqB","sourcesContent":["ion-item.ion-dirty.ion-invalid,\nion-item.ion-touched.ion-invalid {\n    color: #ff5641;\n    border: 1.5px solid #ff5641;\n    border-radius: 10px;\n    i {\n        color: #ff5641;\n    }\n}\n\nsup {\n    color: #ff5641;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
