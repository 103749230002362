// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}

.modal {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  max-height: 87vh !important;
  box-sizing: border-box;
  overflow-y: auto;
  height: 87vh !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1ib3R0b20tc2hlZXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxjQUFBO0VBQ0EsV0FBQTtBQUNKOztBQUVBO0VBRUksY0FBQTtFQUNBLGlCQUFBO0VBQ0EsU0FBQTtFQUNBLDJCQUFBO0VBQ0Esc0JBQUE7RUFDQSxnQkFBQTtFQUNBLHVCQUFBO0FBQUoiLCJmaWxlIjoic2VsZWN0LWJvdHRvbS1zaGVldC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICB3aWR0aDogMTAwJTtcbn1cblxuLm1vZGFsIHtcbiAgICAvLyBiYWNrZ3JvdW5kOiAjZWVlZWVlO1xuICAgIHBhZGRpbmctdG9wOiAwO1xuICAgIHBhZGRpbmctYm90dG9tOiAwO1xuICAgIG1hcmdpbjogMDtcbiAgICBtYXgtaGVpZ2h0OiA4N3ZoICFpbXBvcnRhbnQ7XG4gICAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgICBvdmVyZmxvdy15OiBhdXRvO1xuICAgIGhlaWdodDogODd2aCAhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./apps/app/src/app/component/select/bottom-sheet/select-bottom-sheet.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;AACJ;;AAEA;EAEI,cAAA;EACA,iBAAA;EACA,SAAA;EACA,2BAAA;EACA,sBAAA;EACA,gBAAA;EACA,uBAAA;AAAJ;AACA,owBAAowB","sourcesContent":[":host {\n    display: block;\n    width: 100%;\n}\n\n.modal {\n    // background: #eeeeee;\n    padding-top: 0;\n    padding-bottom: 0;\n    margin: 0;\n    max-height: 87vh !important;\n    box-sizing: border-box;\n    overflow-y: auto;\n    height: 87vh !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
