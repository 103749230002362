import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AssetCardLayout,
    ChargeEntity,
    IncomeEntity,
    Mode,
    ProEntity,
    PropertyEntity,
    SocietyEntity,
    SocietyType,
    Story,
    UserEntity,
} from '@omedom/data';
import { ChargeService, IncomeService, PropertyService, UserService } from '@omedom/services';
import { OmedomMovement } from '@omedom/utils';
import * as lodash from 'lodash';
import { combineLatest, Subscription } from 'rxjs';

@Component({
    selector: 'omedom-society-card',
    templateUrl: './society-card.component.html',
    styleUrls: ['./society-card.component.scss'],
})
export class SocietyCardComponent implements OnInit, OnDestroy {
    /**
     * @description Society Data
     * @author Jérémie Lopez
     * @type {SocietyEntity}
     * @memberof SocietyCardComponent
     */
    @Input() society?: SocietyEntity;

    /**
     * @description Income from society
     * @author Jérémie Lopez
     * @type {IncomeEntity[]}
     * @memberof SocietyCardComponent
     */
    incomes?: IncomeEntity[];

    /**
     * @description Charges from property
     * @author Hanane Djeddal
     * @type {ChargeEntity[]}
     * @memberof PropertyCardComponent
     */
    charges?: ChargeEntity[];

    properties?: PropertyEntity[];

    /**
     * @description Society types
     * @author Jérémie Lopez
     * @memberof SocietyCardComponent
     */
    types = SocietyType;

    /**
     * @description Date of now
     * @author Jérémie Lopez
     * @memberof SocietyCardComponent
     */
    date = new Date().toUTC();

    /**
     * @description Next mouvement amount
     * @author Hanane Djeddal
     * @type {number}
     * @memberof SocietyCardComponent
     */
    nextTreasury?: number;

    /**
     * @description Show societies in list or card view (list view by default)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof SocietyCardComponent
     * @default true
     */
    @Input() listView = true;

    /**
     * @description
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {boolean}
     * @memberof SocietyCardComponent
     * @default true
     */
    @Input() layout = AssetCardLayout.Default;
    protected assetCardLayout = AssetCardLayout;

    /**
     * @description User data
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @private
     * @type {UserEntity}
     * @memberof SocietyCardComponent
     */
    private user?: UserEntity;

    @Input() showPastAndFutureMovements = true;

    @Input() mode: Mode = Mode.app;

    @Output() societyClick = new EventEmitter();

    allMode = Mode;

    lastMouvement?: Story;

    nextMouvement?: Story;

    subscriptions: Subscription[] = [];

    constructor(
        private incomeService: IncomeService,
        private chargeService: ChargeService,
        private propertyService: PropertyService,
        private userService: UserService
    ) {}

    public get gridTemplateColumns() {
        if (this.mode === this.allMode.desktop) {
            if (this.showPastAndFutureMovements) {
                return 'grid-template-columns: calc(33% - 15px) calc(67% - 5px)';
            } else {
                return 'grid-template-columns: 1fr';
            }
        }
        return '';
    }

    public get isSharedSociety(): boolean {
        return !!this.society && !!this.user && this.society?.userUID !== this.user?.uid;
    }

    async ngOnInit(): Promise<void> {
        if (!this.society) {
            return;
        }

        const charges$ = this.chargeService._getChargesBySociety(this.society.uid);
        const incomes$ = this.incomeService._getIncomesBySociety(this.society.uid);
        const properties$ = this.propertyService._getPropertiesBySociety(this.society.uid);

        if (this.showPastAndFutureMovements) {
            const treasuriesAndProperties$ = combineLatest([
                charges$,
                incomes$,
                properties$,
            ]).subscribe(([charges, incomes, properties]) => {
                this.charges = charges;
                this.incomes = incomes;
                this.properties = properties;
                this.getMouvement();
            });
            this.subscriptions.push(treasuriesAndProperties$);
        }

        const user$ = this.userService.user$.subscribe((user) => (this.user = user));
        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * @description calculate the next and previous mouvements
     * @author Hanane Djeddal
     * @private
     * @memberof PropertyCardComponent
     */
    private getMouvement(): void {
        const properties = this.properties?.filter((x) => x?.societyUID === this.society?.uid);

        const incomes = lodash
            .cloneDeep(this.incomes ?? [])
            .filter(
                (x) =>
                    x.societyUID === this.society?.uid ||
                    properties?.map((p) => p.uid).includes(x.propertyUID ?? '')
            );

        const charges = lodash
            .cloneDeep(this.charges ?? [])
            .filter(
                (x) =>
                    x.societyUID === this.society?.uid ||
                    properties?.map((p) => p.uid).includes(x.propertyUID ?? '')
            );
        const pastStories = OmedomMovement.getPastMovement(
            charges,
            incomes,
            properties ?? [],
            this.date,
            false,
            false,
            this.society ? [{ ...this.society }] : []
        );
        const futureStories = OmedomMovement.getNextMovement(
            charges,
            incomes,
            properties ?? [],
            this.date,
            false,
            false,
            this.society ? [{ ...this.society }] : []
        );
        const lastMouvement = pastStories.filter((h) => h.date <= this.date).slice(-1)[0];
        const nextMouvement = futureStories.filter((h) => h.date >= this.date).slice(0, 1)[0];

        const emptyStory = {
            propertyImg: '',
            propertyName: '',
            uid: '',
            categoryInfo: null,
            periodicityInfo: null,
            amount: null,
            isCharge: true,
            isReaded: false,
            isPayed: false,
            designation: '',
            date: null,
            userUID: '',
        } as any as Story;
        this.lastMouvement = lastMouvement ? lastMouvement : emptyStory;
        this.nextMouvement = nextMouvement ? nextMouvement : emptyStory;
    }

    protected onSocietyClick() {
        this.societyClick.emit();
    }

    /**
     * @description Get the string address of the asset
     * @author Brisset Killian
     * @date 06/06/2024
     * @param {(SocietyEntity )} society
     * @returns {*}  {string}
     * @memberof PropertyCardComponent
     */
    public getStringAddress(society: SocietyEntity): string {
        if (society) {
            const streetNumber = society?.address?.streetNumber
                ? society?.address?.streetNumber + ' '
                : '';
            const street = society?.address?.street ?? '';

            const postalCode = society?.address?.postalCode
                ? society?.address?.postalCode + ' '
                : '';
            const city = society?.address?.city ?? '';

            const addressArray = [
                society?.address?.addressLine2,
                streetNumber + street,
                postalCode + city,
            ];

            return addressArray.filter((x) => x?.replace(/\s/g, '')).join(', ');
        }
        return '';
    }
}
